import React from "react";
import {
  Icon,
  Tabs,
  TabsSize,
  TabsType,
} from "@virtualcapital/utogi-ui-library";
import { hasPermission, permissions } from "@virtualcapital/utogi-auth-library";

import GeneralList from "./GeneralList/GeneralList";
import Layout from "components/layout";
import Permissions from "./permissions";

import { SettingsHeader, SettingsHeaderLabel } from "./settings.styles";

const Setting: React.FC = () => {
  const canUpdateSettings = hasPermission(
    permissions.maps.EDIT_MAPS_OFFICE_SETTINGS
  );

  return (
    <Layout>
      <SettingsHeader>
        <Icon icon="settings" />
        <SettingsHeaderLabel>Settings</SettingsHeaderLabel>
      </SettingsHeader>
      <div className="setting__tabs-contain">
        <Tabs
          type={TabsType.DEFAULT}
          size={TabsSize.DEFAULT}
          items={[
            {
              title: "Office Settings",
              content: (
                <Tabs
                  type={TabsType.DEFAULT}
                  items={[
                    {
                      title: "Permissions",
                      content: <Permissions />,
                    },
                    {
                      title: "Property Data",
                      content: <GeneralList isEditable={canUpdateSettings} />,
                    },
                  ]}
                />
              ),
            },
          ]}
        />
      </div>
    </Layout>
  );
};

export default Setting;
