import { useMutation } from "@apollo/react-hooks";
import { toaster } from "baseui/toast";
import { useEffect, useState } from "react";
import { SAVE_REPLY_TO_DRAFT, SAVE_TO_DRAFT, UPDATE_DRAFT } from "../../query";
import { transformRecipientValues } from "../../../helpers";

export const useAutoSave = ({ draftState, draftMeta, handleError }: any) => {
  const [isFirstRun, setIsFirstRun] = useState<boolean>(true);
  const [timeoutId, setTimeoutId] = useState<null | string>(null);
  const [hasQueuedSave, setHasQueuedSave] = useState(false);

  const [
    callSaveToDraftMutation,
    { data: draftData, loading: draftCreating },
  ] = useMutation(SAVE_TO_DRAFT, {
    onError: handleError,
  });

  const [
    callSaveReplyToDraftMutation,
    { data: replyToDraftData, loading: replyToDraftCreating },
  ] = useMutation(SAVE_REPLY_TO_DRAFT, {
    onError: handleError,
  });

  const [
    callUpdateDraftMutation,
    { data: updatedDraftData, loading: draftUpdating },
  ] = useMutation(UPDATE_DRAFT, {
    onError: handleError,
  });

  const resetTimeout = (id: any, newId: any) => {
    clearTimeout(id);
    setTimeoutId(newId);
  };

  const saveOrUpdateDraft = (draftInput: any) => {
    const {
      to,
      cc,
      bcc,
      body,
      subject,
      files,
      replyTo,
      replyToMessageId,
    } = draftInput;
    const variables: any = {
      to: transformRecipientValues(to),
      cc: transformRecipientValues(cc),
      bcc: transformRecipientValues(bcc),
      body,
      subject,
      files,
    };

    if (draftMeta?.id) {
      const { id } = draftMeta;
      // variables.version = version.toString();
      callUpdateDraftMutation({ variables: { ...variables, id } });
    } else if (replyToMessageId) {
      callSaveReplyToDraftMutation({
        variables: {
          ...variables,
          replyToMessageId,
          replyTo: transformRecipientValues(replyTo)[0],
        },
      });
    } else {
      callSaveToDraftMutation({ variables });
    }
  };

  const autoSaveDraft = (draftValues: any) => {
    const { body, subject } = draftValues;

    if (body && subject)
      resetTimeout(
        timeoutId,
        setTimeout(() => saveOrUpdateDraft(draftValues), 500)
      );
  };

  useEffect(() => {
    if (isFirstRun) {
      setIsFirstRun(false);
    } else {
      const {
        to,
        cc,
        bcc,
        body,
        subject,
        files,
        replyTo,
        replyToMessageId,
      } = draftState;

      if (draftUpdating || draftCreating) {
        setHasQueuedSave(true);
        return;
      }
      autoSaveDraft({
        to,
        cc,
        bcc,
        body,
        subject,
        files,
        replyTo,
        replyToMessageId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftState]);

  useEffect(() => {
    if (!draftUpdating && !draftCreating && hasQueuedSave) {
      const {
        to,
        cc,
        bcc,
        body,
        subject,
        files,
        replyTo,
        replyToMessageId,
      } = draftState;
      autoSaveDraft({
        to,
        cc,
        bcc,
        body,
        subject,
        files,
        replyTo,
        replyToMessageId,
      });
      setHasQueuedSave(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftUpdating, draftCreating, replyToDraftCreating]);

  const onSaveDraft = (data: any) => {
    const { body, subject } = data;

    if (!subject || !body) {
      toaster.negative("subject and body is required", {});
      return;
    }

    saveOrUpdateDraft(data);
  };

  return {
    onSaveDraft,
    draftData: draftData || replyToDraftData,
    updatedDraftData,
    saving: draftUpdating || draftCreating || replyToDraftCreating,
  };
};
