import BaseInput from "components/base-input/base-input";
import React, { useState } from "react";
import { SketchPicker } from "react-color";

import "./ColorPicker.scss";

const ColorPicker = ({
  placeholder,
  onChanged,
  color,
  error,
}: {
  color: string;
  placeholder: string;
  onChanged: Function;
  error?: null | string;
}) => {
  const [isPickerOpen, togglePicker] = useState(false);
  return (
    <div className="color-picker">
      <div className="color-picker-inner">
        <div
          className="color-picker-thumb"
          style={{
            backgroundColor: color,
            borderColor: color && color !== "" ? color : "#e8e8e8",
          }}
          onClick={() => togglePicker(true)}
        />
        <BaseInput
          onFocus={() => togglePicker(true)}
          value={color}
          placeholder={placeholder}
        />
      </div>
      {error !== null && <div className="form-errors">{error}</div>}
      {isPickerOpen && (
        <>
          <SketchPicker
            color={color}
            onChangeComplete={({ hex }) => onChanged(hex)}
          />
          <div
            className="color-picker-bg"
            onClick={() => togglePicker(false)}
          />
        </>
      )}
    </div>
  );
};

export default ColorPicker;
