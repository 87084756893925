import { styled } from "baseui";
import React, { useLayoutEffect, useRef } from "react";
import { RecipientType } from "../../emailEditor";
import EmailPartyField from "../emailPartyField";
import { Recipients } from "../recipients";

const InputField = styled("div", () => ({
  borderBottom: "1px solid #ddd",
  padding: "10px 0 5px 0",
}));

const EmailParties = ({
  to,
  recipientsChanged,
  cc,
  bcc,
  showCc,
  showBcc,
  isComposing,
  autoFocusToField = true,
  onChangeUiState,
}: any) => {
  const toInputRef = useRef(null);

  const parties = [...to, ...cc, ...bcc];

  const handleClickRecipients = () => {
    onChangeUiState({ isComposing: false });
  };

  // Auto focus to field effect
  useLayoutEffect(() => {
    if (toInputRef.current && autoFocusToField) {
      setTimeout(() => {
        // @ts-ignore
        toInputRef?.current && toInputRef.current.click();
      });
    }
  }, [toInputRef, autoFocusToField]);

  return (
    <>
      {isComposing ? (
        <Recipients
          onClick={() => handleClickRecipients()}
          onFocus={() => handleClickRecipients()}
          parties={parties}
        />
      ) : (
        <>
          <InputField>
            <EmailPartyField
              toRef={toInputRef}
              name="To: "
              id="email-to"
              value={to}
              onChange={(change: any) =>
                recipientsChanged(RecipientType.TO, change)
              }
            />
          </InputField>
          {showCc && (
            <InputField>
              <EmailPartyField
                name="Cc: "
                id="email-cc"
                value={cc}
                onChange={(change: any) =>
                  recipientsChanged(RecipientType.CC, change)
                }
              />
            </InputField>
          )}
          {showBcc && (
            <InputField>
              <EmailPartyField
                name="Bcc: "
                id="email-bcc"
                value={bcc}
                onChange={(change: any) =>
                  recipientsChanged(RecipientType.BCC, change)
                }
              />
            </InputField>
          )}
        </>
      )}
    </>
  );
};

export default EmailParties;
