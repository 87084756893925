import React, { useState } from "react";
import { Button } from "baseui/button";
import { StatefulMenu } from "baseui/menu";
import { styled } from "baseui";
import { toaster } from "baseui/toast";

import { MapStatus } from "constants/campaign";

import { getMapStatus } from "helpers/map";
import { getStatusBackgroundColor } from "helpers/status";

type Props = {
  status: MapStatus;
  initialStatus: MapStatus;
  changeStatus: (status: MapStatus) => void;
  canChangeStatusPermission: boolean;
};

const Wrapper = styled("div", () => ({
  position: "relative",
}));

const ChangeStatus: React.FC<Props> = ({
  status,
  changeStatus,
  initialStatus,
  canChangeStatusPermission,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const getBackgroundColor = () => {
    if (isOpen) {
      return "#C4C400";
    }
    return getStatusBackgroundColor(status);
  };

  const onChangeHandler = (status: MapStatus) => {
    setIsOpen(false);
    changeStatus(status);
  };

  const getStatusList = () => {
    switch (initialStatus) {
      case MapStatus.DRAFT:
        return [
          { label: "Active", status: MapStatus.ACTIVE },
          { label: "Delete", status: MapStatus.DELETE },
        ];
      case MapStatus.ACTIVE:
        return [{ label: "Archive", status: MapStatus.ARCHIVE }];
      case MapStatus.ARCHIVE:
        return [{ label: "Delete", status: MapStatus.DELETE }];
      default:
        return [];
    }
  };

  const statusList = getStatusList();

  return (
    <Wrapper>
      <Button
        onClick={() =>
          canChangeStatusPermission
            ? setIsOpen(!isOpen)
            : toaster.negative(
                "You do not have permission to change status.",
                {}
              )
        }
        disabled={statusList.length === 0}
        overrides={{
          BaseButton: {
            style: () => ({
              backgroundColor: getBackgroundColor(),
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
              width: "160px",
              ":hover": {
                backgroundColor: getBackgroundColor(),
                boxShadow: `0 3px 6px ${getBackgroundColor()}80`,
              },
            }),
          },
        }}
      >
        {isOpen ? "Select Status" : getMapStatus(status)}
      </Button>
      {isOpen && (
        <StatefulMenu
          items={statusList}
          onItemSelect={({ item }) => onChangeHandler(item.status)}
          overrides={{
            List: {
              style: () => ({
                position: "absolute",
                width: "160px",
                zIndex: 2,
              }),
            },
          }}
        />
      )}
    </Wrapper>
  );
};

export default ChangeStatus;
