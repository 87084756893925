import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import _ from "lodash";

import AddTagsAndLayers from "./AddTagsAndLayers";

import { PropertySelectionType } from "constants/property";

import { useMap } from "context/map/use-map";
import { Coordinate } from "context/map/map.types";

import { Property } from "interfaces/property";
import { MapRolePermissions } from "../../../types/permissions";
import { canAccessContacts } from "../../../helpers/permission/common";

export type AddTo = {
  selectionType: PropertySelectionType;
  selectedArea: Coordinate[];
  properties: Property[];
};

type Props = {
  addTo: AddTo;
  onClose: Function;
  permissions: MapRolePermissions;
};

const GET_CONTACT_LIST = gql`
  query GetContactListQuery($properties: [PropertyInputType]!) {
    contact {
      propertyContact(properties: $properties) {
        id
        firstName
        lastName
        mobileNumber
        landline
        email
        occupancyStatus
      }
    }
  }
`;

const GET_CONTACTS_BY_AREA = gql`
  query GetContactListByAreaQuery($selectedArea: [PointerValue]) {
    contact {
      contactInBoundary(selectedArea: $selectedArea) {
        id
        firstName
        lastName
      }
    }
  }
`;

const GET_FILTERS = gql`
  query($campaign: String!) {
    prospect {
      filters(campaign: $campaign) {
        layers {
          id
          name
          type {
            id
            title
          }
          primaryColor
          secondaryColor
          addFade
          addTransition
          transitionTime
          fadeTime
        }
        tags {
          id
          name
        }
      }
    }
  }
`;

const AddTagsAndLayersContainer = ({ addTo, onClose, permissions }: Props) => {
  const [contactList, setContactList] = useState<any>([]);
  const { properties, selectionType, selectedArea } = addTo;
  const { selectedMaps } = useMap();
  const hasContactPermission = canAccessContacts();

  const campaign = selectedMaps !== null ? selectedMaps[0].id : "";

  const { loading } = useQuery(GET_CONTACT_LIST, {
    variables: { properties },
    onCompleted: (data) => {
      setContactList(_.uniqBy(data.contact.propertyContact, "id"));
    },
    skip: !(
      hasContactPermission &&
      selectionType !== PropertySelectionType.AREA &&
      properties.length > 0
    ),
  });

  const { loading: multipleContactLoading } = useQuery(GET_CONTACTS_BY_AREA, {
    variables: { selectedArea },
    onCompleted: (data) => {
      setContactList(_.uniqBy(data.contact.contactInBoundary, "id"));
    },
    skip: !(
      hasContactPermission &&
      selectionType === PropertySelectionType.AREA &&
      selectedArea.length > 0
    ),
  });

  const { loading: loadingLayersAndTags, data } = useQuery(GET_FILTERS, {
    variables: {
      campaign,
    },
    fetchPolicy: "no-cache",
  });

  return (
    <AddTagsAndLayers
      contactList={contactList}
      contactLoading={loading || multipleContactLoading}
      addTo={addTo}
      onClose={onClose}
      layers={data?.prospect?.filters?.layers || []}
      tags={data?.prospect?.filters?.tags || []}
      isLoading={loadingLayersAndTags}
      permissions={permissions}
    />
  );
};

export default AddTagsAndLayersContainer;
