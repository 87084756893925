import { styled } from "baseui";
import { Input } from "baseui/input";
import React from "react";

const InputField = styled("div", () => ({
  borderBottom: "1px solid #ddd",
  padding: "10px 0 5px 0",
}));

const EmailRecipients = styled("span", () => ({
  fontSize: "14px",
}));

export const Recipients = ({ onClick, parties, onFocus }: any) => {
  return (
    <InputField onClick={onClick}>
      {parties.length ? (
        <EmailRecipients>
          {parties.map((party: any) => party.email).join(", ")}
        </EmailRecipients>
      ) : (
        <Input
          placeholder="Recipients"
          onFocus={onFocus}
          overrides={{
            Root: {
              style: () => ({
                border: "none",
              }),
            },
            Input: {
              style: () => ({
                backgroundColor: "#ffffff",
                fontSize: "12px",
                padding: "2px",
              }),
            },
          }}
        />
      )}
    </InputField>
  );
};

export default Recipients;
