import { useQuery } from "@apollo/react-hooks";
import { Icon, Scrollbars } from "@virtualcapital/utogi-ui-library";
import { gql } from "apollo-boost";
import { styled, useStyletron } from "baseui";
import { Button, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import ComposeEmail from "../email/components/compose";
import { EmailSignatureContext } from "context/email-signature-context/email-signature-context";
import moment from "moment";
import React, { useContext, useState } from "react";
import config from "config";
import { ComposerMode, ComposerStates } from "../contants";
import { getForwardBody, getReplyBody } from "../helpers";

const Container = styled("div", () => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "space-between",
}));

const ActionButtonContainer = styled("div", () => ({
  display: "flex",
  justifyContent: "flex-start",
}));

const EmailBodyMessage = styled("div", () => ({
  display: "flex",
  flexDirection: "column",
  fontSize: "12px",
}));

const ComposerContainer = styled("div", ({ $active }: any) => ({
  minHeight: "500px",
  transition:
    "border 0.15s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1)",
  borderRadius: "8px",
  padding: "12px 16px",
  margin: "27px",
  display: "flex",
  border: $active ? "1px solid transparent" : "1px solid #dadce0",
  boxShadow: $active
    ? "0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 2px 6px 2px rgba(60, 64, 67, 0.149)"
    : "none",
  ":hover": {
    boxShadow:
      "0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 2px 6px 2px rgba(60, 64, 67, 0.149)",
    border: "1px solid transparent",
  },
}));

const ModalContainer = styled("div", () => ({
  borderBottom: "1px solid #ececec",
  marginBottom: "10px",
  paddingBottom: "10px",
}));

const ProfileContainer = styled("div", () => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontSize: "14px",
  paddingBottom: "10px",
}));

const ProfileRow = styled("div", () => ({
  display: "flex",
  alignItems: "center",
}));

const ProfileIcon = styled("span", () => ({
  backgroundColor: "#f0f8ff",
  color: "#63c9ef",
  borderRadius: "50%",
  padding: "6px",
  lineHeight: 1,
  marginRight: "10px",
  fontSize: "12px",
}));

const GET_EMAIL_VIEW_LIST = gql`
  query($thread: String!) {
    email {
      viewThread(thread: $thread) {
        id
        body
        from {
          name
          email
        }
        date
        to {
          name
          email
        }
        cc {
          name
          email
        }
        subject
        files {
          id
          account
          contentType
          filename
          object
          size
        }
        parsedHtml
      }
    }
  }
`;

type Props = {
  reference: string;
};

const EmailModal: React.FC<Props> = ({ reference: thread }) => {
  const [composerMode, setComposerMode] = useState(ComposerMode.REPLY);
  const [composerState, setComposerState] = useState(ComposerStates.HIDDEN);

  const { signature, loading: signatureLoading } = useContext(
    EmailSignatureContext
  );

  const { data, loading, refetch } = useQuery(GET_EMAIL_VIEW_LIST, {
    variables: { thread },
  });
  const [css] = useStyletron();

  const Loader = () => (
    <div>
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
        })}
      >
        <Skeleton
          animation={true}
          height={"24px"}
          width={"24px"}
          overrides={{
            Root: { style: { borderRadius: "50%", marginRight: "10px" } },
          }}
        />
        <Skeleton animation={true} height={"24px"} width={"100%"} />
      </div>
      <Skeleton
        animation={true}
        height={"100px"}
        width={"100%"}
        overrides={{ Root: { style: { marginBottom: "10px" } } }}
      />
    </div>
  );

  const handleOnClickForwardEmail = () => {
    setComposerState(ComposerStates.BLURRED);
    setComposerMode(ComposerMode.FORWARD);
  };

  const handleOnClickReplyToAll = () => {
    setComposerState(ComposerStates.BLURRED);
    setComposerMode(ComposerMode.REPLY_TO_ALL);
  };

  const handleOnClickReply = () => {
    setComposerState(ComposerStates.BLURRED);
    setComposerMode(ComposerMode.REPLY);
  };

  const handleOnEditorFocused = () => {
    setComposerState(ComposerStates.FOCUSED);
  };

  const handleOnEditorBlurred = () => {
    setComposerState(ComposerStates.BLURRED);
  };

  const handleOnDelete = () => {
    setComposerState(ComposerStates.HIDDEN);
  };

  const getForwardEmailBody = (thread: any) => {
    return getForwardBody({
      fromName: thread.from[0]?.name,
      fromEmail: thread.from[0]?.email,
      toName: thread.to[0]?.name,
      toEmail: thread.to[0]?.email,
      date: thread.date,
      subject: thread.subject,
      body: JSON.parse(thread.parsedHtml),
    });
  };

  const getReplyEmailBody = (thread: any) => {
    return getReplyBody({
      name: thread.from[0]?.name,
      email: thread.from[0]?.email,
      body: JSON.parse(thread.parsedHtml),
    });
  };

  const handleOnDraftSent = () => {
    setComposerState(ComposerStates.HIDDEN);
    refetch();
  };

  return (
    <Container>
      {loading ? (
        <div>
          <Loader />
          <Loader />
        </div>
      ) : (
        <Scrollbars noScrollX style={{ height: "calc(100vh - 90px)" }}>
          {data?.email?.viewThread?.map(
            ({
              body,
              id,
              from,
              date,
              cc,
              to,
              subject,
              files,
              parsedHtml,
            }: any) => (
              <ModalContainer key={id}>
                <ProfileContainer>
                  <ProfileRow>
                    <ProfileIcon>
                      <Icon icon="profile" />
                    </ProfileIcon>
                    {from[0].name}
                  </ProfileRow>
                  {moment(new Date(parseInt(date, 10))).format("DD MMMM YYYY")}
                </ProfileContainer>
                <div dangerouslySetInnerHTML={{ __html: body }} />
                <div>
                  {composerState === ComposerStates.HIDDEN && (
                    <ActionButtonContainer>
                      <Button
                        onClick={handleOnClickReply}
                        size={SIZE.compact}
                        overrides={{
                          BaseButton: {
                            style: {
                              marginRight: "10px",
                              width: "100px",
                            },
                          },
                        }}
                      >
                        <Icon icon="chevron-left" /> {" Reply"}
                      </Button>
                      {cc && cc.length > 0 && (
                        <Button
                          onClick={handleOnClickReplyToAll}
                          size={SIZE.compact}
                          overrides={{
                            BaseButton: {
                              style: {
                                marginRight: "10px",
                                minWidth: "120px",
                              },
                            },
                          }}
                        >
                          <Icon icon="chevron-left" />
                          <Icon icon="chevron-left" /> {" Reply to all"}
                        </Button>
                      )}
                      <Button
                        onClick={handleOnClickForwardEmail}
                        size={SIZE.compact}
                        overrides={{
                          BaseButton: {
                            style: {
                              width: "100px",
                            },
                          },
                        }}
                      >
                        <Icon icon="chevron-right" /> {" Forward"}
                      </Button>
                    </ActionButtonContainer>
                  )}
                  <EmailBodyMessage>
                    {composerState !== ComposerStates.HIDDEN && (
                      <ComposerContainer
                        $active={composerState === ComposerStates.FOCUSED}
                      >
                        {!signatureLoading && (
                          <ComposeEmail
                            onFocus={handleOnEditorFocused}
                            onBlur={handleOnEditorBlurred}
                            replyTo={to}
                            replyToMessageId={id}
                            onDelete={handleOnDelete}
                            composeMode={composerMode}
                            draft={{
                              to:
                                composerMode === ComposerMode.FORWARD ? [] : to,
                              cc:
                                composerMode === ComposerMode.REPLY_TO_ALL
                                  ? cc
                                  : [],
                              subject:
                                composerMode === ComposerMode.FORWARD
                                  ? `Fwd: ${subject}`
                                  : `Re: ${subject}`,
                              body:
                                composerMode === ComposerMode.FORWARD
                                  ? `${
                                      signature?.body || ""
                                    } ${getForwardEmailBody({
                                      body,
                                      id,
                                      from,
                                      date,
                                      to,
                                      subject,
                                      parsedHtml,
                                    })}`
                                  : `${
                                      signature?.body || ""
                                    } ${getReplyEmailBody({
                                      from,
                                      parsedHtml,
                                    })}`,
                              files:
                                composerMode === ComposerMode.FORWARD
                                  ? files
                                  : [],
                            }}
                            onSent={handleOnDraftSent}
                          />
                        )}
                      </ComposerContainer>
                    )}
                  </EmailBodyMessage>
                </div>
              </ModalContainer>
            )
          )}
          <Button
            $as="a"
            target="_blank"
            size={SIZE.default}
            overrides={{
              BaseButton: {
                style: {
                  color: "#00aeef",
                  backgroundColor: "#d2f7ff",
                  borderRadius: "10px",
                  fontSize: "12px",
                  ":hover": {
                    color: "#00aeef",
                    backgroundColor: "#a6efff",
                  },
                },
              },
            }}
            href={`${config.DOMAIN_PROTOCOL}email.${config.DOMAIN_SUFFIX}/sent/${thread}`}
          >
            Open in Email
          </Button>
        </Scrollbars>
      )}
    </Container>
  );
};

export default EmailModal;
