import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "baseui/icon";

import { IconButton } from "@virtualcapital/utogi-ui-library";
import PermissionList from "./PermissionsList";
import ManageRole from "./ManageRole";

import {
  PermissionContainer,
  PermissionContent,
  PermissionGroupHeader,
  PermissionGroupHeaderTitle,
  ToggleButton,
} from "./styles";

type Props = {
  title: string;
  canEdit: boolean;
  id: string;
  permissions: any;
  refetch: Function;
};

const PermissionGroup: React.FC<Props> = ({
  title,
  canEdit,
  id,
  permissions,
  refetch,
}) => {
  const [isOpen, toggleIsOpen] = useState(false);
  const [isOnEdit, toggleIsOnEdit] = useState(false);
  const onEditHandler = () => {
    if (!isOpen) {
      toggleIsOpen(true);
    }
    toggleIsOnEdit(true);
  };
  const onSuccess = async () => {
    await refetch();
    toggleIsOnEdit(!isOnEdit);
  };
  return (
    <PermissionContainer>
      <PermissionGroupHeader>
        <div>
          <PermissionGroupHeaderTitle onClick={() => toggleIsOpen(!isOpen)}>
            {title}
          </PermissionGroupHeaderTitle>
          {canEdit && !isOnEdit && (
            <IconButton icon="edit" onClick={() => onEditHandler()} />
          )}
        </div>
        <ToggleButton onClick={() => toggleIsOpen(!isOpen)}>
          {isOpen ? <ChevronUp /> : <ChevronDown />}
        </ToggleButton>
      </PermissionGroupHeader>
      {isOpen && (
        <PermissionContent>
          {isOnEdit ? (
            <ManageRole
              name={title}
              id={id}
              permissions={permissions}
              onSuccess={onSuccess}
            />
          ) : (
            <PermissionList values={permissions} />
          )}
        </PermissionContent>
      )}
    </PermissionContainer>
  );
};

export default PermissionGroup;
