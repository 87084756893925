import { styled } from "baseui";
import { Skeleton } from "baseui/skeleton";
import React from "react";

const Container = styled("div", () => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "space-between",
}));

const FormContainer = styled("div", () => ({
  display: "flex",
}));

const FormDateContainer = styled("div", () => ({
  paddingRight: "15px",
}));

const ReminderLoader = () => {
  return (
    <Container>
      <div>
        <FormContainer>
          <FormDateContainer>
            <Skeleton animation width="174px" height="80px" />
          </FormDateContainer>
          <FormDateContainer>
            <Skeleton animation width="99px" height="80px" />
          </FormDateContainer>
          <div>
            <Skeleton animation width="54px" height="80px" />
          </div>
        </FormContainer>
        <Skeleton
          animation
          width="100%"
          height="44px"
          overrides={{
            Root: { style: { marginBottom: "16px", marginTop: "16px" } },
          }}
        />
        <Skeleton
          animation
          width="100%"
          height="220px"
          overrides={{
            Root: { style: { marginBottom: "16px" } },
          }}
        />
        <Skeleton
          animation
          width="100%"
          height="72px"
          overrides={{
            Root: { style: { marginBottom: "16px" } },
          }}
        />
        <Skeleton
          animation
          width="100%"
          height="72px"
          overrides={{
            Root: { style: { marginBottom: "16px" } },
          }}
        />
        <Skeleton
          animation
          width="100%"
          height="72px"
          overrides={{
            Root: { style: { marginBottom: "16px" } },
          }}
        />
      </div>
      <Skeleton animation width="100%" height="40px" />
    </Container>
  );
};

export default ReminderLoader;
