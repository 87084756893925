import React from "react";
import { Icon } from "@virtualcapital/utogi-ui-library";
import { Link } from "react-router-dom";
import moment from "moment";
import { gql, useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { KIND } from "baseui/button";
import { useStyletron } from "baseui";

import Button from "components/Button";

import { ICampaignTemplate } from "interfaces/campaignTemplate";

import "./campaignTemplateListItem.scss";

type Props = ICampaignTemplate & { deleteClicked: (id: string) => void };

const CREATE_CAMPAIGN_FROM_TEMPLATE = gql`
  mutation($id: ID!) {
    campaignTemplate {
      createCampaignFromTemplate(id: $id) {
        id
      }
    }
  }
`;

const CampaignTemplateListItem = ({
  id,
  name,
  description,
  createdUser: { name: createdBy, id: createdUser },
  lastEditBy,
  history,
  deleteClicked,
}: Props & RouteComponentProps) => {
  const [css] = useStyletron();

  const [createCampaignFromTemplate, { loading }] = useMutation(
    CREATE_CAMPAIGN_FROM_TEMPLATE,
    {
      onError: () => {},
      onCompleted: (data) => {
        if (
          data &&
          data.campaignTemplate &&
          data.campaignTemplate.createCampaignFromTemplate
        )
          history.push(
            `/maps/view/${data.campaignTemplate.createCampaignFromTemplate.id}`
          );
      },
    }
  );

  return (
    <div className="campaign-template-list-item box">
      <div className="campaign-template-list-item-container">
        <div className="campaign-template-list-item-header">
          <Link to={`template/view/${id}`}>{name}</Link>
        </div>
        <div className="campaign-template-list-item-description">
          {description}
        </div>
        <div className="campaign-template-list-item-meta">
          <span className="user">Created by {createdBy}</span>
          <span>
            {lastEditBy &&
              `Updated: ${moment(new Date(lastEditBy.time)).format(
                "DD MMMM YYYY"
              )}`}
          </span>
        </div>
        <div className="campaign-template-list-item-actions">
          <Link
            to={`/template/view/${id}`}
            className={css({
              color: "#00aeef",
              backgroundColor: "#d2f7ff",
              marginRight: "5px",
              padding: "8px 10px",
              borderRadius: "5px",
              marginBottom: "5px",
              ":hover": {
                color: "#00aeef",
                backgroundColor: "#a6efff",
              },
            })}
          >
            <Icon icon="edit" />
          </Link>
          <Button
            kind={KIND.tertiary}
            onClick={() => deleteClicked(id)}
            overrides={{
              BaseButton: {
                style: {
                  color: "#ff0000",
                  backgroundColor: "#f6d8d8",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  marginBottom: "5px",
                  ":hover": {
                    color: "#ff0000",
                    backgroundColor: "#fbb9b9",
                  },
                },
              },
            }}
          >
            <Icon icon="delete" />
          </Button>
          <br />
          <Button
            onClick={() => createCampaignFromTemplate({ variables: { id } })}
            isLoading={loading}
          >
            Create Map
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CampaignTemplateListItem);
