import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@virtualcapital/utogi-ui-library";
import { useStyletron } from "baseui";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { toaster } from "baseui/toast";
import { hasPermission, permissions } from "@virtualcapital/utogi-auth-library";
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip";

const currentMapPath = "/maps/selected";

const Sidebar: React.FC<RouteComponentProps> = ({ location: { pathname } }) => {
  const [css] = useStyletron();
  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef<any>(null);

  const links = [
    {
      label: "Current Map",
      icon: "satellite",
      link: currentMapPath,
      exact: true,
    },
    {
      label: "Maps",
      icon: "marketing",
      link: "/maps",
      exact: false,
    },
    {
      label: "Templates",
      icon: "styling",
      link: "/template",
      exact: false,
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const getNavStyles = (isActive: boolean, link: string): any =>
    css({
      color:
        (isActive && pathname !== currentMapPath) ||
        (pathname === currentMapPath && link === currentMapPath)
          ? "#005474"
          : "#ffffff",
      padding: "20px",
      display: "flex",
      fontSize: "15px",
      borderLeft: `5px solid ${
        (isActive && pathname !== currentMapPath) ||
        (pathname === currentMapPath && link === currentMapPath)
          ? "#005474"
          : "#00aeef"
      }`,
      textDecoration: "none",
      alignItems: "center",
      ...(isOpen && {
        color: "#01aeef",
        borderLeftColor:
          (isActive && pathname !== currentMapPath) ||
          (pathname === currentMapPath && link === currentMapPath)
            ? "#01aeef"
            : "#ffffff",
      }),
    });

  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={css({
          background: "none",
          border: "none",
          display: "none",
          position: "fixed",
          backgroundColor: "#01adf9",
          color: "#ffffff",
          padding: "8px 8px 8px 16px",
          zIndex: 3,
          borderRadius: " 0 50% 50% 0",
          left: "-12px",
          top: "75px",
          boxShadow: "#00000016 0px 3px 6px",
          fontSize: "10px",
          "@media (max-width: 768px)": {
            display: isOpen ? "none" : "block",
          },
        })}
      >
        <Icon icon="chevron-right" />
      </button>
      <div
        className={css({
          backgroundColor: "#00aeef",
          borderRadius: "0 40px 0 0",
          height: "calc(100vh - 75px)",
          paddingTop: "40px",
          position: "fixed",
          bottom: 0,
          ...(isOpen && {
            zIndex: 2,
            backgroundColor: "#fff",
            borderRight: "50px solid #01aeef",
          }),
          "@media (max-width: 768px)": {
            left: isOpen ? 0 : "-100%",
            top: 0,
            height: "100%",
            width: "100%",
            borderRadius: 0,
          },
        })}
        ref={wrapperRef}
      >
        <div
          className={css({
            position: "relative",
          })}
        >
          <button
            onClick={() => setIsOpen(!isOpen)}
            className={css({
              background: "none",
              border: "none",
              color: "#ffffff",
              lineHeight: "43px",
              ...(isOpen
                ? {
                    position: "absolute",
                    right: "-40px",
                    top: 0,
                  }
                : {
                    padding: "0 25px",
                    marginBottom: "10px",
                  }),
            })}
          >
            {isOpen ? (
              <Icon icon="chevron-left" />
            ) : (
              <Icon icon="chevron-right" />
            )}
          </button>
          {links.map(({ label, link, icon, exact }) => (
            <NavLink
              onClick={() => setIsOpen(false)}
              to={link}
              exact={exact}
              className={(isActive) => getNavStyles(isActive, link)}
            >
              {isOpen ? (
                <Icon icon={icon} />
              ) : (
                <StatefulTooltip
                  content={() => <div>{label}</div>}
                  placement={PLACEMENT.left}
                  popoverMargin={10}
                >
                  <div>
                    <Icon icon={icon} />
                  </div>
                </StatefulTooltip>
              )}
              {isOpen && (
                <div className={css({ paddingLeft: "10px" })}>{label}</div>
              )}
            </NavLink>
          ))}
          {hasPermission(permissions.maps.EDIT_MAPS_OFFICE_SETTINGS) ? (
            <NavLink
              to="/settings"
              className={(isActive) => getNavStyles(isActive, "/settings")}
            >
              {isOpen ? (
                <Icon icon="settings" className={css({ fontSize: "20px" })} />
              ) : (
                <StatefulTooltip
                  content={() => <div>Setting</div>}
                  placement={PLACEMENT.left}
                  popoverMargin={10}
                >
                  <div>
                    <Icon
                      icon="settings"
                      className={css({ fontSize: "20px" })}
                    />
                  </div>
                </StatefulTooltip>
              )}
              {isOpen && (
                <div className={css({ paddingLeft: "10px" })}>Setting</div>
              )}
            </NavLink>
          ) : (
            <div
              className={css({
                color: "#ffffff",
                padding: "20px",
                display: "flex",
                fontSize: "15px",
                borderLeft: `5px solid #00aeef`,
                textDecoration: "none",
                alignItems: "center",
                ...(isOpen && {
                  color: "#01aeef",
                  borderLeftColor: "#ffffff",
                }),
              })}
              onClick={() =>
                toaster.negative(
                  "You don’t have the permission to access the settings page",
                  {}
                )
              }
            >
              <Icon icon="settings" className={css({ fontSize: "20px" })} />
              {isOpen && (
                <div className={css({ paddingLeft: "10px" })}>Setting</div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(Sidebar);
