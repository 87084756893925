import { MapRolePermissions } from "../../types/permissions";
import { canEditOtherUsersCampaign } from "./common";
import { MapPermission } from "../../constants/permissions";
import {
  hasPermission,
  permissions as modulePermission,
} from "@virtualcapital/utogi-auth-library";

export const canAddLayers = (permissions: MapRolePermissions): boolean =>
  canEditOtherUsersCampaign() ||
  (permissions && permissions[MapPermission.ADD_LAYERS]);

export const canAddTags = (permissions: MapRolePermissions): boolean =>
  canEditOtherUsersCampaign() ||
  (permissions && permissions[MapPermission.ADD_TAGS]);

export const canAddNotes = (permissions: MapRolePermissions): boolean =>
  canEditOtherUsersCampaign() ||
  (permissions && permissions[MapPermission.ADD_NOTES]);

export const canAccessManagerView = (
  isAllMapMode: boolean,
  permissions: MapRolePermissions
): boolean =>
  isAllMapMode
    ? hasPermission(modulePermission.maps.VIEW_TEAM_VIEW)
    : hasPermission(modulePermission.maps.VIEW_TEAM_VIEW) ||
      (permissions && permissions[MapPermission.VIEW_TEAM_VIEW]);
