import { MapRolePermissions } from "../../types/permissions";
import { canEditOtherUsersCampaign } from "./common";
import { MapPermission } from "../../constants/permissions";
import { hasPermission, permissions } from "@virtualcapital/utogi-auth-library";

export const canInviteUsers = (permissions: MapRolePermissions): boolean =>
  canEditOtherUsersCampaign() ||
  (permissions && permissions[MapPermission.INVITE_OTHER_USERS]);

export const canSetUpMap = (permissions: MapRolePermissions): boolean =>
  canEditOtherUsersCampaign() ||
  (permissions && permissions[MapPermission.EDIT_MAP_SETUP]);

export const canChangeStatus = (permissions: MapRolePermissions): boolean =>
  canEditOtherUsersCampaign() ||
  (permissions && permissions[MapPermission.UPDATE_THE_STATUS_OF_THE_MAP]);

export const canUpdateTheOwner = (permissions?: MapRolePermissions): boolean =>
  canEditOtherUsersCampaign() ||
  (permissions !== undefined && permissions[MapPermission.UPDATE_THE_OWNER]);

export const canBeTheOwner = () =>
  canEditOtherUsersCampaign() ||
  !hasPermission(
    permissions.maps.RESTRICT_THIS_USER_ROLE_FROM_BEING_A_MAP_OWNER
  );
