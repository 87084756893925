import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { ApolloError, gql } from "apollo-boost";
import { styled } from "baseui";
import { FormControl } from "baseui/form-control";
import { Select } from "baseui/select";
import { Textarea } from "baseui/textarea";
import { toaster } from "baseui/toast";
import React, { useEffect, useState } from "react";
import { DatePicker } from "baseui/datepicker";
import moment from "moment";

import ContactSearch, { SelectedContacts } from "./ContactSearch";
import NoteLoader from "./loader/NoteLoader";
import { GET_PROPERTY_CONTACTS, GET_SELECTED_CONTACTS } from "./query";
import { Contact } from "../../../../types/contact";
import SaveButton from "../../../../components/SaveButton";
import { PropertySelectionType } from "constants/property";
import { IPropertyIdentifier } from "../interface";

const Container = styled("div", () => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "space-between",
}));

const GET_NOTE = gql`
  query GetNote($id: ID!) {
    note {
      get(id: $id) {
        note
        contacts
        accessLevel
        date
      }
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation(
    $note: String!
    $contacts: [ID]
    $accessLevel: NoteAccessLevel!
    $selectionType: String
    $selectedArea: [GraphQLCoordinateInput]
    $properties: [PropertyInputType]
    $campaign: ID
    $date: String
  ) {
    note {
      create(
        note: $note
        contacts: $contacts
        accessLevel: $accessLevel
        selectionType: $selectionType
        selectedArea: $selectedArea
        properties: $properties
        campaign: $campaign
        date: $date
      ) {
        message
        success
      }
    }
  }
`;

const EDIT_NOTE = gql`
  mutation(
    $id: ID!
    $note: String
    $contacts: [String]
    $accessLevel: NoteAccessLevel
    $date: String
  ) {
    note {
      edit(
        id: $id
        note: $note
        date: $date
        contacts: $contacts
        accessLevel: $accessLevel
      ) {
        id
      }
    }
  }
`;

type Props = {
  onCompleted: Function;
  reference?: null | string;
  selectionType?: PropertySelectionType;
  selectedArea?: [];
  campaign?: string | null;
  properties?: IPropertyIdentifier[];
  contacts?: string[];
};

const accessLevels = [
  { id: "COMPANY", label: "Company" },
  { id: "OFFICE", label: "Office" },
  { id: "PRIVATE", label: "Private" },
];

const NoteModal: React.FC<Props> = ({
  onCompleted,
  reference,
  campaign,
  properties,
  selectedArea,
  selectionType,
  contacts,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [note, setNote] = useState("");
  const [accessLevel, setAccessLevel] = useState([accessLevels[2]]);
  const [noteError, setNoteError] = useState<null | string>(null);
  const [contactError, setContactError] = useState<null | string>(null);
  const [invitedContacts, setInvitedContacts] = useState<SelectedContacts[]>(
    []
  );
  const [date, setDate] = useState([new Date()]);

  const [noteMutation, { loading }] = useMutation(
    reference ? EDIT_NOTE : CREATE_NOTE,
    {
      onCompleted: (data) => {
        if (data?.note?.create?.success) {
          onCompleted();
        }
        if (data?.note?.edit?.id) {
          onCompleted();
        }
      },
      onError: ({ graphQLErrors }: ApolloError) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message }) => toaster.negative(message, {}));
        }
      },
    }
  );

  const [getNote] = useLazyQuery(GET_NOTE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.note?.get) {
        const { note, contacts, accessLevel, date: noteDate } = data.note.get;
        if (note) setNote(note);
        if (accessLevel)
          setAccessLevel(accessLevels.filter(({ id }) => id === accessLevel));
        if (contacts && contacts.length > 0) {
          getSelectedContacts({ variables: { ids: contacts } });
        } else {
          setIsLoading(false);
        }
        if (noteDate) {
          setDate([new Date(noteDate)]);
        }
      }
    },
  });

  const [getSelectedContacts] = useLazyQuery(GET_SELECTED_CONTACTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.contact?.list?.contacts) {
        setInvitedContacts(
          data?.contact?.list?.contacts.map(
            ({ id, displayName, firstName, lastName }: Contact) => ({
              label: displayName || `${firstName} ${lastName}`,
              id,
            })
          )
        );
        setIsLoading(false);
      }
    },
  });

  const [getPropertyContacts] = useLazyQuery(GET_PROPERTY_CONTACTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.contact?.propertyContact?.length > 0) {
        setInvitedContacts(
          data.contact.propertyContact.map(
            ({ id, displayName, firstName, lastName }: Contact) => ({
              label: displayName || `${firstName} ${lastName}`,
              id,
            })
          )
        );
      }
      setIsLoading(false);
    },
  });
  useEffect(() => {
    if (reference) {
      getNote({ variables: { id: reference } });
    } else if (selectionType === PropertySelectionType.ID && properties) {
      getPropertyContacts({ variables: { properties } });
    } else if (selectionType === PropertySelectionType.AREA) {
      setIsLoading(false);
    } else if (contacts && contacts.length > 0) {
      getSelectedContacts({ variables: { ids: contacts } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNoteAdded = () => {
    if (note.trim().length === 0) {
      setNoteError("Description is required.");
      return;
    } else if (note.trim().length > 4000) {
      setNoteError("Maximum 4000 characters.");
      return;
    }
    if (
      selectionType === PropertySelectionType.ID &&
      invitedContacts.length === 0
    ) {
      setContactError("At least one contact is required.");
      return;
    }
    setNoteError(null);
    setContactError(null);
    noteMutation({
      variables: {
        ...(reference && { id: reference }),
        ...(properties && { properties: properties || [] }),
        ...(selectedArea && { selectedArea }),
        ...(selectionType && { selectionType }),
        ...(selectionType && { selectionType }),
        ...(campaign && { campaign }),
        note,
        accessLevel: accessLevel[0].id,
        contacts: invitedContacts.map(({ id }) => id),
        date: moment(new Date(date[0] || new Date())).format("YYYY-MM-DD"),
      },
    });
  };

  if (isLoading) {
    return <NoteLoader />;
  }

  return (
    <Container>
      <div>
        <FormControl caption={`${note.length}/4000`} error={noteError}>
          <Textarea
            placeholder="Enter Description"
            value={note}
            onChange={(event) => setNote(event.currentTarget.value)}
            rows={10}
            error={note.length > 4000 || noteError !== null}
          />
        </FormControl>
        <FormControl label="Note Date">
          <DatePicker
            formatString="dd/MM/yyyy"
            placeholder="DD/MM/YYYY"
            value={date}
            onChange={({ date }) =>
              setDate(Array.isArray(date) ? date : [date])
            }
            maxDate={new Date()}
            autoFocusCalendar={false}
          />
        </FormControl>
        {selectionType !== PropertySelectionType.AREA && (
          <FormControl label="Contacts" error={contactError}>
            <ContactSearch
              invitedContacts={invitedContacts}
              setContacts={setInvitedContacts}
            />
          </FormControl>
        )}
        <FormControl label="Note Privacy">
          <Select
            value={accessLevel}
            onChange={({ value }: any) => setAccessLevel(value)}
            options={[
              { id: "COMPANY", label: "Company" },
              { id: "MAP", label: "Map" },
              { id: "OFFICE", label: "Office" },
              { id: "PRIVATE", label: "Private" },
            ]}
            clearable={false}
          />
        </FormControl>
      </div>
      <SaveButton onClick={onNoteAdded} isLoading={loading} />
    </Container>
  );
};

export default NoteModal;
