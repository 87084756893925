import React, { useEffect, useState } from "react";
import { Scrollbars } from "@virtualcapital/utogi-ui-library";

type Props = {
  height: string;
  noScrollX?: any;
};

const WithScrollbar: React.FC<Props> = ({ height, children, ...props }) => {
  const [show, toggleShow] = useState(false);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      toggleShow(window.innerWidth > 768);
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (show) {
    return (
      <Scrollbars
        style={{
          height,
        }}
        {...props}
      >
        {children}
      </Scrollbars>
    );
  }

  return <div>{children}</div>;
};

export default WithScrollbar;
