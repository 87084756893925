import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useStyletron } from "baseui";
import { Input } from "baseui/input";
import { StatefulMenu } from "baseui/menu";
import { Spinner } from "baseui/spinner";
import { Tag } from "baseui/tag";
import React, { useEffect, useState } from "react";
import { Contact } from "../../../types/contact.interface";

const GET_AUTOCOMPLETE_CONTACTS = gql`
  query getData($perPage: Int, $page: Int, $keyword: String) {
    contact {
      list(pagination: { perPage: $perPage, page: $page }, keyword: $keyword) {
        pagination {
          perPage
          page
          total
        }
        contacts {
          id
          displayName
          firstName
          lastName
          mobileNumber
          email
          landline
        }
      }
    }
  }
`;

export type SelectedContacts = { label: string; id: string };

type Props = {
  invitedContacts: SelectedContacts[];
  setContacts: (contacts: SelectedContacts[]) => void;
};

const ContactSearch: React.FC<Props> = ({ invitedContacts, setContacts }) => {
  const [css] = useStyletron();

  const [keyword, setKeyword] = useState("");
  const [suggestions, setSuggestions] = useState<null | Contact[]>(null);

  const variables = {
    perPage: 5,
    page: 1,
    keyword,
  };

  const [callAutocompleteQuery, { loading }] = useLazyQuery(
    GET_AUTOCOMPLETE_CONTACTS,
    {
      onCompleted: (data) => {
        if (keyword && keyword.length > 2) {
          setSuggestions(
            data?.contact?.list?.contacts.map(
              ({ id, displayName, firstName, lastName }: Contact) => ({
                label: displayName || `${firstName} ${lastName}`,
                id,
              })
            )
          );
        }
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    }
  );

  const onContactRemoved = (removed: string) => {
    setContacts(invitedContacts.filter(({ id }) => id !== removed));
  };

  const onContactSelected = (selected: SelectedContacts) => {
    if (!invitedContacts.some(({ id }) => id === selected.id)) {
      setContacts([...invitedContacts, selected]);
    }
  };

  useEffect(() => {
    setSuggestions(null);
    if (keyword && keyword.length > 2) {
      callAutocompleteQuery({ variables: { ...variables, keyword } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  return (
    <div>
      <div className={css({ position: "relative", flex: 1 })}>
        <Input
          value={keyword}
          onChange={(e: any) => setKeyword(e.target.value)}
          placeholder="Search"
          clearOnEscape
          endEnhancer={() => {
            if (loading) {
              return <Spinner size={24} />;
            }
          }}
          overrides={{
            Root: {
              style: { paddingRight: 0, marginBottom: "5px" },
            },
          }}
        />
        {suggestions && (
          <StatefulMenu
            items={suggestions}
            onItemSelect={({ item }) => {
              onContactSelected(item);
              setSuggestions(null);
              setKeyword("");
            }}
            overrides={{
              List: {
                style: {
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  position: "absolute",
                  zIndex: 1000,
                  top: "44px",
                  width: "100%",
                },
              },
            }}
          />
        )}
      </div>
      {invitedContacts.map(({ label, id }) => (
        <Tag
          key={id}
          onActionClick={() => onContactRemoved(id)}
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                backgroundColor: $theme.colors.accent,
                borderTopColor: $theme.colors.accent,
                borderBottomColor: $theme.colors.accent,
                borderRightColor: $theme.colors.accent,
                borderLeftColor: $theme.colors.accent,
                color: "#ffffff",
              }),
            },
          }}
        >
          {label}
        </Tag>
      ))}
    </div>
  );
};

export default ContactSearch;
