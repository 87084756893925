import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
  Actions,
  Icon,
  Tabs,
  TabsSize,
} from "@virtualcapital/utogi-ui-library";
import {
  hasPermission,
  permissions as modulePermissions,
} from "@virtualcapital/utogi-auth-library";
import classnames from "classnames";
import { Notification, KIND } from "baseui/notification";
import { Option } from "baseui/select";

import PeopleTab from "components/prospect/propertyModal/peopleTab";
import PropertyDetails from "containers/prospect/PropertyDetails";
import TimelineTab from "containers/prospect/TimelineTab";
import Select from "components/SingleSelect";

import { PERMISSION_ERROR } from "constants/message";

import { useMap } from "context/map/use-map";

import { IUnitDetails } from "interfaces/property";

import { canAddNotes } from "../../../helpers/permission/map";
import { canAccessContacts } from "../../../helpers/permission/common";

import { GET_CONTACT_LIST } from "../../../components/prospect/propertyModal/peopleTab/graphql";

import { MapRolePermissions } from "../../../types/permissions";

import "./PropertyModal.scss";
import { toaster } from "baseui/toast";
import Drawer from "../../../components/drawer";
import { ANCHOR } from "baseui/drawer";
import { useStyletron } from "baseui";
import { usePopup } from "context/popup/use-popup";
import { ActivePopup } from "context/popup/popup.types";
import { PropertySelectionType } from "constants/property";
import { isMultiUnitProperty } from "helpers/address";

type Props = {
  units: any;
  onClose: Function;
  onAction: Function;
  isAllMapMode: boolean;
  searchedAddress?: string | null;
  openAddTagsAndLayers: Function;
  permissions: MapRolePermissions;
};

type SelectedProp = {
  heading: null | string;
  unit: null | string;
  property: any;
};

const PropertyModal = ({
  onClose,
  units,
  isAllMapMode,
  searchedAddress,
  openAddTagsAndLayers,
  permissions,
}: Props) => {
  const hasEmailModule = hasPermission(
    modulePermissions.email.IS_EMAIL_AVAILABLE
  );
  const hasAddNotesPermission = canAddNotes(permissions);
  const hasContactPermission = canAccessContacts();

  const [selectedProperty, setSelectedProperty] = useState<SelectedProp>({
    heading: null,
    unit: null,
    property: undefined,
  });
  const [hasPropertyContacts, setHasPropertyContacts] = useState(false);
  const { selectedMaps } = useMap();
  const [css] = useStyletron();

  const isMultiUnit = isMultiUnitProperty(units);

  const { openPopup } = usePopup();

  const onCloseHandler = () => {
    onClose();
  };
  const {
    loading: loadingPropertyContacts,
    data: propertyContacts,
    refetch: refetchPropertyContacts,
  } = useQuery(GET_CONTACT_LIST, {
    fetchPolicy: "no-cache",
    variables: {
      properties: [
        { property: selectedProperty.property, unit: selectedProperty.unit },
      ],
    },
    skip: !(selectedProperty.property !== undefined && hasContactPermission),
  });

  useEffect(() => {
    if (units?.length > 0) {
      const [{ address, lotName, propertyId, unitNo }] = units;
      setSelectedProperty({
        heading: address?.fullAddress || lotName,
        unit: unitNo,
        property: propertyId,
      });
    }
    // eslint-disable-next-line
  }, [units]);

  useEffect(() => {
    if (searchedAddress && units?.length > 0) {
      const found = units.find(({ address }: IUnitDetails) => {
        if (address?.fullAddress) {
          return address.fullAddress === searchedAddress;
        }
        return false;
      });
      if (found) {
        setSelectedProperty({
          ...selectedProperty,
          heading: found?.address?.fullAddress,
        });
      }
    }
    // eslint-disable-next-line
  }, [searchedAddress, units]);

  useEffect(() => {
    setHasPropertyContacts(
      propertyContacts?.contact?.propertyContact.length > 0
    );
  }, [propertyContacts]);

  const onUnitChangeHandler = (unit: string) => {
    setHasPropertyContacts(false);
    const currentUnit = units.find(
      ({ unitNo }: IUnitDetails) => unit === unitNo
    );
    if (currentUnit) {
      const {
        unitNo: unit,
        propertyId: property,
        address: { fullAddress },
        lotName,
      } = currentUnit;
      setSelectedProperty({
        unit,
        property,
        heading: fullAddress || lotName,
      });
    }
  };

  const campaign = isAllMapMode ? "all" : selectedMaps[0].id;
  const { heading, property, unit } = selectedProperty;

  return (
    <Drawer
      header={
        <div className="header-left">
          <Icon
            icon="bell"
            className={css({
              backgroundColor: "#f99c1c",
              padding: "5px",
              borderRadius: "50%",
              marginRight: "10px",
              fontSize: "15px",
            })}
          />
          {heading}
        </div>
      }
      anchor={ANCHOR.left}
      isOpen={true}
      onClose={onCloseHandler}
    >
      <div className="property-modal">
        <div className="property-modal-content">
          {isMultiUnit && (
            <div className="property-modal-content-header">
              <Icon icon="home" />
              <Select
                options={units.reduce(
                  (unitList: Option[], currentUnit: any) => {
                    if (currentUnit?.unitNo && currentUnit.unitNo !== "") {
                      const { unitNo } = currentUnit;
                      unitList.push({ label: unitNo, id: unitNo });
                    }
                    return unitList;
                  },
                  []
                )}
                onChange={onUnitChangeHandler}
                placeholder="Select Unit"
                value={unit || undefined}
              />
            </div>
          )}
          {property && (
            <>
              <div
                className={classnames("actions", {
                  "actions-disabled": selectedMaps.length > 1,
                })}
              >
                {!isAllMapMode && (
                  <Actions
                    title="Action"
                    actions={[
                      {
                        title: "Add Note",
                        icon: "file",
                        onClick: () => {
                          hasAddNotesPermission
                            ? openPopup({
                                campaign,
                                popup: ActivePopup.NOTE,
                                properties: [{ unit, property }],
                                selectionType: PropertySelectionType.ID,
                              })
                            : toaster.negative(PERMISSION_ERROR.ADD_NOTES, {});
                        },
                      },
                      {
                        title: "Add Reminder",
                        icon: "bell",
                        onClick: () => {
                          if (!hasPropertyContacts) {
                            toaster.negative(
                              "You need at least one contact to add a reminder",
                              {}
                            );
                            return;
                          }
                          openPopup({
                            campaign,
                            popup: ActivePopup.REMINDER,
                            properties: [{ unit, property }],
                          });
                        },
                      },
                      {
                        title: "Add Appointment",
                        icon: "schedule",
                        onClick: () => {
                          if (!hasPropertyContacts) {
                            toaster.negative(
                              "You need at least one contact to add an appointment",
                              {}
                            );
                            return;
                          }
                          openPopup({
                            campaign,
                            popup: ActivePopup.APPOINTMENT,
                            properties: [{ unit, property }],
                          });
                        },
                      },
                      {
                        title: "Add Layers & Tags",
                        icon: "layers",
                        onClick: () =>
                          openAddTagsAndLayers({
                            properties: [{ unit, property }],
                            selectionType: PropertySelectionType.ID,
                            selectedArea: [],
                          }),
                      },
                      ...(hasEmailModule
                        ? [
                            {
                              title: "Send Email",
                              icon: "inbox",
                              onClick: () =>
                                openPopup({
                                  popup: ActivePopup.EMAIL,
                                  properties: [{ unit, property }],
                                  campaign,
                                }),
                            },
                          ]
                        : []),
                    ]}
                  />
                )}
              </div>
              <Tabs
                items={[
                  {
                    title: "People",
                    content: hasContactPermission ? (
                      <PeopleTab
                        unit={unit}
                        property={property}
                        loadingPropertyContacts={loadingPropertyContacts}
                        propertyContacts={propertyContacts}
                        refetchPropertyContacts={refetchPropertyContacts}
                      />
                    ) : (
                      <Notification
                        kind={KIND.warning}
                        overrides={{
                          Body: { style: () => ({ width: "100%" }) },
                        }}
                      >
                        Sorry!! You don't have access to the contact module.
                        Please contact your administrator.
                      </Notification>
                    ),
                  },
                  {
                    title: "Property",
                    content: (
                      <PropertyDetails unit={unit} property={property} />
                    ),
                  },
                  {
                    title: "Timeline",
                    content: (
                      <TimelineTab
                        unit={unit}
                        property={property}
                        campaign={campaign}
                      />
                    ),
                  },
                ]}
                size={TabsSize.SMALL}
              />
            </>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default PropertyModal;
