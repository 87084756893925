import React from "react";
import { arrayMove, List } from "baseui/dnd-list";
import FilterLayerType from "./FilterLayerType";
import FilterLayer from "./FilterLayer";
import { ILayerFilter, ILayerTypeFilter } from "../../../interfaces/campaign";
import { useMap } from "../../../context/map/use-map";
import { styled } from "baseui";

type Props = {
  layerTypes: ILayerTypeFilter[];
  layers: ILayerFilter[];
  setLayerTypes: Function;
  setLayers: Function;
  onLayerTypeSelected: (id: string, isSelected: boolean) => void;
  onLayerSelected: (id: string, isSelected: boolean) => void;
  isAllMapMode: boolean;
};

export const Container = styled("div", () => ({
  padding: "10px",
  boxShadow: "rgb(0 0 0 / 9%) 0px 3px 6px",
  borderRadius: "5px",
  color: "rgb(0, 174, 239)",
  marginBottom: "10px",
}));

const FilterLayerList: React.FC<Props> = ({
  layerTypes,
  layers,
  setLayerTypes,
  setLayers,
  onLayerTypeSelected,
  onLayerSelected,
  isAllMapMode,
}) => {
  const { isManagerView } = useMap();

  if (isManagerView) {
    return (
      <div>
        {isAllMapMode
          ? layerTypes.map((layerType) => (
              <Container key={layerType.id}>
                <FilterLayerType
                  {...layerType}
                  onSelectChange={onLayerTypeSelected}
                />
              </Container>
            ))
          : layers.map((layer) => (
              <Container key={layer.id}>
                <FilterLayer {...layer} onSelectChange={onLayerSelected} />
              </Container>
            ))}
      </div>
    );
  }

  return (
    <List
      items={isAllMapMode ? layerTypes : layers}
      onChange={({ oldIndex, newIndex }) =>
        isAllMapMode
          ? setLayerTypes(arrayMove(layerTypes, oldIndex, newIndex))
          : setLayers(arrayMove(layers, oldIndex, newIndex))
      }
      overrides={{
        Label: {
          component: ({ $value }: any) =>
            isAllMapMode ? (
              <FilterLayerType
                {...$value}
                onSelectChange={onLayerTypeSelected}
              />
            ) : (
              <FilterLayer {...$value} onSelectChange={onLayerSelected} />
            ),
        },
        Item: {
          style: () => ({
            paddingTop: "10px",
            paddingLeft: "10px",
            paddingBottom: "10px",
            paddingRight: "10px",
            marginBottom: "10px",
            boxShadow: "0 3px 6px #00000016",
            borderRadius: "5px",
          }),
        },
      }}
    />
  );
};

export default FilterLayerList;
