import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from "react";

import { reducer } from "./map.reducer";

import {
  Coordinate,
  MapActions,
  MapFilters,
  MapState,
  MapViewMode,
  PointerOnMap,
  SelectedMap,
  SetBatchIdPayload,
  SetManagerView,
  SetMapCenterPayload,
  SetMapFiltersPayload,
  SetPointerOnMapPayload,
  SetRecentlySelectedMap,
  SetSelectedAreaPayload,
  SetSelectedMapsPayload,
  SetUsersPayload,
  SetViewModePayload,
  ToggleShowEdgePropertiesPayload,
} from "./map.types";

import { InMapView } from "constants/prospect";
import {
  MAP_CENTER_KEY,
  ProspectViewMode,
  RECENTLY_SELECTED_MAP_KEY,
} from "../../constants/map";
import { COLOR_FILTER_LIST, FADE_OPTIONS_LIST } from "../../helpers/map";
import { IUserFilter } from "../../interfaces/user";
import { useLocation } from "react-router-dom";

const MapContext = createContext(
  {} as {
    selectedMaps: SelectedMap[];
    isMapSelected: boolean;
    isManagerView: boolean;
    selectedArea: null | Coordinate[];
    center: null | Coordinate;
    showEdgeProperties: boolean;
    pointerOnMap: null | PointerOnMap;
    filters: MapFilters;
    viewMode: MapViewMode;
    users: null | IUserFilter[];
    recentlySelectedMap: null | SelectedMap;
    contactCoordinates: [] | Array<Coordinate>;
    batchId: null | string;
    setMapCenter: (payload: SetMapCenterPayload) => void;
    resetSelectedMap: () => void;
    setSelectedMaps: (payload: SetSelectedMapsPayload) => void;
    setSelectedArea: (payload: SetSelectedAreaPayload) => void;
    setPointerOnMap: (payload: SetPointerOnMapPayload) => void;
    setMapFilters: (payload: SetMapFiltersPayload) => void;
    toggleShowEdgeProperties: (
      payload: ToggleShowEdgePropertiesPayload
    ) => void;
    setViewMode: (payload: SetViewModePayload) => void;
    setUsers: (payload: SetUsersPayload) => void;
    setManagerView: (payload: SetManagerView) => void;
    resetMapFilters: () => void;
    setContactCoordinates: (payload: Array<Coordinate>) => void;
    setBatchId: (payload: SetBatchIdPayload) => void;
  }
);

const INITIAL_STATE: MapState = {
  batchId: null,
  selectedMaps: [],
  center: null,
  selectedArea: null,
  showEdgeProperties: false,
  pointerOnMap: null,
  inMapView: InMapView.LAYERS,
  filters: {
    layerTypes: null,
    layers: null,
    viewMode: ProspectViewMode.PROSPECT,
    selectedTags: [],
    fadeOption: [FADE_OPTIONS_LIST[0]],
    colorFilter: [COLOR_FILTER_LIST[0]],
    isByRecentOrder: true,
    selectedUsers: null,
    dateRange: [],
  },
  viewMode: {
    isSatelliteView: false,
    isPeopleView: true,
    isTagsView: false,
    isLayersView: true,
  },
  users: null,
  isManagerView: false,
  recentlySelectedMap: null,
  contactCoordinates: [],
};

const useMapActions = (initialMap: MapState = INITIAL_STATE) => {
  const [state, dispatch] = useReducer(reducer, initialMap);

  const saveMapCenter = (center: Coordinate) =>
    localStorage.setItem(MAP_CENTER_KEY, JSON.stringify(center));

  const saveRecentlySelected = (recentlySelected: SelectedMap) =>
    localStorage.setItem(
      RECENTLY_SELECTED_MAP_KEY,
      JSON.stringify(recentlySelected)
    );

  const setSelectedMaps = (payload: SetSelectedMapsPayload) => {
    dispatch({
      type: MapActions.SET_SELECTED_MAPS,
      payload,
    });
    if (payload.selectedMaps.length === 1) {
      const map = payload.selectedMaps[0];
      saveRecentlySelected(map);
    }
  };

  const setMapCenter = (payload: SetMapCenterPayload) => {
    saveMapCenter(payload.center);
    dispatch({
      type: MapActions.SET_MAP_CENTER,
      payload,
    });
  };

  const setSelectedArea = (payload: SetSelectedAreaPayload) => {
    dispatch({
      type: MapActions.SET_SELECTED_AREA,
      payload,
    });
  };

  const setPointerOnMap = (payload: SetPointerOnMapPayload) => {
    saveMapCenter(payload.pointerOnMap.coordinates);
    dispatch({
      type: MapActions.SET_POINTER_ON_MAP,
      payload,
    });
  };

  const toggleShowEdgeProperties = (
    payload: ToggleShowEdgePropertiesPayload
  ) => {
    dispatch({
      type: MapActions.TOGGLE_SHOW_EDGE_PROPERTIES,
      payload,
    });
  };

  const setMapFilters = (payload: SetMapFiltersPayload) => {
    dispatch({
      type: MapActions.SET_MAP_FILTERS,
      payload,
    });
  };

  const setViewMode = (payload: SetViewModePayload) => {
    dispatch({
      type: MapActions.SET_VIEW_MODE,
      payload,
    });
  };

  const setUsers = (payload: SetUsersPayload) => {
    dispatch({
      type: MapActions.SET_USER,
      payload,
    });
  };

  const setRecentlySelectedMap = (payload: SetRecentlySelectedMap) => {
    dispatch({
      type: MapActions.RECENTLY_SELECTED_MAP,
      payload,
    });
  };

  const setManagerView = (payload: SetManagerView) => {
    dispatch({
      type: MapActions.SET_MANAGER_VIEW,
      payload,
    });
  };

  const resetMapFilters = () => {
    dispatch({
      type: MapActions.RESET_MAP_FILTERS,
    });
  };

  const setContactCoordinates = (payload: Array<Coordinate>) => {
    dispatch({
      type: MapActions.SET_CONTACT_COORDINATES,
      payload,
    });
  };

  const resetSelectedMap = () => {
    localStorage.removeItem(RECENTLY_SELECTED_MAP_KEY);
    dispatch({
      type: MapActions.RESET_SELECTED_MAP,
    });
  };

  const setBatchId = (payload: SetBatchIdPayload) => {
    dispatch({
      type: MapActions.SET_BATCH_ID,
      payload,
    });
  };

  return {
    state,
    setUsers,
    setBatchId,
    setViewMode,
    setMapCenter,
    setMapFilters,
    setManagerView,
    resetMapFilters,
    setSelectedArea,
    setSelectedMaps,
    setPointerOnMap,
    resetSelectedMap,
    setRecentlySelectedMap,
    toggleShowEdgeProperties,
    setContactCoordinates,
  };
};

export const MapProvider = ({ children }: { children: ReactNode }) => {
  const {
    state: {
      users,
      center,
      batchId,
      filters,
      viewMode,
      isManagerView,
      pointerOnMap,
      selectedMaps,
      selectedArea,
      showEdgeProperties,
      recentlySelectedMap,
      contactCoordinates,
    },
    setUsers,
    setBatchId,
    setViewMode,
    setMapCenter,
    setMapFilters,
    setManagerView,
    setSelectedArea,
    resetMapFilters,
    setSelectedMaps,
    setPointerOnMap,
    resetSelectedMap,
    setRecentlySelectedMap,
    toggleShowEdgeProperties,
    setContactCoordinates,
  } = useMapActions();
  const location = useLocation();

  useEffect(() => {
    if (!center) {
      const center = localStorage.getItem(MAP_CENTER_KEY);
      const recentlySelectedMap = localStorage.getItem(
        RECENTLY_SELECTED_MAP_KEY
      );
      if (recentlySelectedMap) {
        setRecentlySelectedMap({
          recentlySelectedMap: JSON.parse(recentlySelectedMap),
          location: location.pathname,
        });
      }
      if (center) {
        setMapCenter({ center: JSON.parse(center) });
      } else {
        setMapCenter({
          center: { lat: -40.66104466155806, lng: 172.38870630040552 },
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  const isMapSelected = selectedMaps.length > 0;

  return (
    <MapContext.Provider
      value={{
        users,
        center,
        batchId,
        filters,
        viewMode,
        isManagerView,
        selectedMaps,
        selectedArea,
        pointerOnMap,
        isMapSelected,
        showEdgeProperties,
        recentlySelectedMap,
        contactCoordinates,
        setUsers,
        setBatchId,
        setViewMode,
        setMapCenter,
        setMapFilters,
        setManagerView,
        resetMapFilters,
        resetSelectedMap,
        setSelectedArea,
        setPointerOnMap,
        setSelectedMaps,
        toggleShowEdgeProperties,
        setContactCoordinates,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export const useMap = () => useContext(MapContext);
