import React, { useState } from "react";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import classnames from "classnames";
import { toaster } from "baseui/toast";
import { gql, useMutation, useQuery } from "@apollo/client";

import { ReactComponent as ManagerViewIcon } from "assets/icons/manager-view.svg";
import { ReactComponent as MultiSelectIcon } from "assets/icons/multi-select.svg";
import { ReactComponent as UndoIcon } from "assets/icons/undo.svg";

import IconButton from "components/iconButton";
import FiltersLayersAndTags from "../FiltersLayersAndTags/FiltersLayersAndTags";
import ViewMode from "../ViewMode";
import FilterUsers from "../FiltersUsers/FiltersUsers";

import { useMap } from "context/map/use-map";

import { IUser } from "../../../interfaces/user";

import { MapRolePermissions } from "../../../types/permissions";

import "./ActionBar.scss";
import { canAccessManagerView } from "../../../helpers/permission/map";
import { event } from "../../../events";
import { REFETCH_PROSPECT_MAP } from "../../../events/prospect";

type Props = {
  zoom: number;
  handleZoomLevel: Function;
  isOnMultiSelectMode: boolean;
  toggleMultiSelectMode: Function;
  permissions: MapRolePermissions;
  isAllMapMode: boolean;
};

const GET_USERS = gql`
  query GetUsersListQuery {
    user {
      id
      name
      assignedColor
    }
  }
`;

const UNDO_LAST_ADDED_LAYER = gql`
  mutation UndoLastAddedLayer($batchId: String!) {
    property {
      undoLastAddedLayers(batch: $batchId) {
        success
      }
    }
  }
`;

const ActionBar = ({
  zoom,
  handleZoomLevel,
  isOnMultiSelectMode,
  toggleMultiSelectMode,
  permissions,
  isAllMapMode,
}: Props) => {
  const {
    isManagerView,
    setManagerView,
    setUsers,
    users,
    batchId,
    setBatchId,
  } = useMap();
  const [isLayerFiltersPinned, setIsLayerFiltersPinned] = useState(false);

  useQuery(GET_USERS, {
    skip: !(isManagerView && users === null),
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.user) {
        const { user } = data;
        setUsers(user.map((user: IUser) => ({ ...user, isSelected: true })));
      }
    },
  });

  const [undoLastAddedLayers] = useMutation(UNDO_LAST_ADDED_LAYER, {
    onError: () => {},
    onCompleted: (data) => {
      if (data?.property?.undoLastAddedLayers?.success) {
        toaster.positive("Action performed successfully!", {});
        event.dispatch(REFETCH_PROSPECT_MAP);
        setBatchId({ batchId: null });
      }
    },
  });

  const hasAccessManagerViewPermission = canAccessManagerView(
    isAllMapMode,
    permissions
  );

  return (
    <div className="action-bar">
      <div className="action-bar-inner">
        {!isAllMapMode && !isManagerView && (
          <div className="action-bar-item">
            <button
              className={classnames("primary", { active: isOnMultiSelectMode })}
              onClick={() => toggleMultiSelectMode(!isOnMultiSelectMode)}
            >
              <MultiSelectIcon width="20" height="50" />
            </button>
            <span className="tool-tip-text">
              Select an area to add activity/layers
            </span>
          </div>
        )}
        {isManagerView && (
          <div className="action-bar-item">
            <StatefulPopover
              content={({ close }) => <FilterUsers close={close} />}
              placement={PLACEMENT.leftTop}
              popoverMargin={12}
              overrides={{
                Body: {
                  style: {
                    "@media (max-width: 768px)": {
                      width: "100%",
                      marginLeft: "0px",
                      transform: "translate3d(0px, 0px, 0px)",
                      position: "fixed",
                      bottom: "0",
                      top: "auto",
                    },
                  },
                },
              }}
            >
              <IconButton
                icon="profile"
                className="view-mode"
                disabled={isOnMultiSelectMode}
              />
            </StatefulPopover>
            <span className="tool-tip-text">User View</span>
          </div>
        )}
        <div className="action-bar-item">
          <StatefulPopover
            content={({ close }) => (
              <ViewMode close={close} isAllMapMode={isAllMapMode} />
            )}
            placement={PLACEMENT.leftTop}
            popoverMargin={12}
            overrides={{
              Body: {
                style: {
                  "@media (max-width: 768px)": {
                    width: "100%",
                    marginLeft: "0px",
                    transform: "translate3d(0px, 0px, 0px)",
                    position: "fixed",
                    bottom: "0",
                    top: "auto",
                  },
                },
              },
            }}
          >
            <IconButton
              icon="eye"
              className="view-mode"
              disabled={isOnMultiSelectMode}
            />
          </StatefulPopover>
          <span className="tool-tip-text">
            Select what you want to see on the map
          </span>
        </div>
        <div className="action-bar-item">
          <StatefulPopover
            content={({ close }) => (
              <FiltersLayersAndTags
                close={close}
                isAllMapMode={isAllMapMode}
                isPinned={isLayerFiltersPinned}
                toggleIsPinned={() => {
                  setIsLayerFiltersPinned(!isLayerFiltersPinned);
                  close();
                }}
              />
            )}
            placement={PLACEMENT.leftTop}
            popoverMargin={12}
            overrides={{
              Body: {
                style: {
                  "@media (max-width: 768px)": {
                    width: "100%",
                    marginLeft: "0px",
                    transform: "translate3d(0px, 0px, 0px)",
                    position: "fixed",
                    bottom: "0",
                    top: "auto",
                  },
                },
              },
            }}
          >
            <IconButton
              icon="layers"
              className="secondary"
              disabled={isOnMultiSelectMode || isLayerFiltersPinned}
            />
          </StatefulPopover>
          <span className="tool-tip-text">
            Select your map filters, layers &amp; tags
          </span>
        </div>
        <div className="action-bar-item">
          <button
            className={classnames("manager-view", { active: isManagerView })}
            onClick={() =>
              hasAccessManagerViewPermission
                ? setManagerView({ isManagerView: !isManagerView })
                : toaster.negative(
                    "You don't have permissions to access team view",
                    {}
                  )
            }
            disabled={isOnMultiSelectMode}
          >
            <ManagerViewIcon width="20" height="50" />
          </button>
          <span className="tool-tip-text">Team View</span>
        </div>
      </div>
      <div className="action-bar-inner">
        <div className="action-bar-item">
          <IconButton
            icon="plus"
            className="light"
            onClick={() => handleZoomLevel(zoom + 1)}
          />
          <span className="tool-tip-text">Zoom in</span>
        </div>
        <div className="action-bar-item">
          <IconButton
            icon="minus"
            className="light"
            onClick={() => handleZoomLevel(zoom - 1)}
          />
          <span className="tool-tip-text">Zoom out</span>
        </div>
        {batchId && (
          <div className="action-bar-item">
            <button
              className="primary"
              onClick={() => undoLastAddedLayers({ variables: { batchId } })}
            >
              <UndoIcon width="20" height="50" />
            </button>
            <span className="tool-tip-text">Undo Layers</span>
          </div>
        )}
      </div>
      {isLayerFiltersPinned && (
        <FiltersLayersAndTags
          close={() => {}}
          isAllMapMode={isAllMapMode}
          isPinned={isLayerFiltersPinned}
          toggleIsPinned={() => setIsLayerFiltersPinned(!isLayerFiltersPinned)}
        />
      )}
    </div>
  );
};

export default ActionBar;
