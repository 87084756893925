import React, { useEffect } from "react";
import { Icon, Checkbox } from "@virtualcapital/utogi-ui-library";
import CampaignUserListItem from "components/campaign/campaignUserListItem";
import _ from "lodash";
import classnames from "classnames";

import WithScrollbar from "helpers/WithScrollbar";

import "./AddTagsAndLayersDropdown.scss";

const DEFAULT_PROPS = {
  all: false,
  property: true,
  user: [],
};

const AddTagsAndLayers = ({
  contactList,
  contactLoading,
  isDisplayDropdown,
  changeDropdownDisplay,
  selectedList,
  updateSelectedList,
}: {
  contactList: any;
  contactLoading: boolean;
  changeDropdownDisplay: Function;
  updateSelectedList: Function;
  isDisplayDropdown: boolean;
  selectedList: any;
}) => {
  const selectAllTimeline = (val: boolean) => {
    const usersList: any = [];
    if (val === true) {
      contactList.map((each: any) => {
        usersList.push(each.id);
        return each;
      });
      updateSelectedList({ ...DEFAULT_PROPS, all: true, user: usersList });
    } else {
      updateSelectedList({ ...DEFAULT_PROPS, user: [] });
    }
  };

  useEffect(() => {
    if (contactList?.length > 0) {
      selectAllTimeline(true);
    }
    // eslint-disable-next-line
  }, [contactList]);

  const selectPropertyTimeline = (val: boolean) => {
    if (val === true) {
      updateSelectedList({ ...selectedList, property: true });
    } else if (selectedList.user.length > 0) {
      updateSelectedList({ ...selectedList, property: false, all: false });
    }
  };

  const selectUserByTimeline = (id: string, val: boolean) => {
    if (val === true) {
      updateSelectedList({ ...selectedList, user: [...selectedList.user, id] });
    } else if (selectedList.user.length > 0) {
      if (selectedList.user.length !== 1 && selectedList.property === false) {
        updateSelectedList({
          ...selectedList,
          user: [...selectedList.user.filter((each: any) => each !== id)],
          all: false,
        });
      }
      if (selectedList.property === true) {
        updateSelectedList({
          ...selectedList,
          user: [...selectedList.user.filter((each: any) => each !== id)],
          all: false,
        });
      }
    }
  };

  const getPlaceholder = () => {
    if (selectedList.all) {
      return "Add To All Timeline";
    } else if (selectedList.property && selectedList.user.length > 0) {
      return "Add To Both Timeline";
    } else if (selectedList.user.length > 0) {
      return "Add To People Timeline";
    } else {
      return "Add To Property Timeline";
    }
  };

  return (
    <div
      className={classnames("add-tags-and-layers-dropdown", {
        "add-tags-and-layers-dropdown--active": isDisplayDropdown,
      })}
    >
      <div className="add-campaign-users-dropDown">
        <div
          className="box select-all"
          onClick={() => changeDropdownDisplay(!isDisplayDropdown)}
        >
          {getPlaceholder()}
          <Icon icon={isDisplayDropdown ? "chevron-up" : "chevron-down"} />
        </div>
      </div>

      {isDisplayDropdown ? (
        <div className="dropdown-options">
          <WithScrollbar height="300px">
            <div className="campaign-users-list">
              <CampaignUserListItem
                name="Add To All Timeline"
                image={null}
                key={"all"}
                action={
                  <Checkbox
                    value={selectedList.all}
                    onChange={selectAllTimeline}
                  />
                }
              />
              <CampaignUserListItem
                name="Add To Property Timeline"
                image={null}
                key={"property"}
                action={
                  <Checkbox
                    value={selectedList.property}
                    onChange={selectPropertyTimeline}
                  />
                }
              />

              {contactLoading && (
                <span className="wait-note">please wait.</span>
              )}

              {contactList.map(
                ({
                  firstName,
                  lastName,
                  id,
                }: {
                  firstName: string;
                  lastName?: string;
                  id: string;
                }) => (
                  <CampaignUserListItem
                    name={`${firstName} ${lastName}`}
                    //TODO: update image when contact image upload is implement
                    image={null}
                    key={id}
                    action={
                      <Checkbox
                        value={_.includes(selectedList.user, id)}
                        onChange={(val: boolean) => {
                          selectUserByTimeline(id, val);
                        }}
                      />
                    }
                  />
                )
              )}
            </div>
          </WithScrollbar>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AddTagsAndLayers;
