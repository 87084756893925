import { PropertySelectionType } from "constants/property";
import { IPropertyIdentifier } from "containers/map/Timeline/interface";

export enum PopupActions {
  OPEN_POPUP = "OPEN_POPUP",
  CLOSE_POPUP = "CLOSE_POPUP",
}

export enum ActivePopup {
  TAG = "TAG",
  NEW_CONTACT = "NEW_CONTACT",
  NOTE = "NOTE",
  REMINDER = "REMINDER",
  APPOINTMENT = "APPOINTMENT",
  EMAIL = "EMAIL",
  EMAIL_VIEW = "EMAIL_VIEW",
}

export type PopupState = {
  activePopup: null | ActivePopup;
  source?: null | ActivePopup;
  reference?: null | string;
  title?: null | string;
  campaign?: string | null;
  selectionType?: PropertySelectionType;
  selectedArea?: [];
  properties?: IPropertyIdentifier[];
};

export type OpenPopupPayload = {
  popup: ActivePopup;
  source?: ActivePopup;
  reference?: string | null;
  title?: string | null;
  campaign?: string | null;
  selectionType?: PropertySelectionType;
  selectedArea?: [];
  properties?: IPropertyIdentifier[];
  contacts?: string[];
};

export type PopupStateActions =
  | { type: PopupActions.OPEN_POPUP; payload: OpenPopupPayload }
  | { type: PopupActions.CLOSE_POPUP };
