import { AuthContext } from "@virtualcapital/utogi-auth-library";
import { Icon } from "@virtualcapital/utogi-ui-library";
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import { Input } from "baseui/input";
import { toaster } from "baseui/toast";
import {
  resolveImageUrl,
  resolveImageUploadUrl,
} from "helpers/resolve-image-url";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Attachments from "./components/attachments";
import EmailParties from "./components/emailParties";
import PartyFieldToggles from "./components/partyFieldToggles";
import {
  EmailEditorContext,
  EMAIL_CONTEXT_ATTACHMENTS_LOADED,
} from "./emailEditorContext";
import {
  ActionContainer,
  AttachmentContainer,
  ComposeEmail,
  ComposeEmailDraftSave,
  ComposeEmailInputField,
  ComposeEmailInputs,
  EmailEditorContainer,
  IconContainer,
  LeftActions,
  SaveDraft,
} from "./styledComponents";
import "./styles.scss";
import { ComposerMode } from "../../../contants";

type UiStateType = {
  showCc: boolean;
  showBcc: boolean;
  isComposing: boolean;
};

export enum RecipientType {
  TO = "to",
  CC = "cc",
  BCC = "bcc",
}

const EmailEditor = ({
  to: emailTo,
  cc: emailCC,
  bcc: emailBCC,
  files,
  subject: emailSubject,
  body: emailBody = "",
  onSaveDraft,
  draftSaving,
  onChange,
  hasDraft,
  onSend,
  onFocus,
  onBlur,
  onDelete,
  isReply,
  deletingDraft,
  composerMode = ComposerMode.NEW_EMAIL,
  sendingDraft,
}: any) => {
  const {
    authData: { token },
  } = useContext(AuthContext);
  const [isFirstRun, setIsFirstRun] = useState(true);
  const [body, setBody] = useState<string>(
    `<p>&nbsp;</p><p>&nbsp;</p>  ${emailBody}`
  );
  const [recipients, setRecipients] = useState<any>({
    to: emailTo || [],
    cc: emailCC || [],
    bcc: emailBCC || [],
  });
  const [subject, setSubject] = useState<string>(emailSubject || "");
  const [uiState, setUiState] = useState<UiStateType>({
    isComposing: true,
    showCc: false,
    showBcc: false,
  });

  const toolBarRef = React.useRef(null);
  const [toolbarElement, setToolbarElement] = useState<any>(undefined);
  // uploadedImageUrl is used for keeping reference to last uploaded image url.
  let uploadedImageUrl: undefined | string = undefined;

  const {
    state: emailContextState,
    dispatch: dispatchEmailContext,
  } = useContext(EmailEditorContext);

  const [showSaveStatus, setShowSaveStatus] = useState(false);
  const fileInputRef = React.useRef();

  // Attachment load effect
  useEffect(() => {
    if (files && emailContextState?.files?.length < 1) {
      dispatchEmailContext({
        type: EMAIL_CONTEXT_ATTACHMENTS_LOADED,
        payload: files,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const recipientsChanged = (type: string, payload: any) => {
    setRecipients({ ...recipients, [type]: payload });
  };

  // show save status effect
  useEffect(() => {
    if (draftSaving) {
      setShowSaveStatus(true);
    } else {
      setTimeout(() => {
        setShowSaveStatus(false);
      }, 1000);
    }
  }, [draftSaving]);

  // on change draft content effect
  useLayoutEffect(() => {
    if (isFirstRun) {
      setIsFirstRun(false);
    } else {
      const { to, cc, bcc } = recipients;
      const { files: fileObjects } = emailContextState;
      const files = fileObjects.map((item: any) => item.id);
      onChange({ to, cc, bcc, body, subject, files });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipients, body, subject, emailContextState.files]);

  const handleClickSaveToDraft = () => {
    const { to, cc, bcc } = recipients;
    const { files: fileObjects } = emailContextState;
    const files = fileObjects.map((item: any) => item.id);

    onSaveDraft({
      to,
      cc,
      bcc,
      body,
      subject,
      files,
    });
  };

  const handleOnFocus = (e: any) => {
    onFocus(e);
  };

  // show cc filed active when reply to all
  useEffect(() => {
    if (emailCC.length) {
      onChangeUiState({ showCc: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (toolBarRef.current) {
      setToolbarElement(toolBarRef.current);
    }
  }, [toolBarRef]);

  const uploadImage = async (formData: any) => {
    const response = await fetch(resolveImageUploadUrl(), {
      method: "POST",
      headers: {
        authorization: token || "",
      },
      body: formData,
    });
    return response.json();
  };

  const imageUploadBeforeHandler = (
    files: any,
    info: any,
    uploadHandler: any
  ) => {
    if (files.length > 0) {
      const file = files[0];
      const fileSizeInMb = file.size / (1024 * 1024);
      if (fileSizeInMb > 25) {
        toaster.negative("Cannot upload images larger than 25 MB", {});
        return null;
      }
      const formData = new FormData();
      formData.append(file.name, file);
      try {
        uploadImage(formData)
          .then((response) => {
            if (response.status === "success") {
              uploadedImageUrl = resolveImageUrl(response.data.url);
              uploadHandler([file]);
            }
          })
          .catch((error) => {
            toaster.negative(error.message, {});
            return null;
          });
        return null;
      } catch (error) {
        toaster.negative("Error", {});
        return null;
      }
    }
  };

  const imageUploadedHandler = (data: any) => {
    // replace base64 src with s3 bucket image url.
    if (data?.src && uploadedImageUrl) {
      data.src = uploadedImageUrl;
    }
  };

  const onChangeUiState = (payload: any) => {
    setUiState({ ...uiState, ...payload });
  };

  const { showCc, showBcc, isComposing } = uiState;
  const { to, cc, bcc } = recipients;
  return (
    <ComposeEmail>
      <PartyFieldToggles
        showCc={showCc}
        showBcc={showBcc}
        onShowCc={() => onChangeUiState({ showCc: true })}
        onShowBcc={() => onChangeUiState({ showBcc: true })}
        isComposing={isComposing}
      />
      <ComposeEmailInputs>
        <EmailParties
          autoFocusToField={composerMode === ComposerMode.FORWARD}
          recipientsChanged={recipientsChanged}
          to={to}
          cc={cc}
          bcc={bcc}
          showCc={showCc}
          showBcc={showBcc}
          isComposing={isComposing}
          onChangeUiState={onChangeUiState}
        />
        {!isReply && (
          <ComposeEmailInputField>
            <Input
              placeholder="Subject"
              value={subject}
              onChange={(event: React.FormEvent<HTMLInputElement>) =>
                setSubject(event.currentTarget.value)
              }
              onFocus={(e) => {
                onChangeUiState({ isComposing: true });
                handleOnFocus(e);
              }}
              onBlur={onBlur}
              overrides={{
                Root: {
                  style: () => ({
                    border: "none",
                  }),
                },
                Input: {
                  style: () => ({
                    backgroundColor: "#ffffff",
                    fontSize: "12px",
                    padding: "2px",
                  }),
                },
              }}
            />
          </ComposeEmailInputField>
        )}

        <EmailEditorContainer>
          {toolbarElement && (
            <SunEditor
              setOptions={{
                height: "auto",
                buttonList: [
                  [
                    ":t-More Text-default.more_text",
                    "font",
                    "fontSize",
                    "formatBlock",
                  ],
                  ["bold", "underline", "italic"],
                  ["fontColor", "hiliteColor"],
                  ["outdent", "indent"],
                  ["align", "horizontalRule", "list", "lineHeight"],
                  ["link"],
                  ["image"],
                ],
                toolbarContainer: toolbarElement,
                imageUrlInput: false,
                imageUploadUrl: undefined,
                showPathLabel: false,
                resizingBar: false,
              }}
              setContents={body}
              onFocus={(e) => {
                onChangeUiState({ isComposing: true });
                handleOnFocus(e);
                const el = e.currentTarget;
                const selection = window.getSelection();
                const range = document.createRange();
                // @ts-ignore
                range.setStart(el.childNodes[0], 0);
                // @ts-ignore
                selection.removeAllRanges();
                // @ts-ignore
                selection.addRange(range);
              }}
              autoFocus={composerMode !== ComposerMode.FORWARD}
              onBlur={(e, editorContents) => {
                setBody(editorContents);
                onBlur(e);
              }}
              onChange={(contents) => setBody(contents)}
              onImageUploadBefore={imageUploadBeforeHandler}
              onImageUpload={imageUploadedHandler}
            />
          )}
        </EmailEditorContainer>
        <AttachmentContainer>
          <Attachments ref={fileInputRef} />
        </AttachmentContainer>
        <div className="sun-editor" id="uc-sun-editor-tools" ref={toolBarRef} />
        <ComposeEmailDraftSave>
          <Button
            onClick={handleClickSaveToDraft}
            disabled={draftSaving}
            overrides={{
              BaseButton: {
                style: () => ({
                  fontWeight: 600,
                  color: "#71d0f6",
                  marginRight: "10px",
                  borderRadius: "10px",
                  border: 0,
                  backgroundColor: "transparent",
                  ":hover": {
                    backgroundColor: "transparent",
                  },
                  ":disabled": {
                    color: "#707070",
                    cursor: "not-allowed",
                  },
                }),
              },
            }}
          >
            {hasDraft ? "Update draft" : "Save to draft"}
          </Button>
          <SaveDraft $hidden={!showSaveStatus}>
            {showSaveStatus &&
              (draftSaving ? (
                <>
                  Saving draft
                  <IconContainer>
                    <Icon icon="refresh" />
                  </IconContainer>
                </>
              ) : (
                hasDraft && "Draft saved"
              ))}
          </SaveDraft>
        </ComposeEmailDraftSave>
      </ComposeEmailInputs>
      <ActionContainer>
        <LeftActions>
          <Button
            overrides={{
              BaseButton: {
                style: () => ({
                  fontSize: "20px",
                  color: "#000000",
                  marginRight: "10px",
                  backgroundColor: "transparent",
                  ":hover": {
                    backgroundColor: "#eee",
                  },
                }),
              },
            }}
            onClick={onDelete}
            isLoading={deletingDraft}
            size={SIZE.default}
            shape={SHAPE.circle}
          >
            <Icon icon="delete" />
          </Button>
          <Button
            size={SIZE.default}
            overrides={{
              BaseButton: {
                style: () => ({
                  borderRadius: "10px",
                  fontSize: "14px",
                  color: "#000000",
                  backgroundColor: "transparent",
                  ":hover": {
                    backgroundColor: "#eee",
                  },
                }),
              },
            }}
            onClick={() => {
              // @ts-ignore
              fileInputRef && fileInputRef.current.click();
            }}
          >
            Attach
          </Button>
        </LeftActions>
        <Button
          size={SIZE.default}
          disabled={draftSaving}
          isLoading={sendingDraft}
          kind={KIND.secondary}
          overrides={{
            BaseButton: {
              style: () => ({
                borderRadius: "10px",
                fontSize: "14px",
                width: "150px",
                "@media (max-width: 426px)": {
                  width: "100%",
                  marginTop: "15px",
                },
              }),
            },
          }}
          onClick={onSend}
        >
          Send
        </Button>
      </ActionContainer>
    </ComposeEmail>
  );
};

export default EmailEditor;
