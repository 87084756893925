import React, { ChangeEvent, useEffect, useState } from "react";
import { useMutation, useQuery, ApolloError } from "@apollo/client";
import gql from "graphql-tag";
import { toaster } from "baseui/toast";
import { DatePicker } from "@virtualcapital/utogi-ui-library";

import Loader from "components/loader";
import Select from "components/SingleSelect";

import { IPropertyDetails, Property } from "interfaces/property";
import { REGIONS, SELECT_VALUE } from "constants/property";

import "./EditPropertyDetails.scss";
import Button from "components/Button";
import { KIND } from "baseui/button";
import Input from "components/base-input/base-input";

const TWO_DECIMAL_NUMBER_REGEXP = /^(\d{0,5}\.\d{1,2}|\d{1,5})$/;

type Props = Property & {
  onComplete: any;
  details: IPropertyDetails;
  refetch: Function;
};

const EditPropertyDetails = ({
  property,
  unit,
  onComplete,
  details: {
    name,
    bathRooms,
    bedRooms,
    livingRooms,
    garage,
    floorArea,
    landArea,
    rates,
    category,
    propertyType,
    region,
    cTFullDescription,
    localAuthority,
    zoning,
    cv,
    cvDate,
    cTNumber,
    lot,
    DP,
  },
  refetch,
}: Props) => {
  const [values, changeValues] = useState({
    name: name ? name : "",
    bedRooms: bedRooms ? bedRooms : "",
    bathRooms: bathRooms ? bathRooms : "",
    livingRooms: livingRooms ? livingRooms : "",
    garage: garage ? garage : "",
    floorArea: floorArea ? floorArea : "",
    landArea: landArea ? landArea : "",
    rates: rates ? rates : "",
    category: category ? category : "",
    propertyType: propertyType ? propertyType : "",
    region: region ? region : "",
    cTFullDescription: cTFullDescription ? cTFullDescription : "",
    localAuthority: localAuthority ? localAuthority : "",
    zoning: zoning ? zoning : "",
    cv: cv ? cv : "",
    cvDate: cvDate ? new Date(cvDate) : null,
    cTNumber: cTNumber ? cTNumber : undefined,
    lot: lot ? lot : undefined,
    DP: DP ? DP : undefined,
  });

  const [errors, setErrors] = useState<any>({
    name: null,
    bedRooms: null,
    bathRooms: null,
    livingRooms: null,
    garage: null,
    floorArea: null,
    landArea: null,
    rates: null,
    category: null,
    propertyType: null,
  });

  const [types, setTypes] = useState<any>(null);

  const [changedAttributes, setChangedAttributes] = useState<any>([]);

  const GET_PROPERTY_CATEGORIES = gql`
    query PropertyQuery {
      property {
        categories
      }
    }
  `;
  const { loading, data } = useQuery(GET_PROPERTY_CATEGORIES, {});
  const UPDATE_PROPERTY = gql`
    mutation(
      $property: PropertyInputType!
      $details: GraphQLPropertyDetailInputs!
      $changed: [String]!
    ) {
      property {
        update(property: $property, details: $details, changed: $changed) {
          status
          message
        }
      }
    }
  `;

  const [updateProperty, { loading: updating }] = useMutation(UPDATE_PROPERTY, {
    onError: ({ graphQLErrors }: ApolloError) => {
      graphQLErrors.map(({ message }) => toaster.negative(message, {}));
    },
    onCompleted: () => {
      toaster.positive("Property updated successfully!", {});
      refetch();
      onComplete();
    },
  });

  useEffect(() => {
    if (category) {
      categoryList.every(({ id, types }: any) => {
        if (id === category) {
          setTypes(types.map(({ id, name: label }: any) => ({ id, label })));
          return false;
        }
        return true;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !data) {
    return <Loader />;
  }
  const {
    property: { categories: categoryList },
  } = data;

  const categories = categoryList.map(({ id, name: label }: any) => ({
    id,
    label,
  }));

  const onChangeValue = (key: string, value: string) => {
    if (!changedAttributes.includes(key)) {
      setChangedAttributes([...changedAttributes, key]);
    }
    if (key === "category") {
      categoryList.every(({ id, types }: any) => {
        if (id === value) {
          setTypes(types.map(({ id, name: label }: any) => ({ id, label })));
          return false;
        }
        return true;
      });
      changeValues({ ...values, [key]: value, propertyType: "" });
    } else {
      changeValues({ ...values, [key]: value });
    }
  };

  const handleFormSubmit = () => {
    let hasErrors = false;
    const inputErrors: any = {
      name: null,
      bedRooms: null,
      bathRooms: null,
      livingRooms: null,
      garage: null,
      floorArea: null,
      landArea: null,
      rates: null,
      category: null,
      propertyType: null,
    };

    const {
      name,
      bedRooms,
      bathRooms,
      livingRooms,
      garage,
      floorArea,
      landArea,
      rates,
      category,
      propertyType,
      region,
      cTFullDescription,
      localAuthority,
      zoning,
      cv,
      cvDate,
      cTNumber,
      lot,
      DP,
    }: any = values;

    if (!name || name === "") {
      inputErrors.name = "Name is required.";
      hasErrors = true;
    }
    if (!category || category === "") {
      inputErrors.category = "Category is required.";
      hasErrors = true;
    }
    if (!propertyType || propertyType === "") {
      inputErrors.propertyType = "Property type is required.";
      hasErrors = true;
    }
    if (bedRooms !== "" && isNaN(bedRooms)) {
      inputErrors.bedRooms = "Bed rooms should be a number.";
      hasErrors = true;
    }
    if (bathRooms !== "" && isNaN(bathRooms)) {
      inputErrors.bathRooms = "Bath rooms should be a number.";
      hasErrors = true;
    }
    if (livingRooms !== "" && isNaN(livingRooms)) {
      inputErrors.livingRooms = "Living rooms should be a number.";
      hasErrors = true;
    }
    if (garage !== "" && isNaN(garage)) {
      inputErrors.garage = "Garage should be a number.";
      hasErrors = true;
    }
    if (floorArea !== "" && isNaN(floorArea)) {
      inputErrors.floorArea = "Floor area is invalid.";
      hasErrors = true;
    } else if (floorArea < 0) {
      inputErrors.floorArea = "Negative values not allowed";
      hasErrors = true;
    }
    if (rates !== "" && isNaN(rates)) {
      inputErrors.rates = "Rates is invalid.";
      hasErrors = true;
    } else if (rates < 0) {
      inputErrors.rates = "Negative values not allowed";
      hasErrors = true;
    }
    if (landArea !== "" && isNaN(landArea)) {
      inputErrors.landArea = "Land area is invalid.";
      hasErrors = true;
    } else if (landArea < 0) {
      inputErrors.landArea = "Negative values not allowed";
      hasErrors = true;
    }
    if (cv !== "" && isNaN(cv)) {
      inputErrors.cv = "CV is invalid.";
      hasErrors = true;
    } else if (cv < 0) {
      inputErrors.cv = "Negative values not allowed";
      hasErrors = true;
    }

    if (hasErrors) {
      setErrors(inputErrors);
    } else {
      updateProperty({
        variables: {
          details: {
            name,
            bedRooms: parseInt(`${bedRooms}`),
            bathRooms: parseInt(`${bathRooms}`),
            livingRooms: parseInt(`${livingRooms}`),
            garage: parseInt(`${garage}`),
            floorArea: parseFloat(`${floorArea}`),
            landArea: parseFloat(`${landArea}`),
            rates: parseFloat(`${rates}`),
            category,
            propertyType,
            region,
            cTFullDescription,
            localAuthority,
            zoning,
            cv,
            cvDate,
            cTNumber,
            lot,
            DP,
          },
          property: {
            property,
            unit,
          },
          changed: changedAttributes,
        },
      });
    }
  };

  return (
    <div className="edit-property-details">
      <div className="edit-property-details-item">
        <label>Name</label>
        <Input
          value={values.name}
          error={errors.name}
          placeholder="Name"
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            onChangeValue("name", value)
          }
        />
      </div>
      <div className="edit-property-details-item">
        <label>Bedrooms</label>
        <Select
          value={values.bedRooms}
          options={SELECT_VALUE}
          placeholder="Select Bedrooms"
          onChange={(value: string) => onChangeValue("bedRooms", value)}
        />
      </div>
      <div className="edit-property-details-item">
        <label>Bathrooms</label>
        <Select
          value={values.bathRooms}
          error={errors.bathRooms}
          options={SELECT_VALUE}
          placeholder="Select Bathrooms"
          onChange={(value: string) => onChangeValue("bathRooms", value)}
        />
      </div>
      <div className="edit-property-details-item">
        <label>Living rooms</label>
        <Select
          value={values.livingRooms}
          error={errors.livingRooms}
          options={SELECT_VALUE}
          placeholder="Select Living Rooms"
          onChange={(value: string) => onChangeValue("livingRooms", value)}
        />
      </div>
      <div className="edit-property-details-item">
        <label>Garage</label>
        <Select
          value={values.garage}
          error={errors.garage}
          options={SELECT_VALUE}
          placeholder="Select Garage"
          onChange={(value: string) => onChangeValue("garage", value)}
        />
      </div>
      <div className="edit-property-details-item">
        <label>Floor area (m2)</label>
        <Input
          value={values.floorArea}
          error={errors.floorArea}
          type="number"
          step={1}
          min={1}
          placeholder="Floor area"
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            onChangeValue("floorArea", value)
          }
        />
      </div>
      <div className="edit-property-details-item">
        <label>Land area (m2)</label>
        <Input
          value={values.landArea}
          error={errors.landArea}
          placeholder="Land area"
          type="number"
          step={1}
          min={1}
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            onChangeValue("landArea", value)
          }
        />
      </div>
      <div className="edit-property-details-item">
        <label>Rates</label>
        <Input
          value={values.rates}
          error={errors.rates}
          placeholder="Rates"
          type="number"
          step={0.01}
          min={0.01}
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
            if (!TWO_DECIMAL_NUMBER_REGEXP.test(value)) {
              const stringValue: string = `${value}`.slice(
                0,
                `${value}`.indexOf(".") + 3
              );
              onChangeValue("rates", stringValue);
            } else {
              onChangeValue("rates", value);
            }
          }}
        />
      </div>
      <div className="edit-property-details-item">
        <label>Region/State</label>
        <Select
          value={values.region}
          placeholder="Region/State"
          options={REGIONS}
          onChange={(value: string) => onChangeValue("region", value)}
        />
      </div>

      <div className="edit-property-details-item">
        <label>CT number</label>
        <Input
          value={values.cTNumber}
          error={errors.cTNumber}
          placeholder="CT number"
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            onChangeValue("cTNumber", value)
          }
        />
      </div>

      <div className="edit-property-details-item">
        <label>Lot</label>
        <Input
          value={values.lot}
          error={errors.lot}
          placeholder="Lot value"
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            onChangeValue("lot", value)
          }
        />
      </div>

      <div className="edit-property-details-item">
        <label>DP</label>
        <Input
          value={values.DP}
          error={errors.DP}
          placeholder="DP value"
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            onChangeValue("DP", value)
          }
        />
      </div>

      <div className="edit-property-details-item">
        <label>CT full description</label>
        <Input
          value={values.cTFullDescription}
          placeholder="CT full description"
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            onChangeValue("cTFullDescription", value)
          }
        />
      </div>
      <div className="edit-property-details-item">
        <label>Local authority</label>
        <Input
          value={values.localAuthority}
          placeholder="Local authority"
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            onChangeValue("localAuthority", value)
          }
        />
      </div>
      <div className="edit-property-details-item">
        <label>Zoning</label>
        <Input
          value={values.zoning}
          placeholder="Zoning"
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            onChangeValue("zoning", value)
          }
        />
      </div>
      <div className="edit-property-details-item">
        <label>CV($)</label>
        <Input
          value={values.cv}
          error={errors.cv}
          placeholder="CV"
          type="number"
          step={1}
          min={1}
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            onChangeValue("cv", parseInt(value).toString())
          }
        />
      </div>
      <div className="edit-property-details-item">
        <label>CV date</label>
        <DatePicker
          formatString="dd/MM/yyyy"
          placeholder="DD/MM/YYYY"
          selected={values.cvDate}
          placeholderText="Select cv date"
          onChange={(date: any) => onChangeValue("cvDate", date)}
        />
      </div>
      <div className="edit-property-details-item">
        <label>Category</label>
        <Select
          value={values.category}
          error={errors.category}
          placeholder="Category"
          options={categories}
          onChange={(value: string) => onChangeValue("category", value)}
        />
      </div>
      {types && (
        <div className="edit-property-details-item">
          <label>Property type</label>

          <Select
            value={values.propertyType}
            error={errors.propertyType}
            placeholder="Property type"
            options={types}
            onChange={(value: string) => onChangeValue("propertyType", value)}
          />
        </div>
      )}
      <div className="property-edit-btn-group">
        <Button
          isLoading={updating}
          kind={KIND.secondary}
          onClick={handleFormSubmit}
        >
          Save
        </Button>
        &nbsp;&nbsp;
        <Button kind={KIND.tertiary} onClick={onComplete}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default EditPropertyDetails;
