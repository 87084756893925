import React, { useState } from "react";
import { toaster } from "baseui/toast";
import { Option, Value } from "baseui/select";
import { ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal";
import { KIND as ButtonKind } from "baseui/button";

import MapUser from "../../map/MapUser";
import SelectWithStyling from "../../../components/select";
import { Props as CampaignProps } from "./";
import { defaultButtonStyles } from "components/Button";

import { MapUserRoleType, MapUserType } from "../../../types/map";

type Props = CampaignProps & {
  list: MapUserType[];
};

const AddCampaignUsers = ({
  list,
  selectedUsers,
  onSave,
  onClickCancel,
  roles,
  isRolesLoading,
  permissions,
}: Props) => {
  const [selectedUserList, setSelectedUserList] = useState<Value>(
    selectedUsers || []
  );

  const onUserListSaved = () => {
    let hasRoleError = false;
    selectedUserList.map(({ role }) => {
      if (!role) {
        hasRoleError = true;
      }
      return true;
    });
    if (hasRoleError) {
      toaster.negative("Select a role for all users.", {});
      return;
    }
    onSave(selectedUserList);
  };

  const onUserRoleChange = (userId: string, role: MapUserRoleType) => {
    const changedUsers = selectedUserList.map((user: Option) => {
      if (user.id === userId) {
        return {
          ...user,
          role,
        };
      }
      return user;
    });
    setSelectedUserList(changedUsers);
  };

  return (
    <>
      <ModalHeader />
      <ModalBody>
        <SelectWithStyling
          options={list}
          onChange={({ value }) => setSelectedUserList(value)}
          labelKey="name"
          value={selectedUserList}
          clearable={false}
          multi={true}
        />
        {selectedUserList.map((user: any) => (
          <MapUser
            user={user}
            onRoleChange={onUserRoleChange}
            roles={roles}
            isRolesLoading={isRolesLoading}
            permissions={permissions}
            key={user.id}
            hasEditPermission={true}
            onMapCreate={false}
          />
        ))}
      </ModalBody>
      <ModalFooter>
        <ModalButton
          overrides={{ BaseButton: { style: defaultButtonStyles } }}
          onClick={onClickCancel}
          kind={ButtonKind.tertiary}
        >
          Cancel
        </ModalButton>
        <ModalButton
          overrides={{ BaseButton: { style: defaultButtonStyles } }}
          onClick={onUserListSaved}
        >
          Save
        </ModalButton>
      </ModalFooter>
    </>
  );
};

export default AddCampaignUsers;
