import React, { useState } from "react";
import { toaster } from "baseui/toast";
import { ConfirmAlert } from "@virtualcapital/utogi-ui-library";

import MapLayer from "containers/maps/MapLayer";
import LayerEditor from "containers/campaign/LayerEditor";
import Drawer from "../../drawer";
import Button from "components/Button";

import { ILayer } from "interfaces/campaign";

import "./MapLayers.scss";
import WithScrollbar from "helpers/WithScrollbar";

type Props = {
  layers: [];
  disableEdit: boolean;
  setLayers: Function;
  hasEditMapSetupPermission: boolean;
};

const INITIAL_LAYER = {
  id: null,
  name: "",
  type: null,
  addTransition: false,
  addFade: false,
  transitionTime: 0,
  fadeTime: 0,
  primaryColor: "",
  secondaryColor: "",
};

const MapLayers = ({
  layers,
  disableEdit,
  setLayers,
  hasEditMapSetupPermission,
}: Props) => {
  const [layerPopup, setLayerPopup] = useState<{
    show: boolean;
    row: null | number;
    layer: null | ILayer;
    title: null | string;
  }>({
    show: false,
    row: null,
    layer: null,
    title: null,
  });

  const onLayerSave = (payload: ILayer) => {
    if (layerPopup.row !== null) {
      setLayers(
        layers.map((layer: ILayer, row: number) => {
          if (row === layerPopup.row) {
            return { ...payload, isEdited: layer.id !== null };
          }
          return layer;
        })
      );
      toaster.positive("Layer updated successfully!", {});
    } else {
      setLayers([...layers, payload]);
      toaster.positive("Layer added successfully!", {});
    }
    setLayerPopup({
      show: false,
      row: null,
      layer: null,
      title: null,
    });
  };

  const onLayerDelete = () => {
    ConfirmAlert({
      closeOnClickOutside: false,
      message: "The deleted layers no longer accessible on the Map.",
      onCancel: () => {},
      onConfirm: () => {
        if (layerPopup.layer?.id) {
          setLayers(
            layers.map((layer: ILayer, row: number) => {
              if (row === layerPopup.row) {
                return { ...layer, isDeleted: layer.id !== null };
              }
              return layer;
            })
          );
        } else {
          setLayers([
            ...layers.filter(
              (layer: ILayer, row: number) => row !== layerPopup.row
            ),
          ]);
        }
        setLayerPopup({
          show: false,
          row: null,
          layer: null,
          title: null,
        });
        toaster.positive("Layer deleted successfully.", {});
      },
    });
  };

  return (
    <div className="map-layers">
      <div className="map-layers-header">
        {!disableEdit && (
          <Button
            onClick={() =>
              hasEditMapSetupPermission
                ? setLayerPopup({
                    ...layerPopup,
                    show: true,
                    title: "Create New Activity/Layer",
                  })
                : toaster.negative(
                    "You do not have permission to create new layers.",
                    {}
                  )
            }
          >
            Create New Activity/Layer
          </Button>
        )}
      </div>
      <WithScrollbar noScrollX height="calc(100vh - 390px)">
        {layers.map((layer: ILayer, row: number) => {
          if (layer.isDeleted) {
            return null;
          }
          return (
            <MapLayer
              {...layer}
              onEditClicked={() => {
                hasEditMapSetupPermission
                  ? setLayerPopup({
                      ...layerPopup,
                      show: true,
                      row,
                      layer,
                      title: "Edit Activity/Layer",
                    })
                  : toaster.negative(
                      "You do not have permission to edit layers.",
                      {}
                    );
              }}
              key={row}
              disableEdit={disableEdit}
            />
          );
        })}
      </WithScrollbar>
      <Drawer
        isOpen={layerPopup.show}
        autoFocus
        onClose={() =>
          setLayerPopup({ ...layerPopup, show: false, row: null, layer: null })
        }
        header={layerPopup.title ? layerPopup.title : ""}
      >
        <LayerEditor
          onSave={onLayerSave}
          layer={layerPopup.layer || INITIAL_LAYER}
          onDelete={onLayerDelete}
          canDelete={layerPopup.row !== null}
        />
      </Drawer>
    </div>
  );
};

export default MapLayers;
