import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { IconButton, Switch } from "@virtualcapital/utogi-ui-library";
import { toaster } from "baseui/toast";
import { KIND, SIZE } from "baseui/button";

import CampaignLayers from "containers/campaign/CampaignLayers";
import Button from "components/Button";

import { MapStatus } from "constants/campaign";
import { PermissionType } from "constants/campaignTemplate";

import { ICampaignTemplate } from "interfaces/campaignTemplate";

import { isName } from "helpers/validations";

import "./CampaignTemplateDetails.scss";
import BaseTextarea from "components/base-textarea/base-textarea";
import BaseInput from "components/base-input/base-input";

const defaultPermission = {
  private: true,
  office: false,
  company: false,
};

export interface ICampaignTemplateDetailsParams {
  id: string;
}

const CampaignTemplateDetails = ({
  campaignTemplate,
  updateCampaignTemplate,
  edit,
  toggleEdit,
  updating,
}: {
  campaignTemplate: ICampaignTemplate;
  updateCampaignTemplate: Function;
  edit: boolean;
  toggleEdit: Function;
  updating: boolean;
}) => {
  const { id } = useParams<ICampaignTemplateDetailsParams>();
  const [template, editTemplate] = useState<any>({
    status: MapStatus.DRAFT,
    name: "",
    description: "",
    layers: [],
    idEdited: false,
  });
  const [errors, setErrors] = useState<any>({ name: null, description: null });
  const [permission, setPermission] = useState(defaultPermission);

  useEffect(() => {
    const {
      status,
      name,
      description,
      layers,
      accessLevels,
    } = campaignTemplate;
    if (accessLevels.length > 0) {
      setPermission({
        private: accessLevels.includes(PermissionType.PRIVATE),
        company: accessLevels.includes(PermissionType.COMPANY),
        office: accessLevels.includes(PermissionType.OFFICE),
      });
    }
    editTemplate({
      name,
      description,
      status,
      layers: layers
        ? layers.map((layer: any) => ({
            ...layer,
            isDeleted: false,
            isEdited: false,
            isAdded: false,
          }))
        : [],
    });
  }, [campaignTemplate]);

  const addLayer = (layer: any) => {
    editTemplate({
      ...template,
      isEdited: true,
      layers: [...template.layers, layer],
    });
    toaster.positive("Layer added successfully!", {});
  };

  const editLayer = (modified: any, row: number) => {
    editTemplate({
      ...template,
      isEdited: true,
      layers: template.layers.map((layer: any, key: number) => {
        if (row === key) {
          return {
            ...layer,
            ...modified,
            isEdited: !layer.isAdded,
          };
        }
        return layer;
      }),
    });
    toaster.positive("Layer updated successfully!", {});
  };

  const deleteLayer = (key: any): any => {
    if (template.layers.length === 1) {
      toaster.negative("Cannot remove the only remaining layer.", {});
      return;
    }

    editTemplate({
      ...template,
      isEdited: true,
      layers: template.layers.filter((_: any, index: number) => key !== index),
    });
    toaster.negative("Layer deleted successfully!", {});
  };

  const changePermission = (permissionType: PermissionType, val: boolean) => {
    switch (permissionType) {
      case PermissionType.PRIVATE:
        setPermission({
          office: false,
          company: false,
          private: val,
        });
        break;
      case PermissionType.COMPANY:
        setPermission({
          company: val,
          office: val,
          private: false,
        });
        break;
      case PermissionType.OFFICE:
        setPermission({
          office: val,
          company: false,
          private: false,
        });
        break;
      default:
        break;
    }
  };

  const saveCampaignHandler = () => {
    const { name, description, status, isPrivate, layers } = template;

    const newErrors: any = {
      name: null,
      description: null,
    };

    let hasError = false;

    switch (true) {
      case !name || name.trim() === "":
        newErrors.name = "Name is required.";
        hasError = true;
        break;
      case name && name.trim().length > 50:
        newErrors.name = "Maximum 50 characters.";
        hasError = true;
        break;
      case !isName(name):
        newErrors.name = "Only letters and numbers are allowed.";
        hasError = true;
        break;
      default:
        hasError = false;
    }

    if (!description || description.trim() === "") {
      newErrors.description = "Description is required.";
      hasError = true;
    } else if (description && description.trim().length > 250) {
      newErrors.description = "Maximum 250 characters.";
      hasError = true;
    }

    if (layers.length === 0) {
      toaster.negative(
        "You must create at least one layer in order to save this campaign.",
        {}
      );
      hasError = true;
    }

    setErrors(newErrors);

    if (hasError) {
      return false;
    }

    let currentPermission = [PermissionType.PRIVATE];
    if (permission.company) {
      currentPermission = [PermissionType.COMPANY, PermissionType.OFFICE];
    } else if (permission.office) {
      currentPermission = [PermissionType.OFFICE];
    }

    const variables = {
      id,
      name,
      description,
      status,
      isPrivate,
      accessLevels: currentPermission,
      layers: layers.map(
        ({
          type,
          name,
          primaryColor,
          secondaryColor,
          transitionTime,
          fadeTime,
          addTransition,
          addFade,
        }: any) => ({
          name,
          primaryColor,
          secondaryColor,
          transitionTime,
          fadeTime,
          addTransition,
          addFade,
          type: type?.id ? type.id : "",
        })
      ),
    };
    updateCampaignTemplate({ variables });
  };

  const {
    createdUser: { name: createdBy, time: createdTime },
    lastEditBy,
  } = campaignTemplate;

  useEffect(() => {
    if (lastEditBy !== null) {
      toggleEdit(true);
    }
  }, [lastEditBy, toggleEdit]);

  const { name, description, layers } = template;
  const { name: lastUpdatedUser, time: lastUpdatedTime } = lastEditBy || {};
  return (
    <>
      <div className="campaign-template-details">
        <div className="campaign-template-details-header">
          {edit ? (
            <div className="campaign-template-details-header-on-edit">
              <div className="form-group">
                <div className="form-group-item">
                  <BaseInput
                    value={name}
                    onChange={({ target: { value: name } }: any) =>
                      editTemplate({ ...template, name, isEdited: true })
                    }
                    placeholder="Name"
                    error={errors.name}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-item">
                  <BaseTextarea
                    value={description}
                    onChange={({ target: { value: description } }: any) =>
                      editTemplate({
                        ...template,
                        description,
                        isEdited: true,
                      })
                    }
                    placeholder="Description"
                    error={errors.description}
                  />
                </div>
              </div>

              <div className="form-group">
                <div className="form-group-item">
                  <div className="access-level">
                    <label>Who can use this template?</label>
                    <Switch
                      labels={["", "Company"]}
                      onChange={(val: boolean) =>
                        changePermission(PermissionType.COMPANY, val)
                      }
                      value={permission.company}
                    />
                    <Switch
                      labels={["", "Office"]}
                      onChange={(val: boolean) =>
                        changePermission(PermissionType.OFFICE, val)
                      }
                      value={permission.office}
                    />
                    <Switch
                      labels={["", "Private"]}
                      value={permission.private}
                      onChange={(val: boolean) =>
                        changePermission(PermissionType.PRIVATE, val)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="campaign-template-details-title">
                {name}{" "}
                <IconButton icon="edit" onClick={() => toggleEdit(true)} />
              </div>
              <span className="campaign-template-details-created-by">
                Created on{" "}
                {moment(new Date(createdTime)).format("DD MMMM YYYY")}
                &nbsp;by&nbsp;
                {createdBy}
              </span>
              <span className="campaign-template-details-updated-by">
                {lastEditBy && (
                  <>
                    Last updated&nbsp;
                    {moment(new Date(lastUpdatedTime)).format("DD MMMM YYYY")}
                    &nbsp; by&nbsp;
                    {lastUpdatedUser}
                  </>
                )}
              </span>
              <span className="campaign-template-details-description">
                {description}
              </span>
            </>
          )}
          {edit && (
            <div className="button-block">
              <Button
                kind={KIND.secondary}
                size={SIZE.compact}
                isLoading={updating}
                onClick={saveCampaignHandler}
              >
                Save Changes
              </Button>
            </div>
          )}
        </div>
        <div>
          <CampaignLayers
            layers={layers}
            deleteLayer={deleteLayer}
            addLayer={addLayer}
            editLayer={editLayer}
            disableEdit={campaignTemplate.status === MapStatus.ARCHIVE}
            canEdit={true}
          />
        </div>
      </div>
    </>
  );
};

export default CampaignTemplateDetails;
