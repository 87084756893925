import React from "react";
import { styled } from "baseui";
import { StatefulPopover } from "baseui/popover";
import { Button, SIZE, KIND, SHAPE } from "baseui/button";
import { Search } from "baseui/icon";
import { Block } from "baseui/block";

import PropertyAndPeopleSearch from "./PropertyAndPeopleSearch";
import { useMap } from "../../context/map/use-map";

const Wrapper = styled("div", () => ({
  padding: "0 10px",
  display: "flex",
  alignItems: "center",
  "@media (max-width: 768px)": {
    display: "none",
  },
}));

const Title = styled("div", () => ({
  color: "#666666",
  maxWidth: "320px",
  overflow: "hidden",
  fontSize: "15px",
  marginRight: "5px",
  "@media (max-width: 768px)": {
    display: "none",
  },
}));

type Props = {
  isAllMapMode: boolean;
};

const MapHeader: React.FC<Props> = ({ isAllMapMode }) => {
  const { selectedMaps } = useMap();
  return (
    <>
      <Wrapper>
        <Title>{isAllMapMode ? "All Maps" : selectedMaps[0].name}</Title>
        <PropertyAndPeopleSearch />
      </Wrapper>
      <StatefulPopover
        content={() => (
          <Block padding="10px" backgroundColor="#ffffff">
            <PropertyAndPeopleSearch />
          </Block>
        )}
        returnFocus
        autoFocus
      >
        <Button
          size={SIZE.mini}
          kind={KIND.secondary}
          shape={SHAPE.circle}
          overrides={{
            Root: {
              style: {
                marginLeft: "5px",
                marginRight: "auto",
                "@media (min-width: 769px)": {
                  display: "none",
                },
              },
            },
          }}
        >
          <Search />
        </Button>
      </StatefulPopover>
    </>
  );
};

export default MapHeader;
