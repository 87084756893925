import {
  AlertActions,
  AlertState,
  AlertStateActions,
  OpenAlertPayload,
} from "./alert.types";

export const reducer = (
  state: AlertState,
  action: AlertStateActions
): AlertState => {
  const openAlert = (payload: OpenAlertPayload): AlertState => ({
    ...state,
    ...payload,
    isOpen: true,
  });
  const closeAlert = () => ({
    isOpen: false,
  });

  switch (action.type) {
    case AlertActions.OPEN_ALERT:
      return openAlert(action.payload);
    case AlertActions.CLOSE_ALERT:
      return closeAlert();
    default:
      return state;
  }
};
