import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";

import Timeline from "../../map/Timeline/Timeline";
import Select from "components/SingleSelect";

import { canAccessContacts } from "helpers/permission/common";
import WithScrollbar from "helpers/WithScrollbar";

import "./TimelineTab.scss";

const GET_CONTACTS = gql`
  query GetTimelineListQuery($property: PropertyInputType!) {
    contact {
      propertyContact(properties: [$property]) {
        firstName
        lastName
        id
      }
    }
  }
`;

type Props = {
  unit: null | string;
  property: number;
  campaign: string;
};

const TimelineTab = ({ unit, property, campaign }: Props) => {
  const hasContactPermission = canAccessContacts();

  const [selectedTimeline, onTimelineChange] = useState("property");

  const params = {
    property: { property, unit },
    type: selectedTimeline === "property" ? "property" : "people",
    contact: selectedTimeline === "property" ? "" : selectedTimeline,
    campaign,
  };

  const { data: contacts } = useQuery(GET_CONTACTS, {
    variables: {
      property: { property, unit },
    },
    fetchPolicy: "no-cache",
    skip: !hasContactPermission,
  });

  return (
    <div className="timeline-tab">
      <div className="timeline-tab-header">
        <Select
          placeholder="Timelines"
          options={[
            { id: "property", label: "Property Timeline" },
            ...(contacts?.contact?.propertyContact.map(
              ({ id, firstName, lastName }: any) => ({
                id,
                label: `${firstName} ${lastName}`,
              })
            ) || []),
          ]}
          value={selectedTimeline}
          onChange={onTimelineChange}
        />
      </div>
      <div className="timeline-tab-content">
        <WithScrollbar noScrollX height="calc(100vh - 280px)">
          <Timeline variables={params} />
        </WithScrollbar>
      </div>
    </div>
  );
};

export default TimelineTab;
