import React from "react";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import config from "config";

const createClient = (token: string | null) => {
  const httpLink = createHttpLink({
    uri: `${config.DOMAIN_PROTOCOL}api.${config.DOMAIN_SUFFIX}/graphql`,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token || "",
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message }) => {
        if (message === "Unauthorized") {
          window.location.reload();
        }
      });
    if (networkError) console.error(`[Network error]: ${networkError}`);
  });

  return new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
  });
};

const client = ({
  token,
  children,
}: {
  token: string | null;
  children: React.ReactNode;
}) => <ApolloProvider client={createClient(token)}>{children}</ApolloProvider>;

export default client;
