import { Button, ButtonType } from "@virtualcapital/utogi-ui-library";
import { useEffect, useState } from "react";
import ReactDOM, { createPortal } from "react-dom";
import "./styles.scss";

// Private portal for confirm
const Portal = ({ children }: { children: any }) => {
  const mount = document.getElementById("confirm-root") as HTMLElement;
  const el = document.createElement("div");

  useEffect((): any => {
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el, mount]);

  return createPortal(children, el);
};

type Props = {
  message: string;
  resolver: Function;
};

export const ConfirmAlert = ({ resolver, message }: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  const onConfirmPopup = () => {
    resolver(true);
    setIsOpen(false);
  };

  const onCancelPopup = () => {
    resolver(false);
    setIsOpen(false);
  };

  return isOpen ? (
    <>
      <div className="confirm-alert">
        <div className="confirm-alert__content">
          <span>{message}</span>
          <div className="confirm-alert__actions">
            <Button hasShadow onClick={onCancelPopup}>
              No
            </Button>
            <Button
              hasShadow
              type={ButtonType.PRIMARY}
              onClick={onConfirmPopup}
            >
              Yes
            </Button>
          </div>
        </div>
      </div>
      <div className="confirm-alert__overlay" />
    </>
  ) : null;
};

export const confirmAlert = (message: string) => {
  return new Promise((resolve, reject) => {
    ReactDOM.render(
      <Portal>
        <ConfirmAlert resolver={resolve} message={message} />{" "}
      </Portal>,
      document.getElementById("confirm-root")
    );
  });
};
