import React, { FC } from "react";
import { arrayMove, List } from "baseui/dnd-list";
import { Spinner } from "baseui/spinner";

import FilterPopup from "components/map/FilterPopup";
import FilterUserRow from "./FiltersUsersRow";

import { useMap } from "context/map/use-map";

import { IUserFilter } from "interfaces/user";

type Props = {
  close: () => void;
};

const FilterUsers: FC<Props> = ({ close }) => {
  const { users, setUsers } = useMap();

  const onUserSelected = (id: string, isSelected: boolean) => {
    if (users) {
      setUsers([
        ...users.map((user: IUserFilter) => {
          if (id === user.id) {
            return {
              ...user,
              isSelected,
            };
          }
          return user;
        }),
      ]);
    }
  };

  return (
    <FilterPopup icon="profile" title="Users" close={close}>
      <div style={{ maxHeight: "calc(100vh - 200px)", overflowY: "scroll" }}>
        <div style={{ padding: "14px 20px" }}>
          {users === null ? (
            <Spinner $color="#00AEEF" />
          ) : (
            <List
              items={users}
              onChange={({ oldIndex, newIndex }) =>
                setUsers(arrayMove(users, oldIndex, newIndex))
              }
              overrides={{
                Label: {
                  component: ({ $value }: any) => (
                    <FilterUserRow
                      {...$value}
                      onSelectChange={onUserSelected}
                    />
                  ),
                },
                Item: {
                  style: () => ({
                    paddingTop: "10px",
                    paddingLeft: "10px",
                    paddingBottom: "10px",
                    paddingRight: "10px",
                    marginBottom: "10px",
                    boxShadow: "0 3px 6px #00000016",
                    borderRadius: "5px",
                  }),
                },
              }}
            />
          )}
        </div>
      </div>
    </FilterPopup>
  );
};

export default FilterUsers;
