import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { useEffect, useState } from "react";

interface Props {
  children: any;
}

export const FETCH_SIGNATURES = gql`
  {
    email {
      signature {
        id
        name
        body
      }
    }
  }
`;

export const EmailSignatureContext: any = React.createContext({});

export const EmailSignatureContextProvider = ({ children }: Props) => {
  const [signature, setSignature]: [any, Function] = useState(null);

  const { data, refetch, loading } = useQuery(FETCH_SIGNATURES, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data?.email?.signature) {
      const [signature] = data.email.signature;

      setSignature(signature || {});
    }
  }, [data]);

  return (
    <EmailSignatureContext.Provider value={{ signature, refetch, loading }}>
      {children}
    </EmailSignatureContext.Provider>
  );
};
