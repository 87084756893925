import React from "react";
import Layout from "components/layout";

import { Route, Switch as RouterSwitch } from "react-router-dom";

import CampaignTemplateList from "containers/campaignTemplate/CampaignTemplateListContainer";
import CreateCampaignTemplate from "containers/campaignTemplate/CreateCampaignTemplate";
import CampaignTemplateDetails from "containers/campaignTemplate/CampaignTemplateDetails";

import "./styles.scss";

export default function Templates() {
  return (
    <Layout>
      <RouterSwitch>
        <Route path="/template" exact>
          <CampaignTemplateList />
        </Route>
        <Route path="/template/create" exact>
          <CreateCampaignTemplate />
        </Route>
        <Route path="/template/view/:id">
          <CampaignTemplateDetails />
        </Route>
      </RouterSwitch>
    </Layout>
  );
}
