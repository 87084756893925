import { useLazyQuery, useMutation, ApolloError } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useStyletron } from "baseui";
import { Button, KIND } from "baseui/button";
import { Search } from "baseui/icon";
import { Input } from "baseui/input";
import { OptionProfile, StatefulMenu } from "baseui/menu";
import React, { useEffect, useRef, useState } from "react";
import { toaster } from "baseui/toast";

import { Contact } from "types/contact";

import { ADD_CONTACT_TO_PROPERTY } from "./graphql";

import { event } from "../../../../events";
import { REFETCH_PROSPECT_MAP } from "../../../../events/prospect";

const GET_AUTOCOMPLETE_CONTACTS = gql`
  query searchContacts($keyword: String) {
    contact {
      list(pagination: { perPage: 5, page: 1 }, keyword: $keyword) {
        contacts {
          id
          displayName
          firstName
          lastName
          mobileNumber
          email
          landline
        }
      }
    }
  }
`;

type Props = {
  property: number;
  unit: string | null;
  refetchPropertyContacts: Function;
};

const ContactSearch: React.FC<Props> = ({
  property,
  unit,
  refetchPropertyContacts,
}) => {
  const [css] = useStyletron();
  const wrapperRef = useRef<any>(null);

  const [keyword, setKeyword] = useState("");
  const [suggestions, setSuggestions] = useState<null | Contact[]>(null);

  const [callAutocompleteQuery, { loading }] = useLazyQuery(
    GET_AUTOCOMPLETE_CONTACTS,
    {
      onCompleted: (data) => {
        if (keyword && keyword.length > 2) {
          setSuggestions(data?.contact?.list?.contacts);
        }
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    }
  );

  const [addContactToProperty, { loading: mutationLoading }] = useMutation(
    ADD_CONTACT_TO_PROPERTY,
    {
      onError: ({ graphQLErrors }: ApolloError) => {
        graphQLErrors.map(({ message }) => toaster.negative(message, {}));
      },
      onCompleted: () => {
        setKeyword("");
        setSuggestions(null);
        event.dispatch(REFETCH_PROSPECT_MAP);
        toaster.positive("Contact added successfully!", {});
        refetchPropertyContacts();
      },
    }
  );

  const onKeydown = (e: any) => {
    if (e.key === "Enter") {
      setSuggestions(null);
    }
  };

  const onSearchClicked = (e: any) => {
    setSuggestions(null);
  };

  useEffect(() => {
    setSuggestions(null);
    if (keyword && keyword.length > 2) {
      callAutocompleteQuery({ variables: { perPage: 5, page: 1, keyword } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setSuggestions(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div
      className={css({
        position: "relative",
        flex: 1,
      })}
      ref={wrapperRef}
    >
      <Input
        value={keyword}
        onChange={(e: any) => setKeyword(e.target.value)}
        placeholder="Search"
        clearOnEscape
        onKeyDown={onKeydown}
        endEnhancer={() => (
          <Button
            kind={KIND.tertiary}
            isLoading={loading || mutationLoading}
            onClick={onSearchClicked}
          >
            <Search />
          </Button>
        )}
        overrides={{
          Root: {
            style: { paddingRight: 0 },
          },
          EndEnhancer: {
            style: { paddingRight: 0, paddingLeft: 0 },
          },
        }}
      />
      {suggestions && (
        <StatefulMenu
          items={suggestions}
          onItemSelect={({ item: { id: contact } }) =>
            addContactToProperty({
              variables: {
                contact,
                property: { unit, property },
              },
            })
          }
          overrides={{
            List: {
              style: {
                paddingTop: "5px",
                paddingBottom: "5px",
                position: "absolute",
                zIndex: 1000,
                top: "44px",
                width: "100%",
              },
            },
            Option: {
              component: OptionProfile,
              props: {
                getProfileItemLabels: ({
                  displayName,
                  firstName,
                  lastName,
                  mobileNumber,
                  landline,
                  email,
                }: Contact) => ({
                  title: displayName || `${firstName} ${lastName}`,
                  subtitle: [
                    ...(email ? [email] : []),
                    ...(mobileNumber ? [mobileNumber] : []),
                    ...(landline ? [landline] : []),
                  ].join(" "),
                }),
                getProfileItemImg: () => null,
                getProfileItemImgText: () => null,
                overrides: {
                  ProfileImgContainer: { style: { display: "none" } },
                  ListItemProfile: { style: { paddingLeft: "5px" } },
                },
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default ContactSearch;
