import { useLazyQuery } from "@apollo/react-hooks";
import { toaster } from "baseui/toast";
import { EmailSignatureContext } from "context/email-signature-context/email-signature-context";
import React, { useContext, useEffect, useState } from "react";
import ComposeEmail from "./components/compose";
import { FETCH_DRAFT } from "./query";
import { Spinner } from "baseui/spinner";
import Drawer from "../../../../components/drawer";
import { IPropertyIdentifier } from "../interface";
import { Contact } from "types/contact";
import { GET_PROPERTY_CONTACTS } from "../Actions/query";

type Props = {
  show: boolean;
  onDelete?: Function;
  onSent?: Function;
  draftId?: string;
  closeModal: Function;
  properties?: IPropertyIdentifier[];
};

const ComposeEmailContainer = ({
  show,
  onDelete,
  onSent,
  draftId,
  closeModal,
  properties,
}: Props) => {
  const [selectedContacts, setSelectedContacts] = useState(null);

  const [fetchDraft, { loading, data }] = useLazyQuery(FETCH_DRAFT, {
    fetchPolicy: "no-cache",
    onError: (e) => toaster.negative("Error", {}),
  });

  const [getPropertyContacts, { loading: contactsLoading }] = useLazyQuery(
    GET_PROPERTY_CONTACTS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data?.contact?.propertyContact?.length > 0) {
          setSelectedContacts(
            data.contact.propertyContact
              .filter(({ email }: any) => email && email.length)
              .map(({ displayName, firstName, lastName, email }: Contact) => ({
                name: displayName || `${firstName} ${lastName}`,
                email,
              }))
          );
        }
      },
    }
  );

  const { signature: contextSignature, loading: loadingSignature } = useContext(
    EmailSignatureContext
  );

  useEffect(() => {
    if (draftId) fetchDraft({ variables: { id: draftId } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftId]);

  useEffect(() => {
    if (properties && properties.length > 0)
      getPropertyContacts({ variables: { properties } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let pageLoading = loading || loadingSignature || contactsLoading;

  const getContent = () => {
    if (pageLoading || !selectedContacts) {
      return <Spinner />;
    } else {
      if (draftId) {
        if (data?.email?.draft) {
          return (
            <ComposeEmail
              onDelete={onDelete}
              closeModal={closeModal}
              onSent={onSent}
              draft={data.email.draft}
              contacts={selectedContacts}
              properties={properties}
            />
          );
        } else {
          return <Spinner />;
        }
      } else {
        return (
          <ComposeEmail
            onDelete={onDelete}
            closeModal={closeModal}
            onSent={onSent}
            body={contextSignature?.body || ""}
            contacts={selectedContacts}
            properties={properties}
          />
        );
      }
    }
  };

  return (
    <Drawer isOpen={show} header="Send Email" onClose={() => closeModal()}>
      {getContent()}
    </Drawer>
  );
};

export default ComposeEmailContainer;
