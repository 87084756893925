import { MapStatus } from "constants/campaign";

export const getStatusBackgroundColor = (status: MapStatus) => {
  switch (status) {
    case MapStatus.ACTIVE:
      return "#00D83A";
    default:
      return "#65DAFF";
  }
};
