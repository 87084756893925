import { styled } from "baseui";
import {
  Checkbox,
  CheckboxProps,
  LABEL_PLACEMENT,
  STYLE_TYPE,
} from "baseui/checkbox";
import React from "react";

export const ToggleContainer = styled("div", () => ({
  marginBottom: "10px",
  display: "flex",
  alignItems: "center",
}));

const Toggle: React.FC<CheckboxProps> = (props) => (
  <Checkbox
    {...props}
    checkmarkType={STYLE_TYPE.toggle_round}
    labelPlacement={LABEL_PLACEMENT.right}
    overrides={{
      Toggle: {
        style: ({ $checked }) => ({
          backgroundColor: $checked ? "#2DCA73" : "#fff",
        }),
      },
      Label: {
        style: () => ({
          color: "#707070",
        }),
      },
    }}
  />
);

export default Toggle;
