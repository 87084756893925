import config from "config";

export const resolveImageUrl = (url: string) =>
  `${config.DOMAIN_PROTOCOL}images.${config.DOMAIN_SUFFIX}${url}`;

export const resolveImageUploadUrl = () =>
  `${config.DOMAIN_PROTOCOL}api.${config.DOMAIN_SUFFIX}/email/images`;

export const processImage = (
  image: string,
  width: number = 100,
  height: number = 100
) => {
  return `${config.DOMAIN_PROTOCOL}://images.${config.DOMAIN_SUFFIX}/images/${width}x${height}/${image}`;
};
