import { gql } from "apollo-boost";

export const SEARCH_CONTACTS = gql`
  query searchContacts($keyword: String!, $limit: Int) {
    email {
      contact(keyword: $keyword, limit: $limit) {
        firstName
        lastName
        email
      }
    }
  }
`;
