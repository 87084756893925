export enum MapPermission {
  INVITE_OTHER_USERS = "inviteOtherUsers",
  UPDATE_THE_OWNER = "updateTheOwner",
  EDIT_MAP_SETUP = "editMapSetup",
  ADD_LAYERS = "addLayers",
  ADD_TAGS = "addTags",
  ADD_NOTES = "addNotes",
  VIEW_TEAM_VIEW = "viewTeamView",
  UPDATE_THE_STATUS_OF_THE_MAP = "updateTheStatusOfTheMap",
}
