import { Icon } from "@virtualcapital/utogi-ui-library";
import { styled } from "baseui";

export const ColorFilter = styled("div", ({ background }: any) => ({
  background,
  width: "32px",
  height: "32px",
  display: "block",
  marginRight: "15px",
  borderRadius: "5px",
  boxShadow: "2px 2px 8px rgb(0 0 0 / 9%)",
}));

export const FadeOption = styled("div", ({ background }: any) => ({
  background,
  width: "32px",
  height: "32px",
  display: "block",
  marginRight: "15px",
  borderRadius: "5px",
  boxShadow: "2px 2px 8px rgb(0 0 0 / 9%)",
}));

export const LabelContainer = styled("div", () => ({
  display: "flex",
  alignItems: "center",
}));

export const SectionHeader = styled("div", () => ({
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
  color: "#666666",
}));

export const SectionHeaderIcon = styled(Icon, () => ({
  marginRight: "10px",
  backgroundColor: "#F99C1C",
  color: "#ffffff",
  padding: "5px",
  borderRadius: "50%",
}));

export const SectionGroup = styled("div", () => ({
  color: "#666666",
}));

export const SectionGroupTitle = styled("span", () => ({
  marginBottom: "5px",
  display: "block",
  fontWeight: 700,
}));

export const SectionGroupInner = styled("div", () => ({
  display: "flex",
  alignItems: "center",
  color: "#666666",
}));

export const SectionGroupInnerIcon = styled(Icon, () => ({
  marginRight: "10px",
}));

export const SelectContainer = styled("div", () => ({
  display: "flex",
  alignItems: "center",
  marginTop: "15px",
}));

export const Hr = styled("hr", () => ({
  border: "1px solid #F7F7F7!important",
  margin: "15px -25px !important",
}));

export const LayerHeader = styled("div", () => ({
  marginBottom: "15px",
}));

export const ActionButton = styled("button", ({ isPinned }: any) => ({
  backgroundColor: "#fff",
  outline: "none  ",
  border: "none",
  borderRadius: "50%",
  padding: "5px 6px",
  color: "#7a7a7a",
  transform: `rotate(${isPinned ? 180 : 0}deg)`,
  "@media (max-width: 768px)": {
    display: "none",
  },
}));
