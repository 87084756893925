import moment from "moment";

export function getTimeInMinutes(time: string) {
  const formattedTime = moment(new Date(time)).format("HH:mm");
  const timeParts = formattedTime.split(":");

  return parseInt(timeParts[0]) * 60 + parseInt(timeParts[1]);
}

export const formatTime = (time: string): Date => {
  const date = new Date();
  const [hours, minutes] = time.split(":");
  date.setHours(parseInt(hours));
  date.setMinutes(parseInt(minutes));
  return date;
};

export function toTitleCase(text: string) {
  return text.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()
  );
}
