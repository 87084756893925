import { styled } from "baseui";
import { Drawer as BaseDrawer, DrawerProps, SIZE } from "baseui/drawer";
import React, { ReactNode } from "react";

type Props = DrawerProps & { header?: ReactNode; width?: string };

const Header = styled("div", () => ({
  background: "#00adf9",
  padding: "15px 20px",
  color: "#ffffff",
}));

const Body = styled("div", () => ({
  padding: "20px",
  flex: 1,
}));

const Drawer: React.FC<Props> = ({ children, header, width, ...props }) => {
  return (
    <BaseDrawer
      overrides={{
        Close: {
          style: () => ({
            backgroundColor: "#ffffff !important",
            borderRadius: "50%",
          }),
        },
        DrawerBody: {
          style: () => ({
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 0,
            width: width ? width : "700px",
            maxWidth: "100%",
            display: "flex",
            flexDirection: "column",
          }),
        },
      }}
      size={SIZE.auto}
      {...props}
    >
      {header && <Header>{header}</Header>}
      <Body>{children}</Body>
    </BaseDrawer>
  );
};

export default Drawer;
