import { AuthContext, Logout } from "@virtualcapital/utogi-auth-library";
import { styled, useStyletron } from "baseui";
import { Overflow, ChevronUp, ChevronDown } from "baseui/icon";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import React, { useContext, useEffect, useState } from "react";
import config from "config";
import { gql, useMutation } from "@apollo/client";
import { toaster } from "baseui/toast";
import Toggle from "components/toggle";

const UPDATE_BETA = gql`
  mutation($beta: Boolean) {
    user {
      update(beta: $beta) {
        id
      }
    }
  }
`;

const Link = styled("a", () => ({
  color: "#777777",
  fontSize: "12px",
  display: "block",
  padding: "10px",
}));

const Button = styled("button", () => ({
  color: "#777777",
  display: "flex",
  outline: "none",
  border: "none",
  justifyContent: "space-between",
  fontSize: "12px",
  background: "none",
  padding: "10px",
  width: "100%",
}));

const UserMenu = () => {
  const [css] = useStyletron();
  const [isOpen, setIsOpen] = useState(false);
  const [isRoleMenuOpen, setIsRoleMenuOpen] = useState(false);
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);
  const [isBetaTurnedOn, setBetaTurnedOn] = useState(false);
  const {
    authData: { links, user, office, currentRole: role, token, beta },
  }: any = useContext(AuthContext);

  const [updateBeta, { loading: updatingBeta }] = useMutation(UPDATE_BETA, {
    onCompleted: (data) => {
      if (data?.user?.update?.id) {
        window.location.reload();
      }
    },
    onError: () => {
      toaster.negative("Failed to update beta status", {});
    },
  });

  const onBetaUpdated = (beta: boolean) => {
    setBetaTurnedOn(beta);
    updateBeta({ variables: { beta } });
  };

  useEffect(() => {
    setBetaTurnedOn(beta);
  }, [beta]);

  const getMenu = () => {
    return (
      <div className={css({ backgroundColor: "#ffffff", padding: "15px" })}>
        <Link href={links.mySettings}>My Profile</Link>
        <Toggle
          checked={isBetaTurnedOn}
          onChange={(e: any) => onBetaUpdated(e.target.checked)}
          disabled={updatingBeta}
        >
          <span style={{ fontSize: "12px" }}>Beta Features</span>
        </Toggle>
        <Button onClick={() => setIsRoleMenuOpen(!isRoleMenuOpen)}>
          User Role
          {isRoleMenuOpen ? (
            <ChevronUp color="#777777" size="15px" />
          ) : (
            <ChevronDown color="#777777" size="15px" />
          )}
        </Button>
        {isRoleMenuOpen && (
          <div className={css({ paddingLeft: "10px" })}>
            {links?.roles?.map(({ title, link, office }: any, key: number) => (
              <Link href={link} key={key}>
                {title}
                <span className={css({ display: "block", color: "#b1b1b1" })}>
                  {office}
                </span>
              </Link>
            ))}
          </div>
        )}
        <Button onClick={() => setIsSettingsMenuOpen(!isSettingsMenuOpen)}>
          Setting{" "}
          {isSettingsMenuOpen ? (
            <ChevronUp color="#777777" size="15px" />
          ) : (
            <ChevronDown color="#777777" size="15px" />
          )}
        </Button>
        {isSettingsMenuOpen && (
          <div className={css({ paddingLeft: "10px" })}>
            {links?.companySettings && (
              <Link href={links.companySettings}>Company Settings</Link>
            )}
            {links?.officeSettings && (
              <Link href={links.officeSettings}>Office Settings</Link>
            )}
          </div>
        )}
        <Logout
          token={token}
          domainProtocol={config.DOMAIN_PROTOCOL}
          domainSuffix={config.DOMAIN_SUFFIX}
        />
      </div>
    );
  };

  return (
    <StatefulPopover
      content={getMenu}
      placement={PLACEMENT.bottom}
      overrides={{ Body: { style: { minWidth: "185px" } } }}
    >
      <button
        className={css({
          display: "flex",
          alignItems: "center",
          background: "none",
          outline: "none",
          border: "none",
          cursor: "pointer",
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div
          className={css({
            display: "flex",
            alignItems: "center",
          })}
        >
          <div
            className={css({
              fontSize: "14px",
              color: "#00AEEF",
              textAlign: "left",
              "@media (max-width: 768px)": {
                maxWidth: "240px",
              },
            })}
          >
            <div
              className={css({
                "@media (max-width: 768px)": {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              })}
            >
              {user?.name}
            </div>
            <div
              className={css({
                fontSize: "12px",
                "@media (max-width: 768px)": {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              })}
            >
              {office?.name}&nbsp;({role?.title})
            </div>
          </div>
          <Overflow
            color="#00AEEF"
            size="30px"
            overrides={{ Svg: { style: { transform: "rotate(90deg)" } } }}
          />
        </div>
      </button>
    </StatefulPopover>
  );
};

export default UserMenu;
