export enum Actions {
  IS_TAGS_SHARED_WITH_COMPANY = 100,
  IS_TAGS_SHARED_WITH_OFFICE,
  IS_TAGS_SHARED_WITH_GROUP,
  IS_TAGS_SHARED,
  SET_INITIAL_SETTINGS,
}

const IS_TAGS_SHARED = "IS_TAGS_SHARED";

const reduceOnCompanyChange = (state: any, payload: any, prefix: string) => {
  let newState = {};
  if (payload) {
    newState = {
      [`${prefix}_WITH_OFFICE`]: true,
      [`${prefix}_WITH_GROUP`]: true,
      [prefix]: true,
    };
  }
  return {
    ...state,
    [`${prefix}_WITH_COMPANY`]: payload,
    ...newState,
  };
};

const reduceOnOfficeChange = (state: any, payload: any, prefix: string) => {
  let newState = {};
  if (payload) {
    newState = {
      [`${prefix}_WITH_GROUP`]: true,
      [prefix]: true,
    };
  } else {
    newState = {
      [`${prefix}_WITH_COMPANY`]: false,
    };
  }
  return {
    ...state,
    [`${prefix}_WITH_OFFICE`]: payload,
    ...newState,
  };
};

const reduceOnGroupeChange = (state: any, payload: any, prefix: string) => {
  let newState = {};
  if (payload) {
    newState = {
      [prefix]: true,
    };
  } else {
    newState = {
      [`${prefix}_WITH_COMPANY`]: false,
      [`${prefix}_WITH_OFFICE`]: false,
      [prefix]: false,
    };
  }
  return {
    ...state,
    [`${prefix}_WITH_GROUP`]: payload,
    ...newState,
  };
};

const reduceOnSharedChange = (state: any, payload: any, prefix: string) => {
  let newState = {};
  if (!payload) {
    newState = {
      [`${prefix}_WITH_GROUP`]: true,
      [prefix]: true,
    };
  } else {
    newState = {
      [`${prefix}_WITH_GROUP`]: false,
      [`${prefix}_WITH_COMPANY`]: false,
      [`${prefix}_WITH_OFFICE`]: false,
      [prefix]: false,
    };
  }
  return {
    ...state,
    ...newState,
  };
};

export const reducer = (state: any, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.SET_INITIAL_SETTINGS:
      return { ...state, ...payload };

    // Tags Setting
    case Actions.IS_TAGS_SHARED_WITH_COMPANY:
      return reduceOnCompanyChange(state, payload, IS_TAGS_SHARED);

    case Actions.IS_TAGS_SHARED_WITH_OFFICE:
      return reduceOnOfficeChange(state, payload, IS_TAGS_SHARED);

    case Actions.IS_TAGS_SHARED_WITH_GROUP:
      return reduceOnGroupeChange(state, payload, IS_TAGS_SHARED);

    case Actions.IS_TAGS_SHARED:
      return reduceOnSharedChange(state, payload, IS_TAGS_SHARED);

    default:
      return { ...state };
  }
};
