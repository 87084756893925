import React from "react";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import queryString from "query-string";
import { useQuery, gql } from "@apollo/client";

import CampaignTemplateList from "./CampaignTemplateList";
import Loader from "components/loader";

export type CampaignTemplateListProps = {
  type: string;
  keyword: string;
};

const CampaignTemplateListContainer = ({
  type,
  keyword,
  location: { search },
}: RouteComponentProps & CampaignTemplateListProps) => {
  const GET_CAMPAIGN_TEMPLATE = gql`
    query($type: String!, $keyword: String!, $pagination: PaginationParams) {
      campaignTemplate {
        list(type: $type, keyword: $keyword, pagination: $pagination) {
          templates {
            id
            name
            description
            createdUser {
              id
              name
            }
            lastEditBy {
              time
            }
            createdAt
          }
          pagination {
            page
            total
            perPage
          }
        }
      }
    }
  `;

  // @ts-ignore
  const params: {
    page: string;
    perPage: string;
  } = queryString.parse(search);
  const page = params.page ? parseInt(params.page) : 1;
  const perPage = params.perPage ? parseInt(params.perPage) : 10;

  const { loading, error, data, refetch: refetchTemplate } = useQuery(
    GET_CAMPAIGN_TEMPLATE,
    {
      variables: {
        type,
        keyword,
        pagination: {
          page,
          perPage,
        },
      },
      fetchPolicy: "no-cache",
    }
  );
  // Handler errors
  if (loading || error) {
    return <Loader />;
  }

  const gotoNextPage = (pagination: any) => {
    refetchTemplate({
      type,
      keyword,
      pagination,
    });
  };
  const {
    campaignTemplate: { list },
  } = data;
  return (
    <CampaignTemplateList
      {...list}
      gotoNextPage={gotoNextPage}
      refetchTemplate={refetchTemplate}
    />
  );
};

export default withRouter(CampaignTemplateListContainer);
