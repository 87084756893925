import { useMutation } from "@apollo/react-hooks";
import { AuthContext } from "@virtualcapital/utogi-auth-library";
import { gql } from "apollo-boost";
import { toaster } from "baseui/toast";
import config from "config";
import React, { useContext, useEffect } from "react";
import {
  EmailEditorContext,
  EMAIL_CONTEXT_ATTACHMENT_ADDED,
} from "../../emailEditorContext";
import AttachmentFile from "../attachmentFile";

const MUTATION = gql`
  mutation($file: GraphQLUpload!, $url: String!) {
    email {
      upload(file: $file, url: $url) {
        id
        object
        accountId
        contentType
        size
        filename
      }
    }
  }
`;

function resolveImageUploadUrl(ID = 1) {
  return `${config.DOMAIN_PROTOCOL}api.${config.DOMAIN_SUFFIX}/email/images`;
}

const FileUploadComponent = ({ inputFile }: any) => {
  const {
    authData: { token },
  } = useContext(AuthContext);

  const [uploadFileList, { data, loading }] = useMutation(MUTATION);
  const { dispatch } = useContext(EmailEditorContext);

  const getFile = async (formData: any) => {
    return fetch(resolveImageUploadUrl(), {
      method: "POST",
      headers: {
        authorization: token || "",
      },
      body: formData,
    });
  };

  useEffect(() => {
    const file = new Blob([inputFile], { type: inputFile.type });

    const formData = new FormData();
    formData.append(inputFile.name, inputFile);
    // @ts-ignore
    file.name = inputFile.name;
    const fileSizeInMb = inputFile.size / (1024 * 1024);
    if (fileSizeInMb > 25) {
      toaster.negative("Cannot upload files larger than 25 MB", {});
      return;
    }

    try {
      getFile(formData)
        .then(async (response) => {
          let result = await response.json();
          const { data } = result;
          uploadFileList({
            variables: {
              file: { filename: inputFile.name, mimetype: inputFile.type },
              url: data.key,
            },
          });
        })
        .catch((e) => {
          toaster.negative("Error", {});
        });
    } catch (e) {
      toaster.negative("Error", {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFile]);

  useEffect(() => {
    if (data?.email?.upload) {
      const file = { ...data.email.upload };
      file.name = inputFile.name;
      dispatch({ type: EMAIL_CONTEXT_ATTACHMENT_ADDED, payload: file });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // TODO: call the mutation to remove the file
  function removeUpload() {}

  // When uploading is done the file will render using the editor context state
  if (!loading) return null;

  return (
    <AttachmentFile
      name={inputFile.name}
      size={inputFile.size}
      uploading={loading}
      onRemove={() => removeUpload()}
    />
  );
};

export default FileUploadComponent;
