import { toaster } from "baseui/toast";
import React, { useContext, useState } from "react";
import {
  EmailEditorContext,
  EMAIL_CONTEXT_ATTACHMENT_REMOVED,
} from "../../emailEditorContext";
import AttachmentFile from "../attachmentFile";
import FileUploadComponent from "../fileUploadComponent";

const Attachments = React.forwardRef((props, ref) => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const {
    state: editorContextState,
    dispatch: dispatchEditorContext,
  } = useContext(EmailEditorContext);

  async function onFileChange({ target: { validity, files } }: any) {
    const file: any = new Blob([files], { type: files[0].type });
    file.name = files[0].name;
    try {
      if (validity.valid) setSelectedFile(files);
    } catch (error) {
      toaster.negative("Error", {});
    }
  }

  const onFileRemoved = (id: string) => {
    dispatchEditorContext({
      type: EMAIL_CONTEXT_ATTACHMENT_REMOVED,
      payload: id,
    });
  };

  return (
    <>
      {editorContextState.files &&
        editorContextState.files.map((file: any, index: number) => (
          <AttachmentFile
            key={index}
            name={file.filename}
            size={file.size}
            uploading={false}
            onRemove={() => onFileRemoved(file.id)}
          />
        ))}
      {selectedFile &&
        Object.keys(selectedFile).map((key, index) => (
          <FileUploadComponent inputFile={selectedFile[key]} key={index} />
        ))}
      <input
        style={{ display: "none" }}
        type="file"
        onChange={onFileChange}
        multiple
        // @ts-ignore
        ref={ref}
      />
    </>
  );
});

export default Attachments;
