import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { useEffect } from "react";
import { Notification, KIND } from "baseui/notification";

import TimelineLoader from "./TimelineLoader";
import TimelineSection, { Order } from "./TimelineSection";

import { event } from "../../../events";
import { ON_TIMELINE_EVENT_MODIFIED } from "events/prospect";

type Props = {
  variables: any;
};

const Timeline: React.FC<Props> = ({ variables }) => {
  const GET_TIMELINES = gql`
    fragment fields on GraphQLTimeline {
      id
      type
      data
      createdAt
      reference
      assignedUsers {
        name
      }
      createdUser {
        id
        name
      }
    }
    query GetTimelineListQuery(
      $property: PropertyInputType!
      $type: String!
      $campaign: String!
      $contact: String!
    ) {
      timeline {
        fromProperty(
          property: $property
          type: $type
          campaign: $campaign
          contact: $contact
        ) {
          today {
            ...fields
          }
          past {
            ...fields
          }
          upComing {
            ...fields
          }
        }
      }
    }
  `;

  const { loading, data, refetch } = useQuery(GET_TIMELINES, {
    variables,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    event.subscribe(ON_TIMELINE_EVENT_MODIFIED, () => {
      refetch();
    });
    return () => {
      event.unsubscribe(ON_TIMELINE_EVENT_MODIFIED);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loading ? (
        <TimelineLoader />
      ) : (
        <div>
          {data?.timeline?.fromProperty?.today.length === 0 &&
            data?.timeline?.fromProperty?.upComing.length === 0 &&
            data?.timeline?.fromProperty?.past.length === 0 && (
              <Notification
                overrides={{
                  Body: { style: { width: "100%" } },
                  InnerContainer: {
                    style: { width: "100%", textAlign: "center" },
                  },
                }}
                kind={KIND.warning}
              >
                No timeline events.
              </Notification>
            )}
          {data?.timeline?.fromProperty?.today &&
            data.timeline.fromProperty.today.length > 0 && (
              <TimelineSection
                order={Order.TODAY}
                records={data.timeline.fromProperty.today}
                contact={variables?.contact}
              />
            )}
          {data?.timeline?.fromProperty?.upComing &&
            data.timeline.fromProperty.upComing.length > 0 && (
              <TimelineSection
                order={Order.UPCOMING}
                records={data.timeline.fromProperty.upComing}
                contact={variables?.contact}
              />
            )}
          {data?.timeline?.fromProperty?.past &&
            data.timeline.fromProperty.past.length > 0 && (
              <TimelineSection
                order={Order.PAST}
                records={data.timeline.fromProperty.past}
                contact={variables?.contact}
              />
            )}
        </div>
      )}
    </div>
  );
};

export default Timeline;
