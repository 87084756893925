import React from "react";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import gql from "graphql-tag";
import { useMutation, ApolloError } from "@apollo/client";
import { toaster } from "baseui/toast";

import CreateCampaignTemplate from "./CreateCampaignTemplate";

const CreateCampaignTemplateContainer = ({ history }: RouteComponentProps) => {
  const CREATE_CAMPAIGN_TEMPLATE = gql`
    mutation(
      $name: String!
      $description: String!
      $layers: [GraphQLCampaignTemplateLayerProps]!
      $accessLevels: [TemplatePermissionEnum]
    ) {
      campaignTemplate {
        create(
          name: $name
          description: $description
          layers: $layers
          accessLevels: $accessLevels
        ) {
          id
        }
      }
    }
  `;

  const [createCampaignTemplate, { loading }] = useMutation(
    CREATE_CAMPAIGN_TEMPLATE,
    {
      onError: ({ graphQLErrors }: ApolloError) => {
        graphQLErrors.map(({ message }) => toaster.negative(message, {}));
      },
      onCompleted: (data) => {
        if (data?.campaignTemplate?.create?.id) {
          toaster.positive("Campaign Template created successfully.", {});
          history.push(`/template/view/${data.campaignTemplate.create.id}`);
        }
      },
    }
  );

  return (
    <>
      <CreateCampaignTemplate
        createCampaignTemplate={createCampaignTemplate}
        loading={loading}
      />
    </>
  );
};

export default withRouter(CreateCampaignTemplateContainer);
