import React, { useState } from "react";
import { Icon, Switch } from "@virtualcapital/utogi-ui-library";
import { KIND } from "baseui/button";

import InputRange from "containers/InputRange";
import ColorPicker from "containers/ColorPicker";
import Loader from "components/loader";
import Input from "components/Input/Input";
import Button from "components/Button";
import Select from "components/select";
import Error from "components/Error";

import { isColor, isNumber } from "helpers/validations";

import { ILayer, ILayerType } from "interfaces/campaign";

import "./LayerEditor.scss";

type Props = {
  onSave: Function;
  layer: ILayer;
  layerTypes: null | ILayerType[];
  onDelete?: Function;
  canDelete?: boolean;
};

const LayerEditor = ({
  onSave,
  layer,
  layerTypes,
  onDelete,
  canDelete = false,
}: Props) => {
  const [fields, onFieldChange] = useState(layer);

  const [error, setErrors] = useState({
    name: null,
    type: null,
    primaryColor: null,
    secondaryColor: null,
    transitionTime: null,
    fadeTime: null,
    addTransition: null,
    addFade: null,
  });

  const {
    id,
    type,
    primaryColor,
    addTransition,
    secondaryColor,
    transitionTime,
    addFade,
    fadeTime,
    name,
  } = fields;

  const onSaveHandler = () => {
    const error: any = {
      name: null,
      type: null,
      primaryColor: null,
      secondaryColor: null,
      transitionTime: null,
      fadeTime: null,
      addTransition: null,
      addFade: null,
    };
    let hasError: boolean;

    switch (true) {
      case !name || name.trim() === "":
        error.name = "Name is required.";
        hasError = true;
        break;
      case name && name.trim().length > 50:
        error.name = "Maximum 50 characters.";
        hasError = true;
        break;
      default:
        hasError = false;
    }

    if (!type || type.id === "") {
      error.type = "Layer category is required.";
      hasError = true;
    }

    if (!primaryColor || primaryColor === "") {
      error.primaryColor = "Layer colour is required. ";
      hasError = true;
    } else if (!isColor(primaryColor)) {
      error.primaryColor = "Layer Color is invalid.";
      hasError = true;
    }
    if (addTransition) {
      if (!secondaryColor || secondaryColor === "") {
        error.secondaryColor = "Second Color is required.";
        hasError = true;
      } else if (!isColor(secondaryColor)) {
        error.secondaryColor = "Second Color is invalid.";
        hasError = true;
      }

      if (!transitionTime) {
        error.transitionTime = "Layer transition day is required.";
        hasError = true;
      } else if (
        !isNumber(transitionTime) ||
        transitionTime < 0 ||
        transitionTime > 365
      ) {
        error.transitionTime = "Number must be between 1 and 365 days.";
        hasError = true;
      }
    }

    if (addFade) {
      if (!fadeTime) {
        error.fadeTime = "Layer fade time is required.";
        hasError = true;
      } else if (!isNumber(fadeTime) || fadeTime < 0 || fadeTime > 365) {
        error.fadeTime = "Number must be between 1 and 365 days.";
        hasError = true;
      }
    }

    setErrors(error);
    if (!hasError) {
      onSave({
        id,
        name,
        type,
        primaryColor,
        secondaryColor,
        transitionTime: addTransition ? transitionTime : 0,
        fadeTime: addFade ? fadeTime : 0,
        addTransition,
        addFade,
      });
    }
  };

  const onDeleteHandler = () => {
    if (onDelete) {
      onDelete();
    }
  };

  const onLayerTypeChange = ({ option }: any) => {
    const newFields = { ...fields, type: option };
    if (!newFields?.id) {
      newFields.name = option.title;
    }
    newFields.primaryColor = option.color;
    onFieldChange(newFields);
  };
  return (
    <div className="add-layer">
      <div className="add-layer-content">
        <div className="layer-type-group">
          <div className="form-field">
            <Icon icon="layers" />
            {layerTypes ? (
              <Select
                options={layerTypes}
                labelKey="title"
                onChange={onLayerTypeChange}
                placeholder="Layer Category"
                value={type ? [type] : []}
                clearable={false}
              />
            ) : (
              <Loader />
            )}
          </div>
          <Error>{error.type}</Error>
        </div>
        <Input
          placeholder="Enter Layer Name"
          value={name}
          onChange={({ target: { value } }) =>
            onFieldChange({ ...fields, name: value })
          }
          error={error.name ? { message: error.name } : null}
        />
        <div className="color-form-group">
          <label>First Colour</label>
          <div className="color-form-group-inner">
            <ColorPicker
              placeholder="Colour Code"
              color={primaryColor}
              onChanged={(primaryColor: any) =>
                onFieldChange({ ...fields, primaryColor })
              }
              error={error.primaryColor}
            />
            <div>
              <Switch
                value={addTransition}
                onChange={(addTransition: any) =>
                  onFieldChange({ ...fields, addTransition })
                }
                labels={[
                  "Do you want this colour to change to another colour after a set period of time?",
                ]}
              />
            </div>
          </div>
        </div>
        {addTransition && (
          <>
            <div className="color-form-group">
              <label>Second Colour</label>
              <div className="color-form-group-inner">
                <ColorPicker
                  placeholder="Colour Code"
                  color={secondaryColor}
                  onChanged={(secondaryColor: any) =>
                    onFieldChange({ ...fields, secondaryColor })
                  }
                  error={error.secondaryColor}
                />
                <InputRange
                  value={transitionTime}
                  onChange={(value: any) =>
                    onFieldChange({
                      ...fields,
                      transitionTime: value ? parseInt(value) : value,
                    })
                  }
                  label="Colour transition day"
                  error={error.transitionTime}
                />
              </div>
            </div>
          </>
        )}
        <div className="color-form-group">
          <div className="color-form-group-inner">
            <div className="add-fade-block">
              <Switch
                value={addFade}
                labels={[
                  "Do you want this Activity/Layer to fade over time (this will occur after the transition day (if any)",
                ]}
                onChange={(addFade: any) =>
                  onFieldChange({ ...fields, addFade })
                }
              />
            </div>
            {addFade && (
              <InputRange
                value={fadeTime}
                onChange={(value: any) =>
                  onFieldChange({
                    ...fields,
                    fadeTime: value ? parseInt(value) : value,
                  })
                }
                label="Fade Time"
                error={error.fadeTime}
              />
            )}
          </div>
        </div>
      </div>
      <div className="button-block">
        {canDelete && onDelete && (
          <Button kind={KIND.secondary} onClick={onDeleteHandler}>
            Delete Layer
          </Button>
        )}
        <Button onClick={onSaveHandler}>Save</Button>
      </div>
    </div>
  );
};

export default LayerEditor;
