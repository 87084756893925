import React, { useEffect } from "react";
import { Polygon, Marker } from "@react-google-maps/api";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { Option } from "baseui/select";

import TagIcon from "assets/icons/tag-icon.svg";
import PeopleIcon from "assets/icons/contact.svg";

import { MarkerMode } from "../../constants/map";
import { useMap } from "../../context/map/use-map";

import { event } from "../../events";
import {
  ON_LAYERS_AND_TAGS_ADDED,
  REFETCH_PROSPECT_MAP,
} from "../../events/prospect";

type Props = {
  area: any;
  onLayerClick: Function;
  isAllMapMode: boolean;
  zoom: number;
};

const MapLayers = ({ area, onLayerClick, isAllMapMode, zoom }: Props) => {
  const GET_PROSPECT_MAP = gql`
    query(
      $area: GraphQLAreaCoordinateInput!
      $campaign: String!
      $selected: [ID]
      $colorFilter: String
      $fadeOption: String
      $startDate: String
      $tags: [ID]
      $toDate: String
      $users: [ID]
      $sortByDate: Boolean
      $markerMode: String!
      $viewMode: String!
    ) {
      prospect {
        properties(
          area: $area
          campaign: $campaign
          viewMode: $viewMode
          selected: $selected
          colorFilter: $colorFilter
          fadeOption: $fadeOption
          sortByDate: $sortByDate
          startDate: $startDate
          tags: $tags
          toDate: $toDate
          users: $users
          markerMode: $markerMode
        ) {
          layers
          tags
          people {
            lat
            lng
            count
          }
        }
      }
    }
  `;
  const GET_PROSPECT_EDGE_PROPERTIES = gql`
    query($area: [GraphQLCoordinateInput]!) {
      prospect {
        edgeProperties(area: $area) {
          properties
        }
      }
    }
  `;

  const {
    users,
    filters,
    viewMode,
    isManagerView,
    selectedArea,
    selectedMaps,
    showEdgeProperties,
    viewMode: { isLayersView },
  } = useMap();

  const getVariablesFromFilters = () => {
    const {
      colorFilter,
      layers: selectedLayers,
      fadeOption,
      isByRecentOrder,
      dateRange,
      selectedUsers,
      selectedTags,
      layerTypes: selectedLayerTypes,
    } = filters;
    let selected: any[] | null = null;
    if (isAllMapMode) {
      if (selectedLayerTypes !== null && selectedLayerTypes.length > 0)
        selected = selectedLayerTypes
          .filter(({ isSelected }: Option) => isSelected)
          .map(({ id }: Option) => id);
    } else {
      if (selectedLayers !== null && selectedLayers.length > 0)
        selected = selectedLayers
          .filter(({ isSelected }: Option) => isSelected)
          .map(({ id }: Option) => id);
    }
    let userFilter = null;
    if (isManagerView) {
      if (users) {
        userFilter = users
          .filter(({ isSelected }) => isSelected)
          .map(({ id }: Option) => id);
      }
    } else {
      if (selectedUsers && selectedUsers.length > 0) {
        userFilter = selectedUsers.map(({ id }: Option) => id);
      }
    }
    return {
      selected,
      viewMode: isManagerView ? "user" : "prospect",
      colorFilter: colorFilter[0].id,
      fadeOption: fadeOption[0].id,
      sortByDate: isByRecentOrder,
      startDate:
        dateRange.length > 0 ? new Date(dateRange[0]).toDateString() : "",
      tags: selectedTags.map(({ id }: Option) => id),
      toDate: dateRange.length > 1 ? new Date(dateRange[1]).toDateString() : "",
      users: userFilter,
      markerMode: viewMode.isPeopleView
        ? MarkerMode.PEOPLE
        : viewMode.isTagsView
        ? MarkerMode.TAG
        : "",
    };
  };

  const { loading, data, refetch } = useQuery(GET_PROSPECT_MAP, {
    fetchPolicy: "no-cache",
    variables: {
      area,
      campaign: isAllMapMode ? "all" : selectedMaps[0].id,
      ...getVariablesFromFilters(),
    },
    skip: zoom < 16,
  });

  const [
    getProspectEdgeProperties,
    { loading: isEdgePropertiesLoading, data: edgeProperties },
  ] = useLazyQuery(GET_PROSPECT_EDGE_PROPERTIES);

  useEffect(() => {
    const refetchProspectMap = () => {
      refetch();
    };

    event.subscribe(ON_LAYERS_AND_TAGS_ADDED, refetchProspectMap);
    event.subscribe(REFETCH_PROSPECT_MAP, refetchProspectMap);

    return () => {
      event.unsubscribe(ON_LAYERS_AND_TAGS_ADDED, refetchProspectMap);
      event.unsubscribe(REFETCH_PROSPECT_MAP, refetchProspectMap);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (showEdgeProperties) {
      getProspectEdgeProperties({ variables: { area: selectedArea } });
    }
    // eslint-disable-next-line
  }, [showEdgeProperties, selectedArea]);

  useEffect(() => {
    refetch({ ...getVariablesFromFilters() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const layers = showEdgeProperties
    ? edgeProperties?.prospect?.edgeProperties?.properties
    : data?.prospect?.properties?.layers;
  const tags = data?.prospect?.properties?.tags || [];
  const people = data?.prospect?.properties?.people || [];

  if (loading || isEdgePropertiesLoading)
    return (
      <div className="map-loader-container">
        <div className="map-loader" />
      </div>
    );

  return (
    <>
      {isLayersView &&
        layers?.map(
          ({ geometry: { coordinates }, color }: any, key: number) => {
            const path: any = [];
            coordinates[0].map((polygon: any) =>
              polygon.map(([lng, lat]: any) => path.push({ lng, lat }))
            );
            return (
              <Polygon
                key={key}
                path={path}
                onClick={(a) => onLayerClick(a)}
                options={{
                  fillColor: showEdgeProperties ? "#C3C3C3" : color,
                  fillOpacity: 1,
                  strokeColor: "#DADCE0",
                  strokeWeight: showEdgeProperties ? 2 : 1,
                  zIndex: 1,
                }}
              />
            );
          }
        )}
      {tags.map((position: any) => (
        <Marker position={position} icon={TagIcon} />
      ))}
      {people.map(({ count, lat, lng }: any) => (
        <Marker
          icon={PeopleIcon}
          position={{ lat, lng }}
          label={count.toString()}
        />
      ))}
    </>
  );
};

export default MapLayers;
