export enum MapStatus {
  ALL = "all",
  ACTIVE = "active",
  DRAFT = "draft",
  ARCHIVE = "archive",
  DELETE = "delete",
}

export enum CampaignActionMode {
  Creating = "creating",
  Editing = "editing",
  Archiving = "Archiving",
}
