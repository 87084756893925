import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useForm } from "react-hook-form";
import { toaster } from "baseui/toast";

import { MapPermission } from "constants/permissions";

import PermissionList from "../PermissionsList";
import Input from "../../../../components/Input/Input";
import Button from "components/Button";

import { Container, InputContainer } from "./styles";

type Props = {
  id?: string;
  name?: string;
  permissions?: any;
  onSuccess: Function;
};

const CREATE_MUTATION = gql`
  mutation CreateMutation($permissions: MapPermissionInput!, $name: String!) {
    map {
      role {
        create(permissions: $permissions, name: $name) {
          success
        }
      }
    }
  }
`;

const EDIT_MUTATION = gql`
  mutation EditMutation(
    $id: ID!
    $name: String!
    $permissions: MapPermissionInput!
  ) {
    map {
      role {
        update(permissions: $permissions, id: $id, name: $name) {
          success
        }
      }
    }
  }
`;

const ManageRole: React.FC<Props> = ({ onSuccess, name, permissions, id }) => {
  const initialValues: any = permissions || {};
  if (!permissions) {
    Object.values(MapPermission).map((key: any) => {
      initialValues[key] = false;
      return false;
    });
  }

  const [values, setValues] = useState(initialValues);
  const { register, handleSubmit, errors } = useForm({
    defaultValues: { name: name || "" },
  });

  const [create, { loading: isOnCreate }] = useMutation(CREATE_MUTATION, {
    onError: () => {
      toaster.negative("Error occurred while creating role.", {});
    },
    onCompleted: (data) => {
      if (data?.map?.role?.create?.success) {
        toaster.positive("Role created successfully!", {});
        onSuccess();
      }
    },
  });

  const [update, { loading: isOnUpdate }] = useMutation(EDIT_MUTATION, {
    onError: () => {
      toaster.negative("Error occurred while updating role.", {});
    },
    onCompleted: (data) => {
      if (data?.map?.role?.update?.success) {
        toaster.positive("Role updated successfully!", {});
        onSuccess();
      }
    },
  });

  const onSubmit = ({ name }: any) => {
    if (id) {
      update({
        variables: {
          id,
          name,
          permissions: values,
        },
      });
    } else {
      create({
        variables: {
          name,
          permissions: values,
        },
      });
    }
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <InputContainer>
        <Input
          type="text"
          id="name"
          name="name"
          placeholder="Enter Role Name"
          ref={register({ required: true, maxLength: 50 })}
          error={errors.name}
          maxTextLength={50}
        />
      </InputContainer>
      <div>
        <PermissionList values={values} setValues={setValues} />
        <Button type="submit" isLoading={isOnCreate || isOnUpdate}>
          Save
        </Button>
      </div>
    </Container>
  );
};

export default ManageRole;
