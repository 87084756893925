import React from "react";
import { Icon } from "@virtualcapital/utogi-ui-library";
import { useStyletron } from "baseui";

import occupancyImage from "assets/images/occupancy.svg";

import Button from "components/Button";

import { useHover } from "helpers/use-hover";

import "./PropertyContacts.scss";

const PropertyContacts = ({
  firstName,
  lastName,
  mobileNumber,
  displayName,
  landline,
  email,
  occupancyStatus,
  honorific,
  id,
  editContact,
  deleteContact,
}: any) => {
  const [css] = useStyletron();
  const [hoverRef, hovered] = useHover();

  return (
    <div className="people-list-item" ref={hoverRef}>
      <ul className="people-list-item-type">
        <li className="active">Contact</li>
        <li className="pull-right">
          <Icon icon="bell" />
        </li>
      </ul>
      <ul className="people-tab-content-details">
        <li
          className={css({
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          })}
        >
          <div>
            <Icon className="people-tab-content-details-icon" icon="profile" />{" "}
            <strong>{firstName}</strong>
          </div>
          <div className={css({ display: "flex", flexDirection: "row" })}>
            <Button
              onClick={() =>
                editContact({
                  firstName,
                  lastName,
                  mobileNumber,
                  displayName,
                  landline,
                  email,
                  occupancyStatus,
                  honorific,
                  id,
                })
              }
              overrides={{
                BaseButton: {
                  style: {
                    color: "#00aeef",
                    backgroundColor: "#d2f7ff",
                    marginRight: "5px",
                    minWidth: "auto",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    "@media screen and (min-width: 768px)": {
                      opacity: hovered ? 1 : 0,
                    },
                    ":hover": {
                      color: "#00aeef",
                      backgroundColor: "#a6efff",
                    },
                  },
                },
              }}
            >
              <Icon icon="edit" />
            </Button>
            <Button
              onClick={() => deleteContact(id)}
              overrides={{
                BaseButton: {
                  style: {
                    color: "#ff0000",
                    backgroundColor: "#f6d8d8",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    "@media screen and (min-width: 768px)": {
                      opacity: hovered ? 1 : 0,
                    },
                    ":hover": {
                      color: "#ff0000",
                      backgroundColor: "#fbb9b9",
                    },
                    minWidth: "auto",
                  },
                },
              }}
            >
              <Icon icon="delete" />
            </Button>
          </div>
        </li>
        <li>
          <Icon className="people-tab-content-details-icon" icon="profile" />{" "}
          <strong>{lastName}</strong>
        </li>
        <li>
          <Icon className="people-tab-content-details-icon" icon="phone" />{" "}
          {mobileNumber}
        </li>
        {landline && landline !== "" && (
          <li>
            <Icon className="people-tab-content-details-icon" icon="call" />{" "}
            {landline}
          </li>
        )}

        <li>
          <Icon className="people-tab-content-details-icon" icon="envelop" />{" "}
          {email}
        </li>
        <li>
          <img src={occupancyImage} alt="occupancy" />
          {occupancyStatus}
        </li>
      </ul>
    </div>
  );
};

export default PropertyContacts;
