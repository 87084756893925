import React from "react";

import LayerThumbnail from "components/map/LayerThumbnail";
import Toggle from "components/toggle";
import { Container, Inner, TextContainer } from "../filter.styles";

import { useMap } from "context/map/use-map";

type Props = {
  id: string;
  name: string;
  type: { name: string };
  primaryColor: string;
  secondaryColor: string;
  addFade: boolean;
  addTransition: boolean;
  transitionTime: number;
  fadeTime: number;
  isSelected: boolean;
  onSelectChange: (id: string, isSelected: boolean) => void;
};

const FilterLayer: React.FC<Props> = ({
  id,
  name,
  type,
  isSelected,
  onSelectChange,
  ...props
}) => {
  const { isManagerView } = useMap();
  return (
    <Container>
      <Inner>
        {!isManagerView && <LayerThumbnail {...props} />}
        <TextContainer>
          <span>{name}</span>
          <span>{type.name}</span>
        </TextContainer>
      </Inner>
      <Toggle
        checked={isSelected}
        onChange={(e: any) => onSelectChange(id, e.target.checked)}
      />
    </Container>
  );
};

export default FilterLayer;
