import { styled } from "baseui";

export const Container = styled("form", () => ({
  padding: "10px",
}));

export const Section = styled("div", () => ({
  marginBottom: "20px",
}));

export const GroupTitle = styled("h4", () => ({
  fontWeight: "bold",
  marginBottom: "10px",
  color: "#707070",
}));

export const ToggleContainer = styled("div", () => ({
  marginBottom: "10px",
  display: "flex",
  alignItems: "top",
  fontSize: ".875rem",
}));

export const ToggleLabel = styled("span", () => ({
  marginLeft: "10px",
}));

export const InputContainer = styled("div", () => ({
  marginBottom: "20px",
}));
