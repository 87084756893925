import React from "react";

import "./PropertyContacts.scss";

import PropertyContact from "./PropertyContact";

const PropertyContacts = ({ users, editContact, deleteContact }: any) => {
  return (
    <div className="property-contacts">
      {users.map((user: any, key: any) => (
        <PropertyContact
          {...user}
          editContact={editContact}
          deleteContact={deleteContact}
        />
      ))}
    </div>
  );
};

export default PropertyContacts;
