import React, { useState } from "react";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useMutation, useQuery, ApolloError } from "@apollo/client";
import { toaster } from "baseui/toast";

import CampaignTemplateTemplateDetails from "./CampaignTemplateDetails";
import Loader from "components/loader";

export interface ICampaignTemplateTemplateDetailsContainerParams {
  id: string;
}

const CampaignTemplateTemplateDetailsContainer = () => {
  const [edit, toggleEdit] = useState(false);

  const fragment = {
    campaignTemplate: gql`
      fragment CampaignTemplateDetails on CampaignTemplateDetailsOutput {
        name
        description
        status
        accessLevels
        createdUser {
          name
          time
        }
        lastEditBy {
          name
          time
        }
        layers {
          name
          type {
            id
            title
          }
          primaryColor
          secondaryColor
          transitionTime
          fadeTime
          addFade
          addTransition
        }
      }
    `,
  };

  const UPDATE_CAMPAIGN_TEMPLATE = gql`
    mutation(
      $id: ID!
      $name: String!
      $description: String!
      $layers: [GraphQLCampaignTemplateLayerProps]
      $accessLevels: [TemplatePermissionEnum]
    ) {
      campaignTemplate {
        update(
          id: $id
          name: $name
          description: $description
          layers: $layers
          accessLevels: $accessLevels
        ) {
          ...CampaignTemplateDetails
        }
      }
    }
    ${fragment.campaignTemplate}
  `;

  const GET_CAMPAIGN_TEMPLATE = gql`
    query($id: ID!) {
      campaignTemplate {
        byId(id: $id) {
          ...CampaignTemplateDetails
        }
      }
    }
    ${fragment.campaignTemplate}
  `;

  const { id } = useParams<ICampaignTemplateTemplateDetailsContainerParams>();

  const { loading, data } = useQuery(GET_CAMPAIGN_TEMPLATE, {
    variables: { id },
  });

  const [updateCampaignTemplate, { loading: updating }] = useMutation(
    UPDATE_CAMPAIGN_TEMPLATE,
    {
      onCompleted: () => {
        toggleEdit(false);
        toaster.positive("Campaign template updated successfully!", {});
      },
      onError: (error: ApolloError) => {
        if (error) {
          const { graphQLErrors } = error;
          graphQLErrors.map(({ message }) => toaster.negative(message, {}));
        } else {
          toggleEdit(false);
          toaster.positive("Campaign template updated successfully!", {});
        }
      },
    }
  );

  if (loading && !data) {
    return <Loader />;
  }

  const {
    campaignTemplate: { byId },
  } = data;

  return (
    <>
      <CampaignTemplateTemplateDetails
        campaignTemplate={byId}
        updateCampaignTemplate={updateCampaignTemplate}
        edit={edit}
        toggleEdit={toggleEdit}
        updating={updating}
      />
    </>
  );
};

export default CampaignTemplateTemplateDetailsContainer;
