import { styled } from "baseui";

const Error = styled("div", () => ({
  listStyle: "none",
  textAlign: "left",
  fontSize: "12px",
  marginTop: "5px",
  color: "#cc6363",
}));

export default Error;
