import React, { useState } from "react";

import "./MapLayer.scss";
import { IconButton } from "@virtualcapital/utogi-ui-library";
import classnames from "classnames";
import { ILayer } from "../../../interfaces/campaign";
import { getLayerBackground } from "../../../helpers/helpers";

type Props = ILayer & { onEditClicked: Function; disableEdit: boolean };

const MapLayer = ({
  name,
  type,
  primaryColor,
  secondaryColor,
  addFade,
  addTransition,
  transitionTime,
  fadeTime,
  onEditClicked,
  disableEdit,
}: Props) => {
  const [expanded, toggleExpand] = useState(false);
  const layerBackground = getLayerBackground({
    primaryColor,
    secondaryColor,
    addFade,
    addTransition,
    transitionTime,
    fadeTime,
  });
  return (
    <div className={classnames("map-layer", { expanded })}>
      <div className="map-layer-row">
        <div className="map-layer-preview">
          <div
            className="primary-color"
            style={{
              background: layerBackground,
            }}
          />
        </div>
        <div className="map-layer-details">
          <div className="map-layer-details-item">{type?.title || "-"}</div>
          <div className="map-layer-details-item">{name}</div>
        </div>
        <div className="map-layer-action">
          <IconButton
            icon={expanded ? "chevron-up" : "chevron-down"}
            onClick={() => toggleExpand(!expanded)}
          />
        </div>
      </div>
      <div className="map-layer-meta">
        <div className="map-layer-details-item">
          Color transition:&nbsp;
          {transitionTime && transitionTime !== 0
            ? `${transitionTime} days`
            : "-"}
        </div>
        <div className="map-layer-details-item">
          Fade time:&nbsp;
          {fadeTime && fadeTime !== 0 ? `${fadeTime} days` : "-"}
        </div>
        <div className="map-layer-details-item">{type?.title || "-"}</div>
        <div className="map-layer-details-item">{name}</div>
        {!disableEdit && (
          <IconButton icon="edit" onClick={() => onEditClicked()} />
        )}
      </div>
    </div>
  );
};

export default MapLayer;
