import { Icon } from "@virtualcapital/utogi-ui-library";
import { styled } from "baseui";
import { Button, SHAPE, SIZE } from "baseui/button";
import React from "react";

const EmailTag = styled("div", () => ({
  display: "inline-block",
  padding: "2px 10px",
  border: "1px solid #71d0f6",
  borderRadius: "15px",
  color: "#71d0f6",
  margin: "0px 5px 5px 0",
  fontSize: "14px",
}));

const EmailPartyTag = ({ item, onClickRemove }: any) => {
  return (
    <EmailTag>
      {item.email}{" "}
      <Button
        onClick={() => onClickRemove()}
        size={SIZE.compact}
        shape={SHAPE.circle}
        overrides={{
          BaseButton: {
            style: () => ({
              border: "1px solid #71d0f6",
              color: "#71d0f6",
              backgroundColor: "transparent",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              fontSize: "12px",
              float: "right",
              marginLeft: "9px",
              width: "fit-content",
              height: "fit-content",
              padding: "3px",
              ":focus": {
                outline: "none",
              },
              ":hover": {
                backgroundColor: "#c1e8f7",
              },
            }),
          },
        }}
      >
        <Icon icon="close" />
      </Button>
    </EmailTag>
  );
};

export default EmailPartyTag;
