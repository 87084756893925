import React, { useState, Fragment } from "react";
import { Icon, ConfirmAlert } from "@virtualcapital/utogi-ui-library";
import { toaster } from "baseui/toast";
import { useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";

import Loader from "components/loader";
import Button from "components/Button";

import CreatePropertyContact from "containers/prospect/CreatePropertyContact";
import EditPropertyContact from "containers/prospect/EditPropertyContact";
import PropertyContacts from "containers/prospect/PropertyContacts";
import ContactSearch from "./ContactSearch";
import { REMOVE_CONTACT_FROM_PROPERTY } from "./graphql";

import { event } from "../../../../events";
import { REFETCH_PROSPECT_MAP } from "../../../../events/prospect";

import WithScrollbar from "helpers/WithScrollbar";

import { Contact } from "../../../../types/contact";

import "./styles.scss";

type Props = {
  property: number;
  unit: string | null;
  loadingPropertyContacts: boolean;
  propertyContacts: any;
  refetchPropertyContacts: Function;
};

export default function PeopleTab({
  property,
  unit,
  loadingPropertyContacts,
  propertyContacts,
  refetchPropertyContacts,
}: Props) {
  const [css] = useStyletron();

  const [addPeople, togglePeopleAdd] = useState(false);
  const [editPeople, togglePeopleEdit] = useState(false);
  const [selectedUser, editSelectedUser]: [any, Function] = useState({
    firstName: null,
    lastName: null,
    mobileNumber: null,
    displayName: null,
    landline: null,
    email: null,
    occupancyStatus: null,
    id: null,
  });

  const [removeContactFromProperty] = useMutation(
    REMOVE_CONTACT_FROM_PROPERTY,
    {
      onError: () => {
        toaster.negative("Error occurred while removing contact.", {});
      },
      onCompleted: () => {
        event.dispatch(REFETCH_PROSPECT_MAP);
        toaster.positive("Contact successfully removed!", {});
        refetchPropertyContacts();
      },
    }
  );

  if (loadingPropertyContacts || !propertyContacts) {
    return <Loader />;
  }
  const {
    contact: { propertyContact },
  } = propertyContacts;

  const deleteContact = (contactId: string) => {
    ConfirmAlert({
      closeOnClickOutside: false,
      message:
        "Are you sure you want to delete this contact from this property?",
      onCancel: () => {},
      onConfirm: () => {
        removeContactFromProperty({
          variables: {
            contact: contactId,
            property: { unit, property },
          },
        });
      },
    });
  };

  const editContact = ({
    firstName,
    lastName,
    mobileNumber,
    displayName,
    landline,
    email,
    occupancyStatus,
    id,
    honorific,
  }: Contact) => {
    editSelectedUser({
      firstName,
      lastName,
      mobileNumber,
      displayName,
      landline,
      email,
      occupancyStatus,
      id,
      honorific,
    });
    togglePeopleEdit(true);
  };

  const formReset = () => {
    togglePeopleEdit(false);
    togglePeopleAdd(false);
  };

  return (
    <WithScrollbar noScrollX height="calc(100vh - 220px)">
      <div className="people-tab">
        <h5>
          {addPeople
            ? "Add to property"
            : editPeople
            ? "Edit property contact"
            : ""}
        </h5>
        {addPeople ? (
          <CreatePropertyContact
            formReset={formReset}
            property={property}
            unit={unit}
            onSuccess={() => {
              togglePeopleAdd(false);
              refetchPropertyContacts();
            }}
          />
        ) : editPeople ? (
          <EditPropertyContact
            selectedUser={selectedUser}
            formReset={formReset}
            onSuccess={() => {
              togglePeopleAdd(false);
              refetchPropertyContacts();
            }}
            property={property}
            unit={unit}
          />
        ) : (
          <Fragment>
            <div
              className={css({
                "@media (min-width: 641px)": {
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                },
              })}
            >
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  flex: 1,
                  "@media screen and (max-width: 640px)": {
                    marginBottom: "15px",
                  },
                })}
              >
                <Icon icon="profile" className={css({ marginRight: "10px" })} />
                <ContactSearch
                  property={property}
                  unit={unit}
                  refetchPropertyContacts={refetchPropertyContacts}
                />
              </div>
              <Button
                onClick={() => togglePeopleAdd(true)}
                kind={KIND.secondary}
              >
                Add New Person
              </Button>
            </div>
            <div className="people-tab-content">
              <div className="people-list">
                <PropertyContacts
                  users={propertyContact}
                  editContact={editContact}
                  deleteContact={deleteContact}
                />
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </WithScrollbar>
  );
}
