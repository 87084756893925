import React, { ReactNode } from "react";
import { Icon } from "@virtualcapital/utogi-ui-library";
import { styled } from "baseui";
import { Button } from "baseui/button";
import { Delete } from "baseui/icon";

type Props = {
  title: string;
  icon: string;
  close: () => void;
  isPinned?: boolean;
  action?: ReactNode;
};

const FilterContainer = styled("div", ({ isPinned }: any) => ({
  background: "#ffffff",
  fontSize: "14px",
  width: "400px",
  maxWidth: "100%",
  ...(isPinned
    ? {
        position: "fixed",
        top: "80px",
        right: "80px",
      }
    : {}),
}));

const FilterHeader = styled("div", () => ({
  backgroundColor: "#00AEEF",
  padding: "15px",
  color: "#ffffff",
  display: "flex",
  justifyContent: "space-between",
}));

const FilterHeaderIcon = styled(Icon, () => ({
  color: "#7A7A7A",
  background: "#ffffff",
  padding: "6px",
  borderRadius: "50%",
  marginRight: "15px",
}));

const FilterPopup: React.FC<Props> = ({
  children,
  icon,
  title,
  isPinned,
  action,
  close,
}) => {
  return (
    <FilterContainer isPinned={isPinned}>
      <FilterHeader>
        <div>
          <FilterHeaderIcon icon={icon} />
          {title}
        </div>
        {action}
        <Button
          onClick={close}
          overrides={{
            Root: {
              style: {
                backgroundColor: "#ffffff",
                borderRadius: "50%",
                paddingLeft: "0",
                paddingRight: "0",
                paddingTop: "0",
                paddingBottom: "0",
                "@media (min-width: 769px)": { display: "none" },
              },
            },
          }}
        >
          <Delete size={20} color="#555" />
        </Button>
      </FilterHeader>
      <div className="filter-popup-body">{children}</div>
    </FilterContainer>
  );
};

export default FilterPopup;
