import React from "react";

export const EMAIL_CONTEXT_ATTACHMENT_ADDED = "EMAIL_CONTEXT_ATTACHMENT_ADDED";
export const EMAIL_CONTEXT_ATTACHMENTS_LOADED =
  "EMAIL_CONTEXT_ATTACHMENTS_LOADED";
export const EMAIL_CONTEXT_ATTACHMENT_REMOVED =
  "EMAIL_CONTEXT_ATTACHMENT_REMOVED";

const initialState = {
  files: [],
  draft: null,
};

export const EmailEditorContext: any = React.createContext(initialState);

const EmailEditorContextProvider = ({ children }: any) => {
  const [state, dispatch] = React.useReducer((state: any, action: any) => {
    const { type, payload } = action;
    switch (type) {
      case EMAIL_CONTEXT_ATTACHMENT_ADDED:
        return { ...state, files: [...state.files, payload] };
      case EMAIL_CONTEXT_ATTACHMENTS_LOADED:
        return { ...state, files: [...state.files, ...payload] };
      case EMAIL_CONTEXT_ATTACHMENT_REMOVED:
        return {
          ...state,
          files: state.files.filter(({ id }: any) => id !== payload),
        };
      default:
        return { ...state };
    }
  }, initialState);

  return (
    <EmailEditorContext.Provider value={{ state, dispatch }}>
      {children}
    </EmailEditorContext.Provider>
  );
};

export default EmailEditorContextProvider;
