import { Block } from "baseui/block";
import { Button, SIZE } from "baseui/button";
import { toaster } from "baseui/toast";
import { defaultButtonStyles } from "components/Button";
import React from "react";
import Drawer from "components/drawer";
import ComposeEmail from "./email";
import AppointmentModal from "./Actions/AppointmentModal";
import EmailModal from "./Actions/EmailModal";
import NoteModal from "./Actions/NoteModal";
import ReminderModal from "./Actions/ReminderModal";
import { usePopup } from "../../../context/popup/use-popup";
import { ActivePopup } from "../../../context/popup/popup.types";
import { EmailSignatureContextProvider } from "context/email-signature-context/email-signature-context";

type Props = {
  refetch: Function;
};

const PopupContainer: React.FC<Props> = ({ refetch }) => {
  const {
    activePopup,
    source,
    title,
    properties,
    selectedArea,
    selectionType,
    campaign,
    reference,
    contacts,
    closePopup,
    openPopup,
  } = usePopup();
  switch (activePopup) {
    case ActivePopup.NOTE:
      return (
        <Drawer
          isOpen={true}
          header={`${reference ? "Edit" : "Add New"} Note`}
          onClose={() => closePopup()}
        >
          <NoteModal
            selectedArea={selectedArea}
            selectionType={selectionType}
            properties={properties}
            campaign={campaign}
            contacts={contacts}
            onCompleted={() => {
              refetch();
              if (source === ActivePopup.REMINDER) {
                toaster.positive(
                  <Block
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    Note Added
                    <Button
                      overrides={{
                        BaseButton: {
                          style: {
                            ...defaultButtonStyles,
                          },
                        },
                      }}
                      size={SIZE.compact}
                      onClick={() =>
                        openPopup({
                          popup: ActivePopup.REMINDER,
                          source: ActivePopup.NOTE,
                        })
                      }
                    >
                      Quick Reminder
                    </Button>
                  </Block>,
                  {
                    closeable: false,
                    overrides: {
                      InnerContainer: {
                        style: { width: "100%" },
                      },
                    },
                  }
                );
              } else {
                toaster.positive(
                  `Note ${reference ? "edited" : "added"} successfully!`,
                  {}
                );
              }
              closePopup();
            }}
            reference={reference}
          />
        </Drawer>
      );
    case ActivePopup.REMINDER:
      return (
        <Drawer
          isOpen={true}
          header={`${reference ? "Edit" : "Add New"} Reminder`}
          onClose={() => closePopup()}
        >
          <ReminderModal
            properties={properties}
            campaign={campaign}
            reference={reference}
            onCompleted={() => {
              refetch();
              closePopup();
              toaster.positive(
                `Reminder ${reference ? "edited" : "added"} successfully!`,
                {}
              );
            }}
          />
        </Drawer>
      );
    case ActivePopup.APPOINTMENT:
      return (
        <Drawer
          isOpen={true}
          header={`${reference ? "Edit" : "Add New"} Appointment`}
          onClose={() => closePopup()}
        >
          <AppointmentModal
            onCompleted={() => {
              refetch();
              closePopup();
              toaster.positive(
                `Appointment ${reference ? "edited" : "added"} successfully!`,
                {}
              );
            }}
            properties={properties}
            campaign={campaign}
            reference={reference}
          />
        </Drawer>
      );
    case ActivePopup.EMAIL_VIEW:
      if (!reference) return <></>;
      return (
        <Drawer
          isOpen={true}
          header={title || "Email Full Conversation"}
          onClose={() => closePopup()}
        >
          <EmailModal reference={reference} />
        </Drawer>
      );
    case ActivePopup.EMAIL:
      return (
        <EmailSignatureContextProvider>
          <ComposeEmail
            show={true}
            onDelete={() => closePopup()}
            closeModal={() => closePopup()}
            properties={properties}
          />
        </EmailSignatureContextProvider>
      );

    default:
      return null;
  }
};

export default PopupContainer;
