import React from "react";
import { Spinner } from "baseui/spinner";
import { Button, ButtonProps } from "baseui/button";

import { defaultButtonStyles } from "components/Button";
import { useHover } from "../helpers/use-hover";
import { mergeDeep } from "../helpers/object";

type Props = ButtonProps & {
  isLoading: boolean;
  onClick?: Function;
  text?: string;
  onLoadText?: string;
};

const SaveButton = ({
  isLoading,
  onLoadText = "Saving",
  text = "Save",
  kind,
  overrides,
  ...props
}: Props) => {
  const [hoverRef, hovered] = useHover();

  return (
    <Button
      {...props}
      ref={hoverRef}
      startEnhancer={() =>
        isLoading ? (
          <Spinner $size="20px" $borderWidth="4px" $color="#ffffff" />
        ) : null
      }
      disabled={isLoading}
      overrides={mergeDeep(
        {
          BaseButton: {
            style: {
              ...defaultButtonStyles,
              ...(!kind && {
                backgroundColor: hovered ? "#53ce53 !important" : "#d8f6d8",
                color: hovered ? "#ffffff !important" : "#53ce53",
              }),
              "@media (min-width: 769px)": {
                minWidth: "150px",
              },
            },
          },
        },
        overrides
      )}
    >
      {isLoading ? onLoadText : text}
    </Button>
  );
};

export default SaveButton;
