import { ColorFilters, FadeType } from "../constants/map";
import { MapStatus } from "../constants/campaign";

export const COLOR_FILTER_LIST = [
  {
    label: "All colours",
    id: ColorFilters.ALL_COLORS,
    filter:
      "linear-gradient(135deg, #ffbb00 0%, #ffbb00 50%, #00aeef 50%, #ffffff 100%)",
  },
  {
    label: "Hide first colour",
    id: ColorFilters.HIDE_FIRST_COLOR,
    filter:
      "linear-gradient(135deg, #929292 0%, #929292 50%, #00aeef 50%, #ffffff 100%)",
  },
  {
    label: "Hide second colour",
    id: ColorFilters.HIDE_SECOND_COLOR,
    filter:
      "linear-gradient(135deg, #ffbb00 0%, #ffbb00 50%, #929292 50%, #ffffff 100%)",
  },
  {
    label: "All as first colour",
    id: ColorFilters.ALL_AS_FIRST_COLOR,
    filter: "#ffbb00",
  },
  {
    label: "All as second colour",
    id: ColorFilters.ALL_AS_SECOND_COLOR,
    filter: "#00aeef",
  },
];

export const FADE_OPTIONS_LIST = [
  {
    label: "Layer Fade",
    id: FadeType.LAYER_FADE,
    fade: "linear-gradient(135deg, #ffbb00 0%, #ffbb00 40%, #ffffff 100%)",
  },
  {
    label: "No Fade",
    id: FadeType.NO_FADE,
    fade: "#ffbb00",
  },
];

export const getMapStatus = (status: string) => {
  switch (status) {
    case MapStatus.ACTIVE:
      return "Active";
    case MapStatus.DRAFT:
      return "Draft";
    case MapStatus.DELETE:
      return "Delete";
    case MapStatus.ARCHIVE:
      return "Archive";
    default:
      return status;
  }
};
