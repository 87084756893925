import React, { Suspense, useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Authenticate, AuthContext } from "@virtualcapital/utogi-auth-library";
import { gql } from "apollo-boost";
import { createInstance, OptimizelyProvider } from "@optimizely/react-sdk";
import { toaster } from "baseui/toast";
import { API, graphqlOperation } from "aws-amplify";
import LogRocket from "logrocket";

import Maps from "pages/maps";
import Templates from "pages/templates";
import Analytics from "pages/analytics";
import Settings from "pages/settings";

import Loader from "components/loader";

import GraphQLProvider from "graphql/client";

import Theme from "./theme";

import {
  NotificationEventSourceType,
  NotificationSettings,
} from "constants/notifications";

import {
  ON_NEW_NOTIFICATION_RECEIVED,
  ON_REMINDER_MODIFIED,
} from "events/notifications";
import { event } from "./events";

import config from "./config";

import "./App.scss";

const optimizely = createInstance({
  sdkKey: process.env.REACT_APP_ROLLOUT_KEY,
});

const ON_NOTIFICATION_CHANGED = gql`
  subscription SubscribeToData($name: String!) {
    subscribe(name: $name) {
      data
    }
  }
`;

const App = () => {
  const {
    authData: { token, user },
  } = useContext(AuthContext);
  if (config.LOG_ROCKET && user)
    LogRocket.identify(user.id, {
      name: user.name,
    });
  useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(ON_NOTIFICATION_CHANGED, { name: user?.id })
      // @ts-ignore
    ).subscribe({
      next: (res: any) => {
        if (res?.value?.data?.subscribe?.data) {
          const data = JSON.parse(res?.value?.data?.subscribe?.data);
          switch (data?.event) {
            case NotificationEventSourceType.NEW_NOTIFICATION:
              event.dispatch(ON_NEW_NOTIFICATION_RECEIVED);
              switch (data?.data?.type) {
                case NotificationSettings.PROPERTY_CAMPAIGN_STATUS_CHANGE:
                  toaster.info("Your campaign status changed", {});
                  break;
                case NotificationSettings.GENERAL_CAMPAIGN_STATUS_CHANGE:
                  toaster.info("Your campaign status changed", {});
                  break;
                case NotificationSettings.GENERAL_CAMPAIGN_FACEBOOK_AD_STATUS_CHANGE:
                  toaster.info("Your Facebook campaign status changed", {});
                  break;
                case NotificationSettings.PROPERTY_CAMPAIGN_FACEBOOK_AD_STATUS_CHANGE:
                  toaster.info("Your Facebook campaign status changed", {});
                  break;
                case NotificationSettings.FACEBOOK_ACCOUNT_EXPIRY:
                  toaster.info("Your Facebook Ad Account has expired", {});
                  break;
                case NotificationSettings.HIGH_PRIORITY_REMINDER_DUE:
                  toaster.info(
                    <div>
                      <strong>Your have a reminder</strong>
                      <p>
                        {data?.data?.data?.name}:&nbsp;High:&nbsp;
                        {data?.data?.data?.date}
                      </p>
                    </div>,
                    {}
                  );
                  break;
                case NotificationSettings.MEDIUM_PRIORITY_REMINDER_DUE:
                  toaster.info(
                    <div>
                      <strong>Your have a reminder</strong>
                      <p>
                        {data?.data?.data?.name}:&nbsp;Medium:&nbsp;
                        {data?.data?.data?.date}
                      </p>
                    </div>,
                    {}
                  );
                  break;
                case NotificationSettings.LOW_PRIORITY_REMINDER_DUE:
                  toaster.info(
                    <div>
                      <strong>Your have a reminder</strong>
                      <p>
                        {data?.data?.data?.name}:&nbsp;Low:&nbsp;
                        {data?.data?.data?.date}
                      </p>
                    </div>,
                    {}
                  );
                  break;
                case NotificationSettings.CONTACT_IMPORT_STATUS_CHANGE:
                  toaster.info(
                    "Your contact import processed successfully",
                    {}
                  );
                  break;
                case NotificationSettings.EMAIL_RECEIVED:
                  toaster.info("You have a new email", {});
                  break;
              }
              break;
            case NotificationEventSourceType.NEW_EMAIL:
              toaster.info("You have new email", {});
              break;
            case NotificationEventSourceType.REMINDER_MODIFIED:
              event.dispatch(ON_REMINDER_MODIFIED);
              break;
            default:
              break;
          }
        }
      },
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [user]);
  return (
    <Theme>
      <GraphQLProvider token={token}>
        <OptimizelyProvider
          optimizely={optimizely}
          user={{ id: user ? user.id : "guest" }}
        >
          <Router>
            <Suspense fallback={<Loader />}>
              <Switch>
                <Route exact path="/" render={() => <Redirect to="/maps" />} />

                <Route path="/maps" component={Maps} />
                <Route path="/template" component={Templates} />
                <Route path="/settings" component={Settings} />
                <Route exact path="/analytics" component={Analytics} />
                <Route exact path="/authenticate" component={Authenticate} />
              </Switch>
            </Suspense>
          </Router>
        </OptimizelyProvider>
      </GraphQLProvider>
    </Theme>
  );
};

export default App;
