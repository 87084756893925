import React from "react";
import Map from "./maps";
import { MapProvider } from "context/map/use-map";

const Maps = () => {
  return (
    <MapProvider>
      <Map />
    </MapProvider>
  );
};

export default Maps;
