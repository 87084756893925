import React from "react";
import { useStyletron } from "baseui";

import Header from "components/Header/Header";
import Sidebar from "./Sidebar";
import { hasPermission, permissions } from "@virtualcapital/utogi-auth-library";

type Props = {
  hasFullScreenContent?: boolean;
  filter?: React.ReactNode;
};

const Layout: React.FC<Props> = ({
  children,
  hasFullScreenContent,
  filter,
}) => {
  const [css] = useStyletron();
  const isAvailable = hasPermission(
    permissions.maps.IS_THE_MAPS_MODULE_AVAILABLE
  );

  return (
    <>
      <Header filter={filter} />
      {isAvailable ? (
        <div>
          <Sidebar />
          <div
            className={css({
              margin: "20px 20px 0px 85px",
              "@media (max-width: 768px)": {
                margin: hasFullScreenContent ? 0 : "20px 10px 0 30px",
              },
            })}
          >
            {children}
          </div>
        </div>
      ) : (
        <div
          className={css({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 66px)",
          })}
        >
          <p>You don’t have permissions to view this module</p>
        </div>
      )}
    </>
  );
};

export default Layout;
