import React, { createContext, ReactNode, useContext, useReducer } from "react";
import { reducer } from "./popup.reducer";
import {
  ActivePopup,
  OpenPopupPayload,
  PopupActions,
  PopupState,
} from "./popup.types";
import PopupContainer from "../../containers/map/Timeline/PopupContainer";
import { PropertySelectionType } from "constants/property";
import { IPropertyIdentifier } from "containers/map/Timeline/interface";

const PopupContext = createContext(
  {} as {
    activePopup: null | ActivePopup;
    source?: null | ActivePopup;
    reference?: null | string;
    title?: null | string;
    campaign?: string | null;
    selectionType?: PropertySelectionType;
    selectedArea?: [];
    properties?: IPropertyIdentifier[];
    contacts?: string[];
    openPopup: (payload: OpenPopupPayload) => void;
    closePopup: () => void;
  }
);

const INITIAL_STATE: PopupState = {
  activePopup: null,
  source: null,
  reference: null,
};

const usePopupActions = (initialPopup: PopupState = INITIAL_STATE) => {
  const [state, dispatch] = useReducer(reducer, initialPopup);

  const openPopup = (payload: OpenPopupPayload) =>
    dispatch({
      type: PopupActions.OPEN_POPUP,
      payload,
    });

  const closePopup = () =>
    dispatch({
      type: PopupActions.CLOSE_POPUP,
    });

  return {
    state,
    openPopup,
    closePopup,
  };
};

export const PopupProvider = ({
  children,
  refetch,
}: {
  children: ReactNode;
  refetch: Function;
}) => {
  const { state, openPopup, closePopup } = usePopupActions();
  const { activePopup } = state;
  return (
    <PopupContext.Provider
      value={{
        ...state,
        openPopup,
        closePopup,
      }}
    >
      {children}
      {activePopup && <PopupContainer refetch={refetch} />}
    </PopupContext.Provider>
  );
};

export const usePopup = () => useContext(PopupContext);
