import { styled } from "baseui";

export const Container = styled("div", {
  display: "flex",
});

export const Box = styled("div", ({ $isLast }: { $isLast: boolean }) => ({
  padding: "5px 10px",
  borderRadius: "5px",
  border: "1px solid #70707014",
  boxShadow: "0 0 10px #00000016",
  marginBottom: $isLast ? "20px" : "40px",
  color: "#545454",
  flex: 1,
}));

export const Header = styled("div", {
  "@media screen and (min-width: 540px)": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export const Body = styled("div", {
  marginTop: "20px",
  marginBottom: "5px",
});

export const HeaderAction = styled("div", ({ $hovered }: any) => ({
  opacity: 1,
  "@media screen and (min-width: 540px)": {
    display: "flex",
    justifyContent: "space-between",
    opacity: $hovered ? 1 : 0,
  },
}));

export const TimelineLeftIcon = styled("div", {
  borderRadius: "5px",
  fontSize: "13px",
  background: "#f99c1c",
  color: "#fff",
  padding: "8px",
  lineHeight: 1,
});

export const TimelineLeft = styled("div", {
  marginRight: "15px",
  position: "relative",
});

export const TimelineLeftLine = styled("div", {
  borderLeft: "2px dashed #e8e8e8",
  position: "absolute",
  height: "calc(100% - 30px)",
  left: "14px",
  top: "30px",
});

export const HeaderMeta = styled("div", {
  "@media screen and (min-width: 540px)": {
    paddingLeft: "10px",
    marginLeft: "10px",
    borderLeft: "1px solid #00000016",
  },
});
