import { Location } from "history";
import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import { ConfirmAlert } from "@virtualcapital/utogi-ui-library";

interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
}
const RouteLeavingGuard = ({ when, navigate }: Props) => {
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation) {
      ConfirmAlert({
        closeOnClickOutside: true,
        message:
          "Are you sure you want to leave this page without saving? This action can’t be undone.",
        onCancel: () => {},
        onConfirm: () => {
          setConfirmedNavigation(true);
        },
      });
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
    // eslint-disable-next-line
  }, [confirmedNavigation, lastLocation]);
  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
    </>
  );
};
export default RouteLeavingGuard;
