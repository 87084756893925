import React, { useEffect, useState } from "react";
import { Icon } from "@virtualcapital/utogi-ui-library";
import { styled } from "baseui";
import { gql, useQuery } from "@apollo/client";
import { TYPE, Value } from "baseui/select";

import FilterPopup from "components/map/FilterPopup";
import Select from "../../components/select";
import Toggle from "../../components/toggle";

import { useMap } from "context/map/use-map";
import { canAccessContacts } from "../../helpers/permission/common";
import { StatefulTooltip, PLACEMENT } from "baseui/tooltip";

type Props = {
  close: () => void;
  isAllMapMode: boolean;
};

const Section = styled("div", () => ({
  display: "flex",
  justifyContent: "space-between",
  color: "#666666",
  padding: "15px 20px",
  borderBottom: "1px solid #F7F7F7",
}));

const TagContainer = styled("div", () => ({
  borderBottom: "1px solid #F7F7F7",
  padding: "15px 20px",
}));

const TagInner = styled("div", () => ({
  display: "flex",
  justifyContent: "space-between",
  color: "#666666",
  marginBottom: "15px",
}));

const IconContainer = styled(Icon, () => ({
  fontSize: "20px",
  marginRight: "10px",
}));

const GET_FILTERS = gql`
  query($campaign: String!) {
    prospect {
      filters(campaign: $campaign) {
        layers {
          id
          name
          type {
            id
            title
          }
          primaryColor
          secondaryColor
          addFade
          addTransition
          transitionTime
          fadeTime
        }
        layerTypes {
          id
          title
          color
        }
        users(status: ACTIVATED, campaign: $campaign) {
          id
          name
        }
        tags {
          id
          name
        }
      }
    }
  }
`;

const ViewMode: React.FC<Props> = ({ close, isAllMapMode }) => {
  const {
    viewMode,
    setViewMode,
    filters: { selectedTags },
    selectedMaps,
    setMapFilters,
  } = useMap();

  const [tags, setTags] = useState([]);
  const hasContactPermission = canAccessContacts();

  const getTagLabel = ({ option }: any) => {
    return <>#{option.label}</>;
  };

  const { loading, data } = useQuery(GET_FILTERS, {
    variables: {
      campaign: isAllMapMode ? "all" : selectedMaps[0].id,
    },
  });

  const setSelectedTags = (selectedTags: Value) => {
    setMapFilters({
      selectedTags,
    });
  };

  useEffect(() => {
    if (data?.prospect?.filters) {
      const {
        prospect: {
          filters: { tags },
        },
      } = data;
      setTags(tags.map(({ name: label, id }: any) => ({ label, id })));
    }
  }, [data]);

  const getViewModeContainer = (children: React.ReactNode) => {
    if (hasContactPermission) return <>{children}</>;
    return (
      <StatefulTooltip
        placement={PLACEMENT.bottom}
        popoverMargin={8}
        content={() => (
          <div>
            Sorry !! You don't have access to the contact module. Please contact
            your administrator.
          </div>
        )}
      >
        {children}
      </StatefulTooltip>
    );
  };

  return (
    <FilterPopup icon="eye" title="View on map" close={close}>
      <Section>
        <div>
          <IconContainer icon="satellite" />
          Satellite
        </div>
        <Toggle
          checked={viewMode.isSatelliteView}
          onChange={(e: any) =>
            setViewMode({ isSatelliteView: e.target.checked })
          }
        />
      </Section>
      {getViewModeContainer(
        <Section>
          <div>
            <IconContainer icon="people" />
            People
          </div>
          <Toggle
            checked={viewMode.isPeopleView}
            onChange={(e: any) =>
              setViewMode({
                isPeopleView: e.target.checked,
                ...(e.target.checked && { isTagsView: false }),
              })
            }
            disabled={!hasContactPermission}
          />
        </Section>
      )}
      <Section>
        <div>
          <IconContainer icon="key" />
          Layers
        </div>
        <Toggle
          checked={viewMode.isLayersView}
          onChange={(e: any) => setViewMode({ isLayersView: e.target.checked })}
        />
      </Section>
      <TagContainer>
        <TagInner>
          <div>
            <IconContainer icon="flow" />
            Tags
          </div>
          <Toggle
            checked={viewMode.isTagsView}
            onChange={(e: any) =>
              setViewMode({
                isTagsView: e.target.checked,
                ...(e.target.checked && { isPeopleView: false }),
              })
            }
          />
        </TagInner>
        <Select
          closeOnSelect={true}
          options={tags}
          value={selectedTags}
          multi
          isLoading={loading}
          placeholder="Select tags"
          onChange={(params) => setSelectedTags(params.value)}
          type={TYPE.search}
          openOnClick={false}
          maxDropdownHeight="300px"
          getValueLabel={getTagLabel}
          getOptionLabel={getTagLabel}
        />
      </TagContainer>
    </FilterPopup>
  );
};

export default ViewMode;
