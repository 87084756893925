import { Icon } from "@virtualcapital/utogi-ui-library";
import { styled } from "baseui";
import { Button, SIZE } from "baseui/button";
import moment from "moment";
import queryString from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Container,
  TimelineLeft,
  TimelineLeftIcon,
  TimelineLeftLine,
} from "./styles";
import { Order } from "./TimelineSection";
import config from "../../../config";
import { processImage } from "../../../helpers/resolve-image-url";
import { TimelineBuyerProps } from "./interface";
import { defaultButtonStyles } from "../../../components/Button";

const BuyerContainer = styled("div", () => ({
  padding: "5px",
  "@media screen and (min-width: 769px)": {
    display: "flex",
  },
}));

const BuyerProfileBody = styled("div", () => ({
  flex: 1,
  marginRight: "15px",
}));

const Image = styled("div", ({ $img }: any) => ({
  width: "210px",
  height: "125px",
  overflow: "hidden",
  marginRight: "15px",
  maxWidth: "100%",
  backgroundImage: `url(${$img})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
}));

const MarketingRef = styled("div", () => ({
  backgroundColor: "#00AEEF",
  color: "#ffffff",
  padding: "5px 10px",
  fontSize: "12px",
  borderRadius: "5px",
  margin: "10px 0",
  display: "inline-block",
}));

const PropertyDetails = styled("div", () => ({
  display: "flex",
  flex: 3,
  marginTop: "15px",
}));

const PropertyDetailsItem = styled("div", () => ({
  marginRight: "10px",
}));

const Title = styled("div", () => ({
  fontSize: "17px",
  fontWeight: 400,
}));

const PropertyDetailsMeta = styled("div", () => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

type Props = TimelineBuyerProps & {
  order: Order;
  isLast: boolean;
};

const TimelineBuyer: React.FC<Props> = ({
  order,
  isLast,
  data: {
    referenceId,
    address,
    priceDetails,
    image,
    createdAt,
    bedroom,
    totalBathroom,
    livingRoom,
    garage,
    landArea,
    floorArea,
    campaignId,
  },
}) => {
  const {
    goBack,
    location: { search },
  } = useHistory();

  const onGoBack = () => {
    const { from } = queryString.parse(search) as { from?: string };
    if (from) {
      window.location.replace(from);
    } else {
      goBack();
    }
  };

  return (
    <Container>
      <TimelineLeft>
        <TimelineLeftIcon>
          <Icon icon="profile" />
        </TimelineLeftIcon>
        {!(isLast && order === Order.PAST) && <TimelineLeftLine />}
      </TimelineLeft>
      <Box $isLast={isLast}>
        <BuyerContainer>
          {image && <Image $img={processImage(image, 210, 125)} />}
          <BuyerProfileBody>
            <Title>{address}</Title>
            <MarketingRef>Utogi id: {referenceId}</MarketingRef>
            <div>
              {priceDetails?.saleType && priceDetails?.saleMethod
                ? `${priceDetails?.saleType} by ${priceDetails?.saleMethod}`
                : "-"}
            </div>
            <PropertyDetails>
              <PropertyDetailsItem>
                <Icon icon="bedroom" />
                &nbsp; <span>{bedroom ? bedroom : "-"}</span>
              </PropertyDetailsItem>
              <PropertyDetailsItem>
                <Icon icon="bathroom" />
                &nbsp; <span>{totalBathroom ? totalBathroom : "-"}</span>
              </PropertyDetailsItem>
              <PropertyDetailsItem>
                <Icon icon="living-room" />
                &nbsp; <span>{livingRoom ? livingRoom : "-"}</span>
              </PropertyDetailsItem>
              <PropertyDetailsItem>
                <Icon icon="garage" />
                &nbsp; <span>{garage ? garage : "-"}</span>
              </PropertyDetailsItem>
              <PropertyDetailsItem>
                <Icon icon="house-size" />
                &nbsp; <span>{floorArea ? floorArea : "-"}</span>
              </PropertyDetailsItem>
              <PropertyDetailsItem>
                <Icon icon="land-size" />
                &nbsp; <span>{landArea ? landArea : "-"}</span>
              </PropertyDetailsItem>
            </PropertyDetails>
          </BuyerProfileBody>
          <PropertyDetailsMeta>
            <div>Added on {moment(createdAt).format("DD MMMM YYYY")}</div>
            <Button
              overrides={{
                BaseButton: {
                  style: {
                    ...defaultButtonStyles,
                    width: "100%",
                    "@media screen and (max-width: 768px)": {
                      marginTop: "15px",
                      marginBottom: "15px",
                    },
                  },
                },
              }}
              size={SIZE.compact}
              onClick={onGoBack}
            >
              Go Back
            </Button>
            <Button
              $as="a"
              target="_blank"
              overrides={{
                BaseButton: {
                  style: {
                    ...defaultButtonStyles,
                    width: "100%",
                  },
                },
              }}
              size={SIZE.compact}
              href={`${config.DOMAIN_PROTOCOL}marketing.${config.DOMAIN_SUFFIX}/campaign/property/${campaignId}/buyers`}
            >
              View Campaign
            </Button>
          </PropertyDetailsMeta>
        </BuyerContainer>
      </Box>
    </Container>
  );
};

export default TimelineBuyer;
