import { useMutation } from "@apollo/react-hooks";
import { Icon } from "@virtualcapital/utogi-ui-library";
import { ApolloError, gql } from "apollo-boost";
import { useStyletron } from "baseui";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { toaster } from "baseui/toast";
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip";
import moment from "moment";
import React, { useState } from "react";

import {
  Body,
  Box,
  Container,
  Header,
  HeaderAction,
  TimelineLeft,
  TimelineLeftIcon,
  TimelineLeftLine,
} from "./styles";
import { Order } from "./TimelineSection";
import { TimelineAppointmentProps } from "./interface";
import { useHover } from "../../../helpers/use-hover";
import { useAlert } from "../../../context/alert/use-alert";
import { usePopup } from "../../../context/popup/use-popup";
import { ON_TIMELINE_EVENT_MODIFIED } from "../../../events/prospect";
import { event } from "../../../events";
import Button from "../../../components/Button";
import { ActivePopup } from "../../../context/popup/popup.types";

const COMPLETE_APPOINTMENT = gql`
  mutation($reference: ID!) {
    appointment {
      complete(id: $reference) {
        id
      }
    }
  }
`;

const DELETE_APPOINTMENT = gql`
  mutation($reference: ID!) {
    appointment {
      delete(id: $reference) {
        id
      }
    }
  }
`;

type Props = TimelineAppointmentProps & {
  order: Order;
  isLast: boolean;
};

const TimelineAppointment: React.FC<Props> = ({
  order,
  isLast,
  data,
  id,
  assignedUsers,
  reference,
}) => {
  const [hoverRef, hovered] = useHover();
  const { openAlert } = useAlert();
  const { openPopup } = usePopup();

  const [css] = useStyletron();
  const [checked, setChecked] = useState(data.isCompleted);

  const [
    completeAppointment,
    { loading: completeMutationLoading },
  ] = useMutation(COMPLETE_APPOINTMENT, {
    onCompleted: (data) => {
      if (data?.appointment?.complete?.id) {
        toaster.positive("Appointment completed successfully!", {});
        setTimeout(() => {}, 7000);
      }
    },
    onError: ({ graphQLErrors }: ApolloError) => {
      graphQLErrors.map(({ message }) => toaster.negative(message, {}));
    },
  });

  const [deleteAppointment, { loading: deleteMutationLoading }] = useMutation(
    DELETE_APPOINTMENT,
    {
      errorPolicy: "all",
      onError: (error) => {
        if (error && error.message) {
          toaster.negative("Error occurred while deleting appointment", {});
        }
      },
      onCompleted: () => {
        toaster.positive("Appointment deleted successfully", {});
        event.dispatch(ON_TIMELINE_EVENT_MODIFIED);
      },
    }
  );

  const onDelete = () => {
    openAlert({
      content: "Are you sure you want to delete this appointment?",
      primaryButton: {
        title: "Delete",
        action: () => deleteAppointment({ variables: { reference } }),
      },
      secondaryButton: { title: "Cancel", action: () => {} },
    });
  };

  const getCheckbox = () => {
    const onChange = () => {
      setChecked(true);
      completeAppointment({ variables: { reference } });
    };

    if (!data?.isCompleted) {
      return (
        <StatefulTooltip
          content={() => <span>Mark as Completed</span>}
          placement={PLACEMENT.topLeft}
          overrides={{
            Body: {
              style: () => ({
                backgroundColor: "#ffffff",
              }),
            },
            Inner: {
              style: () => ({
                backgroundColor: "#ffffff",
                color: "#707070",
              }),
            },
          }}
        >
          <div>
            <Checkbox
              checked={checked}
              onChange={onChange}
              labelPlacement={LABEL_PLACEMENT.right}
              disabled={data.isCompleted || completeMutationLoading}
              overrides={{
                Checkmark: {
                  style: ({ $checked }) => ({
                    backgroundColor: $checked ? "#53ce53" : "#ffffff",
                    borderTopColor: $checked ? "#53ce53" : "#757575",
                    borderBottomColor: $checked ? "#53ce53" : "#757575",
                    borderRightColor: $checked ? "#53ce53" : "#757575",
                    borderLeftColor: $checked ? "#53ce53" : "#757575",
                  }),
                },
                Label: {
                  style: {
                    fontSize: "inherit",
                  },
                },
              }}
            >
              Appointment
            </Checkbox>
          </div>
        </StatefulTooltip>
      );
    }
    return "Appointment";
  };

  const getDate = () => {
    if (moment(data?.date).isSame(moment(), "day")) return `Today`;
    if (moment(data?.date).isSame(moment().add(1, "days"), "day"))
      return `Tomorrow`;
    return `${moment(new Date(data?.date)).format("DD MMMM YYYY")}`;
  };

  return (
    <Container>
      <TimelineLeft>
        <TimelineLeftIcon>
          <Icon icon="schedule" />
        </TimelineLeftIcon>
        {!(isLast && order === Order.PAST) && <TimelineLeftLine />}
      </TimelineLeft>
      <Box ref={hoverRef} $isLast={isLast}>
        <Header>
          <div className={css({ display: "flex" })}>{getCheckbox()}</div>
          <HeaderAction $hovered={hovered}>
            <Button
              onClick={() =>
                openPopup({
                  reference,
                  popup: ActivePopup.APPOINTMENT,
                })
              }
              overrides={{
                BaseButton: {
                  style: {
                    color: "#00aeef",
                    backgroundColor: "#d2f7ff",
                    marginRight: "5px",
                    minWidth: "auto",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    ":hover": {
                      color: "#00aeef",
                      backgroundColor: "#a6efff",
                    },
                  },
                },
              }}
            >
              <Icon icon="edit" />
            </Button>
            <Button
              onClick={onDelete}
              isLoading={deleteMutationLoading}
              overrides={{
                BaseButton: {
                  style: {
                    color: "#ff0000",
                    backgroundColor: "#f6d8d8",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    ":hover": {
                      color: "#ff0000",
                      backgroundColor: "#fbb9b9",
                    },
                    minWidth: "auto",
                  },
                },
              }}
            >
              <Icon icon="delete" />
            </Button>
          </HeaderAction>
        </Header>
        <Body>
          <div
            className={css({
              fontSize: "14px",
              color: "#666666",
              marginBottom: "20px",
            })}
          >
            <p className={css({ fontWeight: 600, marginBottom: "5px" })}>
              {data.name}
            </p>
            <p className={css({ color: "#07aeef" })}>
              {moment(new Date(data.date)).format("DD/MM/YYYY")}&nbsp;
              {`${data.startTime}`} {" - "} {data.endTime}
            </p>
            {data?.description && (
              <p className={css({ fontSize: "18px" })}>{data.description}</p>
            )}
            {assignedUsers && assignedUsers.length > 0 && (
              <p>
                Assigned: {assignedUsers.map(({ name }) => name).join(", ")}
              </p>
            )}
          </div>
          <div
            className={css({
              backgroundColor: "#e6e7e8",
              color: "#7a7a7a",
              padding: "10px 15px",
              borderRadius: "5px",
              display: "inline-block",
            })}
          >
            {`${getDate()} at ${data.startTime}`}
          </div>
        </Body>
      </Box>
    </Container>
  );
};

export default TimelineAppointment;
