import moment from "moment";

export function formatEmailBlockquoteDate(date: Date) {
  return (
    moment(date).format("ddd, MMM D, YYYY ") +
    "at " +
    moment(date).format("h:mm A")
  );
}

export function transformRecipientValues(
  recipients: Array<{ name: string; email: string }>
) {
  return recipients && recipients.length > 0
    ? recipients.map(({ name, email }) => ({ name, email }))
    : [];
}

export function validateEmail(payload: any) {
  const missing: string[] = [];

  const { to, body, subject } = payload || {};

  if (!to?.length) missing.push("a recipient");
  if (!body) missing.push("body");
  if (!subject) missing.push("subject");

  if (missing.length) {
    const last = missing.pop();
    const first = missing.length ? missing : [last];
    const append = missing.length ? `${first.join(", ")} and ${last}` : last;

    throw new Error(`Cannot send email without ${append}`);
  }
}

export const getForwardBody = ({
  fromName,
  fromEmail,
  toName,
  toEmail,
  date,
  subject,
  body,
}: any) => {
  return `
  
    <br/>
    <br/>
    <div class="utogi_quote">
    <div dir="ltr" class="gmail_attr">---------- Forwarded message ---------
      <br/>
      From:
      <strong class="gmail_sendername" dir="auto">${fromName}</strong> 
      <span dir="auto">&lt;<a href="mailto:${fromEmail}">${fromEmail}</a>&gt;</span>
      <br/>
      Date: ${formatEmailBlockquoteDate(new Date(parseInt(date, 10)))}
      <br/>
      Subject:  ${subject}
      <br/>
      To: ${toName} &lt;
      <a href="mailto:${toEmail}">${toEmail}</a>&gt;
      <br/>
    </div>
    <br/>
    <br/>
    </div>
    ${body}
    `;
};

export const getReplyBody = ({ name, email, body }: any) => {
  return `

    <br>
    <br>
    <div class="utogi_quote">
    <div dir="ltr" class="gmail_attr">
    On ${formatEmailBlockquoteDate(new Date())} ${name} &lt;
    <a href="mailto:${email}" target="_blank">
    ${email}</a>&gt; wrote:
    <br>
    </div>
    <blockquote class="utogi_quote" style="margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex">
    ${body}
    </blockquote></div>
    `;
};
