import React, { useEffect, useState } from "react";
import {
  Icon,
  IconButton,
  MultiSelect,
} from "@virtualcapital/utogi-ui-library";
import { gql, useLazyQuery } from "@apollo/client";
import {
  hasPermission,
  permissions as modulePermissions,
} from "@virtualcapital/utogi-auth-library";
import { toaster } from "baseui/toast";
import classnames from "classnames";

import Loader from "components/loader";
import { AddTo } from "../AddTagsAndLayers";
import Button from "components/Button";

import { useMap } from "context/map/use-map";

import { PERMISSION_ERROR } from "constants/message";
import { PropertySelectionType } from "constants/property";

import { canAddNotes } from "../../../helpers/permission/map";
import { canAccessContacts } from "../../../helpers/permission/common";

import { IUnitDetails } from "interfaces/property";

import { MapRolePermissions } from "../../../types/permissions";

import { GET_CONTACT_LIST } from "../../../components/prospect/propertyModal/peopleTab/graphql";

import "./PropertyPopup.scss";
import { usePopup } from "context/popup/use-popup";
import { ActivePopup } from "context/popup/popup.types";
import { isMultiUnitProperty } from "helpers/address";

type PropertyPopupProps = {
  closePopup: any;
  openAddTagsAndLayers: (payload: AddTo) => void;
  onPropertyOpen: Function;
  lat: number;
  lng: number;
  permissions: MapRolePermissions;
  isAllMapMode: boolean;
};

const GET_UNIT_LIST = gql`
  query MapQuery($lat: Float!, $lng: Float!) {
    property {
      units(lat: $lat, lng: $lng) {
        propertyId
        unitNo
        address {
          fullAddress
        }
        lotName
      }
    }
  }
`;

const PropertyPopup = ({
  closePopup,
  openAddTagsAndLayers,
  lat,
  lng,
  onPropertyOpen,
  permissions,
  isAllMapMode,
}: PropertyPopupProps) => {
  const [units, setUnits] = useState<any>([]);
  const [selectedUnits, setSelectedUnits] = useState<any>([]);
  const [errors, setErrors] = useState<any>(null);
  const { selectedMaps } = useMap();
  const [hasPropertyContacts, setHasPropertyContacts] = useState(false);
  const hasContactPermission = canAccessContacts();

  const [heading, setPopupHeading] = useState(null);
  const hasAddNotesPermission = canAddNotes(permissions);
  const hasEmailModule = hasPermission(
    modulePermissions.email.IS_EMAIL_AVAILABLE
  );
  const { openPopup } = usePopup();

  const [getUnits, { loading }] = useLazyQuery(GET_UNIT_LIST, {
    variables: { lat, lng },
    onCompleted: (data: any) => {
      if (data?.property?.units) {
        const {
          property: { units },
        } = data;
        setUnits(units);
        const [{ propertyId: property }] = units;
        if (property && selectedUnits.length === 1) {
          const [{ value: unit }] = selectedUnits;
          if (hasContactPermission)
            getPropertyContacts({
              variables: { properties: [{ property, unit }] },
            });
        }

        const isMultiUnit = isMultiUnitProperty(units);
        if (!isMultiUnit) {
          const [{ unitNo: value, propertyId: property }] = units;
          setSelectedUnits([{ value, property }]);
        }
        if (units.length > 0) {
          const [{ address, lotName }] = units;
          if (!heading) {
            setPopupHeading(address?.fullAddress || lotName);
          }
        }
      }
    },
  });

  const [getPropertyContacts] = useLazyQuery(GET_CONTACT_LIST, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      setHasPropertyContacts(data?.contact?.propertyContact.length > 0);
    },
  });

  useEffect(() => {
    getUnits();
    // eslint-disable-next-line
  }, []);

  const getContent = () => {
    const isMultiUnit = isMultiUnitProperty(units);

    const onTagAndLayerClicked = () => {
      const [{ propertyId: property }] = units;

      if (isMultiUnit && selectedUnits.length === 0) {
        setErrors("Select one or more units to continue.");
        return;
      }
      const properties = selectedUnits.map(({ value: unit }: any) => ({
        unit,
        property,
      }));
      openAddTagsAndLayers({
        properties,
        selectionType: PropertySelectionType.ID,
        selectedArea: [],
      });
    };

    const onActionHandler = (popup: ActivePopup) => {
      const [{ propertyId: property }] = units;

      if (isMultiUnit && selectedUnits.length === 0) {
        setErrors("Select one or more units to continue.");
        return;
      }
      if (popup === ActivePopup.APPOINTMENT || popup === ActivePopup.REMINDER) {
        if (selectedUnits.length > 1) {
          toaster.negative(
            `You can't add ${
              popup === ActivePopup.APPOINTMENT ? "an" : "a"
            } ${popup} for multiple units at once`,
            {}
          );
          return;
        }
        if (!hasPropertyContacts) {
          toaster.negative(
            `You need at least one contact to add ${
              popup === ActivePopup.APPOINTMENT ? "an" : "a"
            } ${popup}`,
            {}
          );
          return;
        }
      }
      const properties = selectedUnits.map(({ value: unit }: any) => ({
        unit,
        property,
      }));
      openPopup({
        popup,
        properties,
        campaign: selectedMaps[0].id,
        selectionType: PropertySelectionType.ID,
      });
      closePopup();
    };

    const onUnitsChangeHandler = (
      selectedValues: Array<{ name: string; value: string }>
    ) => {
      setHasPropertyContacts(false);
      setSelectedUnits(selectedValues);
      if (selectedValues.length === 1) {
        const [{ value }] = selectedValues;
        const found = units.find(
          ({ unitNo }: IUnitDetails) => unitNo === value
        );
        if (found) {
          const { address, lotName } = found;
          setPopupHeading(address.fullAddress || lotName);
        }
      }
    };

    return (
      <>
        <div className="property-popup-header">
          <Icon icon="plus" className="add-button" />
          <span>{heading}</span>
          <IconButton
            onClick={closePopup}
            icon="close"
            className="close-button"
          />
        </div>
        <div className="property-popup-action">
          {isMultiUnit && (
            <div className="property-popup-action-item">
              <MultiSelect
                options={units.reduce(
                  (
                    unitList: Array<{ name: string; value: string }>,
                    currentUnit: IUnitDetails
                  ) => {
                    if (currentUnit?.unitNo && currentUnit.unitNo !== "") {
                      const { unitNo } = currentUnit;
                      unitList.push({ name: unitNo, value: unitNo });
                    }
                    return unitList;
                  },
                  []
                )}
                values={[]}
                onChange={onUnitsChangeHandler}
                placeholder="Select Units"
                error={errors}
              />
            </div>
          )}
          {!isAllMapMode && (
            <>
              <button
                className="property-popup-action-item"
                onClick={() => onTagAndLayerClicked()}
              >
                <Icon icon="layers" /> Layer/Tag
              </button>
              <button
                className="property-popup-action-item"
                onClick={() =>
                  hasAddNotesPermission
                    ? onActionHandler(ActivePopup.NOTE)
                    : toaster.negative(PERMISSION_ERROR.ADD_NOTES, {})
                }
              >
                <Icon icon="file" /> Note
              </button>
              <button
                className="property-popup-action-item"
                onClick={() => onActionHandler(ActivePopup.REMINDER)}
              >
                <Icon icon="bell" /> Reminder
              </button>
              <button
                className="property-popup-action-item"
                onClick={() => onActionHandler(ActivePopup.APPOINTMENT)}
              >
                <Icon icon="schedule" /> Appointment
              </button>
              {hasEmailModule && (
                <button
                  className="property-popup-action-item"
                  onClick={() => {
                    if (selectedUnits.length > 1)
                      return toaster.negative(
                        "Cannot send email with multiple units selected",
                        {}
                      );
                    onActionHandler(ActivePopup.EMAIL);
                  }}
                >
                  <Icon icon="inbox" /> Email
                </button>
              )}
            </>
          )}

          <div className="button-container">
            <Button onClick={() => onPropertyOpen(units, heading)}>
              Open Property
            </Button>
          </div>
        </div>
      </>
    );
  };

  if (loading) {
    return (
      <div className="property-popup">
        <div className="property-popup-loader">
          <Loader />
        </div>
      </div>
    );
  }

  if (!units || units?.length === 0) {
    return (
      <div className="property-popup invalid-property">
        <span>Invalid Property</span>
        <IconButton
          onClick={closePopup}
          icon="close"
          className="close-button"
        />
      </div>
    );
  }

  return (
    <div className={classnames("property-popup", { "all-map": isAllMapMode })}>
      {getContent()}
    </div>
  );
};

export default PropertyPopup;
