import { styled, useStyletron } from "baseui";
import { ChevronDown, ChevronUp } from "baseui/icon";
import React, { useState } from "react";
import TimelineAppointment from "./TimelineAppointment";
import TimelineBuyer from "./TimelineBuyer";
import TimelineEmail from "./TimelineEmail";
import TimelineNote from "./TimelineNote";
import TimelineReminder from "./TimelineReminder";
import TimelineTag from "./TimelineTag";
import TimelineLayer from "./TimelineLayer";
import Button from "../../../components/Button";
import { TimelineEventType } from "../../../constants/prospect";

export enum Order {
  TODAY = "Today",
  UPCOMING = "Up & Coming",
  PAST = "Past",
}

const TimelineLeftLine = styled("div", ({ $order }: { $order: Order }) => ({
  borderLeft: "2px dashed #e8e8e8",
  position: "absolute",
  ...($order === Order.TODAY
    ? { height: "calc(100% - 30px)", top: "30px" }
    : { height: "100%", top: "0px" }),
  left: "14px",
}));

type Props = {
  order: Order;
  records: any[];
  contact?: string;
};

const TimelineSection: React.FC<Props> = ({ order, records, contact }) => {
  const [isOpen, toggleIsOpen] = useState(true);
  const [css] = useStyletron();

  const getContent = () => {
    return records.map(({ type, ...rest }, index) => {
      switch (type) {
        case TimelineEventType.TAG:
          return (
            <TimelineTag
              key={index}
              order={order}
              isLast={records.length === index + 1}
              {...rest}
            />
          );
        case TimelineEventType.NOTE:
          return (
            <TimelineNote
              key={index}
              order={order}
              isLast={records.length === index + 1}
              {...rest}
            />
          );
        case TimelineEventType.REMINDER:
          return (
            <TimelineReminder
              key={index}
              order={order}
              isLast={records.length === index + 1}
              {...rest}
              contact={contact}
            />
          );
        case TimelineEventType.APPOINTMENT:
          return (
            <TimelineAppointment
              key={index}
              order={order}
              isLast={records.length === index + 1}
              {...rest}
            />
          );
        case TimelineEventType.BUYER:
          return (
            <TimelineBuyer
              key={index}
              order={order}
              isLast={records.length === index + 1}
              {...rest}
            />
          );
        case TimelineEventType.EMAIL:
          return (
            <TimelineEmail
              key={index}
              order={order}
              isLast={records.length === index + 1}
              {...rest}
            />
          );
        case TimelineEventType.LAYER:
          return (
            <TimelineLayer
              key={index}
              order={order}
              isLast={records.length === index + 1}
              {...rest}
            />
          );
      }
      return null;
    });
  };
  return (
    <div>
      <div
        className={css({
          position: "relative",
          paddingBottom: "20px",
          fontSize: "14px",
        })}
      >
        <TimelineLeftLine $order={order} />
        <Button
          onClick={() => toggleIsOpen(!isOpen)}
          endEnhancer={() =>
            isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />
          }
          overrides={{
            BaseButton: {
              style: {
                fontSize: "14px",
                backgroundColor: "#d2f7ff",
                color: "#00aeef",
                display: "flex",
                justifyContent: "space-between",
                marginLeft: "50px",
                ":active": {
                  color: "#00aeef",
                  backgroundColor: "#d2f7ff",
                },
                ":hover": {
                  color: "#00aeef",
                  backgroundColor: "#d2f7ff",
                },
              },
            },
          }}
        >
          {order}
        </Button>
      </div>
      {isOpen && <>{getContent()}</>}
    </div>
  );
};

export default TimelineSection;
