import React from "react";
import EmailEditor from "./emailEditor";
import EmailEditorContextProvider from "./emailEditorContext";

const EmailEditorContainer = (props: any) => {
  return (
    <EmailEditorContextProvider>
      <EmailEditor {...props} />
    </EmailEditorContextProvider>
  );
};

export default EmailEditorContainer;
