import { useLazyQuery } from "@apollo/react-hooks";
import { styled } from "baseui";
import { Input } from "baseui/input";
import React, { useEffect, useState } from "react";
import EmailPartyTag from "../emailPartyTag";
import { SEARCH_CONTACTS } from "./query";
import { isValidEmail } from "../../../../../../../../helpers/validations";
import Loader from "../../../../../../../../components/loader";

const PartyField = styled("div", () => ({
  display: "flex",
}));

const PartyFieldLabel = styled("label", () => ({
  paddingRight: "10px",
  fontSize: "14px",
}));

const PartyFieldInputContainer = styled("div", ({ $focused }: any) => ({
  overflowX: $focused ? "unset" : "hidden",
  width: $focused ? "auto" : 0,
  height: $focused ? "auto" : 0,
  display: "inline-block",
  position: "relative",
}));

const SuggestionContainer = styled("div", () => ({
  position: "absolute",
  backgroundColor: "#ffffff",
  zIndex: 999,
  padding: "5px 0px",
  minWidth: "400px",
  boxShadow: "rgb(0 0 0 / 28%) -5px 0px 20px",
  top: "30px",
  borderRadius: "5px",
}));

const Suggestion = styled("div", () => ({
  padding: "2px 5px",
  ":hover": {
    backgroundColor: "rgb(246, 246, 246)",
  },
  cursor: "pointer",
}));

const SuggestionName = styled("div", () => ({
  fontSize: "14px",
}));

const SuggestionEmail = styled("div", () => ({
  fontSize: "12px",
}));

type Props = {
  toRef?: any;
  name: string;
  id: string;
  value?: any;
  onChange: Function;
};

const BACKSPACE_KEY_CODE = 8;
const COMMA_KEY = ",";

const EmailPartyField = ({ name, id, value = [], onChange }: Props) => {
  const [inputValue, setInput] = useState<string>("");
  const [values, setValues] = useState<any[]>(value);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [contactSuggestions, setContactSuggestions] = useState<any[]>([]);

  const [searchContacts, { loading }] = useLazyQuery(SEARCH_CONTACTS, {
    onCompleted: (data) => {
      let suggestions = [];
      const contacts = data?.email?.contact;
      if (contacts && contacts.length > 0) {
        suggestions = contacts.map((item: any) => ({
          name: `${item.firstName} ${item.lastName}`,
          email: item.email,
        }));
      }
      setContactSuggestions(inputValue !== "" ? suggestions : []);
    },
  });

  useEffect(() => {
    if (id === "email-to") {
      setIsInputFocused(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChange(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleOnSelected = (value: any) => {
    let isValid = true;
    if (value) {
      setValues([...values, { name: value, email: value }]);
      setInput("");
      return;
    }

    // If nothing returned from the auto complete
    // set current typed input as the email
    const { length } = inputValue;
    const newValue =
      inputValue.charAt(length - 1) === ","
        ? inputValue.substr(0, length - 1)
        : inputValue;
    setInput(newValue);

    isValid = isValidEmail(newValue);

    if (isValid) {
      setValues([...values, { name: newValue, email: newValue }]);
      setInput("");
    } else {
      setIsValid(false);
    }
  };

  const handleOnBlur = () => {
    const isValid = isValidEmail(inputValue);

    if (isValid) {
      setValues([...values, { name: inputValue, email: inputValue }]);
      setInput("");
    } else {
      setIsValid(false);
    }
    if (contactSuggestions.length > 0) return;
    setIsInputFocused(false);
  };

  const deleteLast = () => {
    values.pop();
    setValues([...values]);
  };

  const onChangeValue = (event: any) => {
    const input = event.currentTarget.value;
    setContactSuggestions([]);
    if (input.charAt(input.length - 1) === COMMA_KEY) {
      handleOnSelected(inputValue);
      return;
    } else if (input.length > 2) {
      searchContacts({ variables: { keyword: input.toLowerCase(), limit: 8 } });
    }
    setInput(input);
  };

  const onBackspace = (event: any) => {
    if (event.keyCode === BACKSPACE_KEY_CODE && inputValue === "") {
      deleteLast();
    }
  };

  const onSelectSuggestion = (name: string, email: string) => {
    setValues([...values, { name: name !== "" ? name : email, email }]);
    setInput("");
    setContactSuggestions([]);
  };

  return (
    <PartyField
      onClick={() => {
        setIsInputFocused(true);
      }}
    >
      <PartyFieldLabel htmlFor={id}>{name}</PartyFieldLabel>
      <div>
        {values.map((item, index) => (
          <EmailPartyTag
            item={item}
            onClickRemove={() =>
              setValues([...values.filter((_, key) => key !== index)])
            }
            key={index}
          />
        ))}
        <PartyFieldInputContainer $focused={isInputFocused}>
          <Input
            autoFocus={id === "email-to"}
            onBlur={handleOnBlur}
            value={inputValue}
            onChange={onChangeValue}
            onKeyDown={onBackspace}
            overrides={{
              Root: {
                style: () => ({
                  border: "none",
                }),
              },
              Input: {
                style: () => ({
                  backgroundColor: "#ffffff",
                  fontSize: "12px",
                  padding: "2px",
                }),
              },
            }}
            error={!isValid}
          />
          {(contactSuggestions.length > 0 || loading) && (
            <SuggestionContainer
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              {loading && <Loader />}
              {contactSuggestions.map(({ name, email }) => (
                <Suggestion
                  key={`${name}-${email}`}
                  onClick={() => {
                    onSelectSuggestion(name, email);
                  }}
                >
                  <SuggestionName>{name}</SuggestionName>
                  <SuggestionEmail>{email}</SuggestionEmail>
                </Suggestion>
              ))}
            </SuggestionContainer>
          )}
        </PartyFieldInputContainer>
      </div>
    </PartyField>
  );
};

export default EmailPartyField;
