import { gql } from "@apollo/client";

export const GET_CONTACT_LIST = gql`
  query GetContactListQuery($properties: [PropertyInputType]!) {
    contact {
      propertyContact(properties: $properties) {
        firstName
        honorific
        lastName
        displayName
        mobileNumber
        landline
        email
        occupancyStatus
        id
      }
    }
  }
`;

export const SEARCH_CONTACTS = gql`
  query getContacts($keyword: String) {
    contact {
      list(keyword: $keyword) {
        contacts {
          firstName
          lastName
          mobileNumber
          landline
          email
          id
        }
      }
    }
  }
`;

export const ADD_CONTACT_TO_PROPERTY = gql`
  mutation($property: GraphQLCreatePropertyContactInput!, $contact: ID!) {
    contact {
      addContactToProperty(property: $property, contact: $contact) {
        contact
      }
    }
  }
`;

export const REMOVE_CONTACT_FROM_PROPERTY = gql`
  mutation($property: GraphQLCreatePropertyContactInput!, $contact: ID!) {
    contact {
      removeContactFromProperty(property: $property, contact: $contact) {
        success
        message
      }
    }
  }
`;
