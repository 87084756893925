import React, { forwardRef, InputHTMLAttributes, ForwardedRef } from "react";

import "./textarea.scss";
import classNames from "classnames";

type Props = InputHTMLAttributes<HTMLTextAreaElement> & {
  error?: any;
  rows?: number;
  maxTextLength?: number;
};

const TextArea = forwardRef(
  (
    { error, className, maxTextLength, ...rest }: Props,
    ref: ForwardedRef<HTMLTextAreaElement>
  ) => {
    const getErrors = (error: any) => {
      const Error = ({ children }: { children: string }) => (
        <div className="form-errors"> {children} </div>
      );
      if (!error) return null;
      switch (error.type) {
        case "required":
          return <Error>This field is required.</Error>;
        case "maxLength":
          return (
            <Error>
              {maxTextLength
                ? `Maximum ${maxTextLength} characters.`
                : "Max length exceeded."}
            </Error>
          );
        case "minLength":
          return <Error>Min length not met.</Error>;
        default:
          return <Error>{error.message}</Error>;
      }
    };
    const hasErrors = error !== null && error !== undefined;

    return (
      <div
        className={classNames(
          "textarea",
          {
            "has-errors": hasErrors,
          },
          className
        )}
      >
        <textarea ref={ref} {...rest} />
        {getErrors(error)}
      </div>
    );
  }
);

export default TextArea;
