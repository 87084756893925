import React from "react";
import { IconButton } from "@virtualcapital/utogi-ui-library";
import { toaster } from "baseui/toast";

import { PERMISSION_ERROR } from "constants/message";

import { getLayerBackground } from "helpers/helpers";

import { ILayer } from "interfaces/campaign";

import "./campaignLayerListItem.scss";

type Props = ILayer & {
  onDelete: Function;
  editLayer: Function;
  row: number;
  canEdit: boolean;
};

const campaignLayerListItem = ({
  onDelete,
  editLayer,
  isDeleted,
  canEdit,
  ...layer
}: Props) => {
  if (isDeleted) return null;
  const {
    name,
    type,
    primaryColor,
    secondaryColor,
    addFade,
    addTransition,
    transitionTime,
    fadeTime,
    row,
  } = layer;
  const layerBackground = getLayerBackground({
    primaryColor,
    secondaryColor,
    addFade,
    addTransition,
    transitionTime,
    fadeTime,
  });

  return (
    <>
      <div className="box campaign-layer-list-item">
        <div className="campaign-layer-list-item-body">
          <div className="progress-bar">
            <div
              className="primary-color"
              style={{
                background: layerBackground,
              }}
            />
          </div>
          <div className="campaign-layer-list-item-body-meta">
            <div className="campaign-layer-list-item-body-meta-item">
              {name}
            </div>
            <div className="campaign-layer-list-item-body-meta-item">
              {type && type.title && type.title}
            </div>
            <div className="campaign-layer-list-item-body-meta-item">
              {addTransition && transitionTime !== 0
                ? `Transition Time: ${transitionTime} Days`
                : "Transition Time: N/A"}
            </div>
            <div className="campaign-layer-list-item-body-meta-item">
              {addFade && fadeTime !== 0
                ? `Fade Time: ${fadeTime} days`
                : "Fade Time: N/A"}
            </div>
            <div className="button-block">
              <IconButton
                icon="edit"
                className="btn-edit"
                onClick={() => {
                  if (canEdit) return editLayer(layer, row);
                  toaster.negative(PERMISSION_ERROR.EDIT_CAMPAIGN, {});
                }}
              />
              <IconButton
                icon="delete"
                className="btn-delete"
                onClick={() => {
                  if (!canEdit) {
                    return toaster.negative(PERMISSION_ERROR.DELETE_LAYERS, {});
                  }
                  onDelete();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default campaignLayerListItem;
