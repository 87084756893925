import React from "react";
import { DrawingManager, Polygon } from "@react-google-maps/api";

import MultiSelectOptions from "../prospect/MultiSelectOptions";

import { useMap } from "context/map/use-map";
import { MapRolePermissions } from "../../types/permissions";

type Props = {
  openAddTagsAndLayers: Function;
  toggleMultiSelectMode: Function;
  permissions: MapRolePermissions;
};

const MultiSelectMode = ({
  openAddTagsAndLayers,
  toggleMultiSelectMode,
  permissions,
}: Props) => {
  const { selectedArea, setSelectedArea } = useMap();
  if (selectedArea) {
    return (
      <>
        <Polygon
          path={selectedArea}
          options={{
            fillColor: "#fddab1",
            fillOpacity: 0.3,
            strokeColor: "#ed8815",
            strokeWeight: 5,
            clickable: false,
            editable: false,
            zIndex: 2,
          }}
        />
        <MultiSelectOptions
          openAddTagsAndLayers={openAddTagsAndLayers}
          onClose={() => toggleMultiSelectMode(false)}
          permissions={permissions}
        />
      </>
    );
  }
  return (
    <DrawingManager
      drawingMode="polygon"
      options={{
        polygonOptions: {
          fillColor: "#fddab1",
          fillOpacity: 0.3,
          strokeColor: "#ed8815",
          strokeWeight: 5,
          clickable: false,
          editable: false,
          zIndex: 2,
        },
      }}
      onPolygonComplete={(polygon) => {
        if (polygon) {
          const path = polygon.getPath();

          const coordinates = [];
          for (let i = 0; i < path.length; i++) {
            coordinates.push({
              lat: path.getAt(i).lat(),
              lng: path.getAt(i).lng(),
            });
          }
          setSelectedArea({
            selectedArea: coordinates.concat([coordinates[0]]),
          });

          polygon.setMap(null);
        }
      }}
    />
  );
};

export default MultiSelectMode;
