import React from "react";
import Slider from "react-input-slider";
import { Icon, Input } from "@virtualcapital/utogi-ui-library";

import "./InputRange.scss";

const InputRange = ({
  label,
  onChange,
  value = 0,
  error,
}: {
  label: string;
  onChange: Function;
  value: number;
  error?: null | string;
}) => {
  const hasError = error !== null;
  return (
    <div className="input-range">
      <div className="input-range-inner">
        <div className="input-range-left">
          <label>{label}</label>
          <Slider
            axis="x"
            x={value}
            onChange={({ x }) => onChange(x)}
            styles={{
              active: {
                backgroundColor: "#00adf9",
              },
            }}
            xmax={365}
            xmin={1}
          />
        </div>
        <div className="input-range-right">
          <label>Days</label>
          <div className="input-range-right-inner">
            <Input
              value={value.toString()}
              onChange={(x: any) => onChange(x)}
            />
            {hasError && <Icon icon="caution" />}
          </div>
        </div>
      </div>
      {hasError && <div className="form-errors">{error}</div>}
    </div>
  );
};

export default InputRange;
