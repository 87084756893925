import {
  OpenPopupPayload,
  PopupActions,
  PopupState,
  PopupStateActions,
} from "./popup.types";

export const reducer = (
  state: PopupState,
  action: PopupStateActions
): PopupState => {
  const openPopup = ({
    popup: activePopup,
    ...rest
  }: OpenPopupPayload): PopupState => ({
    ...state,
    ...rest,
    activePopup,
  });
  const closePopup = (): PopupState => ({
    ...state,
    source: null,
    activePopup: null,
    reference: null,
    title: null,
  });

  switch (action.type) {
    case PopupActions.OPEN_POPUP:
      return openPopup(action.payload);
    case PopupActions.CLOSE_POPUP:
      return closePopup();
    default:
      return state;
  }
};
