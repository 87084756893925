import {
  Coordinate,
  MapActions,
  MapState,
  MapStateActions,
  SetBatchIdPayload,
  SetManagerView,
  SetMapCenterPayload,
  SetMapFiltersPayload,
  SetPointerOnMapPayload,
  SetRecentlySelectedMap,
  SetSelectedAreaPayload,
  SetSelectedMapsPayload,
  SetUsersPayload,
  SetViewModePayload,
  ToggleShowEdgePropertiesPayload,
} from "./map.types";

import { InMapView } from "../../constants/prospect";
import { ProspectViewMode } from "../../constants/map";

import { COLOR_FILTER_LIST, FADE_OPTIONS_LIST } from "../../helpers/map";

export const reducer = (state: MapState, action: MapStateActions): MapState => {
  const setMapCenter = ({ center }: SetMapCenterPayload) => ({
    ...state,
    center,
  });
  const setSelectedArea = ({ selectedArea }: SetSelectedAreaPayload) => ({
    ...state,
    selectedArea,
  });

  const setSelectedMaps = ({ selectedMaps }: SetSelectedMapsPayload) => ({
    ...state,
    selectedMaps,
    ...(selectedMaps.length === 1 && { recentlySelectedMap: selectedMaps[0] }),
  });

  const setPointerOnMap = ({ pointerOnMap }: SetPointerOnMapPayload) => ({
    ...state,
    pointerOnMap,
    inMapView: InMapView.POINTER,
    center: pointerOnMap.coordinates,
  });

  const toggleShowEdgeProperties = ({
    showEdgeProperties,
  }: ToggleShowEdgePropertiesPayload) => ({
    ...state,
    showEdgeProperties,
  });

  const setMapFilters = (filters: SetMapFiltersPayload) => ({
    ...state,
    filters: {
      ...state.filters,
      ...filters,
    },
  });

  const setMapViewMode = (payload: SetViewModePayload) => ({
    ...state,
    viewMode: {
      ...state.viewMode,
      ...payload,
    },
  });

  const setUsers = (users: SetUsersPayload) => ({
    ...state,
    users,
  });

  const setRecentlySelectedMap = ({
    recentlySelectedMap,
    location,
  }: SetRecentlySelectedMap) => ({
    ...state,
    recentlySelectedMap,
    ...(location === "/maps/selected" && {
      selectedMaps: [recentlySelectedMap],
    }),
  });

  const setManagerView = ({ isManagerView }: SetManagerView) => ({
    ...state,
    isManagerView,
  });

  const resetMapFilters = () => ({
    ...state,
    selectedMaps: [],
    selectedArea: null,
    showEdgeProperties: false,
    pointerOnMap: null,
    inMapView: InMapView.LAYERS,
    filters: {
      layerTypes: [],
      layers: [],
      viewMode: ProspectViewMode.PROSPECT,
      selectedTags: [],
      fadeOption: [FADE_OPTIONS_LIST[0]],
      colorFilter: [COLOR_FILTER_LIST[0]],
      isByRecentOrder: true,
      selectedUsers: null,
      dateRange: [],
    },
    viewMode: {
      isSatelliteView: false,
      isPeopleView: true,
      isTagsView: false,
      isLayersView: true,
    },
    users: null,
    isManagerView: false,
  });

  const setContactCoordinates = (coordinates: Array<Coordinate>) => ({
    ...state,
    contactCoordinates: coordinates,
  });

  const resetSelectedMap = () => ({
    ...resetMapFilters(),
    recentlySelectedMap: null,
  });

  const setBatchId = ({ batchId }: SetBatchIdPayload) => ({
    ...state,
    batchId,
  });

  switch (action.type) {
    case MapActions.SET_SELECTED_MAPS:
      return setSelectedMaps(action.payload);
    case MapActions.SET_MAP_CENTER:
      return setMapCenter(action.payload);
    case MapActions.SET_SELECTED_AREA:
      return setSelectedArea(action.payload);
    case MapActions.SET_POINTER_ON_MAP:
      return setPointerOnMap(action.payload);
    case MapActions.TOGGLE_SHOW_EDGE_PROPERTIES:
      return toggleShowEdgeProperties(action.payload);
    case MapActions.SET_MAP_FILTERS:
      return setMapFilters(action.payload);
    case MapActions.SET_VIEW_MODE:
      return setMapViewMode(action.payload);
    case MapActions.SET_USER:
      return setUsers(action.payload);
    case MapActions.RECENTLY_SELECTED_MAP:
      return setRecentlySelectedMap(action.payload);
    case MapActions.SET_MANAGER_VIEW:
      return setManagerView(action.payload);
    case MapActions.RESET_MAP_FILTERS:
      return resetMapFilters();
    case MapActions.SET_CONTACT_COORDINATES:
      return setContactCoordinates(action.payload);
    case MapActions.RESET_SELECTED_MAP:
      return resetSelectedMap();
    case MapActions.SET_BATCH_ID:
      return setBatchId(action.payload);
    default:
      return state;
  }
};
