import React from "react";
import { useQuery, gql } from "@apollo/client";

import PropertyDetails from "./PropertyDetails";
import Loader from "components/loader";
import { Property } from "../../../interfaces/property";

type Props = Property;

const PropertyDetailsContainer = ({ property, unit }: Props) => {
  const GET_PROPERTY_DETAILS = gql`
    query($unit: String, $property: ID) {
      property {
        getPropertyDetails(unit: $unit, property: $property) {
          name
          type
          propertyStatus
          bedRooms
          bathRooms
          livingRooms
          garage
          zoning
          street
          suburb
          houseNumber
          streetNumber
          postcode
          country
          cv
          cvDate
          propertyTitle
          city
          region
          propertyBoundaries {
            coordinates
          }
          cTFullDescription
          localAuthority
          rates
          property
          landArea
          floorArea
          category
          propertyType
          unit
          unitNumber
          coordinates {
            lat
            lng
          }
          cTNumber
          lot
          DP
        }
      }
    }
  `;

  const { data, loading, refetch } = useQuery(GET_PROPERTY_DETAILS, {
    variables: { property, unit },
    fetchPolicy: "no-cache",
  });

  if (loading && !data) {
    return <Loader />;
  }

  const {
    property: { getPropertyDetails },
  } = data;

  return (
    <PropertyDetails
      property={property}
      unit={unit}
      details={getPropertyDetails}
      refetch={refetch}
    />
  );
};

export default PropertyDetailsContainer;
