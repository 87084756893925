import { styled } from "baseui";

export const Header = styled("div", () => ({
  marginBottom: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontWeight: 500,
  color: "#00AEEF",
  fontSize: "18px",
}));

export const PermissionGroupHeader = styled("div", () => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: "16px",
  padding: "15px 30px",
  borderBottom: "0.2px solid rgb(56 43 43 / 12%)",
  fontWeight: 600,
}));

export const PermissionGroupHeaderTitle = styled("span", () => ({
  marginRight: "15px",
  cursor: "pointer",
}));

export const ToggleButton = styled("button", () => ({
  background: "none",
  border: "none",
  outline: "none",
}));

export const PermissionContainer = styled("div", () => ({
  marginBottom: "25px",
}));

export const PermissionContent = styled("div", () => ({
  padding: "15px 30px",
}));
