import React, { useEffect } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { toaster } from "baseui/toast";

import PropertyTagList from "./PropertyTagList";

import { event } from "../../../events";

import { Property } from "interfaces/property";
import { ON_LAYERS_AND_TAGS_ADDED } from "../../../events/prospect";

type Props = Property;

const PropertyTagListContainer = ({ unit, property }: Props) => {
  const GET_PROPERTY_TAGS = gql`
    query($property: ID!, $unit: String) {
      property {
        propertyTags(property: $property, unit: $unit) {
          id
          name
        }
      }
    }
  `;
  const DELETE_PROPERTY_TAG = gql`
    mutation($id: String!) {
      property {
        deleteTag(id: $id) {
          id
        }
      }
    }
  `;

  const { data, refetch } = useQuery(GET_PROPERTY_TAGS, {
    variables: { property, unit },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const onSuccess = () => {
      refetch();
    };

    event.subscribe(ON_LAYERS_AND_TAGS_ADDED, onSuccess);

    return () => {
      event.unsubscribe(ON_LAYERS_AND_TAGS_ADDED, onSuccess);
    };
  }, [refetch]);

  const [deleteTag, { loading }] = useMutation(DELETE_PROPERTY_TAG, {
    onError: (error) => {
      if (error) {
        toaster.negative("Tag removing failed", {});
      }
    },
    onCompleted: () => {
      toaster.positive("Tag removed successfully", {});
      refetch();
    },
  });
  return (
    <PropertyTagList
      tags={data?.property?.propertyTags || []}
      loading={loading}
      deleteTag={deleteTag}
    />
  );
};

export default PropertyTagListContainer;
