export enum NotificationEventSourceType {
  NEW_NOTIFICATION = "NEW_NOTIFICATION",
  NEW_EMAIL = "NEW_EMAIL",
  NEW_TIMELINE_RECORD = "NEW_TIMELINE_RECORD",
  CAMPAIGN_TIMELINE_UPDATED = "CAMPAIGN_TIMELINE_UPDATED",
  CAMPAIGN_CHECKLIST_UPDATED = "CAMPAIGN_CHECKLIST_UPDATED",
  NEW_ACTIVITY_ADDED = "NEW_ACTIVITY_ADDED",
  REMINDER_MODIFIED = "REMINDER_MODIFIED",
}

export enum NotificationSettings {
  HIGH_PRIORITY_REMINDER_DUE = "highPriorityReminderDue",
  MEDIUM_PRIORITY_REMINDER_DUE = "mediumPriorityReminderDue",
  LOW_PRIORITY_REMINDER_DUE = "lowPriorityReminderDue",
  CONTACT_IMPORT_STATUS_CHANGE = "contactImportStatusChange",
  EMAIL_SYNC_COMPLETED = "emailSyncCompleted",
  EMAIL_RECEIVED = "emailReceived",
  RECEIVE_DAILY_UPDATE_EMAILS = "receiveDailyUpdateEmails",
  // marketing notifications
  PROPERTY_CAMPAIGN_PROPERTY_FILE_DOWNLOADED = "propertyCampaignPropertyFileDownloaded",
  PROPERTY_CAMPAIGN_STATUS_CHANGE = "propertyCampaignStatusChange",
  GENERAL_CAMPAIGN_STATUS_CHANGE = "generalCampaignStatusChange",
  PROPERTY_CAMPAIGN_FACEBOOK_AD_STATUS_CHANGE = "propertyCampaignFacebookAdStatusChange",
  GENERAL_CAMPAIGN_FACEBOOK_AD_STATUS_CHANGE = "generalCampaignFacebookAdStatusChange",
  PROPERTY_CAMPAIGN_CAMPAIGN_EXPIRY = "propertyCampaignCampaignExpiry",
  PROPERTY_CAMPAIGN_OPEN_HOME_ADDED_BUYERS = "propertyCampaignOpenHomeAddedBuyers",
  FACEBOOK_ACCOUNT_EXPIRY = "facebookAccountExpiry",
}
