import { styled } from "baseui";

export const LayerPreview = styled("div", ({ background }: any) => ({
  background,
  width: "30px",
  height: "30px",
  display: "block",
  borderRadius: "5px",
}));

export const Container = styled("div", () => ({
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const Inner = styled("div", () => ({
  display: "flex",
  alignItems: "center",
}));

export const TextContainer = styled("div", () => ({
  marginLeft: "10px",
}));
