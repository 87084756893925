import React, { FC } from "react";
import { getLayerBackground } from "helpers/helpers";

import { LayerPreview } from "containers/map/filter.styles";

type Props = {
  primaryColor: string;
  secondaryColor: string;
  addFade: boolean;
  addTransition: boolean;
  transitionTime: number;
  fadeTime: number;
};

const LayerThumbnail: FC<Props> = ({
  primaryColor,
  secondaryColor,
  addFade,
  addTransition,
  transitionTime,
  fadeTime,
}) => {
  const background = getLayerBackground({
    primaryColor,
    secondaryColor,
    addFade,
    addTransition,
    transitionTime,
    fadeTime,
  });

  return <LayerPreview background={background} />;
};

export default LayerThumbnail;
