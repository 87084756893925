import { useMutation } from "@apollo/react-hooks";
import { Icon } from "@virtualcapital/utogi-ui-library";
import { ApolloError, gql } from "apollo-boost";
import { useStyletron } from "baseui";
import { SIZE } from "baseui/button";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { StatefulPopover } from "baseui/popover";
import { toaster } from "baseui/toast";
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip";
import moment from "moment";
import React, { useState } from "react";
import {
  Body,
  Box,
  Container,
  Header,
  HeaderAction,
  HeaderMeta,
  TimelineLeft,
  TimelineLeftIcon,
  TimelineLeftLine,
} from "./styles";
import { Order } from "./TimelineSection";
import { TimelineReminderProps } from "./interface";
import { useHover } from "../../../helpers/use-hover";
import { usePopup } from "../../../context/popup/use-popup";
import { useAlert } from "../../../context/alert/use-alert";
import { ON_TIMELINE_EVENT_MODIFIED } from "../../../events/prospect";
import { event } from "../../../events";
import { ActivePopup } from "../../../context/popup/popup.types";
import Button from "../../../components/Button";

type Props = TimelineReminderProps & {
  order: Order;
  isLast: boolean;
  contact: string;
};

const DELETE_REMINDER = gql`
  mutation($reference: ID!) {
    reminder {
      delete(id: $reference) {
        id
      }
    }
  }
`;

const REMINDER_COMPLETE_MUTATION = gql`
  mutation completeReminder($id: ID!, $isCompleted: Boolean!) {
    reminder {
      changeStatus(id: $id, isCompleted: $isCompleted) {
        status
      }
    }
  }
`;

const TimelineReminder: React.FC<Props> = ({
  order,
  isLast,
  data,
  createdUser,
  reference,
  contact,
}) => {
  const [hoverRef, hovered] = useHover();
  const [css] = useStyletron();
  const [checked, setChecked] = useState(data.isCompleted);
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  const { openPopup } = usePopup();
  const { openAlert } = useAlert();

  const [completeReminder, { loading: completeReminderLoading }] = useMutation(
    REMINDER_COMPLETE_MUTATION,
    {
      onCompleted: (data) => {
        if (data?.reminder?.changeStatus?.status) {
          toaster.positive("Reminder status changed successfully!!", {});
          setShowAddNoteModal(true);
          setTimeout(() => {
            setShowAddNoteModal(false);
          }, 7000);
        }
      },
      onError: ({ graphQLErrors }: ApolloError) => {
        graphQLErrors.map(({ message }) => toaster.negative(message, {}));
      },
    }
  );

  const [deleteReminder, { loading: deleteMutationLoading }] = useMutation(
    DELETE_REMINDER,
    {
      errorPolicy: "all",
      onError: (error) => {
        if (error && error.message) {
          toaster.positive("Error occurred while deleting reminder", {});
        }
      },
      onCompleted: () => {
        toaster.negative("Reminder deleted successfully", {});
        event.dispatch(ON_TIMELINE_EVENT_MODIFIED);
      },
    }
  );

  const onDelete = () => {
    openAlert({
      content: "Are you sure you want to delete this reminder?",
      primaryButton: {
        title: "Delete",
        action: () => deleteReminder({ variables: { reference } }),
      },
      secondaryButton: { title: "Cancel", action: () => {} },
    });
  };

  const getCheckbox = () => {
    const onChange = () => {
      setChecked(!checked);
      completeReminder({ variables: { id: reference, isCompleted: !checked } });
    };
    const getColor = () => {
      switch (data?.priority) {
        case "low":
          return "#008000";
        case "medium":
          return "#ffa500";
        case "high":
          return "#ff0000";
      }
    };
    const checkbox = (
      <Checkbox
        checked={checked}
        onChange={onChange}
        labelPlacement={LABEL_PLACEMENT.right}
        disabled={completeReminderLoading}
        overrides={{
          Checkmark: {
            style: ({ $checked }) => ({
              borderTopLeftRadius: "50%",
              borderTopRightRadius: "50%",
              borderBottomLeftRadius: "50%",
              borderBottomRightRadius: "50%",
              backgroundColor: $checked ? "#53ce53" : "#ffffff",
              borderTopColor: $checked ? "#53ce53" : "#757575",
              borderBottomColor: $checked ? "#53ce53" : "#757575",
              borderRightColor: $checked ? "#53ce53" : "#757575",
              borderLeftColor: $checked ? "#53ce53" : "#757575",
            }),
          },
          Label: {
            style: {
              fontSize: "inherit",
            },
          },
        }}
      >
        <span
          className={css({
            margin: "2px 10px 2px 0",
            height: "8px",
            width: "8px",
            borderRadius: "50%",
            display: "inline-block",
            backgroundColor: getColor(),
          })}
        />
        Reminder
      </Checkbox>
    );
    const onNoteClicked = () => {
      openPopup({
        popup: ActivePopup.NOTE,
        contacts: [contact],
        source: ActivePopup.REMINDER,
      });
      setShowAddNoteModal(false);
    };
    if (showAddNoteModal) {
      return (
        <StatefulPopover
          initialState={{ isOpen: true }}
          placement={PLACEMENT.top}
          overrides={{
            Inner: {
              style: () => ({
                backgroundColor: "#ffffff",
                fontSize: "10px",
                color: "#00d202",
                fontWeight: 600,
                textAlign: "center",
                padding: "5px",
              }),
            },
          }}
          content={() => (
            <div>
              Completed!
              <Button
                onClick={onNoteClicked}
                size={SIZE.compact}
                overrides={{
                  Root: {
                    style: () => ({
                      display: "block",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      marginTop: "5px",
                    }),
                  },
                }}
              >
                Add Note
              </Button>
            </div>
          )}
          returnFocus
          autoFocus
        >
          <div>{checkbox}</div>
        </StatefulPopover>
      );
    }
    if (!checked) {
      return (
        <StatefulTooltip
          content={() => <span>Mark as Completed</span>}
          placement={PLACEMENT.topLeft}
          overrides={{
            Body: {
              style: () => ({
                backgroundColor: "#ffffff",
              }),
            },
            Inner: {
              style: () => ({
                backgroundColor: "#ffffff",
                color: "#707070",
              }),
            },
          }}
        >
          <div>{checkbox}</div>
        </StatefulTooltip>
      );
    }
    return checkbox;
  };

  const labelContent = () => {
    if (data?.isCompleted) {
      return "Completed";
    }
    const time = data?.allDay ? "" : ` at ${data.startTime}`;
    if (moment(data?.date).isSame(moment(), "day")) return `Today${time}`;
    if (moment(data?.date).isSame(moment().add(1, "days"), "day"))
      return `Tomorrow${time}`;
    return `${moment(new Date(data?.date)).format("DD MMMM YYYY")}${time}`;
  };

  return (
    <Container>
      <TimelineLeft>
        <TimelineLeftIcon>
          <Icon icon="bell" />
        </TimelineLeftIcon>
        {!(isLast && order === Order.PAST) && <TimelineLeftLine />}
      </TimelineLeft>
      <Box ref={hoverRef} $isLast={isLast}>
        <Header>
          <div
            className={css({
              "@media screen and (min-width: 540px)": { display: "flex" },
            })}
          >
            {getCheckbox()}
            <HeaderMeta>
              {data.isCompleted
                ? `Completed on ${moment(data.completedAt).format(
                    "DD MMMM YYYY"
                  )}`
                : `Due on ${moment(data.date).format("DD MMMM YYYY")}`}
            </HeaderMeta>
            <HeaderMeta>{createdUser.name}</HeaderMeta>
          </div>
          <HeaderAction $hovered={hovered}>
            <Button
              onClick={() =>
                openPopup({
                  reference,
                  contacts: [],
                  popup: ActivePopup.REMINDER,
                })
              }
              overrides={{
                BaseButton: {
                  style: {
                    color: "#00aeef",
                    backgroundColor: "#d2f7ff",
                    marginRight: "5px",
                    minWidth: "auto",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    ":hover": {
                      color: "#00aeef",
                      backgroundColor: "#a6efff",
                    },
                  },
                },
              }}
            >
              <Icon icon="edit" />
            </Button>
            <Button
              overrides={{
                BaseButton: {
                  style: {
                    color: "#ff0000",
                    backgroundColor: "#f6d8d8",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    ":hover": {
                      color: "#ff0000",
                      backgroundColor: "#fbb9b9",
                    },
                    minWidth: "auto",
                  },
                },
              }}
              onClick={onDelete}
              isLoading={deleteMutationLoading}
            >
              <Icon icon="delete" />
            </Button>
          </HeaderAction>
        </Header>
        <Body>
          <div
            className={css({
              fontSize: "18px",
              color: "#666666",
              marginBottom: "20px",
            })}
          >
            <p className={css({ fontSize: "24px" })}>{data.name}</p>
            {data?.description && data.description !== "" && (
              <p>{data.description}</p>
            )}
          </div>
          <div
            className={css({
              backgroundColor: data?.isCompleted ? "#e6e7e8" : "#d8f6d8",
              color: data?.isCompleted ? "#7a7a7a" : "#2dca73",
              padding: "10px 15px",
              borderRadius: "5px",
              display: "inline-block",
            })}
          >
            {labelContent()}
          </div>
        </Body>
      </Box>
    </Container>
  );
};

export default TimelineReminder;
