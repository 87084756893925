import { useQuery, useMutation } from "@apollo/client";
import { Switch } from "@virtualcapital/utogi-ui-library";
import React, { useContext, useReducer, useEffect } from "react";
import { AuthContext } from "@virtualcapital/utogi-auth-library";
import { GET_MODULE_SETTINGS, UPDATE_MODULE_SETTINGS } from "./query";
import Loader from "components/loader";

import { Actions, reducer } from "./reducer";
import { useStyletron } from "baseui";

const initialState = {
  IS_TAGS_SHARED_WITH_COMPANY: false,
  IS_TAGS_SHARED_WITH_OFFICE: false,
  IS_TAGS_SHARED_WITH_GROUP: false,
  IS_TAGS_SHARED: false,
};

interface IProps {
  isEditable: boolean;
}

const GeneralList: React.FC<IProps> = ({ isEditable }: IProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    authData: { office },
  } = useContext(AuthContext);
  const [css] = useStyletron();

  const setState = (type: Actions, payload: boolean) => {
    dispatch({ type, payload });
  };

  const { loading, data } = useQuery(GET_MODULE_SETTINGS, {
    variables: { ids: [office?.id] },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const [office] = data?.office;

      if (office?.mapsModuleSettings)
        setState(Actions.SET_INITIAL_SETTINGS, office?.mapsModuleSettings);
    },
  });

  const [callUpdateSettingMutation] = useMutation(UPDATE_MODULE_SETTINGS);

  useEffect(() => {
    if (data) callUpdateSettingMutation({ variables: { ...state } });
    // eslint-disable-next-line
  }, [state]);

  if (loading) return <Loader />;

  return (
    <table className={css({ width: "100%", fontSize: "14px" })}>
      <thead className={css({ color: "#00aeef" })}>
        <tr>
          <td />
          <td className={css({ padding: "5px" })}>Company</td>
          <td className={css({ padding: "5px" })}>Office</td>
          <td className={css({ padding: "5px" })}>Private</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Property Tag Sharing</td>
          <td>
            <Switch
              onChange={(e: any) =>
                isEditable && setState(Actions.IS_TAGS_SHARED_WITH_COMPANY, e)
              }
              value={state.IS_TAGS_SHARED_WITH_COMPANY}
            />
          </td>
          <td>
            <Switch
              onChange={(e: any) =>
                isEditable && setState(Actions.IS_TAGS_SHARED_WITH_OFFICE, e)
              }
              value={state.IS_TAGS_SHARED_WITH_OFFICE}
            />
          </td>
          <td>
            <Switch
              onChange={(e: any) =>
                isEditable && setState(Actions.IS_TAGS_SHARED, e)
              }
              value={!state.IS_TAGS_SHARED}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default GeneralList;
