import { useMutation } from "@apollo/react-hooks";
import { AuthContext } from "@virtualcapital/utogi-auth-library";
import { Icon } from "@virtualcapital/utogi-ui-library";
import { gql } from "apollo-boost";
import { useStyletron } from "baseui";
import { SIZE } from "baseui/button";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";
import { toaster } from "baseui/toast";
import moment from "moment";
import React, { useContext } from "react";
import { event } from "../../../events";
import {
  Body,
  Box,
  Container,
  Header,
  HeaderAction,
  HeaderMeta,
  TimelineLeft,
  TimelineLeftIcon,
  TimelineLeftLine,
} from "./styles";
import { Order } from "./TimelineSection";
import { TimelineEmailProps } from "./interface";
import { useHover } from "../../../helpers/use-hover";
import { usePopup } from "../../../context/popup/use-popup";
import { useAlert } from "../../../context/alert/use-alert";
import { ON_TIMELINE_EVENT_MODIFIED } from "../../../events/prospect";
import Button from "../../../components/Button";
import { ActivePopup } from "../../../context/popup/popup.types";
import { toTitleCase } from "../../../helpers/format";

type Props = TimelineEmailProps & {
  order: Order;
  isLast: boolean;
};

const DELETE_EMAIL = gql`
  mutation($reference: ID!) {
    email {
      deleteTimeline(reference: $reference) {
        success
      }
    }
  }
`;

const TimelineEmail: React.FC<Props> = ({
  order,
  isLast,
  createdAt,
  reference,
  data: { name, emailState, email: emailContent, accessLevel },
  createdUser,
}) => {
  const [hoverRef, hovered] = useHover();
  const { openPopup } = usePopup();
  const { openAlert } = useAlert();
  const [css] = useStyletron();
  const {
    authData: { user },
  } = useContext(AuthContext);

  const [deleteEmail, { loading: deleteMutationLoading }] = useMutation(
    DELETE_EMAIL,
    {
      errorPolicy: "all",
      onError: (error) => {
        if (error && error.message) {
          toaster.negative("Error occurred while deleting email", {});
        }
      },
      onCompleted: () => {
        toaster.positive("Email deleted successfully", {});
        event.dispatch(ON_TIMELINE_EVENT_MODIFIED);
      },
    }
  );

  const onDelete = () => {
    openAlert({
      content: "Are you sure you want to delete this email?",
      primaryButton: {
        title: "Delete",
        action: () => deleteEmail({ variables: { reference } }),
      },
      secondaryButton: { title: "Cancel", action: () => {} },
    });
  };

  return (
    <Container>
      <TimelineLeft>
        <TimelineLeftIcon>
          <Icon icon="envelop" />
        </TimelineLeftIcon>
        {!(isLast && order === Order.PAST) && <TimelineLeftLine />}
      </TimelineLeft>
      <Box ref={hoverRef} $isLast={isLast}>
        <Header>
          <div>
            <span>Email</span>
            <HeaderMeta>
              {toTitleCase(emailState)} on{" "}
              {moment(new Date(createdAt)).format("DD MMMM YYYY")} by{" "}
              {createdUser.name}
            </HeaderMeta>
          </div>
          <HeaderAction $hovered={hovered}>
            {/* <Button
              onClick={() =>
                openPopup({
                  reference,
                  contacts: [],
                  popup: ActivePopup.EMAIL,
                })
              }
              overrides={{
                BaseButton: {
                  style: {
                    color: '#00aeef',
                    backgroundColor: '#d2f7ff',
                    marginRight: '5px',
                    minWidth: 'auto',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    ':hover': {
                      color: '#00aeef',
                      backgroundColor: '#a6efff',
                    },
                  },
                },
              }}
            >
              <Icon icon='edit' />
            </Button> */}
            <Button
              isLoading={deleteMutationLoading}
              onClick={onDelete}
              overrides={{
                BaseButton: {
                  style: {
                    color: "#ff0000",
                    backgroundColor: "#f6d8d8",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    ":hover": {
                      color: "#ff0000",
                      backgroundColor: "#fbb9b9",
                    },
                    minWidth: "auto",
                  },
                },
              }}
            >
              <Icon icon="delete" />
            </Button>
          </HeaderAction>
        </Header>
        <Body>
          <div
            className={css({
              fontSize: "14px",
              marginBottom: "10px",
              wordBreak: "break-all",
              maxHeight: "60px",
              overflow: "hidden",
            })}
            dangerouslySetInnerHTML={{ __html: emailContent.body }}
          />
          {user?.id === createdUser?.id && (
            <Button
              onClick={() =>
                openPopup({
                  popup: ActivePopup.EMAIL_VIEW,
                  contacts: [],
                  reference: emailContent.thread_id,
                  title: emailContent.subject,
                })
              }
              size={SIZE.compact}
              overrides={{
                BaseButton: {
                  style: {
                    color: "#00aeef",
                    backgroundColor: "#d2f7ff",
                    minWidth: "auto",
                    ":hover": {
                      color: "#00aeef",
                      backgroundColor: "#a6efff",
                    },
                  },
                },
              }}
            >
              See full conversation
            </Button>
          )}
          <RadioGroup
            value={accessLevel}
            onChange={(e) => {}}
            align={ALIGN.horizontal}
            overrides={{
              RadioGroupRoot: {
                style: () => ({
                  justifyContent: "flex-end",
                  "@media screen and (max-width: 540px)": {
                    display: "block",
                  },
                }),
              },
              RadioMarkOuter: {
                style: ({ $checked }) => ({
                  backgroundColor: $checked ? "#00d202" : "#666666",
                }),
              },
            }}
          >
            <Radio value="private">Private</Radio>
            <Radio value="office">Office</Radio>
            <Radio value="company">Company</Radio>
          </RadioGroup>
        </Body>
      </Box>
    </Container>
  );
};

export default TimelineEmail;
