import React from "react";
import { useStyletron } from "styletron-react";
import moment from "moment";

import config from "config";

import { Priority, ReminderSource } from "../../../constants/reminder";

import { IReminder } from "../../../interfaces/reminder";

const Reminder: React.FC<IReminder> = ({
  name,
  source,
  dateTime,
  priority,
  campaign,
  invitedContacts,
  subtitle,
}) => {
  const [css] = useStyletron();

  const domainProtocol = config.DOMAIN_PROTOCOL;
  const domainSuffix = config.DOMAIN_SUFFIX;

  const onClicked = async () => {
    if (source === ReminderSource.MARKETING && campaign) {
      window.location.href = `${domainProtocol}marketing.${domainSuffix}/campaign/property/${campaign}/timeline`;
    } else if (invitedContacts.length > 0) {
      window.location.href = `${domainProtocol}contacts.${domainSuffix}/contacts/${invitedContacts[0]}`;
    }
  };

  const getPriority = () => {
    switch (priority) {
      case Priority.LOW:
        return <div style={{ color: "#008000" }}>Low Priority</div>;
      case Priority.MEDIUM:
        return <div style={{ color: "#ffa500" }}>Medium Priority</div>;
      case Priority.HIGH:
        return <div style={{ color: "#ff0000" }}>High Priority</div>;
    }
  };

  return (
    <div
      className={css({
        width: "300px",
        display: "flex",
        padding: "5px",
        margin: "0 -5px 5px",
        gap: "10px",
        alignItems: "flex-start",
        "@media (min-width: 769px)": {
          width: "350px",
        },
      })}
    >
      <img
        className={css({ maxWidth: "30px" })}
        src={
          source === ReminderSource.MARKETING
            ? require("assets/icons/app-icons/marketing.svg").default
            : require("assets/icons/app-icons/contacts.svg").default
        }
        alt="App Icon"
      />
      <div
        className={css({
          width: "calc(100% - 20px)",
          paddingRight: "5px",
          fontSize: "11px",
        })}
      >
        <button
          onClick={onClicked}
          className={css({
            fontSize: "13px",
            textAlign: "left",
            display: "block",
          })}
        >
          <strong>{name}</strong>
          <div>{subtitle}</div>
          <div>Due on {moment(dateTime).format("DD MMMM YYYY")}</div>
          {getPriority()}
        </button>
      </div>
    </div>
  );
};

export default Reminder;
