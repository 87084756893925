import React from "react";

import Toggle from "components/toggle";

import {
  Container,
  Inner,
  TextContainer,
  LayerPreview,
} from "../filter.styles";

type Props = {
  id: string;
  name: string;
  assignedColor: string;
  isSelected: boolean;
  onSelectChange: (id: string, isSelected: boolean) => void;
};

const FilterUserRow: React.FC<Props> = ({
  id,
  name,
  assignedColor,
  isSelected,
  onSelectChange,
}) => {
  return (
    <Container>
      <Inner>
        <LayerPreview background={assignedColor} />
        <TextContainer>
          <span>{name}</span>
        </TextContainer>
      </Inner>
      <Toggle
        checked={isSelected}
        onChange={(e: any) => onSelectChange(id, e.target.checked)}
      />
    </Container>
  );
};

export default FilterUserRow;
