import React, { useContext } from "react";
import { Icon } from "@virtualcapital/utogi-ui-library";
import { Link, useHistory } from "react-router-dom";
import { Pagination, SIZE } from "baseui/pagination";
import { useStyletron } from "baseui";
import { AuthContext } from "@virtualcapital/utogi-auth-library";

import SelectMap from "./SelectMap";
import { defaultButtonStyles } from "components/Button";
import { MapListItem } from "../../containers/maps/MapList";

import { useMap } from "context/map/use-map";
import { SelectedMap } from "context/map/map.types";

import { getMapStatus } from "helpers/map";
import { canEditOtherUsersCampaign } from "../../helpers/permission/common";
import { getStatusBackgroundColor } from "helpers/status";
import WithScrollbar from "helpers/WithScrollbar";

import { MapUserType } from "../../types/map";

type MapTableProps = {
  list: MapListItem[];
  pagination: any;
  isAllSelected: boolean;
  fetchMaps: Function;
  selectMap: Function;
  isMapSelected: Function;
  selectAll: Function;
};

const MapTable = ({ pagination, fetchMaps, list }: MapTableProps) => {
  const { setSelectedMaps, recentlySelectedMap } = useMap();
  const history = useHistory();
  const {
    authData: { user: currentUser },
  } = useContext(AuthContext);
  const [css] = useStyletron();

  const onMapSelected = (map: SelectedMap) => {
    setSelectedMaps({ selectedMaps: [map] });
    history.push("/maps/selected");
  };

  const hasEditPermission = (users: MapUserType[]) =>
    canEditOtherUsersCampaign() ||
    users.some((user) => user.id === currentUser?.id);

  return (
    <>
      <WithScrollbar noScrollX height="calc(100vh - 340px)">
        {list.length > 0 ? (
          <>
            <table className="maps-table">
              <thead>
                <tr>
                  <th />
                  <th>Map Name</th>
                  <th>Created By</th>
                  <th>Number of users</th>
                  <th>Status</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {list.map(({ id, name, createdBy, users, status }) => {
                  return (
                    <tr key={id}>
                      <td>
                        {id === recentlySelectedMap?.id && (
                          <SelectMap
                            id={id}
                            name={name}
                            onMapSelected={onMapSelected}
                            status={status}
                          >
                            <Icon
                              icon="satellite"
                              className={css({
                                fontSize: "24px",
                                color: "#ed8815",
                              })}
                            />
                          </SelectMap>
                        )}
                      </td>
                      <td>
                        <SelectMap
                          id={id}
                          name={name}
                          onMapSelected={onMapSelected}
                          status={status}
                        >
                          {name}
                        </SelectMap>
                      </td>
                      <td>
                        <SelectMap
                          id={id}
                          name={name}
                          onMapSelected={onMapSelected}
                          status={status}
                        >
                          {createdBy}
                        </SelectMap>
                      </td>
                      <td>
                        <SelectMap
                          id={id}
                          name={name}
                          onMapSelected={onMapSelected}
                          status={status}
                        >
                          {users?.length || 0}
                        </SelectMap>
                      </td>
                      <td>
                        <SelectMap
                          id={id}
                          name={name}
                          onMapSelected={onMapSelected}
                          status={status}
                        >
                          {getMapStatus(status)}
                        </SelectMap>
                      </td>
                      <td>
                        <div className="map-action">
                          {hasEditPermission(users) && (
                            <Link
                              to={`/maps/view/${id}`}
                              className="list-item-action"
                            >
                              <Icon icon="edit" />
                            </Link>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div
              className={css({
                "@media (min-width: 769px)": {
                  display: "none",
                },
              })}
            >
              {list.map(({ id, name, createdBy, users, status }) => (
                <div
                  className={css({
                    display: "flex",
                    borderBottom: ".2px solid #eaeaea",
                    marginBottom: "15px",
                    paddingBottom: "10px",
                    fontSize: "14px",
                  })}
                >
                  <div
                    className={css({
                      width: "28px",
                      marginRight: "15px",
                    })}
                  >
                    {id === recentlySelectedMap?.id && (
                      <SelectMap
                        id={id}
                        name={name}
                        onMapSelected={onMapSelected}
                        status={status}
                      >
                        <Icon
                          icon="satellite"
                          className={css({
                            fontSize: "24px",
                            color: "#ed8815",
                          })}
                        />
                      </SelectMap>
                    )}
                  </div>
                  <div className={css({ flex: 1 })}>
                    <SelectMap
                      id={id}
                      name={name}
                      onMapSelected={onMapSelected}
                      status={status}
                    >
                      <div
                        className={css({
                          fontWeight: 600,
                          fontSize: "16px",
                          marginBottom: "5px",
                        })}
                      >
                        {name}
                      </div>
                      <div className={css({ fontSize: "12px" })}>
                        {createdBy}
                      </div>
                      <div
                        className={css({
                          backgroundColor: getStatusBackgroundColor(status),
                          color: "#ffffff",
                          padding: "5px 15px",
                          display: "inline-block",
                          borderRadius: "5px",
                          fontSize: "12px",
                          margin: "5px 0 10px",
                        })}
                      >
                        {getMapStatus(status)}
                      </div>
                    </SelectMap>
                  </div>
                  {hasEditPermission(users) && (
                    <div>
                      <Link
                        to={`/maps/view/${id}`}
                        className={css({
                          ...defaultButtonStyles,
                          display: "inline-block",
                          color: "#00aeef",
                          backgroundColor: "#d2f7ff",
                          padding: "7px 11px",
                          ":hover": {
                            color: "#00aeef",
                            backgroundColor: "#a6efff",
                          },
                        })}
                      >
                        <Icon icon="edit" />
                      </Link>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        ) : (
          <>No results found</>
        )}
      </WithScrollbar>
      {list.length > 0 && (
        <div className={css({ display: "flex" })}>
          <Pagination
            size={SIZE.mini}
            onPageChange={({ nextPage: page }) =>
              fetchMaps({
                pagination: { page, perPage: pagination.perPage },
              })
            }
            currentPage={pagination.page}
            numPages={pagination.total}
            overrides={{ Root: { style: { margin: "15px auto" } } }}
          />
        </div>
      )}
    </>
  );
};

export default MapTable;
