import { useMutation } from "@apollo/react-hooks";
import { Icon } from "@virtualcapital/utogi-ui-library";
import { gql } from "apollo-boost";
import { toaster } from "baseui/toast";
import moment from "moment";
import React from "react";
import {
  Box,
  Container,
  Header,
  HeaderAction,
  HeaderMeta,
  TimelineLeft,
  TimelineLeftIcon,
  TimelineLeftLine,
} from "./styles";
import { Order } from "./TimelineSection";
import { useHover } from "../../../helpers/use-hover";
import { useAlert } from "../../../context/alert/use-alert";
import { ON_TIMELINE_EVENT_MODIFIED } from "../../../events/prospect";
import { event } from "../../../events";
import Button from "components/Button";
import { TimelineTagProps } from "./interface";
import { useStyletron } from "baseui";
import { TimelineFilterType } from "./contants";

type Props = TimelineTagProps & {
  order: Order;
  isLast: boolean;
};

const DELETE_PEOPLE_TAG = gql`
  mutation($reference: ID!) {
    contact {
      deleteTag(id: $reference) {
        success
      }
    }
  }
`;

const DELETE_PROPERTY_TAG = gql`
  mutation($reference: String!) {
    property {
      deleteTag(id: $reference) {
        id
        createdAt
      }
    }
  }
`;

const TimelineTag: React.FC<Props> = ({
  order,
  isLast,
  data,
  createdUser,
  createdAt,
  reference,
}) => {
  const [hoverRef, hovered] = useHover();
  const { openAlert } = useAlert();
  const [css] = useStyletron();

  const [deleteTag, { loading: deleteMutationLoading }] = useMutation(
    data?.timeline === TimelineFilterType.PEOPLE
      ? DELETE_PEOPLE_TAG
      : DELETE_PROPERTY_TAG,
    {
      errorPolicy: "all",
      onError: (error) => {
        if (error && error.message) {
          toaster.negative("Error occurred while deleting tag", {});
        }
      },
      onCompleted: (results) => {
        if (
          (data?.timeline === TimelineFilterType.PEOPLE &&
            results?.contact?.deleteTag?.success) ||
          (data?.timeline === TimelineFilterType.PROPERTY &&
            results?.property?.deleteTag?.id)
        ) {
          toaster.positive("Tag deleted successfully", {});
          event.dispatch(ON_TIMELINE_EVENT_MODIFIED);
        } else {
          toaster.negative("Error occurred while deleting tag", {});
        }
      },
    }
  );

  const onDelete = () => {
    openAlert({
      content: "Are you sure you want to delete this tag?",
      primaryButton: {
        title: "Delete",
        action: () => deleteTag({ variables: { reference } }),
      },
      secondaryButton: { title: "Cancel", action: () => {} },
    });
  };

  return (
    <Container>
      <TimelineLeft>
        <TimelineLeftIcon>
          <Icon icon="file" />
        </TimelineLeftIcon>
        {!(isLast && order === Order.PAST) && <TimelineLeftLine />}
      </TimelineLeft>
      <Box ref={hoverRef} $isLast={isLast}>
        <Header>
          <div
            className={css({
              "@media screen and (min-width: 540px)": { display: "flex" },
            })}
          >
            <span>
              #{data.name} {data?.isDeleted ? "deleted" : "added"}
            </span>
            <HeaderMeta>
              {data?.isDeleted ? "Deleted" : "Created"} on{" "}
              {moment(new Date(createdAt)).format("DD MMMM YYYY")} by{" "}
              {createdUser.name}
            </HeaderMeta>
          </div>
          <HeaderAction $hovered={hovered}>
            {!(data?.isDeleted || data?.wasDeleted) && (
              <Button
                onClick={onDelete}
                isLoading={deleteMutationLoading}
                overrides={{
                  BaseButton: {
                    style: {
                      color: "#ff0000",
                      backgroundColor: "#f6d8d8",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      ":hover": {
                        color: "#ff0000",
                        backgroundColor: "#fbb9b9",
                      },
                      minWidth: "auto",
                    },
                  },
                }}
              >
                <Icon icon="delete" />
              </Button>
            )}
          </HeaderAction>
        </Header>
      </Box>
    </Container>
  );
};

export default TimelineTag;
