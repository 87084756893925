import React from "react";
import { useQuery, gql } from "@apollo/client";

import LayerEditor from "./LayerEditor";
import { Loader } from "@virtualcapital/utogi-ui-library";

type Props = {
  onSave: Function;
  layer: any;
  onDelete?: Function;
  canDelete?: boolean;
};

const LayerEditorContainer = (props: Props) => {
  const GET_LAYER_TYPES = gql`
    query {
      layerTypes {
        list {
          title
          id
          color
        }
      }
    }
  `;

  const { data, loading } = useQuery(GET_LAYER_TYPES);

  if (!data || loading) {
    return <Loader isLoading={true} />;
  }

  const {
    layerTypes: { list },
  } = data;

  return <LayerEditor layerTypes={list} {...props} />;
};

export default LayerEditorContainer;
