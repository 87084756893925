export const PROSPECT_VIEW_MODE = {
  PROSPECT: "prospect",
  USER: "user",
  REMINDER: "reminder",
};

export enum TimelineEventType {
  LAYER = "layer",
  TAG = "tag",
  NOTE = "note",
  EMAIL = "email",
  APPOINTMENT = "appointment",
  REMINDER = "reminder",
  BUYER = "buyer",
}

export enum SearchType {
  PROPERTY = "property",
  PEOPLE = "people",
}

export enum InMapView {
  POINTER = "pointer",
  LAYERS = "layers",
  TAGS = "tags",
}

export enum PropertyPopupType {
  PROPERTY_DETAILS = "propertyDetails",
  PROPERTY_POPUP = "propertyPopup",
}
