import React from "react";
import { useQuery, gql } from "@apollo/client";

import AddCampaignUsers from "./AddCampaignUsers";
import Loader from "components/loader";
import { MapUserType } from "../../../types/map";
import { MapRolePermissions } from "../../../types/permissions";

export type Props = {
  selectedUsers: MapUserType[] | null;
  onSave: (user: any) => void;
  onClickCancel: () => void;
  roles: any[];
  isRolesLoading: boolean;
  permissions?: MapRolePermissions;
};

const AddCampaignUsersContainer = (props: Props) => {
  const GET_USERS = gql`
    query {
      user(status: ACTIVATED) {
        name
        id
        image
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_USERS);

  if (loading || error) {
    return <Loader />;
  }
  const { user: list } = data;

  return list !== null ? (
    <AddCampaignUsers list={list} {...props} />
  ) : (
    <Loader />
  );
};

export default AddCampaignUsersContainer;
