import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useStyletron } from "baseui";
import { Button, KIND } from "baseui/button";
import { Search } from "baseui/icon";
import { Input } from "baseui/input";
import { StatefulMenu } from "baseui/menu";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { ICampaign } from "interfaces/campaign";
import { useMap } from "context/map/use-map";
import { toaster } from "baseui/toast";
import { MapStatus } from "constants/campaign";

const GET_AUTOCOMPLETE_CONTACTS = gql`
  query($keyword: String!) {
    map {
      campaign {
        list(
          filter: { keyword: $keyword }
          pagination: { page: 1, perPage: 5 }
        ) {
          campaigns {
            id
            name
            status
          }
        }
      }
    }
  }
`;

type Props = {
  setKeyword: (keyword: string) => void;
};

const CampaignTemplateSearch: React.FC<Props> = ({ setKeyword }) => {
  const history = useHistory();
  const [css] = useStyletron();
  const wrapperRef = useRef<any>(null);
  const { setSelectedMaps } = useMap();

  const [keyword, setValue] = useState("");
  const [suggestions, setSuggestions] = useState<null | ICampaign[]>(null);

  const [callAutocompleteQuery, { loading }] = useLazyQuery(
    GET_AUTOCOMPLETE_CONTACTS,
    {
      onCompleted: (data) => {
        if (keyword && keyword.length > 2) {
          setSuggestions(
            data?.map?.campaign?.list?.campaigns.map(
              ({ id, name: label, status }: ICampaign) => ({
                id,
                label,
                status,
              })
            )
          );
        }
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    }
  );

  const onKeydown = (e: any) => {
    if (e.key === "Enter") {
      setKeyword(keyword);
      setSuggestions(null);
    }
  };

  const onSearchClicked = (e: any) => {
    setKeyword(keyword);
    setSuggestions(null);
  };

  useEffect(() => {
    setSuggestions(null);
    if (keyword && keyword.length > 2) {
      callAutocompleteQuery({ variables: { keyword } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setSuggestions(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div
      className={css({
        position: "relative",
        flex: 1,
        "@media screen and (min-width: 769px)": {
          maxWidth: "550px",
        },
        "@media screen and (max-width: 768px)": {
          marginBottom: "10px",
        },
      })}
      ref={wrapperRef}
    >
      <Input
        value={keyword}
        onChange={(e: any) => setValue(e.target.value)}
        placeholder="Search"
        clearOnEscape
        onKeyDown={onKeydown}
        endEnhancer={() => (
          <Button
            kind={KIND.tertiary}
            isLoading={loading}
            onClick={onSearchClicked}
          >
            <Search />
          </Button>
        )}
        overrides={{
          Root: {
            style: { paddingRight: 0 },
          },
          EndEnhancer: {
            style: { paddingRight: 0, paddingLeft: 0 },
          },
        }}
      />
      {suggestions && (
        <StatefulMenu
          items={suggestions}
          onItemSelect={({ item: { id, label: name, status } }) => {
            if (status === MapStatus.DRAFT) {
              toaster.negative(
                "This map is in draft mode. Please edit the Maps and activate to open in Maps.",
                {}
              );
            } else {
              setSelectedMaps({ selectedMaps: [{ id, name }] });
              history.push("/maps/selected");
            }
          }}
          overrides={{
            List: {
              style: {
                paddingTop: "5px",
                paddingBottom: "5px",
                position: "absolute",
                zIndex: 1000,
                top: "44px",
                width: "100%",
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default CampaignTemplateSearch;
