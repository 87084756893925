import React, { FC } from "react";
import { Select, SelectProps } from "baseui/select";
import { mergeDeep } from "helpers/object";

const SelectWithStyling: FC<SelectProps> = ({ overrides = {}, ...props }) => {
  return (
    <Select
      {...props}
      overrides={mergeDeep(overrides, {
        Root: {
          style: {
            outline: `none`,
            boxShadow: "0 3px 6px #00000016",
          },
        },
        ControlContainer: {
          style: {
            borderBottomWidth: 0,
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            backgroundColor: "#fff",
          },
        },
      })}
    />
  );
};

export default SelectWithStyling;
