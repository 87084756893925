import { useQuery } from "@apollo/react-hooks";
import { Icon } from "@virtualcapital/utogi-ui-library";
import { gql } from "apollo-boost";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { INotification } from "interfaces/notifications";
import React, { useEffect, useState } from "react";
import { useAnimateKeyframes } from "react-simple-animate";
import { useStyletron } from "styletron-react";
import { event } from "../../../events";
import { ON_NEW_NOTIFICATION_RECEIVED } from "../../../events/notifications";
import NotificationList from "./NotificationList";

const GET_NOTIFICATIONS = gql`
  fragment fields on Notification {
    id
    read
    type
    title
    module
    inactive
    reference
    description
  }
  query getNotifications($onlyUnread: Boolean) {
    notification {
      list(onlyUnread: $onlyUnread) {
        today {
          ...fields
        }
        older {
          ...fields
        }
      }
    }
  }
`;

const Notifications = () => {
  const [onlyUnread, toggleOnlyUnread] = useState(false);
  const [hasUnread, toggleHasUnread] = useState(false);
  const [css] = useStyletron();
  const { play, style } = useAnimateKeyframes({
    duration: 5,
    keyframes: [
      "transform: rotate(0)",
      "transform: rotate(30deg)",
      "transform: rotate(-28deg)",
      "transform: rotate(34deg)",
      "transform: rotate(-32deg)",
      "transform: rotate(30deg)",
      "transform: rotate(-28deg)",
      "transform: rotate(26deg)",
      "transform: rotate(-24deg)",
      "transform: rotate(22deg)",
      "transform: rotate(-20deg)",
      "transform: rotate(18deg)",
      "transform: rotate(-16deg)",
      "transform: rotate(14deg)",
      "transform: rotate(-12deg)",
      "transform: rotate(10deg)",
      "transform: rotate(-8deg)",
      "transform: rotate(6deg)",
      "transform: rotate(-4deg)",
      "transform: rotate(2deg)",
      "transform: rotate(-1deg)",
      "transform: rotate(1deg)",
      "transform: rotate(0)",
      "transform: rotate(0)",
    ],
  });

  const { loading, data, refetch } = useQuery(GET_NOTIFICATIONS, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    toggleHasUnread(
      !!data?.notification?.list.today?.find(
        ({ read }: INotification) => !read
      ) ||
        !!data?.notification?.list.older?.find(
          ({ read }: INotification) => !read
        )
    );
  }, [data]);

  useEffect(() => {
    const onNewNotificationReceived = () => {
      play(true);
      refetch();
      toggleHasUnread(true);
    };

    event.subscribe(ON_NEW_NOTIFICATION_RECEIVED, onNewNotificationReceived);

    return () =>
      event.unsubscribe(
        ON_NEW_NOTIFICATION_RECEIVED,
        onNewNotificationReceived
      );
  });

  const onOnlyUnreadChanged = (value: boolean) => {
    refetch({ onlyUnread: value });
    toggleOnlyUnread(value);
  };

  return (
    <div className={css({ marginRight: "15px" })}>
      <StatefulPopover
        content={
          <NotificationList
            loading={loading || !data?.notification?.list}
            list={data?.notification?.list}
            refetch={refetch}
            onlyUnread={onlyUnread}
            toggleOnlyUnread={onOnlyUnreadChanged}
            onOpen={() => toggleHasUnread(false)}
          />
        }
        placement={PLACEMENT.bottom}
        overrides={{
          Body: {
            style: {
              maxWidth: "100%",
              width: "480px",
              maxHeight: "calc(100vh - 100px)",
            },
          },
        }}
      >
        <button
          className={css({
            display: "flex",
            alignItems: "center",
            outline: "none",
            border: "none",
            cursor: "pointer",
            color: "#6B778C",
            padding: "6px 10px",
            backgroundColor: "#fff",
            borderRadius: "50%",
            position: "relative",
            ":hover": {
              color: "#0196ce",
              backgroundColor: "#e6f5fd",
            },
          })}
        >
          <div style={style}>
            <Icon icon="bell" />
          </div>
          {hasUnread && (
            <div
              className={css({
                width: "7px",
                height: "7px",
                backgroundColor: "#FF3E3E",
                borderRadius: "50%",
                position: "absolute",
                top: "7px",
                right: "7px",
              })}
            />
          )}
        </button>
      </StatefulPopover>
    </div>
  );
};

export default Notifications;
