import React from "react";
import { Icon } from "@virtualcapital/utogi-ui-library";

import CampaignLayerListItem from "../campaignLayerListItem";
import WithScrollbar from "helpers/WithScrollbar";

import { ILayer } from "interfaces/campaign";

import "./campaignLayerList.scss";

type Props = {
  layers: ILayer[];
  deleteLayer: Function;
  createNew: any;
  editLayer: Function;
  disableEdit?: boolean;
  canEdit: boolean;
};

const campaignLayerList = ({
  layers,
  deleteLayer,
  createNew,
  editLayer,
  canEdit,
  disableEdit = false,
}: Props) => {
  if (!layers) {
    return null;
  }
  return (
    <WithScrollbar noScrollX height="calc(100vh - 250px)">
      <div className="campaign-layer-list">
        {canEdit && !disableEdit && (
          <button
            onClick={createNew}
            className="box create-button campaign-layer-list-create"
          >
            <Icon icon="plus" />
            Create New Activity/Layer
          </button>
        )}

        {layers.map((layer: ILayer, key) => (
          <CampaignLayerListItem
            row={key}
            key={key}
            {...layer}
            onDelete={() => deleteLayer(key)}
            editLayer={editLayer}
            canEdit={canEdit}
          />
        ))}
      </div>
    </WithScrollbar>
  );
};

export default campaignLayerList;
