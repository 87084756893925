import React from "react";

import AddLayerListItem from "../addLayerListItem";

import "./addLayerList.scss";

const addLayerList = ({ layers, toggleLayerSelect, selectedLayers }: any) => {
  return (
    <div className="add-layer-list">
      {layers.map((layer: any, key: number) => (
        <AddLayerListItem
          key={key}
          {...layer}
          toggleLayerSelect={toggleLayerSelect}
          isSelected={
            selectedLayers.filter(({ id }: any) => id === layer.id).length > 0
          }
        />
      ))}
    </div>
  );
};

export default addLayerList;
