import React, { useEffect, useRef, useState } from "react";
import { IconButton } from "@virtualcapital/utogi-ui-library";
import { useLazyQuery, gql } from "@apollo/client";

import Select from "components/SingleSelect";

import { SearchType } from "constants/prospect";

import { useMap } from "context/map/use-map";

import { ICoordinates } from "interfaces/property";

import "./PropertyAndPeopleSearch.scss";

const PropertyAndPeopleSearch = () => {
  const GET_SEARCH_RESULTS = gql`
    query($searchKey: String, $type: String) {
      prospect {
        prospectSearch(searchKey: $searchKey, type: $type) {
          id
          fullAddress
          fullName
          coordinates {
            lat
            lng
          }
        }
      }
    }
  `;

  const [type, setType] = useState(SearchType.PROPERTY);
  const [keyword, setKeyword] = useState("");
  const [suggestions, setSuggestions] = useState<null | []>(null);
  const keywordRef = useRef<HTMLInputElement>(null);
  const { setPointerOnMap, setContactCoordinates } = useMap();

  const [getSuggestions, { loading, data }] = useLazyQuery(GET_SEARCH_RESULTS, {
    fetchPolicy: "no-cache",
  });

  const onSearchChange = (searchKey: string) => {
    setKeyword(searchKey);
    if (searchKey.length > 3) {
      getSuggestions({
        variables: {
          type,
          searchKey,
        },
      });
    } else {
      setSuggestions(null);
    }
  };

  const onSuggestionClicked = (
    label: string,
    coordinates: Array<ICoordinates>
  ) => {
    setKeyword(label);
    setSuggestions(null);
    if (type === SearchType.PROPERTY) {
      setPointerOnMap({
        pointerOnMap: {
          type,
          coordinates: coordinates[0],
        },
      });
      setContactCoordinates([]);
      return;
    }
    setContactCoordinates(coordinates);
  };

  const onTypeChange = (type: SearchType) => {
    setType(type);
    setKeyword("");
    keywordRef.current && keywordRef.current.focus();
  };

  useEffect(() => {
    if (data?.prospect?.prospectSearch) {
      const {
        prospect: { prospectSearch },
      } = data;
      setSuggestions(
        prospectSearch.map(
          ({ id, fullName, fullAddress, coordinates }: any) => ({
            id,
            fullName,
            fullAddress,
            coordinates,
          })
        )
      );
    }
  }, [data]);

  return (
    <div className="property-and-people-search">
      <Select
        options={[
          { id: SearchType.PROPERTY, label: "Property" },
          { id: SearchType.PEOPLE, label: "People" },
        ]}
        value={type}
        onChange={onTypeChange}
        width="110px"
        overrides={{
          ValueContainer: {
            style: { paddingTop: "5px", paddingBottom: "5px" },
          },
        }}
      />
      <div className="property-and-people-search-input">
        <input
          ref={keywordRef}
          placeholder="Search"
          value={keyword}
          onChange={({ target: { value } }) => onSearchChange(value)}
        />
        {keyword.length > 0 && (
          <IconButton icon="close" onClick={() => onSearchChange("")} />
        )}
      </div>
      {(loading || suggestions) && (
        <>
          <div className="search-results-list">
            {loading ? (
              <div className="search-results-list-item">Loading...</div>
            ) : (
              suggestions !== null &&
              (suggestions.length === 0 ? (
                <div className="search-results-list-item">No Results</div>
              ) : (
                suggestions.map(
                  ({ id, fullAddress, fullName, coordinates }: any) => (
                    <button
                      className="search-results-list-item"
                      key={id}
                      onClick={() =>
                        onSuggestionClicked(
                          type === SearchType.PEOPLE ? fullName : fullAddress,
                          coordinates
                        )
                      }
                    >
                      {type === SearchType.PEOPLE ? fullName : fullAddress}
                    </button>
                  )
                )
              ))
            )}
          </div>
          <div
            className="action-background"
            onClick={() => setSuggestions(null)}
          />
        </>
      )}
    </div>
  );
};

export default PropertyAndPeopleSearch;
