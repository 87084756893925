import { styled } from "baseui";
import { Skeleton } from "baseui/skeleton";
import React from "react";

const Container = styled("div", () => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "space-between",
}));

const NoteLoader = () => {
  return (
    <Container>
      <div>
        <Skeleton
          animation
          width="100%"
          height="220px"
          overrides={{
            Root: { style: { marginBottom: "16px" } },
          }}
        />
        <Skeleton
          animation
          width="100%"
          height="72px"
          overrides={{
            Root: { style: { marginBottom: "16px" } },
          }}
        />
        <Skeleton
          animation
          width="100%"
          height="72px"
          overrides={{
            Root: { style: { marginBottom: "16px" } },
          }}
        />
      </div>
      <Skeleton animation width="100%" height="40px" />
    </Container>
  );
};

export default NoteLoader;
