import { useStyletron } from "baseui";
import { Skeleton } from "baseui/skeleton";
import React from "react";

const TimelineLoader = () => {
  const [css] = useStyletron();

  return (
    <div>
      <Skeleton
        height="48px"
        width="160px"
        animation
        overrides={{
          Root: { style: { marginLeft: "50px", marginBottom: "20px" } },
        }}
      />
      <div className={css({ display: "flex", marginBottom: "20px" })}>
        <Skeleton
          height="29px"
          width="29px"
          animation
          overrides={{ Root: { style: { marginRight: "20px" } } }}
        />
        <Skeleton height="170px" width="100%" animation />
      </div>
      <div className={css({ display: "flex", marginBottom: "20px" })}>
        <Skeleton
          height="29px"
          width="29px"
          animation
          overrides={{ Root: { style: { marginRight: "20px" } } }}
        />
        <Skeleton height="170px" width="100%" animation />
      </div>
    </div>
  );
};

export default TimelineLoader;
