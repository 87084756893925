import React from "react";
import { StatefulTooltip } from "baseui/tooltip";
import { useStyletron } from "baseui";

import { MapStatus } from "../../constants/campaign";

type Props = {
  id: string;
  name: string;
  onMapSelected: Function;
  status: MapStatus;
};

const SelectMap: React.FC<Props> = ({
  children,
  onMapSelected,
  id,
  name,
  status,
}) => {
  const [css] = useStyletron();
  if (status === MapStatus.DRAFT || status === MapStatus.ARCHIVE) {
    return (
      <StatefulTooltip
        popoverMargin={8}
        content={() => (
          <div>
            {status === MapStatus.DRAFT
              ? "This map is in draft mode. Please edit the Maps and activate to open in Maps."
              : "This map is in archive mode. Archive Maps cannot be opened."}
          </div>
        )}
        showArrow
      >
        <button
          className={css({
            cursor: "not-allowed",
            background: "none",
            border: "none",
            textAlign: "left",
            padding: 0,
            color: "#707070",
          })}
        >
          {children}
        </button>
      </StatefulTooltip>
    );
  }
  return (
    <button
      className={css({
        padding: 0,
        background: "none",
        border: "none",
        textAlign: "left",
        color: "#707070",
      })}
      onClick={() => onMapSelected({ id, name })}
    >
      {children}
    </button>
  );
};

export default SelectMap;
