import { Button as BaseButton, ButtonProps } from "baseui/button";
import React from "react";
import { mergeDeep } from "helpers/object";

export const defaultButtonStyles = {
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  borderBottomLeftRadius: "5px",
  borderBottomRightRadius: "5px",
};

const Button: React.FC<ButtonProps> = ({ overrides, ...props }) => {
  return (
    <BaseButton
      {...props}
      overrides={mergeDeep(
        {
          BaseButton: {
            style: {
              ...defaultButtonStyles,
            },
          },
        },
        overrides
      )}
    />
  );
};

export default Button;
