import { Skeleton } from "baseui/skeleton";
import React from "react";
import { useStyletron } from "styletron-react";
import { Select, Value } from "baseui/select";
import { FormControl } from "baseui/form-control";

import Reminder from "./Reminder";

import { IReminderList } from "../../../interfaces/reminder";
import { Priority } from "../../../constants/reminder";

type ReminderListProps = {
  loading: boolean;
  list: IReminderList;
  priority: Value;
  setPriority: (value: Value) => void;
};

const ReminderList: React.FC<ReminderListProps> = ({
  loading,
  list,
  priority,
  setPriority,
}) => {
  const [css] = useStyletron();

  const getContent = () => {
    if (loading) {
      return (
        <div>
          <Skeleton
            height="20px"
            width="100%"
            animation
            overrides={{
              Root: {
                style: { marginBottom: "10px" },
              },
            }}
          />
        </div>
      );
    }
    return (
      <div>
        <div
          className={css({
            maxHeight: "calc(100vh - 110px)",
            overflowY: "auto",
            overflowX: "hidden",
          })}
        >
          <FormControl label="Priority">
            <Select
              placeholder="Filter by priority"
              options={[
                { id: Priority.LOW, label: "Low Priority" },
                { id: Priority.MEDIUM, label: "Medium Priority" },
                { id: Priority.HIGH, label: "High Priority" },
              ]}
              value={priority}
              onChange={({ value }) => setPriority(value)}
            />
          </FormControl>
          {list.overdue.length === 0 &&
          list.today.length === 0 &&
          list.upComing.length === 0 ? (
            <div>You've completed all your reminder</div>
          ) : null}
          {list.overdue.length > 0 && (
            <div>
              <div
                className={css({
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "10px",
                })}
              >
                <div
                  className={css({
                    fontWeight: 600,
                    fontSize: "15px",
                  })}
                >
                  Overdue
                </div>
              </div>
              {list.overdue.map((notification) => (
                <Reminder {...notification} key={notification.id} />
              ))}
            </div>
          )}
          {list.today.length > 0 && (
            <div>
              <div
                className={css({
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "10px",
                })}
              >
                <div
                  className={css({
                    fontWeight: 600,
                    fontSize: "15px",
                  })}
                >
                  Today
                </div>
              </div>
              {list.today.map((notification) => (
                <Reminder {...notification} key={notification.id} />
              ))}
            </div>
          )}
          {list.upComing.length > 0 && (
            <div>
              <div
                className={css({
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "10px",
                })}
              >
                <div
                  className={css({
                    fontWeight: 600,
                    fontSize: "15px",
                  })}
                >
                  Up & Coming (7 Days)
                </div>
              </div>
              {list.upComing.map((notification) => (
                <Reminder {...notification} key={notification.id} />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={css({
        backgroundColor: "#ffffff",
        padding: "15px",
        fontSize: "13px",
      })}
    >
      <div>{getContent()}</div>
    </div>
  );
};

export default ReminderList;
