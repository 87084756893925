import React from "react";

import Toggle from "components/toggle";

import {
  LayerPreview,
  Container,
  Inner,
  TextContainer,
} from "containers/map/filter.styles";

import { useMap } from "context/map/use-map";

type Props = {
  id: string;
  title: string;
  color: string;
  isSelected: boolean;
  onSelectChange: (id: string, isSelected: boolean) => void;
};

const FilterLayerType: React.FC<Props> = ({
  id,
  title,
  color,
  isSelected,
  onSelectChange,
}) => {
  const { isManagerView } = useMap();
  return (
    <Container>
      <Inner>
        {!isManagerView && <LayerPreview background={color} />}
        <TextContainer>
          <span>{title}</span>
        </TextContainer>
      </Inner>
      <Toggle
        checked={isSelected}
        onChange={(e: any) => onSelectChange(id, e.target.checked)}
      />
    </Container>
  );
};

export default FilterLayerType;
