import { AuthContext } from "@virtualcapital/utogi-auth-library";
import { styled } from "baseui";
import queryString from "query-string";
import React, { useContext } from "react";
import config from "config";
import { useHover } from "helpers/use-hover";

export enum Modules {
  AUTH = "auth",
  MAPS = "maps",
  EMAIL = "email",
  CONTACTS = "contacts",
  MARKETING = "marketing",
  TARGET_AUDIENCE = "target-audience",
  OPEN_HOMES = "open-homes",
  DASHBOARD = "reporting",
}

type Props = {
  title: string;
  icon: string;
  link: Modules | string;
  isFullLink?: boolean;
};

export const Li = styled("a", ({ $hovered }: any) => ({
  display: "flex",
  alignItems: "center",
  color: "#afafaf",
  boxShadow: $hovered ? "0 0 5px #00000016" : "",
  padding: "10px",
  margin: "5px",
}));

export const Img = styled("img", ({ $hovered }: any) => ({
  opacity: $hovered ? 1 : "0.7",
  maxWidth: "50px",
  marginRight: "20px",
}));

const AppLink: React.FC<Props> = ({ title, icon, link, isFullLink }) => {
  const [hoverRef, hovered] = useHover();

  const getModule = (link: Modules | string) => {
    switch (link) {
      case Modules.TARGET_AUDIENCE:
        return "TARGET_AUDIENCE";
      case Modules.OPEN_HOMES:
        return "OPEN_HOMES";
      case Modules.DASHBOARD:
        return "DASHBOARD";
      case Modules.AUTH:
        return "DASHBOARD";
      default:
        return link.toUpperCase();
    }
  };
  const {
    authData: { currentRole: role, token },
  }: any = useContext(AuthContext);

  const domainProtocol = config.DOMAIN_PROTOCOL;
  const domainSuffix = config.DOMAIN_SUFFIX;
  return (
    <Li
      ref={hoverRef}
      $hovered={hovered}
      href={
        isFullLink
          ? link
          : `${domainProtocol}auth.${domainSuffix}/auth?${queryString.stringify(
              {
                token,
                redirectTo: `${domainProtocol}${link}.${domainSuffix}/authenticate`,
                module: getModule(link).toUpperCase(),
                role: role.id,
              }
            )}`
      }
    >
      <Img
        alt={title}
        $hovered={hovered}
        src={require(`assets/icons/app-icons/${icon}.svg`).default}
      />
      {title}
    </Li>
  );
};

export default AppLink;
