import { useMutation, useQuery } from "@apollo/react-hooks";
import { toaster } from "baseui/toast";
import React, { useEffect, useState } from "react";
import { DELETE_DRAFT, GET_MODULE_SETTINGS, SEND_EMAIL } from "../../query";
import EmailEditor from "../emailEditor";
import { useAutoSave } from "./useAutoSave";
import { transformRecipientValues, validateEmail } from "../../../helpers";
import { AccessLevels, ComposerMode } from "../../../contants";

type Props = {
  composeMode?: string;
  onSent?: Function;
  onDelete?: Function;
  replyTo?: any;
  replyToMessageId?: any;
  draft?: any;
  closeModal?: Function;
  body?: any;
  onFocus?: Function;
  onBlur?: Function;
  contacts?: any;
  properties?: any;
  campaign?: any;
};

const ComposeEmail = ({
  composeMode = ComposerMode.NEW_EMAIL,
  onSent,
  onDelete,
  replyTo,
  replyToMessageId,
  draft: composeDraft,
  closeModal,
  body: composeBody,
  onFocus,
  onBlur,
  contacts,
  properties,
}: Props) => {
  const [draft, setDraft] = useState<any>(null);
  const [draftState, setDraftState] = useState<any>(null);
  const [accessLevel, setAccessLevel] = useState<any>(AccessLevels.PRIVATE);

  const handleError = (event: any) => {
    if (
      event.message ===
      "GraphQL error: Could not authenticate with the Exchange server"
    ) {
      toaster.negative(
        "Authentication error - your password may have changed. You will need to reconnect your account",
        {}
      );
    } else {
      toaster.negative(event.message, {});
    }
  };

  const handleEmailSent = () => {
    toaster.positive("Email Sent", {});

    onSent && onSent();
  };

  const handleOnDraftDeleteCompleted = (event: any) => {
    toaster.positive("Draft deleted", {});
    onDelete && onDelete(event);
  };

  const [callSendMutation, { loading: sendingDraft }] = useMutation(
    SEND_EMAIL,
    {
      onCompleted: handleEmailSent,
      onError: handleError,
    }
  );

  const [callDeleteDraftMutation, { loading: deletingDraft }] = useMutation(
    DELETE_DRAFT,
    {
      onCompleted: handleOnDraftDeleteCompleted,
      onError: handleError,
    }
  );

  useQuery(GET_MODULE_SETTINGS, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      if (data?.contact?.getContactModuleSettings) {
        const {
          contact: { getContactModuleSettings },
        } = data;
        setAccessLevel(getEmailPrivacyLevel(getContactModuleSettings));
      }
    },
  });

  useEffect(() => {
    if (replyTo)
      setDraftState({
        ...draftState,
        replyTo,
        to: replyTo,
      });
    if (replyToMessageId)
      setDraftState({
        ...draftState,
        replyToMessageId,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replyTo, replyToMessageId]);

  useEffect(() => {
    if (draft) return;

    setDraft(composeDraft);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [composeDraft]);

  const { onSaveDraft, draftData, updatedDraftData, saving } = useAutoSave({
    draftState,
    draftMeta: draft,
    handleError,
  });

  useEffect(() => {
    if (draftData?.email?.createDraft) {
      setDraft(draftData.email.createDraft);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftData]);

  useEffect(() => {
    if (updatedDraftData?.email?.updateDraft) {
      setDraft(updatedDraftData.email.updateDraft);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedDraftData]);

  const handleOnChange = (change: any) => {
    setDraftState({ ...draftState, ...change });
  };

  const handleOnSend = () => {
    try {
      if (composeMode === ComposerMode.NEW_EMAIL) validateEmail(draftState);
      const { to, cc, bcc, body, subject, files } = draftState;

      const variables: any = { to, cc, bcc, body, subject, files };

      if (draft) {
        const { id } = draft;
        variables.draft = id;
      }

      let property = null,
        unit = null;
      if (properties && properties.length > 0) {
        property = properties[0].property;
        unit = properties[0].unit;
      }

      callSendMutation({
        variables: {
          ...variables,
          trackingPayload: {
            module: "MAPS",
            accessLevel,
            ...(property && { property }),
            ...(unit && { unit }),
          },
        },
      });
      closeModal && closeModal();
    } catch (e) {
      toaster.negative("Error", {});
    }
  };

  const getEmailPrivacyLevel = (settings: any) => {
    if (!settings.IS_EMAIL_TIMELINE_RECORD_SHARED) return AccessLevels.PRIVATE;

    if (settings.IS_EMAIL_TIMELINE_RECORD_SHARED_WITH_COMPANY)
      return AccessLevels.COMPANY;

    if (settings.IS_EMAIL_TIMELINE_RECORD_SHARED_WITH_OFFICE)
      return AccessLevels.OFFICE;
  };

  const commonProps = {
    to: [],
    cc: [],
    bcc: [],
    subject: "",
    body: composeBody,
  };

  const { to, cc, bcc, subject, body, files } = composeDraft || {};

  const editorProps = composeDraft
    ? {
        ...commonProps,
        to: to && transformRecipientValues(to),
        cc: cc && transformRecipientValues(cc),
        bcc: bcc && transformRecipientValues(bcc),
        subject,
        body,
        files,
      }
    : contacts && contacts.length > 0
    ? {
        ...commonProps,
        to: transformRecipientValues(contacts),
      }
    : commonProps;

  const handleOnFocus = (event: any) => {
    onFocus && onFocus(event);
  };

  const handleOnDelete = () => {
    const { id, version }: { id: string; version: string } = draft || {};

    if (id && !(version === null || version === undefined)) {
      callDeleteDraftMutation({
        variables: {
          ids: [id],
        },
      });
      return;
    }

    onDelete && onDelete();
  };

  return (
    <EmailEditor
      {...editorProps}
      composeMode={composeMode}
      hasDraft={draft}
      onSaveDraft={onSaveDraft}
      onChange={handleOnChange}
      sendingDraft={sendingDraft}
      draftSaving={saving}
      onSend={handleOnSend}
      onFocus={handleOnFocus}
      onBlur={(e: any) => onBlur && onBlur(e)}
      onDelete={handleOnDelete}
      isReply={replyToMessageId ? true : false}
      deletingDraft={deletingDraft}
    />
  );
};

export default ComposeEmail;
