import { InMapView, SearchType } from "constants/prospect";
import { ProspectViewMode } from "../../constants/map";
import { ILayerFilter, ILayerTypeFilter } from "../../interfaces/campaign";
import { Value } from "baseui/select";
import { IUserFilter } from "../../interfaces/user";

export enum MapActions {
  SET_SELECTED_MAPS = "setSelectedMaps",
  SET_MAP_CENTER = "setMapCenter",
  SET_SELECTED_AREA = "setSelectedArea",
  SET_POINTER_ON_MAP = "setPointerOnMap",
  TOGGLE_SHOW_EDGE_PROPERTIES = "toggleShowEdgeProperties",
  SET_MAP_FILTERS = "setMapFilters",
  SET_VIEW_MODE = "setViewMode",
  SET_USER = "setUser",
  RECENTLY_SELECTED_MAP = "recentlySelectedMap",
  SET_MANAGER_VIEW = "setManagerView",
  RESET_MAP_FILTERS = "resetMapFilters",
  SET_CONTACT_COORDINATES = "setContactCoordinates",
  RESET_SELECTED_MAP = "resetSelectedMap",
  SET_BATCH_ID = "setBatchId",
}

export type Coordinate = { lat: number; lng: number };

export type PointerOnMap = { coordinates: Coordinate; type: SearchType };

export type SelectedMap = { name: string; id: string };

export type MapFilters = {
  layers: null | ILayerFilter[];
  selectedUsers: null | Value;
  selectedTags: Value;
  isByRecentOrder: boolean;
  dateRange: Date[];
  colorFilter: Value;
  fadeOption: Value;
  layerTypes: null | ILayerTypeFilter[];
  viewMode: ProspectViewMode;
};

export type MapViewMode = {
  isSatelliteView: boolean;
  isPeopleView: boolean;
  isTagsView: boolean;
  isLayersView: boolean;
};

export type MapState = {
  batchId: null | string;
  selectedMaps: SelectedMap[];
  center: null | Coordinate;
  selectedArea: null | Coordinate[];
  showEdgeProperties: boolean;
  pointerOnMap: null | PointerOnMap;
  inMapView: InMapView;
  filters: MapFilters;
  viewMode: MapViewMode;
  users: null | IUserFilter[];
  isManagerView: boolean;
  recentlySelectedMap: null | SelectedMap;
  contactCoordinates: [] | Array<Coordinate>;
};

export type SetSelectedMapsPayload = { selectedMaps: SelectedMap[] };
export type SetMapCenterPayload = { center: Coordinate };
export type SetSelectedAreaPayload = { selectedArea: Coordinate[] | null };
export type SetPointerOnMapPayload = { pointerOnMap: PointerOnMap };
export type ToggleShowEdgePropertiesPayload = { showEdgeProperties: boolean };
export type SetManagerView = { isManagerView: boolean };
export type SetMapFiltersPayload = {
  layers?: ILayerFilter[];
  selectedUsers?: Value;
  selectedTags?: Value;
  isByRecentOrder?: boolean;
  dateRange?: Date[];
  colorFilter?: Value;
  fadeOption?: Value;
  layerTypes?: ILayerTypeFilter[];
  viewMode?: ProspectViewMode;
};
export type SetViewModePayload = {
  isSatelliteView?: boolean;
  isPeopleView?: boolean;
  isTagsView?: boolean;
  isLayersView?: boolean;
};
export type SetUsersPayload = IUserFilter[];
export type SetRecentlySelectedMap = {
  recentlySelectedMap: SelectedMap;
  location: string;
};
export type SetBatchIdPayload = { batchId: string | null };

export type MapStateActions =
  | { type: MapActions.SET_SELECTED_MAPS; payload: SetSelectedMapsPayload }
  | { type: MapActions.SET_MAP_CENTER; payload: SetMapCenterPayload }
  | { type: MapActions.SET_SELECTED_AREA; payload: SetSelectedAreaPayload }
  | { type: MapActions.SET_POINTER_ON_MAP; payload: SetPointerOnMapPayload }
  | {
      type: MapActions.TOGGLE_SHOW_EDGE_PROPERTIES;
      payload: ToggleShowEdgePropertiesPayload;
    }
  | { type: MapActions.SET_MAP_FILTERS; payload: SetMapFiltersPayload }
  | { type: MapActions.SET_VIEW_MODE; payload: SetViewModePayload }
  | { type: MapActions.SET_USER; payload: SetUsersPayload }
  | { type: MapActions.SET_MANAGER_VIEW; payload: SetManagerView }
  | { type: MapActions.RECENTLY_SELECTED_MAP; payload: SetRecentlySelectedMap }
  | { type: MapActions.RESET_MAP_FILTERS }
  | { type: MapActions.SET_CONTACT_COORDINATES; payload: Array<Coordinate> }
  | { type: MapActions.RESET_SELECTED_MAP }
  | { type: MapActions.SET_BATCH_ID; payload: SetBatchIdPayload };
