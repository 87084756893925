import React from "react";
import { Error } from "./styles";
import { Textarea, TextareaProps } from "baseui/textarea";

type Props = TextareaProps & {
  error?: any;
  maxTextLength?: number;
};

const BaseTextarea: React.FC<Props> = ({ error, maxTextLength, ...rest }) => {
  const getErrors = (error: any) => {
    if (!error) return null;
    switch (error.type) {
      case "required":
        return <Error>This field is required.</Error>;
      case "maxLength":
        return (
          <Error>
            {maxTextLength
              ? `Maximum ${maxTextLength} characters.`
              : "Max length exceeded."}
          </Error>
        );
      case "minLength":
        return <Error>Min length not met.</Error>;
      default:
        return <Error>{error.message}</Error>;
    }
  };
  const hasErrors = error !== null && error !== undefined;
  return (
    <div>
      <Textarea {...rest} error={hasErrors} />
      {getErrors(error)}
    </div>
  );
};

export default BaseTextarea;
