import React, { useState } from "react";
import { Loader } from "@virtualcapital/utogi-ui-library";
import LayersAndTagsDropdown from "../AddTagsAndLayersDropdown";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { toaster } from "baseui/toast";
import { KIND } from "baseui/button";
import { OnChangeParams, Select, Value } from "baseui/select";

import { ReactComponent as TagIcon } from "assets/icons/tag.svg";

import AddLayerList from "components/prospect/addLayerList/addLayerList";
import { AddTo } from "./";
import Button from "components/Button";

import {
  PropertySelectionType,
  TIMELINE_CREATION_TYPE,
} from "constants/property";

import { useMap } from "context/map/use-map";

import { event } from "../../../events";
import { ON_LAYERS_AND_TAGS_ADDED } from "../../../events/prospect";

import { canAddLayers, canAddTags } from "helpers/permission/map";
import WithScrollbar from "helpers/WithScrollbar";

import { MapRolePermissions } from "../../../types/permissions";

import "./AddTagsAndLayers.scss";

const ADD_LAYERS_AND_TAGS = gql`
  mutation(
    $layers: [LayerInputType]
    $tags: [InputTags]
    $selectionType: String!
    $campaign: String!
    $selectedArea: [GraphQLCoordinateInput]
    $properties: [PropertyInputType]
    $type: String!
    $contacts: [String]
  ) {
    property {
      addLayersAndTags(
        layers: $layers
        tags: $tags
        selectionType: $selectionType
        campaign: $campaign
        selectedArea: $selectedArea
        properties: $properties
        type: $type
        contacts: $contacts
      ) {
        message
        data
      }
    }
  }
`;

const GET_TAGS = gql`
  query {
    prospect {
      filters {
        tags {
          id
          name
        }
      }
    }
  }
`;

const DEFAULT_PROPS = {
  all: false,
  property: true,
  user: [],
};

type Props = {
  addTo: AddTo;
  onClose: Function;
  contactLoading: boolean;
  contactList: [];
  tags: [];
  layers: [];
  isLoading: boolean;
  permissions: MapRolePermissions;
};

const AddTagsAndLayers = ({
  addTo: { selectionType, selectedArea, properties },
  onClose,
  contactList,
  contactLoading,
  tags,
  layers,
  isLoading,
  permissions,
}: Props) => {
  const [selectedLayers, setSelectedLayers] = useState<any>([]);
  const [selectedTags, setSelectedTags] = useState<Value>([]);
  const hasAddLayersPermissions = canAddLayers(permissions);
  const hasAddTagsPermissions = canAddTags(permissions);
  const [isDisplayDropdown, changeDropdownDisplay] = useState<any>(false);
  const [selectedList, updateSelectedList] = useState<any>(DEFAULT_PROPS);
  const [hasError, setError] = useState(false);
  const { selectedMaps, setBatchId } = useMap();
  const { data } = useQuery(GET_TAGS);

  const campaign = selectedMaps[0].id;

  const onSelectedLayersChangeHandler = (
    layer: { id: string; tag: string },
    shouldAdd: boolean
  ) => {
    if (shouldAdd) {
      setSelectedLayers([...selectedLayers, layer]);
    } else {
      setSelectedLayers([
        ...selectedLayers.filter(({ id }: any) => layer.id !== id),
      ]);
    }
  };

  const [addLayersAndTags, { loading }] = useMutation(ADD_LAYERS_AND_TAGS, {
    onError: () => {
      toaster.negative(
        "Error occurred while updating the adding layers and tags.",
        {}
      );
    },

    onCompleted: (data) => {
      if (data?.property?.addLayersAndTags?.data?.layersBatch) {
        setBatchId({
          batchId: data?.property?.addLayersAndTags?.data?.layersBatch,
        });
      }
      if (selectedTags.length > 0 && selectedLayers.length > 0) {
        toaster.positive("Layers and tags added successfully!", {});
      } else if (selectedTags.length > 0) {
        toaster.positive("Tags added successfully!", {});
      } else {
        toaster.positive("Layers added successfully!", {});
      }
      event.dispatch(ON_LAYERS_AND_TAGS_ADDED);
    },
  });

  const addLayersAndTagsHandler = () => {
    if (selectedTags.length === 0 && selectedLayers.length === 0) {
      setError(true);
      return false;
    }
    setError(false);
    const tagsWithErrors = selectedTags.filter(
      ({ isCreatable, label }: any) =>
        isCreatable && !/^[^ !@#$%^&*(),.?":{}|<>]*$/gi.test(label)
    );
    if (tagsWithErrors.length > 0) {
      toaster.negative(
        "Tags can only contain letters, numbers, and underscores",
        {}
      );
      return false;
    }
    const tags = selectedTags.map(({ id: tag, isCreatable, label: name }) => ({
      name,
      tag: isCreatable ? undefined : tag,
    }));
    const layers = selectedLayers;
    const restrictAddLayers = !hasAddLayersPermissions && layers.length > 0;
    const restrictAddTags = !hasAddTagsPermissions && tags.length > 0;
    if (restrictAddLayers && restrictAddTags) {
      toaster.negative("You don't have permissions to add layers and tags", {});
      return false;
    } else if (restrictAddLayers) {
      toaster.negative("You don't have permissions to add layers", {});
      return false;
    } else if (restrictAddTags) {
      toaster.negative("You don't have permissions to add tags", {});
      return false;
    }

    let type = TIMELINE_CREATION_TYPE.BOTH;
    let contacts = [];
    if (selectedList.all) {
      type = TIMELINE_CREATION_TYPE.BOTH;
      contacts = selectedList.user;
    } else if (selectedList.property) {
      if (selectedList.user.length > 0) {
        type = TIMELINE_CREATION_TYPE.BOTH;
      } else {
        type = TIMELINE_CREATION_TYPE.PROPERTY;
      }
      contacts = selectedList.user;
    } else if (selectedList.user.length > 0) {
      type = TIMELINE_CREATION_TYPE.CONTACT;
      contacts = selectedList.user;
    }

    const variables =
      selectionType === PropertySelectionType.AREA
        ? {
            layers,
            tags,
            selectionType,
            campaign,
            selectedArea,
            properties: [],
            type,
            contacts,
          }
        : {
            layers,
            tags,
            selectionType,
            campaign,
            properties,
            selectedArea: [],
            type,
            contacts,
          };

    addLayersAndTags({ variables });
  };

  const getTagLabel = ({ option }: any) => {
    return <>#{option.label}</>;
  };

  const onTagChange = ({ value, option }: OnChangeParams) => {
    const { label, isCreatable } = option as {
      label: string;
      isCreatable?: boolean;
      id: string;
    };
    if (isCreatable) {
      if (!/^[^ !@#$%^&*(),.?":{}|<>]*$/gi.test(label)) {
        toaster.negative(
          "Tags can only contain letters, numbers, and underscores",
          {}
        );
        return;
      }
    }
    setSelectedTags(value);
  };

  const getContent = () => {
    if (isLoading) {
      return <Loader isLoading={true} />;
    }
    return (
      <>
        {hasError && (
          <div>
            <span style={{ color: "tomato", marginBottom: 5 }}>
              select tag or layer
            </span>
          </div>
        )}

        <div style={{ position: "relative", height: 65 }}>
          <LayersAndTagsDropdown
            contactList={contactList}
            contactLoading={contactLoading}
            isDisplayDropdown={isDisplayDropdown}
            changeDropdownDisplay={changeDropdownDisplay}
            selectedList={selectedList}
            updateSelectedList={updateSelectedList}
          />
        </div>
        <div>
          <WithScrollbar noScrollX height="calc(100vh - 220px)">
            <div className="add-tags-and-layers-section-header">Tags</div>
            <div className="tag-container">
              <TagIcon width="32px" height="32px" />
              <Select
                value={selectedTags}
                onChange={onTagChange}
                options={
                  data?.prospect?.filters?.tags?.map(
                    ({ id, name: label }: any) => ({ id, label })
                  ) || []
                }
                isLoading={loading}
                creatable
                maxDropdownHeight="300px"
                multi={true}
                getOptionLabel={getTagLabel}
                getValueLabel={getTagLabel}
                overrides={{
                  Tag: {
                    props: {
                      overrides: {
                        Root: {
                          style: () => ({
                            backgroundColor: "#00adf9",
                          }),
                        },
                      },
                    },
                  },
                }}
              />
            </div>
            <div className="add-tags-and-layers-section-header">Layers</div>
            <AddLayerList
              layers={layers}
              toggleLayerSelect={onSelectedLayersChangeHandler}
              selectedLayers={selectedLayers}
            />
          </WithScrollbar>

          <div className="button-container">
            <Button
              isLoading={loading}
              kind={KIND.secondary}
              onClick={addLayersAndTagsHandler}
              disabled={isDisplayDropdown}
            >
              Save
            </Button>
          </div>
        </div>
      </>
    );
  };

  return <div className="add-tags-and-layers">{getContent()}</div>;
};

export default AddTagsAndLayers;
