import React, { useState } from "react";
import { Tabs, TabsType } from "@virtualcapital/utogi-ui-library";
import { useHistory } from "react-router-dom";

import CampaignTemplateList from "../CampaignTemplateList";
import Button from "components/Button";
import CampaignTemplateSearch from "components/campaignTemplate/Search";

import { CAMPAIGN_TEMPLATE_FILTERS } from "constants/campaignTemplate";

import "./CampaignTemplateListContainer.scss";
import { KIND } from "baseui/button";
import { useStyletron } from "baseui";

const CampaignTemplateListContainer = () => {
  const history = useHistory();
  const [css] = useStyletron();

  const [keyword, onKeywordEntered] = useState("");
  return (
    <div className="campaign-template-list-container">
      <div className="campaign-template-list-container-header">
        <h1> Templates</h1>
        <div
          className={css({
            marginBottom: "25px",
            "@media screen and (min-width: 769px)": {
              display: "flex",
              justifyContent: "space-between",
            },
          })}
        >
          <CampaignTemplateSearch setKeyword={onKeywordEntered} />
          <Button
            onClick={() => history.push("/template/create")}
            kind={KIND.secondary}
          >
            Create Template
          </Button>
        </div>
      </div>
      <div className="campaign-template-list-container-content">
        <Tabs
          items={[
            {
              title: "All",
              content: (
                <CampaignTemplateList
                  keyword={keyword}
                  type={CAMPAIGN_TEMPLATE_FILTERS.ALL}
                />
              ),
            },
            {
              title: "My Templates",
              content: (
                <CampaignTemplateList
                  keyword={keyword}
                  type={CAMPAIGN_TEMPLATE_FILTERS.MINE}
                />
              ),
            },
            // {
            //   title: 'Office',
            //   content: (
            //     <CampaignTemplateList
            //       keyword={keyword}
            //       type={CAMPAIGN_TEMPLATE_FILTERS.OFFICE}
            //     />
            //   ),
            // },
            // {
            //   title: 'Company',
            //   content: (
            //     <CampaignTemplateList
            //       keyword={keyword}
            //       type={CAMPAIGN_TEMPLATE_FILTERS.COMPANY}
            //     />
            //   ),
            // },
            //   {
            //     title: "Coaches",
            //     content: (
            //       <CampaignTemplateList type={CAMPAIGN_TEMPLATE_FILTERS} />
            //     )
            //   }
          ]}
          type={TabsType.PRIMARY}
        />
      </div>
    </div>
  );
};

export default CampaignTemplateListContainer;
