import { gql } from "apollo-boost";

export const GET_SELECTED_CONTACTS = gql`
  query GetSelectedContactQuery($ids: [ID]) {
    contact {
      list(ids: $ids) {
        contacts {
          lastName
          firstName
          displayName
          id
        }
      }
    }
  }
`;

export const GET_PROPERTY_CONTACTS = gql`
  query GetPropertyContactListQuery($properties: [PropertyInputType]!) {
    contact {
      propertyContact(properties: $properties) {
        firstName
        lastName
        displayName
        id
        email
      }
    }
  }
`;
