import { Icon } from "@virtualcapital/utogi-ui-library";
import { styled, useStyletron } from "baseui";
import Button from "components/Button";
import React, { useState } from "react";
import AppMenu from "./AppMenu";
import UserMenu from "./UserMenu";
import Notifications from "./Notifications";
import Reminders from "./reminders";
import { hasBetaAccess } from "@virtualcapital/utogi-auth-library";

const AppIcon = styled("div", ({ _beta }: any) => ({
  position: "relative",
  marginLeft: "20px",
  paddingRight: "5px",
  "@media (max-width: 540px)": {
    display: "none",
  },
  ...(_beta
    ? {
        ":after": {
          content: '"Beta"',
          position: "absolute",
          bottom: "0",
          right: "0",
          background: "rgb(249, 157, 28)",
          color: "#fff",
          fontSize: "9px",
          padding: "0px 3px",
          borderRadius: "2px",
        },
      }
    : {}),
}));

type Props = {
  filter?: React.ReactNode;
};

const Header: React.FC<Props> = ({ filter }) => {
  const [css] = useStyletron();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return (
    <>
      <div
        className={css({
          display: "flex",
          backgroundColor: "#ffffff",
          padding: "10px",
          boxShadow: "0 3px 6px #00000029",
          alignItems: "center",
          justifyContent: "space-between",
          "@media (min-width: 425px)": {
            padding: "10px 10px 10px 40px",
            borderRadius: "0 0 0 40px",
          },
        })}
      >
        <div
          className={css({
            display: "flex",
            alignItems: "center",
          })}
        >
          <Button
            startEnhancer={() => <Icon icon="app" />}
            onClick={() => setIsDrawerOpen(true)}
            overrides={{
              StartEnhancer: {
                style: ({ $theme }) => ({
                  marginRight: 0,
                  color: $theme.colors.accent,
                }),
              },
              BaseButton: {
                style: {
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  backgroundColor: "#ffffff !important",
                },
              },
            }}
          />
          <AppIcon _beta={hasBetaAccess()}>
            <img
              alt="Maps"
              className={css({
                height: "45px",
              })}
              src={require("assets/icons/app-icons/maps.svg").default}
            />
          </AppIcon>
        </div>
        {filter}
        <div className={css({ display: "flex", alignItems: "center" })}>
          <Reminders />
          <Notifications />
          <UserMenu />
        </div>
      </div>
      <AppMenu isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} />
    </>
  );
};

export default Header;
