import React, { useState } from "react";

import ManageRole from "./ManageRole";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import PermissionGroup from "./PermissionGroup";

import { Header } from "./styles";
import Button from "components/Button";
import WithScrollbar from "helpers/WithScrollbar";

const QUERY = gql`
  query getData {
    map {
      roles {
        id
        name
        permissions
        canEdit
      }
    }
  }
`;

const CampaignPermissions = () => {
  const [onCreate, toggleOnCreate] = useState(false);
  const { data, refetch } = useQuery(QUERY, {
    fetchPolicy: "no-cache",
  });
  const onSuccess = () => {
    toggleOnCreate(false);
    refetch();
  };

  return (
    <div>
      {onCreate ? (
        <ManageRole onSuccess={onSuccess} />
      ) : (
        <div>
          <Header>
            Map Roles
            <Button onClick={() => toggleOnCreate(!onCreate)}>
              Create New
            </Button>
          </Header>
          <WithScrollbar noScrollX height="calc(100vh - 350px)">
            {data?.map?.roles.map(({ name, permissions, id, canEdit }: any) => (
              <PermissionGroup
                title={name}
                key={id}
                id={id}
                canEdit={canEdit}
                permissions={permissions}
                refetch={refetch}
              />
            ))}
          </WithScrollbar>
        </div>
      )}
    </div>
  );
};

export default CampaignPermissions;
