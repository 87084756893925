import { ReactNode } from "react";

export enum AlertActions {
  OPEN_ALERT,
  CLOSE_ALERT,
}

export type AlertButtonPayload = {
  title?: string;
  action: Function;
};

export type AlertState = {
  isOpen: boolean;
  title?: string | ReactNode;
  content?: string | ReactNode;
  primaryButton?: AlertButtonPayload;
  secondaryButton?: AlertButtonPayload;
};

export type OpenAlertPayload = {
  title?: string | ReactNode;
  content: string | ReactNode;
  primaryButton: AlertButtonPayload;
  secondaryButton?: AlertButtonPayload;
};

export type AlertStateActions =
  | { type: AlertActions.OPEN_ALERT; payload: OpenAlertPayload }
  | { type: AlertActions.CLOSE_ALERT };
