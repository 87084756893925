import React, { useEffect, useRef, useState } from "react";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Redirect, useHistory, useParams } from "react-router-dom";
import {
  ConfirmAlert,
  IconButton,
  Loader,
  Tabs,
} from "@virtualcapital/utogi-ui-library";
import moment from "moment";
import { styled } from "baseui";
import { Value } from "baseui/select";
import { toaster } from "baseui/toast";

import { ReactComponent as PrivacyIcon } from "assets/icons/privacy.svg";

import MapLayers from "components/maps/MapLayers";
import Input from "components/Input/Input";
import TextArea from "components/textarea";
import ChangeStatus from "components/ChangeStatus";
import SelectWithStyling from "components/select";

import { MapStatus } from "constants/campaign";
import { PrivacyLevel } from "../CreateNewMap/CreateNewMap";

import CampaignUsers from "containers/campaign/CampaignUsers/CampaignUsers";
import RouteLeavingGuard from "../../RouteLeavingGuard";
import { Button } from "baseui/button";
import { defaultButtonStyles } from "components/Button";

import {
  canChangeStatus,
  canSetUpMap,
} from "../../../helpers/permission/map-setup";

import { isName } from "../../../helpers/validations";
import { MapUserRoleType, MapUserType } from "../../../types/map";

import "./Map.scss";

const fragments = {
  campaign: gql`
    fragment CampaignDetails on Campaign {
      id
      name
      description
      status
      isPrivate
      createdUser {
        name
        time
      }
      lastEditBy {
        name
        time
      }
      layers {
        id
        name
        type {
          title
          id
        }
        primaryColor
        secondaryColor
        transitionTime
        fadeTime
        addFade
        addTransition
      }
      users {
        id
        name
        image
        role {
          name
          id
        }
      }
      permissions
    }
  `,
};

const GET_CAMPAIGN = gql`
  query($id: String!) {
    map {
      campaign {
        byId(id: $id) {
          ...CampaignDetails
        }
      }
    }
  }
  ${fragments.campaign}
`;

const UPDATE_CAMPAIGN = gql`
  mutation(
    $id: ID!
    $name: String!
    $description: String!
    $status: String
    $isPrivate: Boolean
    $users: [MapUserInput]!
    $layers: GraphQLLayers!
  ) {
    map {
      campaign {
        update(
          id: $id
          name: $name
          description: $description
          users: $users
          layers: $layers
          status: $status
          isPrivate: $isPrivate
        ) {
          ...CampaignDetails
        }
      }
    }
  }
  ${fragments.campaign}
`;

const DELETE_CAMPAIGN = gql`
  mutation($id: ID!) {
    map {
      campaign {
        delete(id: $id)
      }
    }
  }
`;

const ARCHIVE_CAMPAIGN = gql`
  mutation($id: ID!) {
    map {
      campaign {
        archive(id: $id)
      }
    }
  }
`;

export interface IMapParams {
  id: string;
}

const ActivityContainer = styled("div", () => ({
  display: "flex",
  alignItems: "center",
  marginTop: "10px",
  color: "#B1B1B1",
  maxWidth: "300px",
  "@media (max-width: 768px)": {
    marginBottom: "10px",
  },
}));

const ActivityLabel = styled("span", () => ({
  color: "#828282",
  margin: "0 10px",
}));

export const PrivacyLevels = [
  { label: "Private (Default)", id: PrivacyLevel.PRIVATE },
  { label: "Shared", id: PrivacyLevel.SHARED },
];

const Map = () => {
  const { id } = useParams<IMapParams>();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [layers, setLayers] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [onEdit, setOnEdit] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [status, changeStatus] = useState<MapStatus>(MapStatus.DRAFT);
  const [currentMapStatus, setCurrentMapStatus] = useState<MapStatus>();
  const [privacy, setPrivacy] = useState<Value>([]);
  const history = useHistory();
  const [errors, setErrors] = useState({
    name: null,
    description: null,
  });

  const currentMapName = useRef("");
  const currentMapDescription = useRef("");

  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setOnEdit(false);
        setName(currentMapName.current);
        setDescription(currentMapDescription.current);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef]);

  const { loading, data } = useQuery(GET_CAMPAIGN, {
    variables: { id },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.map?.campaign?.byId) setCampaignData(data?.map?.campaign?.byId);
    },
  });

  const disableEdit =
    status === MapStatus.ARCHIVE || status === MapStatus.DELETE;

  const [updateCampaign, { loading: updating }] = useMutation(UPDATE_CAMPAIGN, {
    onError: ({ graphQLErrors }: ApolloError) => {
      graphQLErrors.map(({ message }) => toaster.negative(message, {}));
    },
    onCompleted: (data) => {
      toaster.positive("Campaign updated successfully!", {});
      if (disableEdit) {
        history.push("/maps");
      }
      setOnEdit(false);
      setIsEdited(false);
      if (data?.map?.campaign?.update)
        setCampaignData(data?.map?.campaign?.update);
    },
  });

  const [deleteCampaign] = useMutation(DELETE_CAMPAIGN, {
    onError: ({ graphQLErrors }: ApolloError) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message }) => toaster.negative(message, {}));
      }
    },
    onCompleted: () => {
      toaster.positive("Map is deleted successfully!", {});
      setOnEdit(false);
      setIsEdited(false);
      history.push("/maps");
    },
  });

  const [archiveCampaign] = useMutation(ARCHIVE_CAMPAIGN, {
    onError: ({ graphQLErrors }: ApolloError) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message }) => toaster.negative(message, {}));
      }
    },
    onCompleted: () => {
      toaster.positive("Map is archived successfully!", {});
      setOnEdit(false);
      setIsEdited(false);
      history.push("/maps");
    },
  });

  const setCampaignData = (campaignData: any) => {
    if (campaignData) {
      const {
        layers,
        users,
        status,
        isPrivate,
        name,
        description,
      } = campaignData;
      setLayers(layers);
      setUsers(users);
      changeStatus(status);
      setName(name);
      setDescription(description);
      currentMapName.current = name;
      currentMapDescription.current = description;
      setPrivacy([isPrivate ? PrivacyLevels[0] : PrivacyLevels[1]]);
      setCurrentMapStatus(status);
    }
  };

  const onUserRoleChange = (userId: string, role: MapUserRoleType) => {
    if (!users) return;
    const changedUsers = users.map((user: MapUserType) => {
      if (user.id === userId) {
        user.role = role;
      }
      return user;
    });
    setUsers(changedUsers);
    setIsEdited(true);
  };

  if (!id) return <Redirect to="/maps" />;

  if (loading || !data) return <Loader isLoading={true} />;

  const {
    map: {
      campaign: {
        byId: {
          lastEditBy: { time: lastUpdatedTime },
          createdUser: { name: createdUser, time: createdTime },
          status: initialStatus,
          permissions,
        },
      },
    },
  } = data;

  const onSave = () => {
    if (currentMapStatus !== status) {
      if (status === MapStatus.ARCHIVE) {
        if (status === MapStatus.ARCHIVE) {
          archiveCampaign({ variables: { id } });
          return;
        }
      }
    }
    if (disableEdit) return;
    const newErrors: any = {
      name: null,
      description: null,
    };

    let hasError = false;

    if (!name || name.trim() === "") {
      newErrors.name = { message: "Name is required." };
      hasError = true;
    } else if (name.trim().length > 50) {
      newErrors.name = { message: "Maximum 50 characters." };
      hasError = true;
    } else if (!isName(name)) {
      newErrors.name = { message: "Only letters and numbers are allowed." };
      hasError = true;
    }

    if (!description || description.trim() === "") {
      newErrors.description = { message: "Description is required." };
      hasError = true;
    } else if (description.trim().length > 250) {
      newErrors.description = { message: "Maximum 250 characters." };
      hasError = true;
    }

    setErrors(newErrors);

    if (hasError) {
      return;
    }

    const added: any = [],
      removed: any = [],
      updated: any = [];

    layers.map(
      ({
        id,
        name,
        type: { id: type },
        primaryColor,
        secondaryColor,
        transitionTime,
        fadeTime,
        addTransition,
        addFade,
        isDeleted,
        isEdited,
      }: any) => {
        if (!id) {
          added.push({
            name,
            type,
            primaryColor,
            secondaryColor,
            transitionTime,
            fadeTime,
            addTransition,
            addFade,
          });
        } else if (isDeleted) {
          removed.push(id);
        } else if (isEdited) {
          updated.push({
            id,
            name,
            type,
            primaryColor,
            secondaryColor,
            transitionTime,
            fadeTime,
            addTransition,
            addFade,
          });
        }
        return null;
      }
    );

    if (users === null || users.length === 0) {
      toaster.negative("At least one user required.", {});
      return;
    }
    if (added.length + layers.length - removed.length === 0) {
      toaster.negative("At least one layer required.", {});
      return;
    }
    let hasRoleError = false;
    users.map(({ role }: MapUserType) => {
      if (!role) {
        hasRoleError = true;
      }
      return true;
    });
    if (hasRoleError) {
      toaster.negative("Select a role for all users.", {});
      return;
    }
    updateCampaign({
      variables: {
        id,
        name,
        description,
        status,
        isPrivate: privacy[0].id === PrivacyLevel.PRIVATE,
        layers: {
          added,
          removed,
          updated,
        },
        users: users.map(({ id, role }: MapUserType) => ({
          id,
          role: role.id,
        })),
      },
    });
  };

  const onStateChanged = async (status: MapStatus) => {
    if (status === MapStatus.DELETE) {
      ConfirmAlert({
        closeOnClickOutside: false,
        message: "Are you sure you want to delete this map?",
        onCancel: () => {},
        onConfirm: () => {
          deleteCampaign({ variables: { id } });
        },
      });
    } else {
      changeStatus(status);
      setIsEdited(true);
    }
  };

  const canChangeStatusPermission = canChangeStatus(permissions);
  const hasEditMapSetupPermission = canSetUpMap(permissions);
  const isPrivate = privacy[0]?.id === PrivacyLevel.PRIVATE;
  return (
    <div className="map">
      <RouteLeavingGuard
        when={isEdited}
        navigate={(path) => history.push(path)}
      />
      {onEdit ? (
        <div className="map-header box">
          <div className="map-header-left" ref={wrapperRef}>
            <Input
              type="text"
              id="name"
              name="name"
              placeholder="Enter Map Name"
              value={name}
              error={errors.name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextArea
              className="map-header-left__description"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              name="description"
              error={errors.description}
              placeholder="Enter Description"
              rows={3}
              maxTextLength={250}
            />
            <ActivityContainer>
              <PrivacyIcon width={20} />
              <ActivityLabel>Activity</ActivityLabel>
              <SelectWithStyling
                options={[
                  { label: "Private (Default)", id: PrivacyLevel.PRIVATE },
                  { label: "Shared", id: PrivacyLevel.SHARED },
                ]}
                value={privacy}
                onChange={(params) => setPrivacy(params.value)}
                clearable={false}
              />
            </ActivityContainer>
          </div>
          <div className="map-header-right">
            <div />
            <Button
              ref={buttonRef}
              onClick={onSave}
              disabled={updating}
              overrides={{
                BaseButton: {
                  style: {
                    ...defaultButtonStyles,
                  },
                },
              }}
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <div className="map-header box">
          <div className="map-header-left">
            <div className="map-header-name">
              {name}{" "}
              {!disableEdit && (
                <IconButton
                  icon="edit"
                  onClick={() =>
                    hasEditMapSetupPermission
                      ? setOnEdit(true)
                      : toaster.negative(
                          "You do not have permission to edit map.",
                          {}
                        )
                  }
                />
              )}
            </div>
            <div className="map-header-description">
              Description: {description}
            </div>
            <div>
              Created by {createdUser} on&nbsp;
              {moment(new Date(createdTime)).format("DD MMMM YYYY")}
            </div>
            <div>
              Last updated on&nbsp;
              {moment(new Date(lastUpdatedTime)).format("DD MMMM YYYY")}
            </div>
            <ActivityContainer>
              <PrivacyIcon width={20} />
              <ActivityLabel>Activity</ActivityLabel>
              {isPrivate ? "Private (Default)" : "Shared"}
            </ActivityContainer>
          </div>
          <div className="map-header-right">
            <ChangeStatus
              status={status}
              changeStatus={onStateChanged}
              initialStatus={initialStatus}
              canChangeStatusPermission={canChangeStatusPermission}
            />
            {isEdited && (
              <Button
                overrides={{
                  BaseButton: {
                    style: {
                      ...defaultButtonStyles,
                    },
                  },
                }}
                onClick={onSave}
                disabled={updating}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      )}
      <div className="map-content">
        <Tabs
          items={[
            {
              title: "Layers",
              content: (
                <MapLayers
                  hasEditMapSetupPermission={hasEditMapSetupPermission}
                  layers={layers}
                  setLayers={(layers: any[]) => {
                    setLayers(layers);
                    setIsEdited(true);
                  }}
                  disableEdit={disableEdit}
                />
              ),
            },
            {
              title: "Users",
              content: (
                <CampaignUsers
                  users={users}
                  onSave={(users: any[]) => {
                    setUsers(users);
                    setIsEdited(true);
                  }}
                  disableEdit={disableEdit}
                  onUserRoleChange={onUserRoleChange}
                  permissions={permissions}
                />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Map;
