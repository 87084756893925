import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Icon } from "@virtualcapital/utogi-ui-library";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { toaster } from "baseui/toast";
import gql from "graphql-tag";
import { KIND } from "baseui/button";

import occupancyImage from "assets/images/occupancy.svg";

import Button from "components/Button";
import Input from "components/base-input/base-input";
import Select from "components/SingleSelect";
import SaveButton from "components/SaveButton";
import Toggle from "components/toggle";
import { confirmAlert } from "components/confirm-alert";

import { event } from "../../../events";
import { REFETCH_PROSPECT_MAP } from "../../../events/prospect";

import { isValidEmail } from "helpers/validations";

import { IUser } from "interfaces/user";

import "./CreatePropertyContact.scss";

export const MY_SELF = { id: "MY_SELF", label: "Myself" };

const CREATE_CONTACT = gql`
  mutation(
    $contact: GraphQLCreateContactInput
    $property: GraphQLCreatePropertyContactInput
  ) {
    contact {
      createContactFromProperty(contact: $contact, property: $property) {
        id
      }
    }
  }
`;

const GET = gql`
  query GetSettings {
    contact {
      personalSettings {
        createContactsFor
      }
      sharingSettings {
        user {
          id
          name
        }
        canView
        canEdit
        canCreate
      }
      usersWithCreateAccess {
        id
        name
      }
    }
    user {
      id
      name
    }
  }
`;

const CreatePropertyContact = ({
  property,
  unit,
  onSuccess,
  formReset,
}: any) => {
  const [values, changeValues] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    landline: "",
    email: "",
    occupancyStatus: "",
    honorific: "",
    displayName: "",
    createFor: "",
  });
  const [isSubscribed, setIsSubscribed] = useState(false);

  const [errors, setErrors] = useState<any>({
    firstName: null,
    lastName: null,
    mobileNumber: null,
    email: null,
  });

  const { data, loading: usersAreLoading } = useQuery(GET);

  const [createContact, { loading }] = useMutation(CREATE_CONTACT, {
    onError: async ({ graphQLErrors }: ApolloError) => {
      if (
        graphQLErrors.find(({ message }) => message === "Duplicate contact")
      ) {
        const confirmed = await confirmAlert(
          "You already have this contact, are you sure you want to create a duplicate?"
        );
        if (confirmed) {
          createContact({
            variables: {
              contact: { ...getVariable(), createDuplicate: true },
              property: {
                property,
                unit,
              },
            },
          });
        }
        return;
      }
      graphQLErrors.map(({ message }) => toaster.negative(message, {}));
    },
    onCompleted: () => {
      toaster.positive("Contact created successfully!", {});
      event.dispatch(REFETCH_PROSPECT_MAP);
      onSuccess();
    },
  });

  const getVariable = () => {
    const {
      firstName,
      lastName,
      mobileNumber,
      email,
      landline,
      occupancyStatus,
      displayName,
      honorific,
      createFor,
    } = values;
    const contact: any = {
      firstName,
      lastName,
      mobileNumber,
      landline,
      email,
      displayName:
        !displayName || displayName === ""
          ? `${firstName} ${lastName}`
          : displayName,
      subscribed: isSubscribed,
      createFor: createFor && createFor !== "" ? createFor : null,
    };

    if (honorific) {
      contact.honorific = honorific;
    }

    if (occupancyStatus) {
      contact.occupancyStatus = occupancyStatus;
    }

    return contact;
  };

  const handleFormSubmit = () => {
    let hasErrors = false;
    const error: any = {
      firstName: null,
      lastName: null,
      mobileNumber: null,
      email: null,
    };
    const { firstName, email } = values;

    if (!firstName || firstName === "") {
      hasErrors = true;
      error.firstName = "First name is required";
    }
    if (email && !isValidEmail(email)) {
      hasErrors = true;
      error.email = "Email is invalid";
    }

    setErrors(error);

    if (hasErrors) return;

    const contact = getVariable();

    createContact({
      variables: {
        contact,
        property: {
          property,
          unit,
        },
      },
    });
  };

  useEffect(() => {
    if (
      data?.contact.usersWithCreateAccess.length > 0 &&
      data?.contact?.personalSettings?.createContactsFor
    ) {
      changeValues({
        ...values,
        createFor: data.contact.personalSettings.createContactsFor,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const createForOptions = useMemo(() => {
    let options = [MY_SELF];
    if (
      data?.contact.usersWithCreateAccess &&
      data?.contact.usersWithCreateAccess.length > 0
    )
      options = [
        ...options,
        ...data?.contact.usersWithCreateAccess.map(
          ({ id, name: label }: IUser) => ({ id, label })
        ),
      ];

    return options;
  }, [data]);

  return (
    <div className="create-property-contact">
      <ul className="people-tab-content-details">
        <li>
          <Icon className="people-tab-content-details-icon" icon="profile" />
          <Select
            placeholder="Select Title"
            options={[
              { label: "Mr", id: "MR" },
              { label: "Mrs", id: "MRS" },
              { label: "Miss", id: "MISS" },
              { label: "Dr", id: "DR" },
              { label: "Mx", id: "MX" },
            ]}
            value={values.honorific}
            onChange={(honorific: any) =>
              changeValues({ ...values, honorific })
            }
          />
        </li>
        <li>
          <Icon className="people-tab-content-details-icon" icon="profile" />{" "}
          <Input
            value={values.firstName}
            placeholder="First Name"
            onChange={({
              target: { value: firstName },
            }: ChangeEvent<HTMLInputElement>) =>
              changeValues({ ...values, firstName })
            }
            error={errors.firstName}
          />
        </li>
        <li>
          <Icon className="people-tab-content-details-icon" icon="profile" />{" "}
          <Input
            value={values.lastName}
            placeholder="Last Name"
            onChange={({
              target: { value: lastName },
            }: ChangeEvent<HTMLInputElement>) =>
              changeValues({ ...values, lastName })
            }
            error={errors.lastName}
          />
        </li>
        <li>
          <Icon className="people-tab-content-details-icon" icon="profile" />{" "}
          <Input
            value={values.displayName}
            placeholder="Display Name"
            onChange={({
              target: { value: displayName },
            }: ChangeEvent<HTMLInputElement>) =>
              changeValues({ ...values, displayName })
            }
            error={errors.displayName}
          />
        </li>
        <li>
          <Icon className="people-tab-content-details-icon" icon="phone" />{" "}
          <Input
            value={values.mobileNumber}
            placeholder="Mobile Number"
            onChange={({
              target: { value: mobileNumber },
            }: ChangeEvent<HTMLInputElement>) =>
              changeValues({ ...values, mobileNumber })
            }
            error={errors.mobileNumber}
          />
        </li>
        <li>
          <Icon className="people-tab-content-details-icon" icon="call" />{" "}
          <Input
            value={values.landline}
            placeholder="Landline"
            onChange={({
              target: { value: landline },
            }: ChangeEvent<HTMLInputElement>) =>
              changeValues({ ...values, landline })
            }
            error={errors.landline}
          />
        </li>
        <li>
          <Icon className="people-tab-content-details-icon" icon="envelop" />{" "}
          <Input
            value={values.email}
            placeholder="Email"
            onChange={({
              target: { value: email },
            }: ChangeEvent<HTMLInputElement>) =>
              changeValues({ ...values, email })
            }
            error={errors.email}
          />
        </li>
        <li>
          <img src={occupancyImage} alt="occupancy" />
          <Select
            error={errors.occupancyStatus}
            placeholder="Select Occupancy Status"
            options={[
              { label: "Owner (Occupied)", id: "owner-occupied" },
              { label: "Owner (Rental)", id: "owner-rental" },
              { label: "Owner (Vacant)", id: "owner-vacant" },
              { label: "Owner (Holiday Home)", id: "owner-holiday-home" },
              { label: "Tenant", id: "tenant" },
            ]}
            value={values.occupancyStatus}
            onChange={(occupancyStatus: any) =>
              changeValues({ ...values, occupancyStatus })
            }
          />
        </li>
        <li>
          <Toggle
            checked={isSubscribed}
            onChange={(e: any) => setIsSubscribed(e.target.checked)}
          >
            Is subscribed to marketing emails
          </Toggle>
        </li>
        <li>
          <div className="create-property-contact-create-for">
            <label>Create For</label>
            <Select
              options={createForOptions}
              placeholder="Myself"
              value={values.createFor}
              onChange={(createFor: string) =>
                changeValues({ ...values, createFor })
              }
              isLoading={usersAreLoading}
            />
          </div>
        </li>
      </ul>
      <div>
        <Button
          onClick={formReset}
          overrides={{ BaseButton: { style: { marginRight: "15px" } } }}
          kind={KIND.tertiary}
        >
          Cancel
        </Button>
        <SaveButton onClick={handleFormSubmit} isLoading={loading} />
      </div>
    </div>
  );
};

export default CreatePropertyContact;
