import { styled } from "baseui";

export const SettingsHeader = styled("div", () => ({
  marginBottom: "20px",
  fontSize: "22px",
}));

export const SettingsHeaderLabel = styled("label", () => ({
  marginLeft: "12px",
}));

export const SettingsContainer = styled("div", () => ({
  padding: "2px 327px 2px 5px",
}));
