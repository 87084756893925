export const PERMISSION_ERROR = {
  EDIT_CAMPAIGN: "You don't have permissions to edit campaigns",
  CREATE_NEW_CAMPAIGN: "You don't have permissions to create new campaigns",
  ARCHIVE_CAMPAIGN: "You don't have permissions to archive campaigns",
  DELETE_LAYERS: "You don't have permissions to delete layers",
  ADD_LAYERS: "You don't have permissions to create new layers",
  ADD_TAGS: "You don't have permissions to add tags",
  ADD_NOTES: "You don't have permissions to add notes",
  SEND_EMAILS: "You don't have permissions to send emails",
  ADD_REMINDERS: "You don't have permissions to add reminders",
  ADD_LAYERS_AND_TAGS: "You don't have permissions to add layers and tags",
  VIEW_PROSPECT_MODE: "You don't have permissions to view prospect mode",
  VIEW_USER_VIEW: "You don't have permissions to view user mode",
};
