import React from "react";
import { Route } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Layout from "components/layout";
import MapList from "containers/maps/MapListContainer";
import Map from "containers/maps/Map";
import SelectedMap from "containers/maps/SelectedMap";
import MapHeader from "containers/maps/MapHeader";

import { useMap } from "context/map/use-map";

import "./maps.scss";

const Maps = () => {
  const { isMapSelected } = useMap();
  const location = useLocation();
  const isAllMapMode = location.pathname === "/maps/all";

  return (
    <Layout
      hasFullScreenContent={isMapSelected}
      filter={isMapSelected ? <MapHeader isAllMapMode={isAllMapMode} /> : null}
    >
      <Route path="/maps" exact>
        <MapList />
      </Route>
      <Route path="/maps/all" exact>
        <SelectedMap isAllMapMode={isAllMapMode} />
      </Route>
      <Route path="/maps/selected" exact>
        <SelectedMap isAllMapMode={isAllMapMode} />
      </Route>
      <Route path="/maps/view/:id" exact>
        <Map />
      </Route>
    </Layout>
  );
};

export default Maps;
