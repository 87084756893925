import { useMutation } from "@apollo/react-hooks";
import { Icon } from "@virtualcapital/utogi-ui-library";
import { gql } from "apollo-boost";
import { Skeleton } from "baseui/skeleton";
import { INotification } from "interfaces/notifications";
import React, { useEffect, useState } from "react";
import { useStyletron } from "styletron-react";
import Notification from "./Notification";
import NotificationSetting from "./NotificationSetting";
import Toggle, { ToggleContainer } from "../../toggle";

type NotificationListProps = {
  loading: boolean;
  list: { today: INotification[]; older: INotification[] };
  refetch: Function;
  onlyUnread: boolean;
  toggleOnlyUnread: Function;
  onOpen: Function;
};

const UPDATE_ALL_AS_READ_STATUS = gql`
  mutation updateReadStatus {
    notification {
      markAsRead {
        success
      }
    }
  }
`;

const NotificationList: React.FC<NotificationListProps> = ({
  loading,
  list,
  refetch,
  onlyUnread,
  toggleOnlyUnread,
  onOpen,
}) => {
  const [onCustomize, toggleOnCustomize] = useState(false);
  const [css] = useStyletron();

  const [updateAllAsReadStatus, { loading: updating }] = useMutation(
    UPDATE_ALL_AS_READ_STATUS,
    {
      onCompleted: (data) => {
        if (data?.notification?.markAsRead?.success) {
          refetch();
        }
      },
    }
  );

  const onReadStatusChange = () => {
    updateAllAsReadStatus();
  };

  useEffect(() => {
    onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getContent = () => {
    if (loading) {
      return (
        <div>
          <Skeleton
            height="20px"
            width="300px"
            animation
            overrides={{
              Root: {
                style: { marginBottom: "10px" },
              },
            }}
          />
        </div>
      );
    }
    return (
      <div>
        <ToggleContainer>
          <Toggle
            checked={onlyUnread}
            onChange={(e: any) => toggleOnlyUnread(e.target.checked)}
          >
            Only show unread
          </Toggle>
        </ToggleContainer>
        {list.today.length + list.older.length === 0 ? (
          <div>You've read all your notifications</div>
        ) : (
          <div
            className={css({
              maxHeight: "calc(100vh - 110px)",
              overflowY: "scroll",
              overflowX: "hidden",
            })}
          >
            {list.today.length > 0 && (
              <div>
                <div
                  className={css({
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "10px",
                  })}
                >
                  <div
                    className={css({
                      fontWeight: 600,
                      textTransform: "uppercase",
                      fontSize: "12px",
                    })}
                  >
                    Today
                  </div>
                  <button
                    disabled={updating}
                    className={css({ cursor: "pointer", fontSize: "12px" })}
                    onClick={onReadStatusChange}
                  >
                    Mark all as read
                  </button>
                </div>
                {list.today.map((notification) => (
                  <Notification
                    {...notification}
                    key={notification.id}
                    refetch={refetch}
                  />
                ))}
              </div>
            )}
            {list.older.length > 0 && (
              <div>
                <div
                  className={css({
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "10px",
                  })}
                >
                  <div
                    className={css({
                      fontWeight: 600,
                      textTransform: "uppercase",
                      fontSize: "12px",
                    })}
                  >
                    Older
                  </div>
                  {list.today.length === 9 && (
                    <button
                      disabled={updating}
                      className={css({ cursor: "pointer", fontSize: "12px" })}
                      onClick={onReadStatusChange}
                    >
                      Mark all as read
                    </button>
                  )}
                </div>
                {list.older.map((notification) => (
                  <Notification
                    {...notification}
                    key={notification.id}
                    refetch={refetch}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={css({
        backgroundColor: "#ffffff",
        padding: "15px",
        fontSize: "13px",
      })}
    >
      <div>
        <button
          className={css({
            marginBottom: "10px",
            fontWeight: 600,
            fontSize: "14px",
            ":hover": {
              color: "#0196ce",
            },
          })}
          onClick={() => toggleOnCustomize(!onCustomize)}
        >
          <Icon icon={onCustomize ? "chevron-left" : "settings"} />
          <span className={css({ marginLeft: "5px" })}>
            {onCustomize ? "Back" : "Settings"}
          </span>
        </button>
        {onCustomize ? <NotificationSetting /> : <>{getContent()}</>}
      </div>
    </div>
  );
};

export default NotificationList;
