import React, { forwardRef } from "react";
import { Icon } from "@virtualcapital/utogi-ui-library";

import "./iconButton.scss";

const iconButton = forwardRef(
  ({ icon, className, isLoading, disabled, ...rest }: any, ref: any) => (
    <button
      className={`icon-button ${className}`}
      ref={ref}
      disabled={disabled || isLoading}
      {...rest}
    >
      {isLoading ? (
        <div className="loading">
          <div className="loader-spin" />
        </div>
      ) : (
        <Icon icon={icon} />
      )}
    </button>
  )
);

iconButton.displayName = "IconButton";

export default iconButton;
