import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { hasPermission, permissions } from "@virtualcapital/utogi-auth-library";
import { KIND, SIZE } from "baseui/button";
import { styled } from "baseui";

import Loader from "components/loader/loader";
import CreateNewMap from "./CreateNewMap";
import MapSearch from "components/maps/Search";
import Toggle from "../../components/toggle";
import Drawer from "../../components/drawer";
import MapList from "./MapList";
import Button from "components/Button";
import MapFilters from "containers/campaign/MapFilters";

import { useMap } from "context/map/use-map";
import { MapStatus } from "../../constants/campaign";

const FilterContainer = styled("div", () => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "20px",
}));

const Filter = styled("div", () => ({
  display: "flex",
  alignItems: "center",
  color: "#70707075",
  fontSize: "12px",
  marginLeft: "30px",
}));

const MapListContainer = () => {
  const GET_CAMPAIGNS = gql`
    query($filter: CampaignFilterParams!, $pagination: PaginationParams) {
      map {
        campaign {
          list(filter: $filter, pagination: $pagination) {
            campaigns {
              id
              name
              status
              createdUser {
                name
                time
                id
              }
              users {
                id
              }
            }
            pagination {
              page
              total
              perPage
            }
          }
        }
      }
    }
  `;

  const location = useLocation();
  const history = useHistory();
  const { isMapSelected, resetMapFilters } = useMap();

  const [keyword, setKeyword] = useState<string>("");
  const [createMap, toggleCreateMap] = useState(false);
  const [status, setStatus] = useState<null | MapStatus>(null);
  const [showOnlyMine, toggleShowOnlyMine] = useState(false);

  useEffect(() => {
    if (location.pathname === "/maps" && isMapSelected) {
      resetMapFilters();
    }
    // eslint-disable-next-line
  }, [location, isMapSelected]);

  const { loading, data, refetch } = useQuery(GET_CAMPAIGNS, {
    fetchPolicy: "no-cache",
    variables: {
      filter: {
        keyword,
        onlyMine: false,
        status,
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
    },
  });

  const onKeywordChange = (keyword: string) => {
    setKeyword(keyword);
    refetch({
      filter: {
        keyword,
        onlyMine: false,
        status,
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
    });
  };

  const onStatusChange = (status: null | MapStatus) => {
    setStatus(status);
    refetch({
      filter: {
        keyword,
        onlyMine: false,
        status,
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
    });
  };

  const onPrivacyChange = (onlyMine: boolean) => {
    toggleShowOnlyMine(onlyMine);
    refetch({
      filter: {
        keyword,
        onlyMine,
        status,
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
    });
  };

  return (
    <>
      <div className="maps-header">
        <div className="maps-heading">Maps Setup</div>
        <div>
          <MapSearch setKeyword={onKeywordChange} />
        </div>
      </div>
      <div className="maps-action">
        {hasPermission(permissions.maps.CREATE_NEW_MAPS) && (
          <Button onClick={() => toggleCreateMap(true)} size={SIZE.compact}>
            Create New Map
          </Button>
        )}
        <Button
          kind={KIND.secondary}
          size={SIZE.compact}
          onClick={() => {
            history.push("/maps/all");
          }}
        >
          View All Maps
        </Button>
      </div>
      <FilterContainer>
        <MapFilters onStatusChange={onStatusChange} status={status} />
        <Filter>
          All
          <Toggle
            checked={showOnlyMine}
            onChange={(e: any) => onPrivacyChange(e.target.checked)}
          />
          My Maps
        </Filter>
      </FilterContainer>
      {loading ? <Loader /> : <MapList refetch={refetch} data={data} />}
      <Drawer
        isOpen={createMap}
        autoFocus
        onClose={() => toggleCreateMap(false)}
        header="Create New Map"
      >
        <CreateNewMap />
      </Drawer>
    </>
  );
};

export default MapListContainer;
