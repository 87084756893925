import React from "react";
import { KIND, Tag } from "baseui/tag";

import { IPropertyTag } from "../../../interfaces/property";

import "./PropertyTagList.scss";
import { useAlert } from "context/alert/use-alert";

type Props = {
  tags: IPropertyTag[];
  loading: boolean;
  deleteTag: Function;
};

const PropertyTagList = ({ tags, deleteTag }: Props) => {
  const { openAlert } = useAlert();

  const onDelete = (id: string) => {
    openAlert({
      content: "Are you sure you want to delete this tag?",
      primaryButton: {
        title: "Delete",
        action: () => deleteTag({ variables: { id } }),
      },
      secondaryButton: { title: "Cancel", action: () => {} },
    });
  };

  return (
    <>
      {tags.map(({ id, name }: IPropertyTag) => (
        <Tag
          key={id}
          onActionClick={() => onDelete(id)}
          color="#00aeef"
          kind={KIND.custom}
        >
          #{name}
        </Tag>
      ))}
    </>
  );
};

export default PropertyTagList;
