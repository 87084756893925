import React from "react";
import ReactDOM from "react-dom";
import { Amplify } from "aws-amplify";
import { AuthProvider } from "@virtualcapital/utogi-auth-library";
import LogRocket from "logrocket";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import config from "./config";

Amplify.configure(config.amplify);

if (config.LOG_ROCKET) LogRocket.init(config.LOG_ROCKET);

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider
      callbackURL="/authenticate"
      module="MAPS"
      appURL={config.APP_URL}
      domainProtocol={config.DOMAIN_PROTOCOL}
      domainSuffix={config.DOMAIN_SUFFIX}
    >
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
