export enum ProspectViewMode {
  PROSPECT = "prospect",
  USER = "user",
  REMINDER = "reminder",
}

export enum FadeType {
  LAYER_FADE = "layer-fade",
  NO_FADE = "no-fade",
}

export enum ColorFilters {
  ALL_COLORS = "all-colours",
  HIDE_FIRST_COLOR = "hide-first-colour",
  HIDE_SECOND_COLOR = "hide-second-colour",
  ALL_AS_FIRST_COLOR = "all-as-first-colour",
  ALL_AS_SECOND_COLOR = "all-as-second-colour",
}

export enum MarkerMode {
  PEOPLE = "people",
  TAG = "tag",
}

export const MAP_CENTER_KEY = "@utogi/center";
export const RECENTLY_SELECTED_MAP_KEY = "@utogi/recently-selected";
