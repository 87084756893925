import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Skeleton } from "baseui/skeleton";
import React, { useState } from "react";
import { useStyletron } from "styletron-react";
import { toaster } from "baseui/toast";

// components
import Toggle from "../../toggle";

// constants
import { NotificationSettings } from "../../../constants/notifications";

const GET_NOTIFICATION_SETTINGS = gql`
  {
    notification {
      notificationSettings {
        settings
      }
    }
  }
`;

const UPDATE_SETTINGS = gql`
  mutation updateSettings($settings: UpdateNotificationSetting!) {
    notification {
      updateSettings(settings: $settings) {
        success
      }
    }
  }
`;

const NotificationSetting = () => {
  const [css] = useStyletron();
  const [settings, setSettings] = useState<any>(
    Object.values(NotificationSettings).reduce(
      (current, key) => ({ ...current, [key]: true }),
      {}
    )
  );

  const { loading } = useQuery(GET_NOTIFICATION_SETTINGS, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      if (data?.notification?.notificationSettings?.settings) {
        const {
          notification: {
            notificationSettings: { settings: current },
          },
        } = data;
        setSettings({ ...settings, ...current });
      }
    },
  });

  const [updateSettings] = useMutation(UPDATE_SETTINGS, {
    onError: () => {
      toaster.negative("Failed to update read status", {});
    },
  });

  const onSettingsChange = (setting: NotificationSettings, status: boolean) => {
    const payload = { ...settings, [setting]: status };
    setSettings(payload);
    updateSettings({ variables: { settings: payload } });
  };

  if (loading) {
    return (
      <div>
        <Skeleton
          height="20px"
          width="300px"
          animation
          overrides={{
            Root: {
              style: { marginBottom: "10px" },
            },
          }}
        />
        <Skeleton
          height="24px"
          width="300px"
          animation
          overrides={{
            Root: {
              style: { marginBottom: "10px" },
            },
          }}
        />
        <Skeleton
          height="24px"
          width="300px"
          animation
          overrides={{
            Root: {
              style: { marginBottom: "10px" },
            },
          }}
        />
        <Skeleton
          height="24px"
          width="300px"
          animation
          overrides={{
            Root: {
              style: { marginBottom: "20px" },
            },
          }}
        />
        <Skeleton
          height="20px"
          width="300px"
          animation
          overrides={{
            Root: {
              style: { marginBottom: "10px" },
            },
          }}
        />
        <Skeleton
          height="24px"
          width="300px"
          animation
          overrides={{
            Root: {
              style: { marginBottom: "10px" },
            },
          }}
        />
        <Skeleton
          height="24px"
          width="300px"
          animation
          overrides={{
            Root: {
              style: { marginBottom: "20px" },
            },
          }}
        />
        <Skeleton
          height="20px"
          width="300px"
          animation
          overrides={{
            Root: {
              style: { marginBottom: "10px" },
            },
          }}
        />
        <Skeleton
          height="24px"
          width="300px"
          animation
          overrides={{
            Root: {
              style: { marginBottom: "10px" },
            },
          }}
        />
        <Skeleton
          height="24px"
          width="300px"
          animation
          overrides={{
            Root: {
              style: { marginBottom: "20px" },
            },
          }}
        />
      </div>
    );
  }

  return (
    <div>
      <div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Web</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong
                  className={css({ padding: "5px 0px 5px", display: "block" })}
                >
                  Contacts
                </strong>
              </td>
              <td />
              <td />
            </tr>
            <tr>
              <td>Reminder Due (High Priority)</td>
              <td>
                <Toggle
                  checked={
                    settings[NotificationSettings.HIGH_PRIORITY_REMINDER_DUE]
                  }
                  onChange={(e: any) =>
                    onSettingsChange(
                      NotificationSettings.HIGH_PRIORITY_REMINDER_DUE,
                      e.target.checked
                    )
                  }
                />
              </td>
              <td />
            </tr>
            <tr>
              <td>Reminder Due (Medium Priority)</td>
              <td>
                <Toggle
                  checked={
                    settings[NotificationSettings.MEDIUM_PRIORITY_REMINDER_DUE]
                  }
                  onChange={(e: any) =>
                    onSettingsChange(
                      NotificationSettings.MEDIUM_PRIORITY_REMINDER_DUE,
                      e.target.checked
                    )
                  }
                />
              </td>
              <td />
            </tr>
            <tr>
              <td>Reminder Due (Low Priority)</td>
              <td>
                <Toggle
                  checked={
                    settings[NotificationSettings.LOW_PRIORITY_REMINDER_DUE]
                  }
                  onChange={(e: any) =>
                    onSettingsChange(
                      NotificationSettings.LOW_PRIORITY_REMINDER_DUE,
                      e.target.checked
                    )
                  }
                />
              </td>
              <td />
            </tr>
            <tr>
              <td>Contact Import Processed</td>
              <td>
                <Toggle
                  checked={
                    settings[NotificationSettings.CONTACT_IMPORT_STATUS_CHANGE]
                  }
                  onChange={(e: any) =>
                    onSettingsChange(
                      NotificationSettings.CONTACT_IMPORT_STATUS_CHANGE,
                      e.target.checked
                    )
                  }
                />
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <strong
                  className={css({ padding: "5px 0px 5px", display: "block" })}
                >
                  Email
                </strong>
              </td>
              <td />
              <td />
            </tr>
            <tr>
              <td>Email Sync Completed</td>
              <td>
                <Toggle
                  checked={settings[NotificationSettings.EMAIL_SYNC_COMPLETED]}
                  onChange={(e: any) =>
                    onSettingsChange(
                      NotificationSettings.EMAIL_SYNC_COMPLETED,
                      e.target.checked
                    )
                  }
                />
              </td>
              <td />
            </tr>
            <tr>
              <td>New Email Received</td>
              <td>
                <Toggle
                  checked={settings[NotificationSettings.EMAIL_RECEIVED]}
                  onChange={(e: any) =>
                    onSettingsChange(
                      NotificationSettings.EMAIL_RECEIVED,
                      e.target.checked
                    )
                  }
                />
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <strong
                  className={css({ padding: "5px 0px 5px", display: "block" })}
                >
                  Daily Overview
                </strong>
              </td>
              <td />
              <td />
            </tr>
            <tr>
              <td>Receive a daily email of your outstanding reminders</td>
              <td />
              <td>
                <Toggle
                  checked={
                    settings[NotificationSettings.RECEIVE_DAILY_UPDATE_EMAILS]
                  }
                  onChange={(e: any) =>
                    onSettingsChange(
                      NotificationSettings.RECEIVE_DAILY_UPDATE_EMAILS,
                      e.target.checked
                    )
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NotificationSetting;
