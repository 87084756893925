import React, { useState } from "react";
import { toaster } from "baseui/toast";
import { gql, useQuery } from "@apollo/client";
import { Option } from "baseui/select";
import { Modal, SIZE, ROLE } from "baseui/modal";

import AddCampaignUsers from "containers/campaign/AddCampaignUsers";
import MapUser from "../../map/MapUser";
import Button from "components/Button";
import WithScrollbar from "helpers/WithScrollbar";

import {
  canInviteUsers,
  canSetUpMap,
} from "../../../helpers/permission/map-setup";
import { IUser } from "interfaces/user";

import { CampaignUsersHeader } from "./CampaignUsers.styles";

import { MapUserRoleType, MapUserType } from "../../../types/map";
import { MapRolePermissions } from "../../../types/permissions";

const QUERY = gql`
  query getData {
    map {
      roles {
        id
        name
        canDelete
        canEdit
      }
    }
  }
`;

type Props = {
  users: MapUserType[];
  onSave: Function;
  disableEdit?: boolean;
  onUserRoleChange: (id: string, role: MapUserRoleType) => void;
  permissions: MapRolePermissions;
};

const CampaignUsers = ({
  users,
  onSave,
  disableEdit = false,
  onUserRoleChange,
  permissions,
}: Props) => {
  const campaignUsers = users || [];
  const [displayAddUser, toggleDisplayUser] = useState(false);
  const [roles, setRoles] = useState<Option[]>([]);

  const { loading: isRolesLoading } = useQuery(QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setRoles(data?.map?.roles || []);
    },
  });

  const hasInviteUsersPermission = canInviteUsers(permissions);
  const onUsersSaved = (users: any) => {
    onSave(users);
    toggleDisplayUser(false);
  };

  const onUserDeleted = (userId: string) => {
    const users = campaignUsers.filter(({ id }: IUser) => userId !== id);
    onSave(users);
    toaster.positive("User removed from campaign successfully!", {});
  };

  const hasEditPermission = canSetUpMap(permissions);

  return (
    <div>
      <CampaignUsersHeader>
        <Button
          disabled={disableEdit}
          onClick={() => {
            if (!hasInviteUsersPermission) {
              toaster.negative("You do not have permission to add users.", {});
              return;
            }
            toggleDisplayUser(!displayAddUser);
          }}
        >
          Add User
        </Button>
      </CampaignUsersHeader>
      <WithScrollbar height="calc(100vh - 320px)">
        <div className="campaign-users-list">
          {campaignUsers.map((user: MapUserType) => (
            <MapUser
              user={user}
              onRoleChange={onUserRoleChange}
              roles={roles}
              isRolesLoading={isRolesLoading}
              permissions={permissions}
              key={user.id}
              onDeleted={onUserDeleted}
              hasEditPermission={hasEditPermission}
              onMapCreate={false}
            />
          ))}
        </div>
      </WithScrollbar>
      <Modal
        onClose={() => toggleDisplayUser(false)}
        isOpen={displayAddUser}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
        overrides={{
          Close: {
            style: () => ({ display: "none" }),
          },
        }}
      >
        <AddCampaignUsers
          onSave={onUsersSaved}
          selectedUsers={campaignUsers}
          onClickCancel={() => toggleDisplayUser(false)}
          roles={roles}
          isRolesLoading={isRolesLoading}
          permissions={permissions}
        />
      </Modal>
    </div>
  );
};

export default CampaignUsers;
