export enum PropertySelectionType {
  ID = "id",
  AREA = "area",
}
export const TIMELINE_CREATION_TYPE = {
  BOTH: "both",
  PROPERTY: "property",
  CONTACT: "contact",
};
export const REGIONS = [
  { label: "Auckland", id: "Auckland" },
  { label: "Bay of Plenty", id: "Bay of Plenty" },
  { label: "Canterbury", id: "Canterbury" },
  { label: "Gisborne", id: "Gisborne" },
  { label: "Hawke's Bay", id: "Hawke's Bay" },
  { label: "Manawatū-Whanganui", id: "Manawatū-Whanganui" },
  { label: "Marlborough", id: "Marlborough" },
  { label: "Nelson", id: "Nelson" },
  { label: "Northland", id: "Northland" },
  { label: "Otago", id: "Otago" },
  { label: "Southland", id: "Southland" },
  { label: "Taranaki", id: "Taranaki" },
  { label: "Tasman", id: "Tasman" },
  { label: "Waikato", id: "Waikato" },
  { label: "Wellington", id: "Wellington" },
  { label: "West Coast", id: "West Coast" },
];

export const SELECT_VALUE = [
  { label: "1", id: 1 },
  { label: "2", id: 2 },
  { label: "3", id: 3 },
  { label: "4", id: 4 },
  { label: "5", id: 5 },
  { label: "6", id: 6 },
  { label: "7", id: 7 },
  { label: "8", id: 8 },
  { label: "9", id: 9 },
  { label: "10", id: 10 },
];
