const config = {
  TOKEN: process.env.REACT_APP_TOKEN || "",
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY || "",
  HELP_HERO_APP_ID: process.env.REACT_APP_A_HELP_HERO_APP_ID || "",
  APP_URL: process.env.REACT_APP_APP_URL || "",
  DOMAIN_SUFFIX: process.env.REACT_APP_DOMAIN_SUFFIX || "",
  DOMAIN_PROTOCOL: process.env.REACT_APP_DOMAIN_PROTOCOL || "",
  OPTIMIZE_SDK: process.env.REACT_APP_ROLLOUT_KEY || "",
  amplify: {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT,
    aws_appsync_region: process.env.REACT_APP_APPSYNC_REGION,
    aws_appsync_authenticationType: "API_KEY",
    aws_appsync_apiKey: process.env.REACT_APP_APPSYNC_API_KEY,
  },
  LOG_ROCKET: process.env.REACT_APP_LOG_ROCKET || null,
};

export default config;
