//import './attachment-file.scss';
import { Icon } from "@virtualcapital/utogi-ui-library";
import { styled } from "baseui";
import { Button, SIZE } from "baseui/button";
import filesize from "filesize";
import React from "react";

const AttachmentContainer = styled("div", () => ({
  display: "flex",
  minWidth: "45%",
  backgroundColor: "#f5f5f5",
  fontSize: "14px",
  justifyContent: "space-between",
  padding: "7px 8px",
  margin: "4px 0 3px",
  fontWeight: 600,
  alignItems: "center",
}));

const AttachmentName = styled("span", () => ({
  color: "#15c",
}));

const AttachmentSize = styled("span", () => ({
  color: "#777",
  paddingLeft: "8px",
}));

const AttachmentUploading = styled("span", () => ({
  fontSize: "10px",
  backgroundColor: "#6787e3",
  color: "#ffffff",
  display: "flex",
  alignItems: "center",
  padding: "0 5px",
}));

type Props = {
  name: string;
  size: number;
  uploading: boolean;
  onRemove: Function;
};

const AttachmentFile = ({ name, size, uploading, onRemove }: Props) => {
  return (
    <AttachmentContainer>
      <div>
        <AttachmentName>{name}</AttachmentName>
        <AttachmentSize>({filesize(size)})</AttachmentSize>
      </div>
      {uploading && <AttachmentUploading>uploading...</AttachmentUploading>}
      {!uploading && (
        <Button
          onClick={() => onRemove()}
          size={SIZE.compact}
          overrides={{
            BaseButton: {
              style: () => ({
                color: "#777",
                border: 0,
                fontWeight: 600,
                backgroundColor: "transparent",
                ":hover": {
                  backgroundColor: "transparent",
                },
              }),
            },
          }}
        >
          <Icon icon="close" />
        </Button>
      )}
    </AttachmentContainer>
  );
};

export default AttachmentFile;
