import React from "react";
import moment from "moment";
import { Icon, IconButton } from "@virtualcapital/utogi-ui-library";
import { hasPermission, permissions } from "@virtualcapital/utogi-auth-library";
import { GoogleMap, Marker } from "@react-google-maps/api";

import { ReactComponent as TagIcon } from "assets/icons/tag.svg";

import PropertyTagList from "../PropertyTagList";

import { IPropertyDetails, Property } from "interfaces/property";

import "./ViewPropertyDetails.scss";

type Props = Property & {
  enableEdit: Function;
  details: IPropertyDetails;
};

const ViewPropertyDetails = ({
  enableEdit,
  details: {
    bedRooms,
    bathRooms,
    livingRooms,
    garage,
    landArea,
    floorArea,
    name,
    street,
    streetNumber,
    suburb,
    city,
    region,
    country,
    propertyTitle,
    cTFullDescription,
    localAuthority,
    zoning,
    cv,
    cvDate,
    rates,
    unitNumber,
    propertyBoundaries,
    coordinates,
    type,
    cTNumber,
    lot,
    DP,
  },
  unit,
  property,
}: Props) => {
  return (
    <div className="view-property-details">
      <div className="view-property-details-feature-list">
        <div className="view-property-details-feature-list-item">
          <Icon icon="bedroom" />
          {bedRooms ? bedRooms : "-"}
        </div>
        <div className="view-property-details-feature-list-item">
          <Icon icon="bathroom" />
          {bathRooms ? bathRooms : "-"}
        </div>
        <div className="view-property-details-feature-list-item">
          <Icon icon="living-room" />
          {livingRooms ? livingRooms : "-"}
        </div>
        <div className="view-property-details-feature-list-item">
          <Icon icon="garage" />
          {garage ? garage : "-"}
        </div>
        <div className="view-property-details-feature-list-item">
          <Icon icon="house-size" />
          {floorArea ? floorArea : "-"}
        </div>
        <div className="view-property-details-feature-list-item">
          <Icon icon="land-size" />
          {landArea ? landArea : "-"}
        </div>
        {hasPermission(permissions.general.EDIT_PROPERTY_DETAILS) && (
          <div className="view-property-details-feature-list-item">
            <IconButton icon="edit" onClick={() => enableEdit()} />
          </div>
        )}
      </div>
      <div className="view-property-details-list">
        <div className="view-property-details-list-item">
          <div className="view-property-details-list-item-section">
            <div className="view-property-details-feature-list-item-name property-meta">
              <div>
                Property Name
                <span>{name ? name : "-"}</span>
              </div>
            </div>
            <table>
              <tbody>
                <tr>
                  <td className="property-meta">
                    Street Number
                    <span>{streetNumber ? streetNumber : "-"}</span>
                  </td>
                  <td className="property-meta">
                    Unit Number<span>{unitNumber ? unitNumber : "-"}</span>
                  </td>
                </tr>
                <tr className="property-meta">
                  <td>
                    Street Name<span>{street ? street : "-"}</span>
                  </td>
                  <td>
                    Title Type<span>{type ? type : "-"}</span>
                  </td>
                </tr>
                <tr>
                  <td className="property-meta">
                    Suburb<span>{suburb ? suburb : "-"}</span>
                  </td>
                  <td className="property-meta">
                    City<span>{city ? city : "-"}</span>
                  </td>
                </tr>
                <tr>
                  <td className="property-meta">
                    Region/State<span>{region ? region : "-"}</span>
                  </td>
                  <td className="property-meta">
                    Country<span>{country ? country : "-"}</span>
                  </td>
                </tr>
                <tr>
                  <td className="property-meta" colSpan={2}>
                    Property Boundaries
                    <span>
                      {propertyBoundaries
                        ? propertyBoundaries.coordinates.map(
                            ([lat, long]: any, key: number) =>
                              `${key > 0 ? " - " : ""}${lat}, ${long}`
                          )
                        : "-"}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="view-property-details-list-item-section">
            {coordinates && coordinates.lat && coordinates.lng && (
              <div style={{ width: "280px", position: "relative" }}>
                <GoogleMap
                  zoom={17}
                  center={coordinates}
                  mapContainerStyle={{ height: "320px", width: "100%" }}
                  options={{
                    streetViewControl: false,
                    zoomControl: false,
                    scrollwheel: false,
                    styles: [
                      {
                        featureType: "poi",
                        stylers: [{ visibility: "off" }],
                      },
                    ],
                  }}
                >
                  <Marker position={coordinates} />
                </GoogleMap>
              </div>
            )}
          </div>
        </div>
        <div className="view-property-details-list-item">
          <div className="view-property-details-list-item-section">
            <table>
              <tbody>
                <tr>
                  <td className="property-meta">
                    Property Title
                    <span>{propertyTitle ? propertyTitle : "-"}</span>
                  </td>
                </tr>

                <tr>
                  <td className="property-meta">
                    CT Number
                    <span>{cTNumber ? cTNumber : "-"}</span>
                  </td>
                  <td className="property-meta">
                    Lot<span>{lot ? lot : "-"}</span>
                  </td>
                </tr>

                <tr>
                  <td className="property-meta">
                    DP
                    <span>{DP ? DP : "-"}</span>
                  </td>
                </tr>

                <tr>
                  <td className="property-meta" colSpan={2}>
                    CT Full Description
                    <span>{cTFullDescription ? cTFullDescription : "-"}</span>
                  </td>
                </tr>
                <tr>
                  <td className="property-meta">
                    Local Authority
                    <span>{localAuthority ? localAuthority : "-"}</span>
                  </td>
                  <td className="property-meta">
                    Zoning<span>{zoning ? zoning : "-"}</span>
                  </td>
                </tr>
                <tr>
                  <td className="property-meta">
                    CV<span>{cv ? cv : "-"}</span>
                  </td>
                  <td className="property-meta">
                    CV Date
                    <span>
                      {cvDate
                        ? moment(new Date(cvDate)).format("DD MMMM YYYY")
                        : "-"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="property-meta">
                    Rates<span>{rates ? rates : "-"}</span>
                  </td>
                  <td className="property-meta" />
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="view-property-details-list-item-section"
            style={{
              backgroundImage: `url(${require("assets/images/missing-property.jpg")})`,
              backgroundPosition: "center",
            }}
          />
        </div>
        <div className="view-property-details-list-item">
          <div className="view-property-details-list-item-section">
            <div className="tags-section">
              <div className="tags-section-icon">
                <TagIcon width="32px" height="32px" />
              </div>
              <div className="tags-section-list">
                <div className="tags-section-list-header">Tags</div>
                <div className="tags-section-list-body">
                  <PropertyTagList unit={unit} property={property} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPropertyDetails;
