import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { AuthContext } from "@virtualcapital/utogi-auth-library";
import { toaster } from "baseui/toast";
import { Spinner } from "baseui/spinner";
import { Option, Value } from "baseui/select";

import Input from "components/base-input/base-input";
import TextArea from "components/base-textarea/base-textarea";
import SelectWithStyling from "../../../components/select";
import MapUser from "../../map/MapUser";

import { MapUserRoleType } from "../../../types/map";
import { FormRow } from "./styles";
import { canBeTheOwner } from "../../../helpers/permission/map-setup";
import Button from "components/Button";

export enum PrivacyLevel {
  PRIVATE = "private",
  SHARED = "shared",
}

const QUERY = gql`
  query getData {
    map {
      roles {
        id
        name
        canDelete
        canEdit
      }
    }
  }
`;

type Props = {};

const privacyOptions = [
  {
    label: "Private (Default)",
    id: PrivacyLevel.PRIVATE,
  },
  {
    label: "Shared",
    id: PrivacyLevel.SHARED,
  },
];

const CreateNewMap: React.FC<Props> = () => {
  const GET_USERS = gql`
    query GetUsersListQuery {
      user {
        id
        name
      }
    }
  `;

  const CREATE_MAP = gql`
    mutation(
      $name: String!
      $description: String!
      $isPrivate: Boolean
      $users: [MapUserInput]!
    ) {
      map {
        campaign {
          create(
            name: $name
            description: $description
            isPrivate: $isPrivate
            users: $users
          ) {
            id
          }
        }
      }
    }
  `;

  const { register, handleSubmit, errors, control } = useForm();
  const [users, setUsers] = useState<Value>([]);
  const [roles, setRoles] = useState<Option[]>([]);
  const [privacy, setPrivacy] = useState<Value>([privacyOptions[0]]);
  const history = useHistory();
  const {
    authData: { user: currentUser },
  } = useContext(AuthContext);

  const [createMap, { loading: isOnCreate }] = useMutation(CREATE_MAP, {
    onError: () => {
      toaster.negative("Error occurred while creating map.", {});
    },
    onCompleted: (data) => {
      if (data?.map?.campaign?.create?.id) {
        history.push(`/maps/view/${data.map.campaign.create.id}`);
        toaster.positive("Map created successfully!", {});
      }
    },
  });

  const onSubmit = ({ name, description }: any) => {
    if (users.length < 1) {
      toaster.negative("At least one user required.", {});
      return;
    }
    let hasRoleError = false;
    users.map(({ role }) => {
      if (!role) {
        hasRoleError = true;
      }
      return true;
    });
    if (hasRoleError) {
      toaster.negative("Select a role for all users.", {});
      return;
    }
    createMap({
      variables: {
        name,
        description,
        isPrivate: privacy[0] && privacy[0].id === PrivacyLevel.PRIVATE,
        users: users.map(({ id, role }: any) => ({
          id,
          role: role.id,
        })),
      },
    });
  };

  const { loading, data } = useQuery(GET_USERS);

  const canBeOwner = canBeTheOwner();

  const { loading: isRolesLoading } = useQuery(QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setRoles(data?.map?.roles || []);
    },
  });

  useEffect(() => {
    if (roles.length > 0) {
      let ownerRole, creatorRole;
      roles.map((role) => {
        if (!role.canDelete && !role.canEdit) {
          ownerRole = role;
        }
        if (!role.canDelete && role.canEdit) {
          creatorRole = role;
        }
        return role;
      });

      setUsers([
        {
          ...currentUser,
          role: canBeOwner ? ownerRole : creatorRole,
        },
      ]);
    }
  }, [canBeOwner, currentUser, roles]);

  if (loading || !data) {
    return <Spinner />;
  }

  const onUserRoleChange = (userId: string, role: MapUserRoleType) => {
    if (!users) return;
    const changedUsers = users.map((user: Option) => {
      if (user.id === userId) {
        return {
          ...user,
          role,
        };
      }
      return user;
    });
    setUsers(changedUsers);
  };

  const { user } = data;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormRow>
        <Input
          type="text"
          id="name"
          name="name"
          placeholder="Enter Map Name"
          ref={register({
            required: true,
            maxLength: { value: 50, message: "Maximum 50 characters." },
            pattern: {
              value: /^[0-9a-zA-Z ]{1,60}$/i,
              message: "Only letters and numbers are allowed.",
            },
          })}
          error={errors.name}
          maxTextLength={50}
        />
      </FormRow>
      <FormRow>
        <Controller
          name="description"
          rules={{ required: true, maxLength: 250 }}
          control={control}
          render={(props) => (
            <TextArea
              error={errors.description}
              placeholder="Enter Description"
              maxTextLength={250}
              rows={8}
              {...props}
            />
          )}
        />
      </FormRow>
      {canBeOwner && (
        <FormRow>
          <SelectWithStyling
            labelKey="name"
            onChange={({ value }) => setUsers(value)}
            options={user}
            placeholder="Add users to Map"
            value={users}
            multi={true}
          />
        </FormRow>
      )}
      {users.map((user: any) => (
        <MapUser
          key={user.id}
          user={user}
          onRoleChange={onUserRoleChange}
          roles={roles}
          isRolesLoading={isRolesLoading}
          hasEditPermission={true}
          onMapCreate={true}
        />
      ))}
      <br />
      <br />
      <FormRow>
        <label>Activity Privacy</label>
        <SelectWithStyling
          options={privacyOptions}
          placeholder="Activity Privacy"
          value={privacy}
          onChange={({ value }) => setPrivacy(value)}
          clearable={false}
        />
      </FormRow>
      <Button type="submit" disabled={isOnCreate}>
        Save
      </Button>
    </form>
  );
};

export default CreateNewMap;
