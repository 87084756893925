import React, { useState } from "react";
import { toaster } from "baseui/toast";

import CampaignLayerList from "components/campaign/campaignLayerList";
import LayerEditor from "containers/campaign/LayerEditor";

import { ILayer } from "interfaces/campaign";

import "./CampaignLayers.scss";
import Drawer from "../../../components/drawer";

type Props = {
  layers: ILayer[];
  deleteLayer: Function;
  addLayer: Function;
  editLayer?: Function;
  disableEdit?: boolean;
  canEdit: boolean;
};

const CampaignLayers = ({
  layers,
  deleteLayer,
  addLayer,
  editLayer = () => {},
  disableEdit = false,
  canEdit,
}: Props) => {
  const [layer, onLayerEdit] = useState<any>({
    show: false,
    layer: null,
    onEdit: false,
    editedRow: null,
  });

  const modifyLayerHandler = (modified: any) => {
    layer.onEdit ? editLayer(modified, layer.editedRow) : addLayer(modified);
    onLayerEdit({ layer: null, show: false, onEdit: false, editedRow: null });
  };

  const editLayerHandler = ({ row, ...layer }: any) => {
    onLayerEdit({ layer, show: true, onEdit: true, editedRow: row });
  };

  return (
    <div className="campaign-layers">
      <CampaignLayerList
        disableEdit={disableEdit}
        layers={layers}
        deleteLayer={deleteLayer}
        canEdit={canEdit}
        createNew={() => {
          if (!canEdit) {
            return toaster.negative(
              "You don't have permissions to create new layers",
              {}
            );
          }
          onLayerEdit({
            layer: {
              id: null,
              name: "",
              type: null,
              addTransition: false,
              addFade: false,
              transitionTime: 0,
              fadeTime: 0,
              primaryColor: "",
              secondaryColor: "",
            },
            show: true,
            onEdit: false,
            editedRow: null,
          });
        }}
        editLayer={editLayerHandler}
      />
      <Drawer
        isOpen={layer.show}
        autoFocus
        onClose={() =>
          onLayerEdit({
            layer: null,
            show: false,
            onEdit: false,
            editedRow: null,
          })
        }
        header="Activity/Layer"
        closeable={true}
      >
        <LayerEditor onSave={modifyLayerHandler} layer={layer.layer} />
      </Drawer>
    </div>
  );
};

export default CampaignLayers;
