import React, { useCallback, useEffect, useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import { Redirect, useHistory } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { Loader } from "@virtualcapital/utogi-ui-library";
import { toaster } from "baseui/toast";

import config from "config";

import PropertyPopup from "../../prospect/PropertyPopup";
import MultiSelectMode from "../MultiSelectMode";
import ActionBar from "../../map/ActionBar";
import MapLayers from "components/prospect/MapLayers";

import { useMap } from "context/map/use-map";
import { Coordinate } from "context/map/map.types";
import { PropertyPopupType } from "constants/prospect";

import PropertyModal from "containers/prospect/PropertyModal";
import AddTagsAndLayers, { AddTo } from "containers/prospect/AddTagsAndLayers";

import { event } from "../../../events";
import {
  ON_LAYERS_AND_TAGS_ADDED,
  ON_TIMELINE_EVENT_MODIFIED,
} from "../../../events/prospect";

import { canAccessContacts } from "../../../helpers/permission/common";

import "./SelectedMap.scss";
import { useWindowDimensions } from "hooks/use-window-dimensions";
import { PopupProvider } from "../../../context/popup/use-popup";
import { AlertProvider } from "../../../context/alert/use-alert";
import Drawer from "components/drawer";

const libraries: any = ["drawing"];

const GET_CAMPAIGN = gql`
  query($id: String!) {
    map {
      campaign {
        byId(id: $id) {
          permissions
        }
      }
    }
  }
`;

type Props = {
  isAllMapMode: boolean;
};

const SelectedMap: React.FC<Props> = ({ isAllMapMode = false }) => {
  const {
    setMapCenter,
    center,
    pointerOnMap,
    selectedMaps,
    isMapSelected,
    setSelectedMaps,
    setSelectedArea,
    resetSelectedMap,
    recentlySelectedMap,
    toggleShowEdgeProperties,
    viewMode: { isSatelliteView },
    contactCoordinates,
  } = useMap();

  const [selectedProperty, setSelectedProperty] = useState<any>(null);
  const [isOnMultiSelectMode, toggleMultiSelectMode] = useState(false);
  const [zoom, setZoom] = useState<any>(19);
  const [currentMap, setCurrentMap] = useState<any>(null);
  const [area, setArea] = useState<any>(null);
  const [addTagsAndLayers, setAddTagsAndLayers] = useState<null | AddTo>(null);
  const history = useHistory();
  const hasContactPermission = canAccessContacts();
  const { setViewMode, viewMode } = useMap();
  const { width } = useWindowDimensions();

  useEffect(() => {
    const isDefaultLocation =
      JSON.stringify(center) ===
      JSON.stringify({ lat: -40.66104466155806, lng: 172.38870630040552 });
    if (isDefaultLocation) {
      setZoom(6);
    }
  }, [center]);

  const getArea = () => {
    const ne = currentMap.getBounds().getNorthEast();
    const sw = currentMap.getBounds().getSouthWest();
    return {
      ne: { lat: ne.lat(), lng: ne.lng() },
      sw: { lat: sw.lat(), lng: sw.lng() },
    };
  };

  useEffect(() => {
    const closePopUp = () => {
      setAddTagsAndLayers(null);
      toggleMultiSelectMode(false);
      toggleShowEdgeProperties({ showEdgeProperties: false });
    };

    if (!isMapSelected && recentlySelectedMap) {
      setSelectedMaps({ selectedMaps: [recentlySelectedMap] });
    }

    event.subscribe(ON_LAYERS_AND_TAGS_ADDED, closePopUp);
    if (!hasContactPermission) {
      setViewMode({ ...viewMode, isPeopleView: false });
    }
    return () => {
      event.unsubscribe(ON_LAYERS_AND_TAGS_ADDED, closePopUp);
    };
    // eslint-disable-next-line
  }, []);

  const { loading, data } = useQuery(GET_CAMPAIGN, {
    variables: {
      id: selectedMaps && selectedMaps.length > 0 ? selectedMaps[0].id : "",
    },
    fetchPolicy: "no-cache",
    skip: !selectedMaps || selectedMaps.length === 0,
    onError: () => {
      toaster.negative(
        "Campaign doesn't exist of you do not have permission to access.",
        {}
      );
      resetSelectedMap();
      history.push("/maps");
    },
  });

  const onLoad = useCallback((map) => {
    setCurrentMap(map);
  }, []);

  const onIdle = () => {
    if (currentMap) {
      const mapCenter = currentMap.getCenter();
      const currentZoom = currentMap.getZoom();
      if (zoom !== currentZoom) setZoom(currentZoom);
      const currentCenter = { lat: mapCenter.lat(), lng: mapCenter.lng() };
      if (JSON.stringify(currentCenter) !== JSON.stringify(center)) {
        setMapCenter({ center: currentCenter });
      }
      const area = getArea();
      setArea(area);
    }
  };

  const onClick = ({ latLng: { lat, lng } }: any): any => {
    if (!isOnMultiSelectMode) {
      if (!selectedProperty) {
        setSelectedProperty(null);
        if (zoom < 16) {
          toaster.negative(
            "You have to Zoom IN to perform the actions on properties.",
            {}
          );
          return;
        }
        setSelectedProperty({
          lat: lat(),
          lng: lng(),
          type: PropertyPopupType.PROPERTY_POPUP,
        });
      } else {
        setSelectedProperty(null);
      }
    }
  };

  const closePopup = () => {
    setSelectedProperty(null);
  };

  if (!center) {
    return (
      <div className="prospect-loader-container">
        <div className="prospect-loader" />
      </div>
    );
  }

  const getPropertyPopup = () => {
    if (selectedProperty !== null) {
      const { type, payload } = selectedProperty;
      if (type === PropertyPopupType.PROPERTY_DETAILS) {
        return (
          <PropertyModal
            units={payload.units}
            onClose={closePopup}
            onAction={() => {}}
            isAllMapMode={isAllMapMode}
            openAddTagsAndLayers={setAddTagsAndLayers}
            permissions={permissions}
          />
        );
      } else if (type === PropertyPopupType.PROPERTY_POPUP) {
        if (width > 768) {
          return (
            <InfoWindow
              options={{ closeBoxURL: "", enableEventPropagation: true }}
              position={selectedProperty}
              onCloseClick={() => setSelectedProperty(null)}
            >
              <PropertyPopup
                closePopup={closePopup}
                openAddTagsAndLayers={(payload) => {
                  setAddTagsAndLayers(payload);
                  closePopup();
                }}
                onPropertyOpen={(units: any[], heading: string) =>
                  setSelectedProperty({
                    ...selectedProperty,
                    type: PropertyPopupType.PROPERTY_DETAILS,
                    payload: { units, heading },
                  })
                }
                lat={selectedProperty.lat}
                lng={selectedProperty.lng}
                permissions={permissions}
                isAllMapMode={isAllMapMode}
              />
            </InfoWindow>
          );
        } else {
          return (
            <PropertyPopup
              closePopup={closePopup}
              openAddTagsAndLayers={(payload) => {
                setAddTagsAndLayers(payload);
                closePopup();
              }}
              onPropertyOpen={(units: any[], heading: string) =>
                setSelectedProperty({
                  ...selectedProperty,
                  type: PropertyPopupType.PROPERTY_DETAILS,
                  payload: { units, heading },
                })
              }
              lat={selectedProperty.lat}
              lng={selectedProperty.lng}
              permissions={permissions}
              isAllMapMode={isAllMapMode}
            />
          );
        }
      }
    }
  };

  const toggleMultiSelectModeHandler = (payload: boolean) => {
    toggleMultiSelectMode(payload);
    setSelectedArea({ selectedArea: null });
  };

  const refetch = () => {
    setTimeout(() => {
      event.dispatch(ON_TIMELINE_EVENT_MODIFIED);
    }, 2000);
  };

  if (!isMapSelected && !recentlySelectedMap) return <Redirect to="/maps" />;

  const permissions = data?.map?.campaign?.byId?.permissions || {};
  return (
    <div className="prospect">
      <AlertProvider>
        <PopupProvider refetch={refetch}>
          <LoadScript
            googleMapsApiKey={config.GOOGLE_API_KEY}
            libraries={libraries}
          >
            <GoogleMap
              zoom={zoom}
              center={center}
              onIdle={onIdle}
              onZoomChanged={onIdle}
              onClick={onClick}
              mapContainerStyle={{
                height: `calc(100vh - ${width > 768 ? "70px" : "55px"})`,
                width: "100%",
              }}
              onLoad={onLoad}
              mapTypeId={isSatelliteView ? "satellite" : "roadmap"}
              options={{
                streetViewControl: false,
                zoomControl: false,
                scrollwheel: false,
                mapTypeControl: false,
                fullscreenControl: false,
                styles: [
                  {
                    featureType: "poi",
                    stylers: [{ visibility: "off" }],
                  },
                ],
              }}
            >
              {loading ? (
                <Loader isLoading={true} />
              ) : (
                <>
                  {getPropertyPopup()}
                  {pointerOnMap && pointerOnMap.coordinates && (
                    <Marker position={pointerOnMap.coordinates} />
                  )}
                  {area !== null && (
                    <MapLayers
                      area={area}
                      onLayerClick={onClick}
                      isAllMapMode={isAllMapMode}
                      zoom={zoom}
                    />
                  )}
                  {isOnMultiSelectMode && (
                    <MultiSelectMode
                      openAddTagsAndLayers={setAddTagsAndLayers}
                      toggleMultiSelectMode={toggleMultiSelectModeHandler}
                      permissions={permissions}
                    />
                  )}
                  {contactCoordinates.length > 0 &&
                    (contactCoordinates as Array<Coordinate>).map(
                      (coordinates: Coordinate, index) => (
                        <Marker key={index} position={coordinates} />
                      )
                    )}
                </>
              )}
            </GoogleMap>
          </LoadScript>
          <Drawer
            isOpen={addTagsAndLayers !== null}
            autoFocus
            onClose={() => setAddTagsAndLayers(null)}
            header="Add Tags &amp; Layers"
          >
            {addTagsAndLayers !== null && (
              <AddTagsAndLayers
                addTo={addTagsAndLayers}
                onClose={() => setAddTagsAndLayers(null)}
                permissions={permissions}
              />
            )}
          </Drawer>
          {!loading && (
            <ActionBar
              zoom={zoom}
              handleZoomLevel={setZoom}
              isOnMultiSelectMode={isOnMultiSelectMode}
              toggleMultiSelectMode={toggleMultiSelectModeHandler}
              permissions={permissions}
              isAllMapMode={isAllMapMode}
            />
          )}
        </PopupProvider>
      </AlertProvider>
    </div>
  );
};

export default SelectedMap;
