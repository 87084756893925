import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { PLACEMENT } from "baseui/popover";
import { Spinner } from "baseui/spinner";
import { StatefulTooltip } from "baseui/tooltip";
import config from "config";
import { useHover } from "helpers/use-hover";
import { INotification } from "interfaces/notifications";
import React from "react";
import { useStyletron } from "styletron-react";
import { NotificationSettings } from "../../../constants/notifications";
import { Modules } from "../AppLink";

const UPDATE_READ_STATUS = gql`
  mutation updateReadStatus($id: ID!, $read: Boolean!) {
    notification {
      update(id: $id, read: $read) {
        success
      }
    }
  }
`;

const Notification: React.FC<INotification & { refetch: Function }> = ({
  title,
  description,
  read,
  id,
  refetch,
  type,
  reference,
  module,
  inactive,
}) => {
  const [css] = useStyletron();
  const [hoverRef, hovered] = useHover();

  const [updateReadStatus, { loading }] = useMutation(UPDATE_READ_STATUS, {
    onCompleted: (data) => {
      if (data?.notification?.update?.success) {
        refetch();
      }
    },
  });

  const onReadStatusChange = () => {
    updateReadStatus({ variables: { id, read: !read } });
  };

  const domainProtocol = config.DOMAIN_PROTOCOL;
  const domainSuffix = config.DOMAIN_SUFFIX;

  const onClicked = async () => {
    if (!read) {
      await updateReadStatus({ variables: { id, read: !read } });
    }
    if (inactive) {
      return;
    }
    switch (type) {
      case NotificationSettings.HIGH_PRIORITY_REMINDER_DUE:
        module === Modules.MARKETING
          ? (window.location.href = `${domainProtocol}marketing.${domainSuffix}/campaign/property/${reference}/`)
          : (window.location.href = `${domainProtocol}contacts.${domainSuffix}/contacts/${reference}`);
        break;
      case NotificationSettings.MEDIUM_PRIORITY_REMINDER_DUE:
        module === Modules.MARKETING
          ? (window.location.href = `${domainProtocol}marketing.${domainSuffix}/campaign/property/${reference}/`)
          : (window.location.href = `${domainProtocol}contacts.${domainSuffix}/contacts/${reference}`);
        break;
      case NotificationSettings.LOW_PRIORITY_REMINDER_DUE:
        module === Modules.MARKETING
          ? (window.location.href = `${domainProtocol}marketing.${domainSuffix}/campaign/property/${reference}/`)
          : (window.location.href = `${domainProtocol}contacts.${domainSuffix}/contacts/${reference}`);
        break;
      case NotificationSettings.CONTACT_IMPORT_STATUS_CHANGE:
        window.location.href = `${domainProtocol}contacts.${domainSuffix}/import-history/${reference}`;
        break;
      case NotificationSettings.EMAIL_SYNC_COMPLETED:
        window.location.href = `${domainProtocol}email.${domainSuffix}/inbox/${reference}`;
        break;
      case NotificationSettings.EMAIL_RECEIVED:
        window.location.href = `${domainProtocol}email.${domainSuffix}/inbox/${reference}`;
        break;
      case NotificationSettings.PROPERTY_CAMPAIGN_STATUS_CHANGE:
        window.location.href = `${domainProtocol}marketing.${domainSuffix}/campaign/property/${reference}/`;
        break;
      case NotificationSettings.PROPERTY_CAMPAIGN_FACEBOOK_AD_STATUS_CHANGE:
        window.location.href = `${domainProtocol}marketing.${domainSuffix}/campaign/property/${reference}/online/facebook`;
        break;
      case NotificationSettings.FACEBOOK_ACCOUNT_EXPIRY:
        window.location.href = `${domainProtocol}marketing.${domainSuffix}/settings/personal/integrations/facebook`;
        break;
      case NotificationSettings.PROPERTY_CAMPAIGN_OPEN_HOME_ADDED_BUYERS:
        window.location.href = `${domainProtocol}marketing.${domainSuffix}/campaign/property/${reference}/buyers`;
        break;
      case NotificationSettings.PROPERTY_CAMPAIGN_PROPERTY_FILE_DOWNLOADED:
        window.location.href = `${domainProtocol}marketing.${domainSuffix}/campaign/property/${reference}/buyers`;
        break;
      case NotificationSettings.PROPERTY_CAMPAIGN_CAMPAIGN_EXPIRY:
        window.location.href = `${domainProtocol}marketing.${domainSuffix}/campaign/property/${reference}/`;
        break;
      case NotificationSettings.GENERAL_CAMPAIGN_STATUS_CHANGE:
        window.location.href = `${domainProtocol}marketing.${domainSuffix}/campaign/general/${reference}/`;
        break;
      case NotificationSettings.GENERAL_CAMPAIGN_FACEBOOK_AD_STATUS_CHANGE:
        window.location.href = `${domainProtocol}marketing.${domainSuffix}/campaign/general/${reference}/online/facebook`;
        break;
    }
  };

  let icon = null;

  switch (type) {
    case NotificationSettings.HIGH_PRIORITY_REMINDER_DUE:
      icon = `reminder-high.svg`;
      break;
    case NotificationSettings.MEDIUM_PRIORITY_REMINDER_DUE:
      icon = `reminder-low.svg`;
      break;
    case NotificationSettings.LOW_PRIORITY_REMINDER_DUE:
      icon = `reminder-low.svg`;
      break;
    case NotificationSettings.CONTACT_IMPORT_STATUS_CHANGE:
      icon = `contact-import.svg`;
      break;
    case NotificationSettings.PROPERTY_CAMPAIGN_STATUS_CHANGE:
      icon = `property-campaign.svg`;
      break;
    case NotificationSettings.GENERAL_CAMPAIGN_STATUS_CHANGE:
      icon = `general-campaign.svg`;
      break;
    case NotificationSettings.PROPERTY_CAMPAIGN_FACEBOOK_AD_STATUS_CHANGE:
      icon = `facebook-ad.svg`;
      break;
    case NotificationSettings.GENERAL_CAMPAIGN_FACEBOOK_AD_STATUS_CHANGE:
      icon = `facebook-ad.svg`;
      break;
    case NotificationSettings.FACEBOOK_ACCOUNT_EXPIRY:
      icon = `facebook-ad.svg`;
      break;
    case NotificationSettings.PROPERTY_CAMPAIGN_OPEN_HOME_ADDED_BUYERS:
      icon = `property-campaign.svg`;
      break;
    case NotificationSettings.PROPERTY_CAMPAIGN_PROPERTY_FILE_DOWNLOADED:
      icon = `property-campaign.svg`;
      break;
    case NotificationSettings.PROPERTY_CAMPAIGN_CAMPAIGN_EXPIRY:
      icon = `property-campaign.svg`;
      break;
  }

  const getStatusButton = () => {
    if (loading) {
      return <Spinner $size="20px" $borderWidth="3px" $color="#01aeef" />;
    }
    if (inactive && read) {
      return null;
    }
    return (
      <StatefulTooltip
        content={() => <div>Mark as {read ? "unread" : "read"}</div>}
        placement={PLACEMENT.left}
        popoverMargin={10}
      >
        <button
          className={css({
            outline: "none",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            border: `1px solid ${hovered ? "#ddd" : "#ffffff"}`,
          })}
          onClick={onReadStatusChange}
        >
          <div
            className={css({
              margin: "5px",
              width: "8px",
              height: "8px",
              backgroundColor: read ? "#ffffff" : "#01aeef",
              borderRadius: "50%",
            })}
          />
        </button>
      </StatefulTooltip>
    );
  };

  return (
    <div
      className={css({
        display: "flex",
        padding: "5px",
        margin: "0 -5px 5px",
        backgroundColor: hovered ? "#f9f9f9" : "#fff",
      })}
      ref={hoverRef}
    >
      {icon && (
        <div>
          <img
            alt={title}
            className={css({
              width: "45px",
              paddingRight: "10px",
              filter: inactive ? "grayscale(1)" : "",
            })}
            src={require(`assets/icons/notifications/${icon}`).default}
          />
        </div>
      )}
      <div
        className={css({
          width: "calc(100% - 20px)",
          paddingRight: "5px",
        })}
      >
        <button
          onClick={onClicked}
          disabled={inactive}
          className={css({
            fontWeight: 600,
            fontSize: "13px",
            textAlign: "left",
            display: "block",
            color: inactive ? "#a0a0a0" : "inherit",
          })}
        >
          {title}
        </button>
        {description && (
          <button
            onClick={onClicked}
            disabled={inactive}
            className={css({
              fontSize: "12px",
              textAlign: "left",
              color: inactive ? "#a0a0a0" : "inherit",
            })}
          >
            {description}
          </button>
        )}
      </div>
      {getStatusButton()}
    </div>
  );
};

export default Notification;
