import { useMutation } from "@apollo/react-hooks";
import { Icon } from "@virtualcapital/utogi-ui-library";
import { gql } from "apollo-boost";
import { useStyletron } from "baseui";
import { toaster } from "baseui/toast";
import moment from "moment";
import React, { useState } from "react";

import {
  Body,
  Box,
  Container,
  Header,
  HeaderAction,
  TimelineLeft,
  TimelineLeftIcon,
  TimelineLeftLine,
} from "./styles";
import { Order } from "./TimelineSection";
import { TimelineLayerProps } from "./interface";
import { useHover } from "../../../helpers/use-hover";
import { useAlert } from "../../../context/alert/use-alert";
import { ON_TIMELINE_EVENT_MODIFIED } from "../../../events/prospect";
import { event } from "../../../events";
import Button from "../../../components/Button";
import { KIND } from "baseui/button";
import { LayerDeleteType } from "./contants";

const DELETE_LAYER = gql`
  mutation($reference: String!) {
    property {
      deleteLayer(id: $reference) {
        id
        createdAt
      }
    }
  }
`;

type Props = TimelineLayerProps & {
  order: Order;
  isLast: boolean;
};

const TimelineLayer: React.FC<Props> = ({
  order,
  isLast,
  data,
  id,
  createdAt,
  reference,
  createdUser: { name: createdBy },
}) => {
  const [isExpand, setIsExpand] = useState(false);

  const [hoverRef, hovered] = useHover();
  const { openAlert } = useAlert();

  const [css] = useStyletron();

  const getLayerBackground = ({
    primaryColor,
    secondaryColor,
    addFade,
    addTransition,
    transitionTime,
    fadeTime,
  }: {
    primaryColor: string;
    secondaryColor: string;
    addFade: boolean;
    addTransition: boolean;
    transitionTime: number;
    fadeTime: number;
  }) => {
    const color2 = secondaryColor === "" ? primaryColor : secondaryColor;
    if (addTransition) {
      const layerTimeline = transitionTime + fadeTime;
      const transitionPercentage = addFade
        ? (transitionTime / layerTimeline) * 100
        : 50;
      return `linear-gradient(to right, ${primaryColor} ${transitionPercentage}%,
      ${color2} ${transitionPercentage}%, ${addFade ? "#fff" : color2} 100%)`;
    } else {
      return addFade
        ? `linear-gradient(to right, ${primaryColor} 0%, ${primaryColor} 50%, #ffffff 100%)`
        : primaryColor;
    }
  };

  const layerBackground = data?.isDeleted
    ? "#8a8787"
    : getLayerBackground({
        primaryColor: data?.primaryColor,
        secondaryColor: data?.secondaryColor,
        addFade: data?.addFade,
        addTransition: data?.addTransition,
        transitionTime: data?.transitionTime,
        fadeTime: data?.fadeTime,
      });

  const campaignRunningDays = moment().diff(moment(createdAt), "days");
  const campaignTimeline = data?.transitionTime + data?.fadeTime;
  const cursorPosition =
    campaignRunningDays > campaignTimeline
      ? "100%"
      : `${(campaignRunningDays / campaignTimeline) * 100}%`;

  const [deleteLayer, { loading: deleteMutationLoading }] = useMutation(
    DELETE_LAYER,
    {
      errorPolicy: "all",
      onError: (error) => {
        if (error && error.message) {
          toaster.negative("Error occurred while deleting layer", {});
        }
      },
      onCompleted: () => {
        toaster.positive("Layer deleted successfully", {});
        event.dispatch(ON_TIMELINE_EVENT_MODIFIED);
      },
    }
  );

  const onDelete = () => {
    openAlert({
      content: "Are you sure you want to delete this layer?",
      primaryButton: {
        title: "Delete",
        action: () => deleteLayer({ variables: { reference } }),
      },
      secondaryButton: { title: "Cancel", action: () => {} },
    });
  };

  return (
    <Container>
      <TimelineLeft>
        <TimelineLeftIcon>
          <Icon icon="schedule" />
        </TimelineLeftIcon>
        {!(isLast && order === Order.PAST) && <TimelineLeftLine />}
      </TimelineLeft>
      <Box ref={hoverRef} $isLast={isLast}>
        <Header>
          <div className={css({ display: "flex" })}>
            {data?.name} {data?.isDeleted ? "Deleted" : "Added"}
            <span
              className={css({
                paddingLeft: "10px",
                borderLeft: "1px solid #00000016",
                marginLeft: "10px",
              })}
            >
              Created on&nbsp;
              {moment(new Date(createdAt)).format("DD MMMM YYYY")}
              &nbsp;by {createdBy}
            </span>
          </div>
          <HeaderAction $hovered={true}>
            <Button
              onClick={onDelete}
              isLoading={deleteMutationLoading}
              overrides={{
                BaseButton: {
                  style: {
                    color: "#ff0000",
                    backgroundColor: "#f6d8d8",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    marginRight: "5px",
                    ":hover": {
                      color: "#ff0000",
                      backgroundColor: "#fbb9b9",
                    },
                    minWidth: "auto",
                    "@media screen and (min-width: 540px)": {
                      opacity: hovered ? 1 : 0,
                    },
                  },
                },
              }}
            >
              <Icon icon="delete" />
            </Button>
            <Button
              onClick={() => setIsExpand(!isExpand)}
              kind={KIND.tertiary}
              overrides={{
                BaseButton: {
                  style: {
                    color: "#00aeef",
                    minWidth: "auto",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    "@media screen and (min-width: 540px)": {
                      opacity: hovered || isExpand ? 1 : 0,
                    },
                  },
                },
              }}
            >
              {isExpand ? (
                <Icon icon="chevron-up" />
              ) : (
                <Icon icon="chevron-down" />
              )}
            </Button>
          </HeaderAction>
        </Header>
        {isExpand && (
          <Body>
            {data?.isDeleted && (
              <div>
                <span>
                  {data?.deletedFrom === LayerDeleteType.CAMPAIGN
                    ? "Campaign"
                    : "Property"}{" "}
                  layer deletion
                </span>
              </div>
            )}
            <div>
              <span
                className={css({
                  paddingRight: "10px",
                  marginRight: "10px",
                  borderRight: "1px solid",
                })}
              >
                {data?.layerTypeTitle}
              </span>
              <span
                className={css({
                  paddingRight: "10px",
                  marginRight: "10px",
                  borderRight: "1px solid",
                })}
              >
                {data?.name}
              </span>
              <span>{data?.campaignName}</span>
            </div>
            Status:
            {data?.isDeleted && data?.deletedDate
              ? ` Deleted on 
                ${moment(new Date(data?.deletedDate)).format(
                  "DD MMMM YYYY"
                )} by 
                ${createdBy}`
              : "Active"}
            <div className={css({ position: "relative", marginTop: "5px" })}>
              <div
                className={css({
                  height: "15px",
                  display: "inline-block",
                  width: "100%",
                  background: layerBackground,
                })}
              />
              <div
                className={css({
                  height: "25px",
                  width: "2px",
                  background: "#F99C1C",
                  position: "absolute",
                  zIndex: 100,
                  top: "-5px",
                  left: cursorPosition,
                })}
              />
            </div>
          </Body>
        )}
      </Box>
    </Container>
  );
};

export default TimelineLayer;
