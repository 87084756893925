import React, { useState } from "react";

import EditPropertyDetails from "../EditPropertyDetails/EditPropertyDetails";
import ViewPropertyDetails from "../ViewPropertyDetails";
import WithScrollbar from "helpers/WithScrollbar";

import { IPropertyDetails, Property } from "interfaces/property";

import "./PropertyDetails.scss";

type Props = Property & {
  details: IPropertyDetails;
  refetch: Function;
};

const PropertyDetails = ({ property, unit, details, refetch }: Props) => {
  const [onEdit, toggleOnEdit] = useState(false);
  return (
    <div className="property-details">
      <WithScrollbar noScrollX height="calc(100vh - 220px)">
        {onEdit ? (
          <EditPropertyDetails
            details={details}
            property={property}
            unit={unit}
            onComplete={() => toggleOnEdit(false)}
            refetch={refetch}
          />
        ) : (
          <ViewPropertyDetails
            details={details}
            unit={unit}
            property={property}
            enableEdit={() => toggleOnEdit(true)}
          />
        )}
      </WithScrollbar>
    </div>
  );
};

export default PropertyDetails;
