import { gql } from "apollo-boost";

export const FETCH_DRAFT = gql`
  query fetchDraft($id: String) {
    email {
      draft(id: $id) {
        id
        thread
        subject
        from {
          name
          email
        }
        to {
          name
          email
        }
        cc {
          name
          email
        }
        bcc {
          name
          email
        }
        date
        unread
        starred
        snippet
        body
        folder {
          name
        }
        labels {
          name
        }
        files {
          id
          filename
          size
        }
      }
    }
  }
`;

export const SEND_EMAIL = gql`
  mutation sendEmail(
    $draft: String
    $subject: String
    $body: String
    $to: [GraphQLEmailAndName]
    $cc: [GraphQLEmailAndName]
    $bcc: [GraphQLEmailAndName]
    $trackingPayload: GraphQLEmailTracking
    $files: [String]
  ) {
    email {
      compose(
        draft: $draft
        subject: $subject
        body: $body
        to: $to
        cc: $cc
        bcc: $bcc
        file: $files
        isTracking: true
        trackingPayload: $trackingPayload
      ) {
        id
        thread
      }
    }
  }
`;

export const DELETE_DRAFT = gql`
  mutation deleteDraft($ids: [String]!) {
    email {
      deleteDraft(drafts: $ids) {
        success
        message
      }
    }
  }
`;

export const SAVE_TO_DRAFT = gql`
  mutation createDraft(
    $subject: String
    $body: String
    $to: [GraphQLEmailThreadParticipantInput]
    $cc: [GraphQLEmailThreadParticipantInput]
    $bcc: [GraphQLEmailThreadParticipantInput]
    $files: [String]
  ) {
    email {
      createDraft(
        subject: $subject
        body: $body
        to: $to
        cc: $cc
        bcc: $bcc
        file: $files
      ) {
        id
        subject
        body
        version
      }
    }
  }
`;

export const SAVE_REPLY_TO_DRAFT = gql`
  mutation createReplyToDraft(
    $subject: String
    $body: String
    $to: [GraphQLEmailThreadParticipantInput]
    $cc: [GraphQLEmailThreadParticipantInput]
    $bcc: [GraphQLEmailThreadParticipantInput]
    $replyTo: [GraphQLEmailAndName]
    $replyToMessageId: String
    $files: [String]
  ) {
    email {
      createDraft(
        subject: $subject
        body: $body
        to: $to
        cc: $cc
        bcc: $bcc
        file: $files
        replyTo: $replyTo
        replyToMessageId: $replyToMessageId
      ) {
        id
        subject
        body
        version
      }
    }
  }
`;

export const UPDATE_DRAFT = gql`
  mutation updateDraft(
    $subject: String!
    $body: String!
    $to: [GraphQLEmailThreadParticipantInput]
    $cc: [GraphQLEmailThreadParticipantInput]
    $bcc: [GraphQLEmailThreadParticipantInput]
    $files: [String]
    $id: String!
  ) {
    email {
      updateDraft(
        draft: { id: $id }
        subject: $subject
        body: $body
        to: $to
        cc: $cc
        bcc: $bcc
        files: $files
      ) {
        id
        version
      }
    }
  }
`;

export const GET_SELECTED_CONTACTS = gql`
  query GetSelectedContactQuery($ids: [ID]) {
    contact {
      list(ids: $ids) {
        contacts {
          firstName
          email
        }
      }
    }
  }
`;

export const GET_MODULE_SETTINGS = gql`
  {
    contact {
      getContactModuleSettings {
        IS_EMAIL_TIMELINE_RECORD_SHARED_WITH_COMPANY
        IS_EMAIL_TIMELINE_RECORD_SHARED_WITH_OFFICE
        IS_EMAIL_TIMELINE_RECORD_SHARED
      }
    }
  }
`;
