import React from "react";
import { Checkbox } from "@virtualcapital/utogi-ui-library";

import "./addLayerListItem.scss";
import { getLayerBackground } from "../../../helpers/helpers";

const addLayerListItem = ({
  id,
  name,
  type,
  primaryColor,
  secondaryColor,
  addFade,
  addTransition,
  transitionTime,
  fadeTime,
  toggleLayerSelect,
  isSelected,
}: any) => {
  const layerBackground = getLayerBackground({
    primaryColor,
    secondaryColor,
    addFade,
    addTransition,
    transitionTime,
    fadeTime,
  });

  return (
    <div className="add-layer-list-item box">
      <div className="add-layer-list-item-content">
        <div className="color-block" style={{ background: layerBackground }} />
        <div className="add-layer-list-item-title">
          {name}

          <span>{type && type.title ? type.title : ""}</span>
        </div>
      </div>

      <div className="add-layer-list-item-actions">
        <Checkbox
          onChange={(value: boolean) =>
            toggleLayerSelect({ id, type: type.id }, value)
          }
          value={isSelected}
        />
      </div>
    </div>
  );
};

export default addLayerListItem;
