import { styled } from "baseui";
import { KIND as ButtonKind, SIZE as ButtonSize } from "baseui/button";
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  ROLE,
  SIZE,
} from "baseui/modal";
import React, { createContext, ReactNode, useContext, useReducer } from "react";
import { reducer } from "./alert.reducer";
import { AlertActions, AlertState, OpenAlertPayload } from "./alert.types";

const AlertContext = createContext(
  {} as {
    openAlert: (payload: OpenAlertPayload) => void;
    closeAlert: () => void;
  }
);

const INITIAL_STATE: AlertState = {
  isOpen: false,
};

const useMapActions = (initialAlert: AlertState = INITIAL_STATE) => {
  const [state, dispatch] = useReducer(reducer, initialAlert);

  const openAlert = (payload: OpenAlertPayload) => {
    dispatch({
      type: AlertActions.OPEN_ALERT,
      payload,
    });
  };

  const closeAlert = () => {
    dispatch({
      type: AlertActions.CLOSE_ALERT,
    });
  };

  return {
    state,
    openAlert,
    closeAlert,
  };
};

const ModalBodyContent = styled("div", () => ({
  marginTop: "42px",
  fontSize: "0.9rem",
  color: "#707070",
}));

export const AlertProvider = ({ children }: { children: ReactNode }) => {
  const {
    state: { isOpen, title, content, primaryButton, secondaryButton },
    openAlert,
    closeAlert,
  } = useMapActions();

  return (
    <AlertContext.Provider
      value={{
        openAlert,
        closeAlert,
      }}
    >
      {children}
      <Modal
        onClose={() => closeAlert()}
        closeable
        isOpen={isOpen}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
      >
        {title && <ModalHeader>{title}</ModalHeader>}
        <ModalBody>
          <ModalBodyContent>{content}</ModalBodyContent>
        </ModalBody>
        <ModalFooter>
          {secondaryButton?.action && (
            <ModalButton
              kind={ButtonKind.secondary}
              size={ButtonSize.compact}
              onClick={() => {
                secondaryButton.action();
                closeAlert();
              }}
              overrides={{
                BaseButton: {
                  style: () => ({
                    paddingLeft: "35px",
                    paddingRight: "35px",
                    borderRadius: "5px",
                  }),
                },
              }}
            >
              {secondaryButton?.title || "Cancel"}
            </ModalButton>
          )}
          {primaryButton && primaryButton?.action && (
            <ModalButton
              onClick={() => {
                primaryButton.action();
                closeAlert();
              }}
              overrides={{
                BaseButton: {
                  style: () => ({
                    paddingLeft: "35px",
                    paddingRight: "35px",
                    borderRadius: "5px",
                  }),
                },
              }}
              size={ButtonSize.compact}
              kind={ButtonKind.primary}
            >
              {primaryButton?.title || "Okay"}
            </ModalButton>
          )}
        </ModalFooter>
      </Modal>
    </AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);
