import React from "react";
import { Icon, Switch, IconButton } from "@virtualcapital/utogi-ui-library";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import Draggable from "react-draggable";
import { toaster } from "baseui/toast";

import { PERMISSION_ERROR } from "constants/message";

import { useMap } from "context/map/use-map";

import Button from "components/Button";

import { event } from "../../../events";
import { ON_LAYERS_AND_TAGS_ADDED } from "../../../events/prospect";

import { canAddNotes } from "../../../helpers/permission/map";
import { useWindowDimensions } from "hooks/use-window-dimensions";

import { MapRolePermissions } from "../../../types/permissions";

import "./MultiSelectOptions.scss";
import { usePopup } from "context/popup/use-popup";
import { PropertySelectionType } from "constants/property";
import { ActivePopup } from "context/popup/popup.types";

type Props = {
  openAddTagsAndLayers: Function;
  onClose: any;
  permissions: MapRolePermissions;
};

const MultiSelectOptions = ({
  openAddTagsAndLayers,
  onClose,
  permissions,
}: Props) => {
  const { openPopup } = usePopup();
  const {
    selectedArea: selected,
    toggleShowEdgeProperties,
    showEdgeProperties,
    selectedMaps,
  } = useMap();
  const { width } = useWindowDimensions();

  const toggleShowEdgePropertiesHandlers = (showEdgeProperties: boolean) => {
    toggleShowEdgeProperties({ showEdgeProperties });
  };
  const DELETE_LAST_LAYER = gql`
    mutation($campaign: String!, $selectedArea: [GraphQLCoordinateInput]) {
      property {
        deleteLastLayers(selectedArea: $selectedArea, campaign: $campaign) {
          message
        }
      }
    }
  `;

  //TODO: remove when package ts definition is updated
  const selectedArea: any = selected;

  const [deleteLastLayer, { loading }] = useMutation(DELETE_LAST_LAYER, {
    onError: () => {
      toaster.negative(
        "Error occurred while updating the adding layers and tags.",
        {}
      );
    },
    onCompleted: () => {
      toaster.positive("Layers removed successfully!", {});
      event.dispatch(ON_LAYERS_AND_TAGS_ADDED);
    },
  });

  const hasAddNotesPermission = canAddNotes(permissions);

  const campaign = selectedMaps[0].id;

  const Container = width > 736 ? Draggable : "div";

  return (
    <Container>
      <div className="multi-select-options-container">
        <div className="multi-select-options">
          <div className="multi-select-options-header">
            <Icon icon="chart" /> Multi Select Options
            <IconButton
              className="close-button"
              icon="close"
              onClick={onClose}
            />
          </div>
          <div className="multi-select-options-body">
            <div className="multi-select-options-body-toggle">
              <label className="form-label">Show Edge Properties </label>
              <Switch
                value={showEdgeProperties}
                onChange={toggleShowEdgePropertiesHandlers}
              />
            </div>
            <Button
              onClick={() =>
                openAddTagsAndLayers({
                  selectedArea,
                  properties: [],
                  selectionType: PropertySelectionType.AREA,
                })
              }
            >
              Add Layers &amp; Tags
            </Button>
            <Button
              onClick={() => {
                if (hasAddNotesPermission) {
                  openPopup({
                    popup: ActivePopup.NOTE,
                    campaign,
                    selectedArea,
                    selectionType: PropertySelectionType.AREA,
                  });
                  onClose();
                } else {
                  toaster.negative(PERMISSION_ERROR.ADD_REMINDERS, {});
                }
              }}
            >
              Add Note
            </Button>
            <Button
              isLoading={loading}
              onClick={() =>
                deleteLastLayer({ variables: { selectedArea, campaign } })
              }
            >
              Delete Last Layer
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default MultiSelectOptions;
