export enum BuyerStatus {
  INTERESTED = "INTERESTED",
  THINK_ABOUT_IT = "THINK_ABOUT_IT",
  NOT_INTERESTED = "NOT_INTERESTED",
  CANNOT_CONTACT = "CANNOT_CONTACT",
  HAVE_NOT_CONTACTED = "HAVE_NOT_CONTACTED",
  ENQUIRY_ONLY = "ENQUIRY_ONLY",
}

export enum CampaignStatus {
  DRAFT = "DRAFT",
  ACTIVATED = "ACTIVATED",
  WITHDRAWN = "WITHDRAWN",
  DELETED = "DELETED",
  SOLD = "SOLD",
  ACTIVE = "ACTIVE",
}

export enum AccessLevels {
  COMPANY = "company",
  OFFICE = "office",
  PRIVATE = "private",
}

export enum ComposerMode {
  NEW_EMAIL = "NEW_EMAIL",
  REPLY = "REPLY",
  REPLY_TO_ALL = "REPLY_TO_ALL",
  FORWARD = "FORWARD",
}

export enum ComposerStates {
  HIDDEN = "HIDDEN",
  FOCUSED = "FOCUSED",
  BLURRED = "BLURRED",
}

export enum LayerDeleteType {
  PROPERTY = "property",
  CAMPAIGN = "campaign",
}

export enum TimelineFilterType {
  PROPERTY = "property",
  PEOPLE = "people",
}
