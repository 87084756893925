import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useStyletron } from "baseui";
import { Button, KIND } from "baseui/button";
import { Search } from "baseui/icon";
import { Input } from "baseui/input";
import { OptionProfile, StatefulMenu } from "baseui/menu";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { ICampaignTemplate } from "interfaces/campaignTemplate";

const GET_AUTOCOMPLETE_CONTACTS = gql`
  query($keyword: String!) {
    campaignTemplate {
      list(
        type: "all"
        keyword: $keyword
        pagination: { page: 1, perPage: 5 }
      ) {
        templates {
          id
          name
          description
        }
      }
    }
  }
`;

type Props = {
  setKeyword: (keyword: string) => void;
};

const CampaignTemplateSearch: React.FC<Props> = ({ setKeyword }) => {
  const history = useHistory();
  const [css] = useStyletron();
  const wrapperRef = useRef<any>(null);

  const [keyword, setValue] = useState("");
  const [suggestions, setSuggestions] = useState<null | ICampaignTemplate[]>(
    null
  );

  const [callAutocompleteQuery, { loading }] = useLazyQuery(
    GET_AUTOCOMPLETE_CONTACTS,
    {
      onCompleted: (data) => {
        if (keyword && keyword.length > 2) {
          setSuggestions(data?.campaignTemplate?.list?.templates);
        }
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    }
  );

  const onKeydown = (e: any) => {
    if (e.key === "Enter") {
      setKeyword(keyword);
      setSuggestions(null);
    }
  };

  const onSearchClicked = (e: any) => {
    setKeyword(keyword);
    setSuggestions(null);
  };

  useEffect(() => {
    setSuggestions(null);
    if (keyword && keyword.length > 2) {
      callAutocompleteQuery({ variables: { perPage: 5, page: 1, keyword } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setSuggestions(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div
      className={css({
        position: "relative",
        flex: 1,
        "@media screen and (min-width: 769px)": {
          maxWidth: "550px",
        },
        "@media screen and (max-width: 768px)": {
          marginBottom: "10px",
        },
      })}
      ref={wrapperRef}
    >
      <Input
        value={keyword}
        onChange={(e: any) => setValue(e.target.value)}
        placeholder="Search"
        clearOnEscape
        onKeyDown={onKeydown}
        endEnhancer={() => (
          <Button
            kind={KIND.tertiary}
            isLoading={loading}
            onClick={onSearchClicked}
          >
            <Search />
          </Button>
        )}
        overrides={{
          Root: {
            style: { paddingRight: 0 },
          },
          EndEnhancer: {
            style: { paddingRight: 0, paddingLeft: 0 },
          },
        }}
      />
      {suggestions && (
        <StatefulMenu
          items={suggestions}
          onItemSelect={({ item: { id } }) =>
            history.push(`/template/view/${id}`)
          }
          overrides={{
            List: {
              style: {
                paddingTop: "5px",
                paddingBottom: "5px",
                position: "absolute",
                zIndex: 1000,
                top: "44px",
                width: "100%",
              },
            },
            Option: {
              component: OptionProfile,
              props: {
                getProfileItemLabels: ({
                  name: title,
                  description: subtitle,
                }: ICampaignTemplate) => ({
                  title,
                  subtitle,
                }),
                getProfileItemImg: () => null,
                getProfileItemImgText: () => null,
                overrides: {
                  ProfileImgContainer: { style: { display: "none" } },
                  ListItemProfile: { style: { paddingLeft: "5px" } },
                },
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default CampaignTemplateSearch;
