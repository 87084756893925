import React from "react";
import { Avatar, AvatarSizes } from "@virtualcapital/utogi-ui-library";

import config from "config";

import "./campaignUserListItem.scss";

type Props = {
  image: string | null;
  name: string;
  action?: any;
};

const campaignUserListItem = ({ image, name, action }: Props) => {
  const processProfileImage = (image: string) => {
    return `${config.DOMAIN_PROTOCOL}images.${config.DOMAIN_SUFFIX}/images/100x100/${image}`;
  };

  return (
    <div className="box campaign-user-list-item">
      <div className="campaign-user-list-item-left">
        <Avatar
          size={AvatarSizes.SMALL}
          src={image ? processProfileImage(image) : ""}
        />
        {name}
      </div>
      {action && action}
    </div>
  );
};

export default campaignUserListItem;
