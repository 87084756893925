import { useQuery } from "@apollo/react-hooks";
import { Spinner } from "baseui/spinner";
import { gql } from "apollo-boost";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import React, { useState, useMemo, useEffect } from "react";
import { useStyletron } from "styletron-react";
import { Value } from "baseui/select";

import ReminderList from "./ReminderList";

import { ON_REMINDER_MODIFIED } from "events/reminder";
import { event } from "../../../events";

import { IReminder } from "interfaces/reminder";

const GET_REMINDERS = gql`
  fragment fields on Reminder {
    id
    allDay
    dateTime
    name
    priority
    source
    invitedContacts
    campaign
    subtitle
  }
  query getReminders {
    reminder {
      list {
        today {
          ...fields
        }
        overdue {
          ...fields
        }
        upComing {
          ...fields
        }
      }
    }
  }
`;

const Reminders = () => {
  const [css] = useStyletron();

  const { loading, data, refetch } = useQuery(GET_REMINDERS, {
    fetchPolicy: "no-cache",
  });
  const [priority, setPriority] = useState<Value>([]);

  useEffect(() => {
    event.subscribe(ON_REMINDER_MODIFIED, () => {
      refetch();
    });
    return () => {
      event.unsubscribe(ON_REMINDER_MODIFIED);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let count = 0;
  let overdueCount = 0;
  let todayCount = 0;
  if (data?.reminder?.list) {
    const {
      reminder: {
        list: { overdue, today },
      },
    } = data;
    overdueCount = overdue.length;
    todayCount = today.length;
    count = overdueCount + todayCount;
  }

  let backgroundColor = "#9E9E9E";
  if (todayCount > 0) {
    backgroundColor = "#2dca73";
  }
  if (overdueCount > 0) {
    backgroundColor = "#ff0000";
  }

  const list = useMemo(() => {
    let overdue = [];
    let today = [];
    let upComing = [];
    if (data?.reminder?.list) {
      const {
        reminder: { list },
      } = data;
      overdue = list.overdue;
      today = list.today;
      upComing = list.upComing;
      if (priority.length > 0) {
        const [{ id: currentPriority }] = priority;
        overdue = overdue.filter(
          ({ priority }: IReminder) => currentPriority === priority
        );
        today = today.filter(
          ({ priority }: IReminder) => currentPriority === priority
        );
        upComing = upComing.filter(
          ({ priority }: IReminder) => currentPriority === priority
        );
      }
    }
    return {
      overdue,
      today,
      upComing,
    };
  }, [data, priority]);

  return (
    <div className={css({ marginRight: "15px" })}>
      <StatefulPopover
        content={
          <ReminderList
            loading={loading || !data?.reminder?.list}
            list={list}
            priority={priority}
            setPriority={setPriority}
          />
        }
        placement={PLACEMENT.bottom}
        overrides={{
          Body: {
            style: { minWidth: "250px", maxHeight: "calc(100vh - 100px)" },
          },
        }}
      >
        <div className={css({ margin: "3px" })}>
          {loading ? (
            <Spinner $size="28px" $borderWidth="5px" $color="#01aeef" />
          ) : (
            <button
              className={css({
                backgroundColor,
                alignItems: "center",
                outline: "none",
                border: "none",
                cursor: "pointer",
                color: "#fff",
                borderRadius: "50%",
                fontSize: "11px",
                lineHeight: "30px",
                width: "30px",
                textAlign: "center",
              })}
            >
              {count}
            </button>
          )}
        </div>
      </StatefulPopover>
    </div>
  );
};

export default Reminders;
