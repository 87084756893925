import React, { useContext, useEffect, useState } from "react";
import { Option, Value } from "baseui/select";
import { useStyletron } from "baseui";

import SelectWithStyling from "components/select";
import { MapUserRoleType, MapUserType } from "types/map";
import { AuthContext } from "@virtualcapital/utogi-auth-library";
import { ConfirmAlert, IconButton } from "@virtualcapital/utogi-ui-library";
import { toaster } from "baseui/toast";

import { useHover } from "../../helpers/use-hover";
import {
  canBeTheOwner,
  canUpdateTheOwner,
} from "../../helpers/permission/map-setup";

import { MapRolePermissions } from "../../types/permissions";

type Props = {
  user: MapUserType;
  onRoleChange: (id: string, role: MapUserRoleType) => void;
  roles: Option[];
  isRolesLoading: boolean;
  permissions?: MapRolePermissions;
  onDeleted?: (id: string) => void;
  hasEditPermission: boolean;
  onMapCreate: boolean;
};

export default function MapUser({
  user,
  onRoleChange,
  roles,
  isRolesLoading,
  onDeleted,
  permissions,
  hasEditPermission,
  onMapCreate,
}: Props) {
  const [selectedRoles, setSelectedRoles] = useState<Value>(
    user.role ? [user.role] : []
  );
  const [hoverRef, hovered] = useHover();
  const {
    authData: { user: currentUser },
  } = useContext(AuthContext);
  const [css] = useStyletron();

  const onRoleChangedHandler = (value: any) => {
    setSelectedRoles(value);
    onRoleChange(user.id, value[0]);
  };

  const canBeOwner = canBeTheOwner();

  useEffect(() => {
    if (JSON.stringify(user.role) !== JSON.stringify(selectedRoles[0])) {
      setSelectedRoles([user.role]);
    }
  }, [user.role, selectedRoles]);

  const hasUpdateOwnerPermission = canUpdateTheOwner(permissions);

  return (
    <div
      ref={hoverRef}
      className={css({
        width: "100%",
        backgroundColor: "#ffffff",
        borderBottom: "1px solid #e3e3e3",
        padding: "10px 0",
        "@media (min-width: 769px)": {
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
        },
      })}
    >
      <div
        className={css({
          "@media (max-width: 768px)": { marginBottom: "5px" },
        })}
      >
        {user.name}
      </div>
      <div className={css({ alignItems: "center", display: "flex" })}>
        <div
          className={css({
            width: "200px",
            maxWidth: "100%",
          })}
        >
          <SelectWithStyling
            options={roles.filter(({ canEdit, canDelete, name }) => {
              if (!canEdit && !canDelete) {
                if (onMapCreate && currentUser?.id === user.id) {
                  return canBeOwner;
                } else {
                  return hasUpdateOwnerPermission;
                }
              }
              return true;
            })}
            placeholder="Select Role"
            labelKey="name"
            value={selectedRoles}
            onChange={(params) => onRoleChangedHandler(params.value)}
            clearable={false}
            isLoading={isRolesLoading}
            disabled={!hasEditPermission}
          />
        </div>
        {onDeleted && (
          <div
            className={css({ marginLeft: "10px", opacity: hovered ? 1 : 0 })}
          >
            <IconButton
              icon="delete"
              onClick={() => {
                hasEditPermission
                  ? ConfirmAlert({
                      closeOnClickOutside: false,
                      message:
                        "Are you sure you want to remove this user from the campaign?",
                      onCancel: () => {},
                      onConfirm: () => onDeleted(user.id),
                    })
                  : toaster.negative(
                      "You do not have permission to edit layers.",
                      {}
                    );
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
