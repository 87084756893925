export enum Priority {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}

export enum ReminderSource {
  CONTACTS = "CONTACTS",
  MARKETING = "MARKETING",
}
