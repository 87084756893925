import React from "react";
import {
  GroupTitle,
  Section,
  ToggleContainer,
  ToggleLabel,
} from "./ManageRole/styles";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import Toggle from "../../../components/toggle";
import { MapPermission } from "../../../constants/permissions";

type Props = {
  values: any;
  setValues?: Function;
};

const PermissionList: React.FC<Props> = ({ values, setValues }) => {
  const getToggle = (key: MapPermission, title: string) => (
    <ToggleContainer>
      <Toggle
        checked={values[key]}
        onChange={(e: any) =>
          setValues
            ? setValues({
                ...values,
                [key]: e.target.checked,
              })
            : {}
        }
      />
      <ToggleLabel>{title}</ToggleLabel>
    </ToggleContainer>
  );
  return (
    <>
      <Section>
        <FlexGrid
          flexGridColumnCount={[1, 1, 2, 5]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
        >
          <FlexGridItem>
            <GroupTitle>Users</GroupTitle>
            {getToggle(MapPermission.INVITE_OTHER_USERS, "Invite other users")}
            {getToggle(MapPermission.UPDATE_THE_OWNER, "Update the owner")}
          </FlexGridItem>
          <FlexGridItem>
            <GroupTitle>Edit Map Setup</GroupTitle>
            {getToggle(
              MapPermission.EDIT_MAP_SETUP,
              "Edit Map Setup (Eg Add, remove, layers, change name of map etc)"
            )}
          </FlexGridItem>
          <FlexGridItem>
            <GroupTitle>On the Map</GroupTitle>
            {getToggle(MapPermission.ADD_LAYERS, "Add layers")}
            {getToggle(MapPermission.ADD_TAGS, "Add tags")}
            {getToggle(MapPermission.ADD_NOTES, "Add notes")}
            {getToggle(MapPermission.VIEW_TEAM_VIEW, "View Team View")}
          </FlexGridItem>
          <FlexGridItem>
            <GroupTitle>Campaign Status</GroupTitle>
            {getToggle(
              MapPermission.UPDATE_THE_STATUS_OF_THE_MAP,
              "Update the status of the map (Eg draft, active, archive, delete)"
            )}
          </FlexGridItem>
        </FlexGrid>
      </Section>
    </>
  );
};

export default PermissionList;
