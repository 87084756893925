import { BaseProvider, createTheme } from "baseui";
import { PLACEMENT, ToasterContainer } from "baseui/toast";
import React from "react";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";

const engine = new Styletron();

const primitives = {
  textPrimary: "#9F9F9F",
  contentPrimary: "#707070",
  accent: "#00AEEF",
  accent50: "#FDEDFC",
  accent100: "#FCD3F9",
  accent200: "#F89FF3",
  accent300: "#F45AEA",
  accent400: "#F127E4",
  accent500: "#B71DAD",
  accent600: "#901788",
  accent700: "#600F5B",
};
const theme = createTheme(primitives, {
  colors: {
    borderFocus: primitives.accent,
    contentPrimary: "#707070",
    menuFontHighlighted: primitives.contentPrimary,
    menuFontSelected: primitives.contentPrimary,
    inputPlaceholder: primitives.textPrimary,
    datepickerDayFont: "#00AEEF",
    calendarForeground: "#00AEEF",
    calendarHeaderBackground: "#00AEEF",
    calendarDayForegroundPseudoSelected: "#00AEEF",
    calendarDayForegroundPseudoSelectedHighlighted: "#00AEEF",
    calendarDayForegroundSelected: "#00AEEF",
    calendarDayBackgroundSelectedHighlighted: "#00AEEF",
    buttonPrimaryFill: "#F99D1C",
    buttonPrimaryHover: "#FFB449",
    buttonPrimaryActive: "#FFC572",
    buttonSecondaryFill: "#00ADF9",
    buttonSecondaryText: "#ffffff",
    buttonSecondaryHover: "#09B5FF",
    buttonSecondaryActive: "#0079AD",
    tickFillSelected: "#2dca73",
    tickFillSelectedHover: "#2dca73",
    tickBorder: "#afafaf",
    toastInfoBackground: "#276EF1",
  },
  typography: {
    font100: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: "11px",
      fontWeight: "normal",
      lineHeight: "16px",
    },
    font200: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: "12px",
      fontWeight: "normal",
      lineHeight: "20px",
    },
    font250: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: "12px",
      fontWeight: "normal",
      lineHeight: "20px",
    },
    font300: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: "14px",
      fontWeight: "normal",
      lineHeight: "20px",
    },
    font350: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: "14px",
      fontWeight: "normal",
      lineHeight: "20px",
    },
    font400: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: "16px",
      fontWeight: "normal",
      lineHeight: "24px",
    },
    font450: {
      fontFamily: '"Poppins", sans-serif',
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
    },
    font500: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: "20px",
      fontWeight: "normal",
      lineHeight: "28px",
    },
    font600: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: "24px",
      fontWeight: "normal",
      lineHeight: "36px",
    },
    font700: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: "32px",
      fontWeight: "normal",
      lineHeight: "48px",
    },
    font800: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: "40px",
      fontWeight: "normal",
      lineHeight: "56px",
    },
    font900: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: "52px",
      fontWeight: "normal",
      lineHeight: "68px",
    },
    font1000: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: "72px",
      fontWeight: "normal",
      lineHeight: "96px",
    },
    font1100: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: "96px",
      fontWeight: "normal",
      lineHeight: "116px",
    },
  },
});

const Theme: React.FC = ({ children }) => (
  <StyletronProvider value={engine}>
    <BaseProvider theme={theme}>
      <ToasterContainer autoHideDuration={3000} placement={PLACEMENT.topRight}>
        {children}
      </ToasterContainer>
    </BaseProvider>
  </StyletronProvider>
);

export default Theme;
