import { styled } from "baseui";

export const ComposeEmail = styled("div", () => ({
  height: "100%",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "relative",
  width: "100%",
}));

export const ComposeEmailInputs = styled("div", () => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "calc(100vh - 180px)",
  cursor: "text",
}));

export const ComposeEmailInputField = styled("div", () => ({
  borderBottom: "1px solid #ddd",
  padding: "10px 0 5px 0",
}));

export const EmailEditorContainer = styled("div", () => ({
  flexGrow: 1,
  overflowY: "scroll",
  display: "flex",
  position: "relative",
}));

export const AttachmentContainer = styled("div", () => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

export const ComposeEmailDraftSave = styled("div", () => ({
  alignItems: "center",
  display: "flex",
  border: "none",
  background: "transparent",
  fontSize: "12px",
  margin: "2px 0px 2px 0px",
}));

export const SaveDraft = styled("div", ({ $hidden }: any) => ({
  display: "flex",
  alignItems: "center",
  opacity: $hidden ? 0 : 1,
  transition: "300ms all ease-in-out",
}));

export const IconContainer = styled("span", () => ({
  animation: "spin 1000ms infinite linear",
  width: "20px",
  height: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "15px",
}));

export const ActionContainer = styled("div", () => ({
  position: "relative",
  width: "100%",
  "@media (min-width: 426px)": {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export const LeftActions = styled("div", () => ({
  display: "flex",
}));
