export const CAMPAIGN_TEMPLATE_FILTERS = {
  COMPANY: "company",
  OFFICE: "office",
  ALL: "all",
  MINE: "mine",
};

export enum PermissionType {
  PRIVATE = "PRIVATE",
  OFFICE = "OFFICE",
  COMPANY = "COMPANY",
}
