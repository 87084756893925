const PREFIX = "@@prospect/";

export const ON_LAYERS_AND_TAGS_ADDED = `${PREFIX}ON_LAYERS_AND_TAGS_ADDED`;

export const ON_PROPERTY_CONTACT_ADD_SUCCESS = `${PREFIX}ON_PROPERTY_CONTACT_ADD_SUCCESS`;

export const REFETCH_CAMPAIGN_LIST = `${PREFIX}REFETCH_CAMPAIGN_LIST`;

export const ON_PROSPECT_SEARCH_VALUE_RESET = `${PREFIX}ON_PROSPECT_SEARCH_VALUE_RESET`;

export const REFETCH_PROSPECT_MAP = `${PREFIX}REFETCH_PROSPECT_MAP`;

export const ON_TIMELINE_EVENT_MODIFIED = `${PREFIX}ON_TIMELINE_EVENT_MODIFIED`;
