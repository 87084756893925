import { useMutation } from "@apollo/react-hooks";
import { Icon } from "@virtualcapital/utogi-ui-library";
import { gql } from "apollo-boost";
import { useStyletron } from "baseui";
import { SIZE } from "baseui/button";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";
import { toaster } from "baseui/toast";
import moment from "moment";
import React, { useState } from "react";
import { event } from "../../../events";
import {
  Body,
  Box,
  Container,
  Header,
  HeaderAction,
  HeaderMeta,
  TimelineLeft,
  TimelineLeftIcon,
  TimelineLeftLine,
} from "./styles";
import { Order } from "./TimelineSection";
import { TimelineNoteProps } from "./interface";
import { useHover } from "../../../helpers/use-hover";
import { useAlert } from "../../../context/alert/use-alert";
import { usePopup } from "../../../context/popup/use-popup";
import { ON_TIMELINE_EVENT_MODIFIED } from "../../../events/prospect";
import Button from "../../../components/Button";
import { ActivePopup } from "../../../context/popup/popup.types";

const LIMIT = 360;

type Props = TimelineNoteProps & {
  order: Order;
  isLast: boolean;
};

const DELETE_NOTE = gql`
  mutation($reference: ID!) {
    note {
      delete(id: $reference) {
        id
      }
    }
  }
`;

const TimelineNote: React.FC<Props> = ({
  order,
  isLast,
  reference,
  data,
  createdUser,
}) => {
  const [hoverRef, hovered] = useHover();
  const [css] = useStyletron();
  const { openAlert } = useAlert();
  const { openPopup } = usePopup();

  const [showMore, toggleShowMore] = useState(false);

  const [deleteNote, { loading: deleteMutationLoading }] = useMutation(
    DELETE_NOTE,
    {
      errorPolicy: "all",
      onError: (error) => {
        if (error && error.message) {
          toaster.negative("Error occurred while deleting note", {});
        }
      },
      onCompleted: () => {
        toaster.positive("Note deleted successfully", {});
        event.dispatch(ON_TIMELINE_EVENT_MODIFIED);
      },
    }
  );

  const onDelete = () => {
    openAlert({
      content: "Are you sure you want to delete this note?",
      primaryButton: {
        title: "Delete",
        action: () => deleteNote({ variables: { reference } }),
      },
      secondaryButton: { title: "Cancel", action: () => {} },
    });
  };

  return (
    <Container>
      <TimelineLeft>
        <TimelineLeftIcon>
          <Icon icon="file" />
        </TimelineLeftIcon>
        {!(isLast && order === Order.PAST) && <TimelineLeftLine />}
      </TimelineLeft>
      <Box ref={hoverRef} $isLast={isLast}>
        <Header>
          <div
            className={css({
              "@media screen and (min-width: 540px)": { display: "flex" },
            })}
          >
            <span>Note added</span>
            <HeaderMeta>
              Created on {moment(new Date(data.date)).format("DD MMMM YYYY")} by{" "}
              {createdUser.name}
            </HeaderMeta>
          </div>
          <HeaderAction $hovered={hovered}>
            <Button
              onClick={() =>
                openPopup({
                  reference,
                  contacts: [],
                  popup: ActivePopup.NOTE,
                })
              }
              overrides={{
                BaseButton: {
                  style: {
                    color: "#00aeef",
                    backgroundColor: "#d2f7ff",
                    marginRight: "5px",
                    minWidth: "auto",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    ":hover": {
                      color: "#00aeef",
                      backgroundColor: "#a6efff",
                    },
                  },
                },
              }}
            >
              <Icon icon="edit" />
            </Button>
            <Button
              isLoading={deleteMutationLoading}
              onClick={onDelete}
              overrides={{
                BaseButton: {
                  style: {
                    color: "#ff0000",
                    backgroundColor: "#f6d8d8",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    ":hover": {
                      color: "#ff0000",
                      backgroundColor: "#fbb9b9",
                    },
                    minWidth: "auto",
                  },
                },
              }}
            >
              <Icon icon="delete" />
            </Button>
          </HeaderAction>
        </Header>
        <Body>
          <div
            className={css({
              fontSize: "14px",
              marginBottom: "10px",
              wordBreak: "break-word",
              whiteSpace: "pre-line",
            })}
          >
            {data.note.length > LIMIT && showMore
              ? data.note
              : `${data.note.substr(0, LIMIT - 3).trim()}...`}
          </div>
          {data.note.length > LIMIT && (
            <Button
              onClick={() => toggleShowMore(!showMore)}
              size={SIZE.compact}
              overrides={{
                BaseButton: {
                  style: {
                    color: "#00aeef",
                    backgroundColor: "#d2f7ff",
                    minWidth: "auto",
                    ":hover": {
                      color: "#00aeef",
                      backgroundColor: "#a6efff",
                    },
                  },
                },
              }}
            >
              See More
            </Button>
          )}
          <RadioGroup
            value={data.accessLevel}
            onChange={(e) => {}}
            align={ALIGN.horizontal}
            overrides={{
              RadioGroupRoot: {
                style: () => ({
                  "@media screen and (max-width: 540px)": {
                    display: "block",
                  },
                  justifyContent: "flex-end",
                }),
              },
              RadioMarkOuter: {
                style: ({ $checked }) => ({
                  backgroundColor: $checked ? "#00d202" : "#666666",
                }),
              },
            }}
          >
            <Radio value="private">Private</Radio>
            <Radio value="map">Map</Radio>
            <Radio value="office">Office</Radio>
            <Radio value="company">Company</Radio>
          </RadioGroup>
        </Body>
      </Box>
    </Container>
  );
};

export default TimelineNote;
