import { styled } from "baseui";
import { Button, SIZE } from "baseui/button";
import React from "react";

const EmailAddParties = styled("div", () => ({
  position: "absolute",
  right: 0,
  top: "-10px",
}));

const PartyFieldToggles = ({
  onShowCc,
  onShowBcc,
  showCc,
  showBcc,
  isComposing,
}: any) => {
  return !isComposing ? (
    <EmailAddParties>
      {!showCc && (
        <Button
          onClick={onShowCc}
          size={SIZE.compact}
          overrides={{
            BaseButton: {
              style: () => ({
                color: "#7a7a7a",
                fontSize: "12px",
                background: "transparent",
                border: 0,
                fontWeight: "bold",
                opacity: 0.5,
                ":focus": {
                  outline: "none",
                },
                ":hover": {
                  opacity: 1,
                  background: "transparent",
                },
              }),
            },
          }}
        >
          Cc
        </Button>
      )}
      {!showBcc && (
        <Button
          onClick={onShowBcc}
          size={SIZE.compact}
          overrides={{
            BaseButton: {
              style: () => ({
                color: "#7a7a7a",
                fontSize: "12px",
                background: "transparent",
                border: 0,
                fontWeight: "bold",
                opacity: 0.5,
                ":focus": {
                  outline: "none",
                },
                ":hover": {
                  opacity: 1,
                  background: "transparent",
                },
              }),
            },
          }}
        >
          Bcc
        </Button>
      )}
    </EmailAddParties>
  ) : (
    <></>
  );
};

export default PartyFieldToggles;
