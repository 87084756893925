import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { AuthContext } from "@virtualcapital/utogi-auth-library";
import { ApolloError, gql } from "apollo-boost";
import { styled } from "baseui";
import { DatePicker } from "baseui/datepicker";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Select, Value } from "baseui/select";
import { Textarea } from "baseui/textarea";
import { TimePicker } from "baseui/timepicker";
import { toaster } from "baseui/toast";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import ContactSearch, { SelectedContacts } from "./ContactSearch";
import AppointmentLoader from "./loader/AppointmentLoader";
import { GET_PROPERTY_CONTACTS, GET_SELECTED_CONTACTS } from "./query";
import SaveButton from "../../../../components/SaveButton";
import { Contact } from "../../../../types/contact";
import { IPropertyIdentifier } from "../interface";

const Container = styled("div", () => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "space-between",
}));

const FormContainer = styled("div", () => ({
  display: "flex",
  "@media (max-width: 425px)": {
    display: "block",
  },
}));

const FormDateContainer = styled("div", () => ({
  paddingRight: "15px",
}));

const CREATE_APPOINTMENT = gql`
  mutation(
    $appointment: AppointmentInputParams
    $campaign: ID
    $properties: [PropertyInputType]
  ) {
    appointment {
      create(
        appointment: $appointment
        campaign: $campaign
        properties: $properties
      ) {
        id
      }
    }
  }
`;

const EDIT_APPOINTMENT = gql`
  mutation($id: ID!, $appointment: AppointmentInputParams) {
    appointment {
      update(id: $id, appointment: $appointment) {
        id
      }
    }
  }
`;

const GET_USERS = gql`
  query GetUsers {
    user {
      id
      name
    }
  }
`;

const GET_APPOINTMENT = gql`
  query GetAppointment($id: ID!) {
    appointment {
      get(id: $id) {
        id
        date
        startTime
        endTime
        name
        description
        assignedUsers
        invitedContacts
        repeatPeriod
        repeatUntil
      }
    }
  }
`;

type Props = {
  onCompleted: Function;
  reference?: null | string;
  campaign?: string | null;
  properties?: IPropertyIdentifier[];
};

const AppointmentModal: React.FC<Props> = ({
  onCompleted,
  reference,
  campaign,
  properties,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [date, setDate] = useState([new Date()]);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());

  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState<null | string>(null);
  const [users, setUsers] = useState<Value>([]);
  // used to load selected users in edit mode
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [invitedContacts, setInvitedContacts] = useState<SelectedContacts[]>(
    []
  );
  const [contactError, setContactError] = useState<null | string>(null);
  const { data, loading } = useQuery(GET_USERS);

  const [getAppointment] = useLazyQuery(GET_APPOINTMENT, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.appointment?.get) {
        const {
          appointment: { get },
        } = data;

        if (get?.startTime && get?.startTime !== "") {
          const time = moment(get.startTime, "HH:mm");
          setStartTime(
            moment(get.date)
              .set({
                hour: time.get("hour"),
                minute: time.get("minute"),
                second: time.get("second"),
              })
              .toDate()
          );
        }
        if (get?.endTime && get?.endTime !== "") {
          const time = moment(get.endTime, "HH:mm");
          setEndTime(
            moment(get.date)
              .set({
                hour: time.get("hour"),
                minute: time.get("minute"),
                second: time.get("second"),
              })
              .toDate()
          );
        }
        setDate([new Date(get.date)]);
        setDescription(get.description);
        setName(get.name);

        if (get?.assignedUsers && get.assignedUsers.length > 0) {
          setSelectedUsers(get.assignedUsers);
        }
        if (get?.invitedContacts && get.invitedContacts.length > 0) {
          getSelectedContacts({ variables: { ids: get.invitedContacts } });
        } else {
          setIsLoading(false);
        }
      }
    },
  });

  const [getSelectedContacts] = useLazyQuery(GET_SELECTED_CONTACTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.contact?.list?.contacts) {
        setInvitedContacts(
          data?.contact?.list?.contacts.map(
            ({ id, displayName, firstName, lastName }: Contact) => ({
              label: displayName || `${firstName} ${lastName}`,
              id,
            })
          )
        );
        setIsLoading(false);
      }
    },
  });

  const [getPropertyContacts] = useLazyQuery(GET_PROPERTY_CONTACTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.contact?.propertyContact?.length > 0) {
        setInvitedContacts(
          data.contact.propertyContact.map(
            ({ id, displayName, firstName, lastName }: Contact) => ({
              label: displayName || `${firstName} ${lastName}`,
              id,
            })
          )
        );
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    if (reference) {
      getAppointment({ variables: { id: reference } });
    } else {
      getPropertyContacts({ variables: { properties } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    authData: { user },
  } = useContext(AuthContext);

  useEffect(() => {
    if (!reference && user && user.id) {
      setUsers([{ id: user.id, label: user.name }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (selectedUsers.length > 0 && data?.user.length > 0) {
      setUsers(
        data?.user
          ?.map(({ id, name: label }: any) => ({ id, label }))
          .filter(({ id }: any) => selectedUsers.includes(id))
      );
    }
  }, [data, selectedUsers]);

  const [appointmentMutation, { loading: isSubmitting }] = useMutation(
    reference ? EDIT_APPOINTMENT : CREATE_APPOINTMENT,
    {
      onCompleted: (data) => {
        if (data?.appointment?.create?.[0]?.id) {
          onCompleted();
        }
        if (data?.appointment?.update?.id) {
          onCompleted();
        }
      },
      onError: ({ graphQLErrors }: ApolloError) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message }) => toaster.negative(message, {}));
        }
      },
    }
  );

  const onAppointmentAdded = () => {
    if (name.trim().length === 0) {
      setNameError("Name is required.");
      return;
    }
    if (invitedContacts.length === 0) {
      setContactError("At least one contact is required.");
      return;
    }
    setNameError(null);
    setContactError(null);
    appointmentMutation({
      variables: {
        ...(reference && { id: reference }),
        ...(campaign && { campaign }),
        ...(properties && { properties }),
        appointment: {
          assignedUsers: users.map(({ id }) => id),
          date: moment(new Date(date[0])).format("YYYY-MM-DD"),
          description,
          name,
          startTime: moment(startTime).format("HH:mm"),
          endTime: moment(endTime).format("HH:mm"),
          repeatPeriod: "none",
          invitedContacts: invitedContacts.map(({ id }) => id),
        },
      },
    });
  };

  if (isLoading) {
    return <AppointmentLoader />;
  }

  return (
    <Container>
      <div>
        <FormContainer>
          <FormDateContainer>
            <FormControl label="Date">
              <DatePicker
                formatString="dd/MM/yyyy"
                placeholder="DD/MM/YYYY"
                value={date}
                onChange={({ date }) =>
                  setDate(Array.isArray(date) ? date : [date])
                }
                autoFocusCalendar={false}
              />
            </FormControl>
          </FormDateContainer>
          <FormDateContainer>
            <FormControl label="Start Time">
              <TimePicker
                value={startTime}
                onChange={(date) => setStartTime(date)}
                creatable
                format="12"
              />
            </FormControl>
          </FormDateContainer>
          <div>
            <FormControl label="End Time">
              <TimePicker
                value={endTime}
                onChange={(date) => setEndTime(date)}
                creatable
                format="12"
              />
            </FormControl>
          </div>
        </FormContainer>
        <FormControl error={nameError}>
          <Input
            placeholder="Enter Appointment Name"
            value={name}
            onChange={(event) => setName(event.currentTarget.value)}
            autoFocus={true}
            error={nameError !== null}
          />
        </FormControl>
        <FormControl>
          <Textarea
            placeholder="Enter Description"
            value={description}
            onChange={(event) => setDescription(event.currentTarget.value)}
            rows={10}
          />
        </FormControl>
        <FormControl label="Record on contact timeline" error={contactError}>
          <ContactSearch
            invitedContacts={invitedContacts}
            setContacts={setInvitedContacts}
          />
        </FormControl>
        <FormControl label="Assign To Users">
          <Select
            value={users}
            onChange={({ value }: any) => setUsers(value)}
            options={
              data?.user?.map(({ id, name: label }: any) => ({ id, label })) ||
              []
            }
            isLoading={loading}
            clearable={false}
            maxDropdownHeight="300px"
            multi={true}
            overrides={{
              Tag: {
                props: {
                  overrides: {
                    Root: {
                      style: () => ({
                        backgroundColor: "#00adf9",
                      }),
                    },
                  },
                },
              },
            }}
          />
        </FormControl>
      </div>
      <SaveButton isLoading={isSubmitting} onClick={onAppointmentAdded} />
    </Container>
  );
};

export default AppointmentModal;
