import React, { useState } from "react";
import { Tabs, Switch } from "@virtualcapital/utogi-ui-library";
import { toaster } from "baseui/toast";
import { KIND, SIZE } from "baseui/button";

import CampaignLayers from "containers/campaign/CampaignLayers";
import Button from "components/Button";

import { isName } from "helpers/validations";

import "./CreateCampaignTemplate.scss";

import { PermissionType } from "../../../constants/campaignTemplate";
import BaseInput from "components/base-input/base-input";
import BaseTextarea from "components/base-textarea/base-textarea";
import { FormControl } from "baseui/form-control";

const CreateCampaignTemplate = ({
  createCampaignTemplate,
  loading,
}: {
  createCampaignTemplate: Function;
  loading: boolean;
}) => {
  const [campaign, editCampaignTemplate] = useState<any>({
    name: "",
    description: "",
    layers: [],
    accessLevels: [],
    idEdited: false,
  });

  const [errors, setErrors] = useState<any>({ name: null, description: null });
  const [permission, setPermission] = useState({
    private: true,
    office: false,
    company: false,
  });

  const addLayer = (layer: any) => {
    editCampaignTemplate({
      ...campaign,
      layers: [...campaign.layers, layer],
    });
    toaster.positive("Layer added successfully!", {});
  };

  const editLayer = (modified: any, row: number) => {
    editCampaignTemplate({
      ...campaign,
      isEdited: true,
      layers: campaign.layers.map((layer: any, key: number) => {
        if (row === key) {
          return {
            ...layer,
            ...modified,
            isEdited: layer.isAdded ? false : true,
          };
        }
        return layer;
      }),
    });
    toaster.positive("Layer updated successfully!", {});
  };

  const deleteLayer = (key: any): any => {
    editCampaignTemplate({
      ...campaign,
      layers: [
        ...campaign.layers.filter((_: any, index: number) => key !== index),
      ],
    });
    toaster.positive("Layer deleted successfully!", {});
  };

  const saveCampaignTemplateHandler = () => {
    const { name, description, layers } = campaign;
    const newErrors: any = {
      name: null,
      description: null,
    };

    let hasError = false;

    switch (true) {
      case !name || name.trim() === "":
        newErrors.name = "Name is required.";
        hasError = true;
        break;
      case name && name.trim().length > 50:
        newErrors.name = "Maximum 50 characters.";
        hasError = true;
        break;
      case !isName(name):
        newErrors.name = "Only letters and numbers are allowed.";
        hasError = true;
        break;
      default:
        hasError = false;
    }

    if (!description || description.trim() === "") {
      newErrors.description = "Description is required.";
      hasError = true;
    } else if (description && description.trim().length > 250) {
      newErrors.description = "Maximum 250 characters.";
      hasError = true;
    }

    if (layers.length === 0) {
      toaster.negative(
        "You must create at least one layer in order to save this campaign.",
        {}
      );
      hasError = true;
    }

    setErrors(newErrors);

    if (hasError) {
      return false;
    }

    let currentPermission = [PermissionType.PRIVATE];
    if (permission.company) {
      currentPermission = [PermissionType.COMPANY, PermissionType.OFFICE];
    } else if (permission.office) {
      currentPermission = [PermissionType.OFFICE];
    }

    const variables = {
      name,
      description,
      accessLevels: currentPermission,
      layers: layers.map(
        ({
          name,
          type,
          primaryColor,
          secondaryColor,
          transitionTime,
          fadeTime,
          addTransition,
          addFade,
        }: any) => ({
          name,
          primaryColor,
          secondaryColor,
          transitionTime,
          fadeTime,
          addTransition,
          addFade,
          type: type.id ? type.id : "",
        })
      ),
    };

    createCampaignTemplate({ variables });
  };

  const changePermission = (permissionType: PermissionType, val: boolean) => {
    switch (permissionType) {
      case PermissionType.PRIVATE:
        setPermission({
          office: false,
          company: false,
          private: val,
        });
        break;
      case PermissionType.COMPANY:
        setPermission({
          company: val,
          office: val,
          private: false,
        });
        break;
      case PermissionType.OFFICE:
        setPermission({
          office: val,
          company: false,
          private: false,
        });
        break;
      default:
        break;
    }
  };

  const { name, description, layers } = campaign;
  return (
    <>
      <div className="create-campaign-template">
        <div className="create-campaign-template-header">
          <div className="create-campaign-template-header-on-edit">
            <FormControl error={errors.name}>
              <BaseInput
                value={name}
                onChange={({ target: { value: name } }: any) =>
                  editCampaignTemplate({ ...campaign, name })
                }
                placeholder="Name"
                error={errors.name}
              />
            </FormControl>
            <FormControl
              caption={`${description.length}/250`}
              error={errors.description}
            >
              <BaseTextarea
                value={description}
                onChange={({ target: { value: description } }: any) =>
                  editCampaignTemplate({ ...campaign, description })
                }
                placeholder="Description"
                error={errors.description}
              />
            </FormControl>
            <div className="access-level">
              <label>Who can use this template?</label>
              <Switch
                labels={["", "Company"]}
                onChange={(val: boolean) =>
                  changePermission(PermissionType.COMPANY, val)
                }
                value={permission.company}
              />
              <Switch
                labels={["", "Office"]}
                onChange={(val: boolean) =>
                  changePermission(PermissionType.OFFICE, val)
                }
                value={permission.office}
              />
              <Switch
                labels={["", "Private"]}
                value={permission.private}
                onChange={(val: boolean) =>
                  changePermission(PermissionType.PRIVATE, val)
                }
              />
            </div>
          </div>
        </div>
        <div className="button-block">
          <Button
            isLoading={loading}
            size={SIZE.compact}
            kind={KIND.secondary}
            onClick={saveCampaignTemplateHandler}
          >
            Save Changes
          </Button>
        </div>
        <div>
          <Tabs
            items={[
              {
                title: "Layers",
                content: (
                  <CampaignLayers
                    layers={layers}
                    deleteLayer={deleteLayer}
                    addLayer={addLayer}
                    editLayer={editLayer}
                    canEdit={true}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default CreateCampaignTemplate;
