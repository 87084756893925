import { useState, useEffect } from "react";

export const useStateWithLocalStorage: any = (localStorageKey: string) => {
  const [value, setValue] = useState(
    localStorage.getItem(localStorageKey) || ""
  );
  useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value, localStorageKey]);
  return [value, setValue];
};

const arrayMoveMutate = (array: any[], from: number, to: number): any => {
  const startIndex = to < 0 ? array.length + to : to;
  const item = array.splice(from, 1)[0];
  array.splice(startIndex, 0, item);
};

export const arrayMove = (array: any[], from: number, to: number): any => {
  array = array.slice();
  arrayMoveMutate(array, from, to);
  return array;
};

export const removeEmpty = (obj: { [key: string]: any }): any => {
  if (typeof obj !== "object") return {};
  const object = { ...obj };
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === "object") removeEmpty(val);
    else if (val == null) delete object[key];
  });
  return object;
};

export const getLayerBackground = ({
  primaryColor,
  secondaryColor,
  addFade,
  addTransition,
  transitionTime,
  fadeTime,
}: {
  primaryColor: string;
  secondaryColor: string;
  addFade: boolean;
  addTransition: boolean;
  transitionTime: number;
  fadeTime: number;
}) => {
  const color2 = secondaryColor === "" ? primaryColor : secondaryColor;
  if (addTransition) {
    const layerTimeline = transitionTime + fadeTime;
    const transitionPercentage = addFade
      ? (transitionTime / layerTimeline) * 100
      : 50;
    return `linear-gradient(to right, ${primaryColor} ${transitionPercentage}%,
      ${color2} ${transitionPercentage}%, ${addFade ? "#fff" : color2} 100%)`;
  } else {
    return addFade
      ? `linear-gradient(to right, ${primaryColor} 0%, ${primaryColor} 50%, #ffffff 100%)`
      : primaryColor;
  }
};
