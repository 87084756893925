import React, { FC, useState } from "react";
import { DatePicker } from "baseui/datepicker";
import { Accordion, Panel, StatefulPanel } from "baseui/accordion";
import { SIZE } from "baseui/input";
import { Value } from "baseui/select";
import { gql, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { Spinner } from "baseui/spinner";
import { toaster } from "baseui/toast";
import { useStyletron } from "baseui";

import { ReactComponent as PinIcon } from "assets/icons/pin.svg";

import FilterPopup from "components/map/FilterPopup";
import Select from "components/select";
import Toggle from "components/toggle";
import FilterLayerList from "./FilterLayerList";
import Button from "components/Button";

import { useMap } from "context/map/use-map";

import { COLOR_FILTER_LIST, FADE_OPTIONS_LIST } from "helpers/map";

import {
  ILayer,
  ILayerFilter,
  ILayerType,
  ILayerTypeFilter,
} from "interfaces/campaign";

import {
  ActionButton,
  ColorFilter,
  FadeOption,
  Hr,
  LabelContainer,
  LayerHeader,
  SectionGroup,
  SectionGroupInner,
  SectionGroupInnerIcon,
  SectionGroupTitle,
  SectionHeader,
  SectionHeaderIcon,
  SelectContainer,
} from "./FiltersLayersAndTags.styles";

type Props = {
  close: () => void;
  isAllMapMode: boolean;
  isPinned?: boolean;
  toggleIsPinned: () => void;
};

const GET_FILTERS = gql`
  query($campaign: String!) {
    prospect {
      filters(campaign: $campaign) {
        layers {
          id
          name
          type {
            id
            title
          }
          primaryColor
          secondaryColor
          addFade
          addTransition
          transitionTime
          fadeTime
        }
        layerTypes {
          id
          title
          color
        }
        users(status: ACTIVATED, campaign: $campaign) {
          id
          name
        }
        tags {
          id
          name
        }
      }
    }
  }
`;

const FiltersLayersAndTags: FC<Props> = ({
  close,
  isAllMapMode,
  isPinned = false,
  toggleIsPinned,
}) => {
  const [users, setUsers] = useState([]);
  const {
    selectedMaps,
    setMapFilters,
    isManagerView,
    filters: defaultFilters,
  } = useMap();

  const [layers, setLayers] = useState<ILayerFilter[]>(
    defaultFilters.layers ? defaultFilters.layers : []
  );
  const [layerTypes, setLayerTypes] = useState<ILayerTypeFilter[]>(
    defaultFilters.layerTypes ? defaultFilters.layerTypes : []
  );
  const [selectedUsers, setSelectedUsers] = useState<Value>(
    defaultFilters.selectedUsers ? defaultFilters.selectedUsers : []
  );
  const [isByRecentOrder, setIsByRecentOrder] = useState(
    defaultFilters.isByRecentOrder
  );
  const [dateRange, setDateRange] = useState<Date[]>(defaultFilters.dateRange);
  const [colorFilter, setColorFilter] = useState<Value>(
    defaultFilters.colorFilter
  );
  const [fadeOption, setFadeOption] = useState<Value>(
    defaultFilters.fadeOption
  );

  const [css] = useStyletron();

  const { loading } = useQuery(GET_FILTERS, {
    variables: {
      campaign: isAllMapMode ? "all" : selectedMaps[0].id,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.prospect?.filters) {
        const {
          prospect: {
            filters: { layers, users, layerTypes },
          },
        } = data;
        setUsers(users.map(({ name: label, id }: any) => ({ label, id })));
        setLayers(
          layers.map((layer: ILayer) => ({ ...layer, isSelected: true }))
        );
        setLayerTypes(
          layerTypes.map((layerType: ILayerType) => ({
            ...layerType,
            isSelected: true,
          }))
        );
      }
    },
  });

  const getColorFilterLabel = ({ option }: any) => {
    return (
      <LabelContainer>
        <ColorFilter background={option.filter} />
        {option.label}
      </LabelContainer>
    );
  };

  const getFadeOptionLabel = ({ option }: any) => {
    return (
      <LabelContainer>
        <FadeOption background={option.fade} />
        {option.label}
      </LabelContainer>
    );
  };

  const onLayerSelected = (id: string, isSelected: boolean) => {
    setLayers([
      ...layers.map((layer: ILayerFilter) => {
        if (id === layer.id) {
          return {
            ...layer,
            isSelected,
          };
        }
        return layer;
      }),
    ]);
  };

  const onLayerTypeSelected = (id: string, isSelected: boolean) => {
    setLayerTypes([
      ...layerTypes.map((layerType: ILayerTypeFilter) => {
        if (id === layerType.id) {
          return {
            ...layerType,
            isSelected,
          };
        }
        return layerType;
      }),
    ]);
  };

  const onSave = () => {
    if (dateRange && dateRange.length === 1) {
      toaster.negative("End Date is required.", {});
      return;
    }
    setMapFilters({
      layers,
      layerTypes,
      selectedUsers,
      isByRecentOrder,
      dateRange,
      colorFilter,
      fadeOption,
    });
  };

  return (
    <FilterPopup
      icon="layers"
      title="Filters, Tags &amp; Layers"
      close={close}
      isPinned={isPinned}
      action={
        <ActionButton isPinned={isPinned} onClick={toggleIsPinned}>
          <PinIcon width="15" height="15" fill="#7a7a7a" />
        </ActionButton>
      }
    >
      <div
        className={css({
          maxHeight: "calc(100vh - 170px)",
          overflowY: "scroll",
          "@media (min-width: 769px)": {
            maxHeight: `calc(100vh - ${isPinned ? 200 : 400}px)`,
          },
        })}
      >
        <Accordion
          overrides={{
            Content: {
              style: () => ({
                backgroundColor: "#fff",
              }),
            },
          }}
        >
          <Panel
            title={
              <SectionHeader>
                <SectionHeaderIcon icon="filter" />
                Filters
              </SectionHeader>
            }
          >
            {!isManagerView && (
              <>
                <SectionGroup>
                  <SectionGroupInner>
                    <SectionGroupInnerIcon icon="profile" />
                    <Select
                      closeOnSelect={false}
                      options={users}
                      value={selectedUsers}
                      multi
                      isLoading={loading}
                      placeholder="Select users"
                      onChange={(params) => setSelectedUsers(params.value)}
                    />
                  </SectionGroupInner>
                </SectionGroup>
                <Hr />
              </>
            )}
            <SectionGroup>
              <SectionGroupTitle>Date Range</SectionGroupTitle>
              <SectionGroupInner>
                <SectionGroupInnerIcon icon="calendar" />
                <DatePicker
                  formatString="dd/MM/yyyy"
                  placeholder="DD/MM/YYYY"
                  value={dateRange}
                  onChange={({ date }) =>
                    setDateRange(Array.isArray(date) ? date : [date])
                  }
                  range
                  size={SIZE.compact}
                  overrides={{
                    Input: {
                      props: {
                        overrides: {
                          Root: {
                            style: () => ({
                              boxShadow: "0 3px 6px #00000016",
                              borderBottomWidth: 0,
                              borderTopWidth: 0,
                              borderLeftWidth: 0,
                              borderRightWidth: 0,
                            }),
                          },
                          Input: {
                            style: () => ({
                              backgroundColor: "#fff",
                            }),
                          },
                          Day: {
                            style: {
                              "&:after": {
                                borderTopColor: "#00AEEF",
                                borderBottomColor: "#00AEEF",
                                borderLeftColor: "#00AEEF",
                                borderRightColor: "#00AEEF",
                              },
                            },
                          },
                        },
                      },
                    },
                  }}
                />
              </SectionGroupInner>
              {!isManagerView && !isAllMapMode && (
                <>
                  <SelectContainer>
                    {colorFilter.length > 0 && (
                      <ColorFilter background={colorFilter[0].filter} />
                    )}
                    <Select
                      closeOnSelect={true}
                      options={COLOR_FILTER_LIST}
                      value={colorFilter}
                      placeholder="Select color filter"
                      onChange={(params) => setColorFilter(params.value)}
                      clearable={false}
                      getOptionLabel={getColorFilterLabel}
                    />
                  </SelectContainer>
                  <Hr />
                  <SelectContainer>
                    {fadeOption.length > 0 && (
                      <FadeOption background={fadeOption[0].fade} />
                    )}
                    <Select
                      closeOnSelect={true}
                      clearable={false}
                      options={FADE_OPTIONS_LIST}
                      value={fadeOption}
                      placeholder="Select fade option"
                      onChange={(params) => setFadeOption(params.value)}
                      getOptionLabel={getFadeOptionLabel}
                    />
                  </SelectContainer>
                </>
              )}
            </SectionGroup>
          </Panel>
          <StatefulPanel
            title={
              <SectionHeader>
                <SectionHeaderIcon icon="layers" />
                Layers/Activity
              </SectionHeader>
            }
            initialState={{ expanded: isPinned }}
          >
            {!isManagerView && (
              <LayerHeader>
                <SectionGroupInner>
                  Layer Order
                  <Toggle
                    checked={isByRecentOrder}
                    onChange={(e: any) => setIsByRecentOrder(e.target.checked)}
                  />
                  Most Recent
                </SectionGroupInner>
              </LayerHeader>
            )}
            {loading && <Spinner $color="#00AEEF" />}
            <FilterLayerList
              layerTypes={layerTypes}
              layers={layers}
              setLayerTypes={setLayerTypes}
              setLayers={setLayers}
              onLayerTypeSelected={onLayerTypeSelected}
              onLayerSelected={onLayerSelected}
              isAllMapMode={isAllMapMode}
            />
          </StatefulPanel>
        </Accordion>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <Button
          kind={KIND.secondary}
          size={SIZE.compact}
          onClick={() => close()}
          overrides={{
            BaseButton: {
              style: () => ({
                width: "100%",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
                marginRight: "5px",
              }),
            },
          }}
        >
          Cancel
        </Button>
        <Button
          size={SIZE.compact}
          onClick={() => onSave()}
          overrides={{
            BaseButton: {
              style: () => ({
                width: "100%",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
                marginLeft: "5px",
              }),
            },
          }}
        >
          Save
        </Button>
      </div>
    </FilterPopup>
  );
};

export default FiltersLayersAndTags;
