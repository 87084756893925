import React, { forwardRef } from "react";
import { Input, InputProps } from "baseui/input";
import { Error } from "./styles";
import { FullWidth } from "styles";

type Props = InputProps & {
  error?: any;
  maxTextLength?: number;
};

const BaseInput = forwardRef<HTMLInputElement, Props>(
  ({ error, maxTextLength, ...rest }, ref) => {
    const getErrors = (error: any) => {
      if (!error) return null;
      if (typeof error === "string") {
        return <Error>{error}</Error>;
      }
      switch (error.type) {
        case "required":
          return <Error>This field is required.</Error>;
        case "maxLength":
          return (
            <Error>
              {maxTextLength
                ? `Maximum ${maxTextLength} characters.`
                : "Max length exceeded."}
            </Error>
          );
        case "minLength":
          return <Error>Min length not met.</Error>;
        default:
          return <Error>{error.message}</Error>;
      }
    };
    const hasErrors = error !== null && error !== undefined;
    return (
      <FullWidth>
        <Input inputRef={ref} {...rest} error={hasErrors} />
        {getErrors(error)}
      </FullWidth>
    );
  }
);

export default BaseInput;
