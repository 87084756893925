import React, { useEffect, useState } from "react";
import { styled, useStyletron } from "baseui";
import { MapStatus } from "constants/campaign";
import { Select, Value } from "baseui/select";

const StatusButton = styled("button", (props: { active: boolean }) => ({
  background: "transparent",
  border: "none",
  color: props.active ? "#00aeef" : "#70707075",
  cursor: "pointer",
  padding: "5px 20px 7px",
  position: "relative",
  ":after": {
    background: props.active ? "#f99c1c" : "transparent",
    bottom: "0",
    width: "6px",
    height: "6px",
    color: props.active ? "#f99c1c" : "transparent",
    content: '"',
    left: "calc(50% - 3px)",
    position: "absolute",
    borderRadius: "50%",
    overflow: "hidden",
  },
}));

type Props = {
  onStatusChange: Function;
  status: null | MapStatus;
};

const MapFilters: React.FC<Props> = ({ onStatusChange, status }) => {
  const [css] = useStyletron();
  const [value, setValue] = useState<Value>([]);

  const mapStatusList = [
    { id: MapStatus.ALL, label: "All" },
    { id: MapStatus.ACTIVE, label: "Active" },
    { id: MapStatus.DRAFT, label: "Draft" },
    { id: MapStatus.ARCHIVE, label: "Archive" },
  ];

  useEffect(() => {
    const mapStatus = status || MapStatus.ALL;
    const found = mapStatusList.find(({ id }) => id === mapStatus);
    if (found) {
      setValue([found]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const onStatusChangeHandler = (value: Value) => {
    if (value[0].id) {
      onStatusChange(value[0].id === MapStatus.ALL ? null : value[0].id);
    }
  };

  return (
    <>
      <div
        className={css({ "@media (max-width: 768px)": { display: "none" } })}
      >
        <StatusButton
          onClick={() => onStatusChange(null)}
          active={status === null}
        >
          All
        </StatusButton>
        <StatusButton
          onClick={() => onStatusChange(MapStatus.ACTIVE)}
          active={status === MapStatus.ACTIVE}
        >
          Active
        </StatusButton>
        <StatusButton
          onClick={() => onStatusChange(MapStatus.DRAFT)}
          active={status === MapStatus.DRAFT}
        >
          Draft
        </StatusButton>
        <StatusButton
          onClick={() => onStatusChange(MapStatus.ARCHIVE)}
          active={status === MapStatus.ARCHIVE}
        >
          Archive
        </StatusButton>
      </div>
      <Select
        options={mapStatusList}
        value={value}
        onChange={({ value }) => onStatusChangeHandler(value)}
        backspaceRemoves={false}
        clearable={false}
        deleteRemoves={false}
        escapeClearsValue={false}
        searchable={false}
        overrides={{
          Root: {
            style: {
              "@media (min-width: 769px)": { display: "none" },
            },
          },
        }}
      />
    </>
  );
};

export default MapFilters;
