import React, { FC, useEffect, useState } from "react";
import { OnChangeParams, SelectProps, Value } from "baseui/select";
import Select from "./select";
import { Error } from "components/base-input/styles";
import { useStyletron } from "baseui";

interface IProps
  extends Pick<
    SelectProps,
    Exclude<keyof SelectProps, "onChange" | "value" | "options">
  > {
  value?: string | null;
  onChange: (value: any) => void;
  options: Value;
  width?: string;
}

const SelectWithStyling: FC<IProps> = ({
  value: initialValue,
  onChange,
  error,
  width = "100%",
  ...props
}) => {
  const [value, setValue] = useState<Value>([]);
  const [css] = useStyletron();
  useEffect(() => {
    if (initialValue !== value?.[0]?.id)
      setValue(props.options.filter(({ id }: any) => id === initialValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const onValueChange = (params: OnChangeParams) => {
    setValue(params.value);
    onChange(params.value?.[0]?.id);
  };

  const hasErrors = error !== null && error !== undefined;

  return (
    <div className={css({ width })}>
      <Select
        {...props}
        value={value}
        onChange={onValueChange}
        clearable={false}
        error={hasErrors}
      />
      {error && <Error>{error}</Error>}
    </div>
  );
};

export default SelectWithStyling;
