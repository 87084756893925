import React, { useState } from "react";
import { Icon } from "@virtualcapital/utogi-ui-library";
import { useMutation } from "@apollo/client";
import { isValidEmail } from "helpers/validations";
import gql from "graphql-tag";
import { toaster } from "baseui/toast";

import occupancyImage from "assets/images/occupancy.svg";

import Button from "components/Button";
import Input from "components/base-input/base-input";
import Select from "components/SingleSelect";

import { event } from "../../../events";
import { ON_PROPERTY_CONTACT_ADD_SUCCESS } from "../../../events/prospect";

import "./EditPropertyContact.scss";
import SaveButton from "components/SaveButton";
import { KIND } from "baseui/button";

const EditPropertyContact = ({
  onSuccess,
  formReset,
  selectedUser,
  property,
  unit,
}: any) => {
  const [values, changeValues] = useState(selectedUser);

  const [errors, setErrors] = useState<any>({
    firstName: null,
    lastName: null,
    displayName: null,
    mobileNumber: null,
    email: null,
  });

  const UPDATE_CONTACT_MUTATION = gql`
    mutation updateContact(
      $id: ID
      $landline: String
      $mobileNumber: String
      $email: String
      $firstName: String
      $lastName: String
      $displayName: String
      $occupancyStatus: String
      $property: GraphQLCreatePropertyContactInput
      $honorific: String
    ) {
      contact(id: $id) {
        update(
          landline: $landline
          mobileNumber: $mobileNumber
          email: $email
          firstName: $firstName
          lastName: $lastName
          displayName: $displayName
          property: $property
          occupancyStatus: $occupancyStatus
          honorific: $honorific
        ) {
          id
          displayName
          lastName
          firstName
          mobileNumber
          landline
          email
          occupancyStatus
        }
      }
    }
  `;

  const [updateContact, { loading }] = useMutation(UPDATE_CONTACT_MUTATION, {
    onError: () => {
      toaster.negative("Error occurred while updating contact.", {});
    },
    onCompleted: () => {
      toaster.positive("Contact updated successfully!", {});
      formReset();
      event.dispatch(ON_PROPERTY_CONTACT_ADD_SUCCESS);
      onSuccess();
    },
  });

  const handleFormSubmit = () => {
    let hasErrors = false;
    const error: any = {
      firstName: null,
      lastName: null,
      mobileNumber: null,
      email: null,
    };
    const {
      firstName,
      lastName,
      displayName,
      mobileNumber,
      email,
      landline,
      occupancyStatus,
      honorific,
    } = values;

    if (!firstName || firstName === "") {
      hasErrors = true;
      error.firstName = "First name is required";
    }
    if (email && !isValidEmail(email)) {
      hasErrors = true;
      error.email = "Email is invalid";
    }

    setErrors(error);

    if (hasErrors) return;

    updateContact({
      variables: {
        id: selectedUser.id,
        property: { property, unit },
        occupancyStatus,
        firstName,
        lastName,
        displayName: displayName
          ? displayName
          : `${firstName} ${lastName || ""}`,
        mobileNumber,
        landline,
        honorific,
        email,
      },
    });
  };

  return (
    <div className="edit-property-contact">
      <ul className="people-tab-content-details">
        <li>
          <Icon className="people-tab-content-details-icon" icon="profile" />
          <Select
            placeholder="Select Title"
            options={[
              { label: "Mr", id: "Mr" },
              { label: "Mrs", id: "Mrs" },
              { label: "Miss", id: "Miss" },
              { label: "Dr", id: "Dr" },
              { label: "Mx", id: "Mx" },
            ]}
            value={values.honorific}
            onChange={(honorific: any) =>
              changeValues({ ...values, honorific })
            }
          />
        </li>

        <li>
          <Icon className="people-tab-content-details-icon" icon="profile" />{" "}
          <Input
            value={values.firstName}
            placeholder="First Name"
            onChange={(firstName: any) =>
              changeValues({ ...values, firstName })
            }
            error={errors.firstName}
          />
        </li>
        <li>
          <Icon className="people-tab-content-details-icon" icon="profile" />{" "}
          <Input
            value={values.lastName}
            placeholder="Last Name"
            onChange={(lastName: any) => changeValues({ ...values, lastName })}
            error={errors.lastName}
          />
        </li>
        <li>
          <Icon className="people-tab-content-details-icon" icon="profile" />{" "}
          <Input
            value={values.displayName}
            placeholder="Display Name"
            onChange={(displayName: any) =>
              changeValues({ ...values, displayName })
            }
            error={errors.displayName}
          />
        </li>
        <li>
          <Icon className="people-tab-content-details-icon" icon="phone" />{" "}
          <Input
            value={values.mobileNumber}
            placeholder="Mobile Number"
            onChange={(mobileNumber: any) =>
              changeValues({ ...values, mobileNumber })
            }
            error={errors.mobileNumber}
          />
        </li>
        <li>
          <Icon className="people-tab-content-details-icon" icon="call" />{" "}
          <Input
            value={values.landline}
            placeholder="Landline"
            onChange={(landline: any) => changeValues({ ...values, landline })}
            error={errors.landline}
          />
        </li>
        <li>
          <Icon className="people-tab-content-details-icon" icon="envelop" />{" "}
          <Input
            value={values.email}
            placeholder="Email"
            onChange={(email: any) => changeValues({ ...values, email })}
            error={errors.email}
          />
        </li>

        <li>
          <img src={occupancyImage} alt="occupancy" />
          <Select
            error={errors.occupancyStatus}
            placeholder="Select Occupancy Status"
            options={[
              { label: "Owner (Occupied)", id: "owner-occupied" },
              { label: "Owner (Rental)", id: "owner-rental" },
              { label: "Owner (Vacant)", id: "owner-vacant" },
              { label: "Owner (Holiday Home)", id: "owner-holiday-home" },
              { label: "Tenant", id: "tenant" },
            ]}
            value={values.occupancyStatus}
            onChange={(occupancyStatus: any) =>
              changeValues({ ...values, occupancyStatus })
            }
          />
        </li>
      </ul>
      <div>
        <Button
          onClick={formReset}
          overrides={{ BaseButton: { style: { marginRight: "15px" } } }}
          kind={KIND.tertiary}
        >
          Cancel
        </Button>
        <SaveButton onClick={handleFormSubmit} isLoading={loading} />
      </div>
    </div>
  );
};

export default EditPropertyContact;
