import { gql } from "@apollo/client";

export const GET_MODULE_SETTINGS = gql`
  query office($ids: [ID]) {
    office(ids: $ids) {
      id
      status
      mapsModuleSettings {
        IS_TAGS_SHARED_WITH_COMPANY
        IS_TAGS_SHARED_WITH_OFFICE
        IS_TAGS_SHARED
      }
    }
  }
`;

export const UPDATE_MODULE_SETTINGS = gql`
  mutation updateSettings(
    $IS_TAGS_SHARED_WITH_COMPANY: Boolean!
    $IS_TAGS_SHARED_WITH_OFFICE: Boolean!
    $IS_TAGS_SHARED: Boolean!
  ) {
    office {
      updateMapsModuleSettings(
        IS_TAGS_SHARED: $IS_TAGS_SHARED
        IS_TAGS_SHARED_WITH_OFFICE: $IS_TAGS_SHARED_WITH_OFFICE
        IS_TAGS_SHARED_WITH_COMPANY: $IS_TAGS_SHARED_WITH_COMPANY
      ) {
        IS_TAGS_SHARED_WITH_COMPANY
        IS_TAGS_SHARED_WITH_OFFICE
        IS_TAGS_SHARED
      }
    }
  }
`;
