import React from "react";
import { useMutation, gql, ApolloError } from "@apollo/client";
import { ConfirmAlert } from "@virtualcapital/utogi-ui-library";
import { toaster } from "baseui/toast";
import { Pagination, SIZE } from "baseui/pagination";
import { useStyletron } from "baseui";

import CampaignTemplateListItem from "components/campaignTemplate/campaignTemplateListItem";
import WithScrollbar from "helpers/WithScrollbar";

const DELETE_MUTATION = gql`
  mutation deleteCampaignTemplate($id: ID!) {
    campaignTemplate {
      delete(id: $id)
    }
  }
`;

const CampaignTemplateList = ({
  templates,
  pagination,
  gotoNextPage,
  refetchTemplate,
}: any) => {
  const [css] = useStyletron();

  const [deleteCampaignTemplate] = useMutation(DELETE_MUTATION, {
    onError: ({ graphQLErrors }: ApolloError) => {
      graphQLErrors.map(({ message }) => toaster.negative(message, {}));
    },
    onCompleted: () => {
      toaster.positive("Campaign template deleted successfully", {});
      refetchTemplate();
    },
  });

  return (
    <>
      <WithScrollbar noScrollX height="calc(100vh - 380px)">
        {templates.map((template: any) => (
          <CampaignTemplateListItem
            key={template.id}
            {...template}
            deleteClicked={(id: string) => {
              ConfirmAlert({
                closeOnClickOutside: false,
                message: "Are you sure you want to delete this template?",
                onCancel: () => {},
                onConfirm: () => {
                  deleteCampaignTemplate({
                    variables: { id },
                  });
                },
              });
            }}
          />
        ))}
      </WithScrollbar>
      <div className={css({ display: "flex" })}>
        <Pagination
          size={SIZE.mini}
          numPages={pagination.total}
          currentPage={pagination.page}
          onPageChange={({ nextPage }) => {
            gotoNextPage(Math.min(Math.max(nextPage, 1), pagination.total));
          }}
          overrides={{ Root: { style: { margin: "0 auto" } } }}
        />
      </div>
    </>
  );
};

export default CampaignTemplateList;
