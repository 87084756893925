import React, { useState } from "react";

import Table from "components/map/table";

import { MapStatus } from "../../constants/campaign";

import { MapUserType } from "../../types/map";

export type MapListItem = {
  id: string;
  name: string;
  status: MapStatus;
  createdBy: string;
  createdUser: string;
  users: MapUserType[];
};

type Props = {
  refetch: Function;
  data: any;
};

const MapList: React.FC<Props> = ({ refetch, data }) => {
  const [selected, onSelectChange] = useState<string[]>([]);

  const isItemSelected = (id: string): boolean =>
    selected.filter((mapId) => mapId === id).length > 0;

  const selectItem = (map: string) => {
    if (isItemSelected(map)) {
      onSelectChange((maps: string[]) => maps.filter((id) => id !== map));
    } else {
      onSelectChange((maps: string[]) => [...maps, map]);
    }
  };

  const {
    map: {
      campaign: {
        list: { campaigns, pagination },
      },
    },
  } = data;

  const list: MapListItem[] = campaigns.map(
    ({
      id,
      name,
      status,
      createdUser: { name: createdBy, id: createdUser },
      users,
    }: any) => ({
      id,
      name,
      status,
      createdBy,
      createdUser,
      users,
    })
  );

  const isAllSelected = selected.length === list.length;

  const selectAll = () => {
    if (isAllSelected) {
      onSelectChange([]);
    } else {
      onSelectChange(list.map(({ id }) => id));
    }
    return;
  };

  return (
    <Table
      list={list}
      pagination={pagination}
      fetchMaps={refetch}
      selectMap={selectItem}
      isMapSelected={isItemSelected}
      selectAll={selectAll}
      isAllSelected={isAllSelected}
    />
  );
};

export default MapList;
